import { Checkbox, Modal, Status, ToastContext } from '@cointracker/styleguide';
import { Body3, Button } from '@cointracker/styleguide/src/LoggedIn';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatWalletLabel } from 'src/common/wallet-address';
import { useAddPublicAddressMutation } from 'src/types/graphql-types';
import { AddWalletContext } from '../WalletsHeader/AddWalletButton/AddWalletModal/context';
import { SelectChainCard } from './SelectChainCard';
import { SUPPORTED_EVM_CHAIN_SLUGS } from './constants';
import { useSuggestedWalletsModalStore } from './store';

export const SuggestedWalletsModal = () => {
  const { integrations } = useContext(AddWalletContext);

  const {
    address,
    integration: selectedIntegration,
    closeModal,
    isModalOpen,
  } = useSuggestedWalletsModalStore();
  const [selectedEVMChains, setSelectedEVMChains] = useState<string[]>([]);

  const { showNotification } = useContext(ToastContext);
  const [mutateAddPublicAddress] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
          delay: 5000,
        });
      } else {
        if (
          data?.addPublicAddress?.error ||
          data?.addPublicAddress?.multiAddError
        ) {
          showNotification({
            message:
              data?.addPublicAddress?.error ??
              data?.addPublicAddress?.multiAddError,
            status: Status.Error,
            delay: 5000,
          });
        } else if (data?.addPublicAddress?.multiAddWarning) {
          showNotification({
            message: data?.addPublicAddress?.multiAddWarning,
            status: Status.Info,
            delay: 5000,
          });
        } else {
          showNotification({
            message:
              'Something went wrong when adding your additional wallets. Please try again later.',
            status: Status.Error,
            delay: 5000,
          });
        }
      }
      closeModal();
    },
  });

  const getSupportedEvmChains = useCallback(() => {
    return integrations
      .filter((integration) => integration?.info?.isEvmChain)
      .filter(
        (integration) =>
          selectedIntegration != null &&
          selectedIntegration.slug != integration.slug,
      )
      .filter((integration) =>
        SUPPORTED_EVM_CHAIN_SLUGS.includes(integration.slug),
      );
  }, [integrations, selectedIntegration]);
  useEffect(() => {
    setSelectedEVMChains([]);
  }, [selectedIntegration]);

  const supportedEvmChains = getSupportedEvmChains();

  return (
    <Modal.Root open={isModalOpen} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.ContentHeader title="Add additional wallets" />
        <Modal.ContentBody>
          <Body3 className="text-text-secondary">
            {`We've detected ${supportedEvmChains.length} more wallets related to ${formatWalletLabel(
              address ?? '',
            )}. Select the ones you want to add.`}
          </Body3>
          <div className="my-16 flex gap-10">
            <Checkbox
              id="add-evm-wallets"
              name="add-evm-wallets"
              value="add-evm-wallets"
              label="Select all chains"
              checked={selectedEVMChains.length === supportedEvmChains.length}
              onCheckedChange={(checked) => {
                if (checked) {
                  setSelectedEVMChains(
                    supportedEvmChains.map((chain) => chain.slug),
                  );
                } else {
                  setSelectedEVMChains([]);
                }
              }}
            />
          </div>
          <div className="grid grid-cols-2 justify-between gap-16 pt-10">
            {supportedEvmChains
              .filter((c) => c.slug !== selectedIntegration.slug)
              .map((chain) => (
                <SelectChainCard
                  key={chain.slug}
                  name={chain.name}
                  value={chain.slug}
                  selected={selectedEVMChains.includes(chain.slug)}
                  onChange={(value) => {
                    if (value) {
                      setSelectedEVMChains([...selectedEVMChains, chain.slug]);
                    } else {
                      setSelectedEVMChains(
                        selectedEVMChains.filter((slug) => slug !== chain.slug),
                      );
                    }
                  }}
                  imageUrl={chain.imageUrl}
                />
              ))}
          </div>
        </Modal.ContentBody>
        <Modal.ContentFooter className="flex flex-col gap-8">
          <Button
            fluid
            size="small"
            onClick={async () => {
              const chains = selectedEVMChains.map((chain) => {
                const chainIntegration = integrations.find(
                  (integration) => integration.slug === chain,
                );
                return chainIntegration?.info.cryptoNetwork;
              });
              mutateAddPublicAddress({
                variables: {
                  publicAddressInput: {
                    coinSymbol: selectedIntegration.info.symbol,
                    cryptoNetwork: selectedIntegration.info.cryptoNetwork,
                    slug: selectedIntegration.slug,
                    walletAddress: address,
                    cryptoNetworksToAdd: chains,
                  },
                },
              });
            }}
            disabled={selectedEVMChains.length === 0}
          >
            Add {selectedEVMChains.length} wallets
          </Button>
          <Button onClick={closeModal} size="small" fluid variant="line">
            Skip
          </Button>
        </Modal.ContentFooter>
      </Modal.Content>
    </Modal.Root>
  );
};
