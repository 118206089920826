import classNames from 'classnames';

import { forwardRef, type Ref } from 'react';
import { Grid } from '../Grid';
import { type ChildAndClassNameProps } from '../types';
import { Body1, H2 } from '../typography';
import {
  container,
  containerOuter,
  styledSubtitle,
  styledTitle,
  textContainer,
} from './PricingHeader.css';

export const PricingCopyContainer = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={classNames(textContainer, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

PricingCopyContainer.displayName = 'PricingCopyContainer';

export const PricingHeaderContainer = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={classNames(containerOuter, className)}>
        <Grid className={classNames(container)} ref={ref} {...rest}>
          {children}
        </Grid>
      </div>
    );
  },
);

PricingHeaderContainer.displayName = 'PricingHeaderContainer';

export const PricingSubtitle = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <Body1
        className={classNames(styledSubtitle, className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Body1>
    );
  },
);

PricingSubtitle.displayName = 'PricingSubtitle';

export const PricingTitle = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <H2 className={classNames(styledTitle, className)} ref={ref} {...rest}>
        {children}
      </H2>
    );
  },
);

PricingTitle.displayName = 'PricingTitle';
