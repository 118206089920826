import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { cn } from '../../utils';
import {
  buttonTextVariants,
  navButtonTextVariants,
  navLinkTextVariants,
  type ButtonTextVariants,
  type NavButtonTextVariants,
  type NavLinkTextVariants,
} from './variants';

export interface NavLinkTextProps
  extends HTMLAttributes<HTMLSpanElement>,
    NavLinkTextVariants {
  asChild?: boolean;
}

export const NavLinkText = forwardRef<HTMLSpanElement, NavLinkTextProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'span';
    return (
      <Comp
        ref={ref}
        className={cn(navLinkTextVariants({ className, variant, size }))}
        {...props}
      />
    );
  },
);
NavLinkText.displayName = 'NavLinkText';

export interface NavButtonTextProps
  extends HTMLAttributes<HTMLSpanElement>,
    NavButtonTextVariants {
  asChild?: boolean;
}

export const NavButtonText = forwardRef<HTMLSpanElement, NavButtonTextProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'span';
    return (
      <Comp
        ref={ref}
        className={cn(navButtonTextVariants({ className, variant, size }))}
        {...props}
      />
    );
  },
);
NavButtonText.displayName = 'NavButtonText';

export interface ButtonTextProps
  extends HTMLAttributes<HTMLSpanElement>,
    ButtonTextVariants {
  asChild?: boolean;
}

export const ButtonText = forwardRef<HTMLSpanElement, ButtonTextProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'span';
    return (
      <Comp
        ref={ref}
        className={cn(buttonTextVariants({ className, variant, size }))}
        {...props}
      />
    );
  },
);
ButtonText.displayName = 'ButtonText';
