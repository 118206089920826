import { Integration } from '@cointracker/graphql-types';
import { cn, Combobox } from '@cointracker/styleguide';
import { Body2, Body4 } from '@cointracker/styleguide/src/LoggedIn';
import { Avatar } from '@cointracker/styleguide/src/LoggedIn/Avatar';
import { bodyVariants } from '@cointracker/styleguide/src/LoggedIn/Typography/variants';
import { useCallback } from 'react';

interface CryptoSelectProps {
  cryptoIntegrations: Integration[];
  selectedCrypto?: Integration;
  setSelectedCrypto?: React.Dispatch<React.SetStateAction<Integration>>;
  error?: string;
}

export const CryptoSelect = ({
  cryptoIntegrations,
  setSelectedCrypto,
  selectedCrypto,
  error,
}: CryptoSelectProps) => {
  const onValueChange = useCallback(
    (integrationSlug: string) => {
      const cryptoIntegration = cryptoIntegrations?.find(
        (integration) => integration.slug === integrationSlug,
      );
      setSelectedCrypto(cryptoIntegration || null);
    },
    [cryptoIntegrations, setSelectedCrypto],
  );

  const items = cryptoIntegrations?.map((integration) => ({
    value: integration.slug,
    label: (
      <div className="flex items-center gap-8">
        <Avatar
          src={integration.imageUrl}
          alt={`Icon of ${integration.name}`}
          fallbackText={integration.slug}
          size="tiny"
        />
        <Body2>{integration.name}</Body2>
      </div>
    ),
  }));

  return (
    <div>
      <label
        htmlFor="blockchain-select"
        className={cn(
          bodyVariants({ variant: 'body4', weight: 'bold' }),
          'mb-space-8',
        )}
      >
        Blockchain
      </label>
      <Combobox
        id="blockchain-select"
        items={items}
        placeholder={'Select your blockchain'}
        emptyMessage="No currencies found"
        onValueChange={onValueChange}
        defaultValue={selectedCrypto?.slug}
        hasError={error && error.length > 0}
      />
      {error && error.length > 0 && (
        <Body4 className="mt-space-8 text-text-negative">{error}</Body4>
      )}
    </div>
  );
};
