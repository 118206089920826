import { isEmpty } from 'lodash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Integration } from 'src/types/graphql-types';
import { AddWalletModal } from '.';
import { SuggestedWalletsModal } from '../../../SuggestedWalletsModal';
import useGetIntegrations from '../hooks/useGetIntegrations';
import { AddWalletContext, defaultOnSuccess } from './context';
import { onOpenAddWalletModalProps, onSuccessType } from './types';
import {
  getDataFromURL,
  getIntegrationNameParamFromURL,
  getSelectedIntegration,
} from './utils';

interface AddWalletFlowProviderProps {
  children: ReactNode | ReactNode[];
}

export const AddWalletFlowProvider = ({
  children,
}: AddWalletFlowProviderProps) => {
  const integrationNameFromURL = getIntegrationNameParamFromURL();
  const { fetchIntegrations, loading, integrations } = useGetIntegrations({
    onCompleted: (data) => {
      if (data.integrations) {
        // we should preselect the integration defined by the URL after finishing the integrations fetching
        const integration = getSelectedIntegration(
          data.integrations,
          integrationNameFromURL,
        );
        setSelectedIntegration(integration);
      }
    },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();
  const location = useLocation();
  const navigate = useNavigate();

  const isOnboardingPage = location.pathname.includes('onboarding');

  const [onSuccess, setOnSuccess] = useState<onSuccessType>(() =>
    defaultOnSuccess(navigate),
  );

  const onClose = useCallback(() => {
    // Important: onClose __needs__ to use the window.location instead of the react-router-dom location.
    //            There is a race condition between the onSuccess function navigating away and the useLocation
    //            to return the new location back. In order to avoid that race condition we use the window which is
    //            showing the correct location
    const url = new URL(
      window.location.pathname + window.location.search,
      window.location.origin,
    );
    url.searchParams.delete('add_integration');
    url.searchParams.delete('public_keys');

    window.history.pushState('', '', url.toString());

    setSelectedIntegration(null);
    // setting the isOpen state late in the onClose callback helps with a bug where the wallets modal would not close
    // properly when clicking quickly.
    // https://cointracker-io.slack.com/archives/C5G5DFWLE/p1689742913160429?thread_ts=1689161852.559679&cid=C5G5DFWLE
    setIsOpen(false);
  }, []);

  const setIntegrationFromURL = useCallback(() => {
    // if the add_integration query param exists, set the selected integration
    if (integrationNameFromURL !== null) {
      const integration = getSelectedIntegration(
        integrations,
        integrationNameFromURL,
      );
      setSelectedIntegration(integration);
    } else {
      setSelectedIntegration(null);
      const url = new URL(
        location.pathname + location.search,
        window.location.origin,
      );
      url.searchParams.set('add_integration', '');
      window.history.pushState('', '', url.toString());
    }
  }, [location, integrations, integrationNameFromURL]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    setIntegrationFromURL();
  }, [setIntegrationFromURL]);

  const onSelectIntegration = useCallback(
    (integration: Integration) => {
      setSelectedIntegration(integration);
      const url = new URL(
        location.pathname + location.search,
        window.location.origin,
      );
      const slug = integration?.slug?.toLowerCase();
      const category = integration?.category?.toLowerCase();
      let searchParams = '';
      if (integration) {
        searchParams += slug;
      }
      if (category) {
        searchParams += `_${category}`;
      }
      // add_integration param includes the integration slug followed by _ and the category to avoid collisions for integrations with the same slug
      url.searchParams.set('add_integration', searchParams);
      window.history.pushState('', '', url.toString());
    },
    [setSelectedIntegration, location],
  );

  const metadata = {
    publicKeys: getDataFromURL(),
  };

  const onOpenAddWalletModal = useCallback(
    ({ onSuccess }: onOpenAddWalletModalProps = {}) => {
      if (isOnboardingPage) {
        setIntegrationFromURL();
        return;
      }
      if (onSuccess) {
        setOnSuccess(() => onSuccess);
      } else {
        // Since the AddWalletModal is a singleton if we don't replace with the default onSuccess when opening a new
        // modal then if a component changes the behaviour it will affect all pages and components that rely
        // on the AddWalletModal.
        setOnSuccess(() => defaultOnSuccess(navigate));
      }
      if (!loading && isEmpty(integrations)) {
        fetchIntegrations();
      }
      onOpen();
    },
    [
      fetchIntegrations,
      onOpen,
      integrations,
      loading,
      isOnboardingPage,
      setIntegrationFromURL,
      navigate,
    ],
  );

  useEffect(() => {
    if (isOpen) {
      return;
    }
    // open the modal if the add_integration query param is present
    if (
      integrationNameFromURL === '' ||
      (!selectedIntegration && integrationNameFromURL !== null)
    ) {
      onOpenAddWalletModal();
    }
  }, [
    loading,
    integrations,
    selectedIntegration,
    onOpenAddWalletModal,
    integrationNameFromURL,
    isOpen,
  ]);

  const setOnSuccessCallback = useCallback((onSuccess: onSuccessType) => {
    setOnSuccess(onSuccess);
  }, []);

  return (
    <AddWalletContext.Provider
      value={{
        onOpenAddWalletModal: onOpenAddWalletModal,
        onCloseAddWalletModal: onClose,
        selectedIntegration: selectedIntegration,
        isModalOpen: isOpen,
        metadata,
        onSelectIntegration,
        integrations,
        loading,
        onSuccess,
        setOnSuccess: setOnSuccessCallback,
        refetch: fetchIntegrations,
        setIsHidden,
      }}
    >
      {children}
      <AddWalletModal
        loading={loading}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        onHide={onClose}
        integrations={integrations}
        selectedIntegration={selectedIntegration}
        setSelectedIntegration={onSelectIntegration}
        isHidden={isHidden}
      />
      <SuggestedWalletsModal />
    </AddWalletContext.Provider>
  );
};
