import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { cn } from '../../utils';
import { bodyVariants, type BodyVariants } from './variants';

export interface BodyProps
  extends HTMLAttributes<HTMLParagraphElement>,
    BodyVariants {
  asChild?: boolean;
}

export const Body = forwardRef<HTMLParagraphElement, BodyProps>(
  ({ className, variant, caps, weight, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';
    return (
      <Comp
        ref={ref}
        className={cn(bodyVariants({ className, variant, caps, weight }))}
        {...props}
      />
    );
  },
);
Body.displayName = 'Body';

export const Body1 = forwardRef<HTMLParagraphElement, BodyProps>(
  (props, ref) => <Body ref={ref} variant="body1" {...props} />,
);
Body1.displayName = 'Body1';

export const Body2 = forwardRef<HTMLParagraphElement, BodyProps>(
  (props, ref) => <Body ref={ref} variant="body2" {...props} />,
);

Body2.displayName = 'Body2';

export const Body3 = forwardRef<HTMLParagraphElement, BodyProps>(
  (props, ref) => <Body ref={ref} variant="body3" {...props} />,
);

Body3.displayName = 'Body3';

export const Body4 = forwardRef<HTMLParagraphElement, BodyProps>(
  (props, ref) => <Body ref={ref} variant="body4" {...props} />,
);

Body4.displayName = 'Body4';

export const Body5 = forwardRef<HTMLParagraphElement, BodyProps>(
  (props, ref) => <Body ref={ref} variant="body5" {...props} />,
);

Body5.displayName = 'Body5';
