// GraphQL Mutation to generate Cello token
import { gql } from '@apollo/client';

// Mutation to fetch Cello token: it's a mutation and not a query
// because a query should be idempotent; while the token is generated every time the function is called
// and may not be the same every time.
export const GENERATE_CELLO_TOKEN = gql`
  mutation GenerateCelloToken {
    generateCelloToken {
      success
      celloToken
    }
  }
`;
