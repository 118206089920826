import {
  InlineBanner,
  InlineBannerContent,
  Modal,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Info } from '@phosphor-icons/react';

import { isEmpty } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import {
  ExchangeMutationErrors,
  GetWalletsQuery,
  Integration,
  useAddExchangeWithApiKeyMutation,
  useUpdateExchangeWithApiKeyMutation,
} from 'src/types/graphql-types';

import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import { AddWalletContext } from '../../context';
import { CoverageInfoContainer } from '../CoverageInfoContainer';
import { Instructions } from '../Instructions';
import { emptyCacheQuery } from '../util';
import { validate, ValidationErrors } from './validate';

interface ApiKeyFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  isEditMode?: boolean;
  exchangeId?: string;
  redirectUrl?: string;
}

export const GENERIC_ERROR_MESSAGE =
  'There was a problem while trying to add your exchange. Try again or contact support';
export const ApiKeyForm = (props: ApiKeyFormProps) => {
  const integration = props.integration;
  const syncIntegrationCoverageInfo =
    integration?.info?.syncIntegrationCoverageInfo;

  const [apiKey, setApiKey] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errors, setErrors] = useState<ValidationErrors>();

  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const [addExchange, { loading }] = useAddExchangeWithApiKeyMutation({
    onCompleted: (data) => {
      if (data.addExchangeWithApiKey.success) {
        showNotification({
          status: Status.Success,
          message: `Added new exchange`,
        });
        onSuccess(
          data.addExchangeWithApiKey.createdExchange,
          props.redirectUrl,
        );
        props.onCloseModal?.();
        return;
      }

      if (
        data.addExchangeWithApiKey.error ===
        ExchangeMutationErrors.AccountAlreadyLinked
      ) {
        setErrorText('Account is already linked');
      } else if (
        data.addExchangeWithApiKey.error ===
        ExchangeMutationErrors.InvalidAccount
      ) {
        setErrorText('Account is invalid');
      } else if (
        data.addExchangeWithApiKey.error ===
        ExchangeMutationErrors.InvalidApiKey
      ) {
        setErrorText('API key is invalid');
      } else {
        setErrorText(GENERIC_ERROR_MESSAGE);
      }
    },
    update: (cache, { data }) => {
      if (!data.addExchangeWithApiKey.success) {
        return;
      }
      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newExchanges = [
        ...walletsData.exchanges,
        data.addExchangeWithApiKey.createdExchange,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, exchanges: newExchanges },
      });
    },
    onError: () => {
      setErrorText(GENERIC_ERROR_MESSAGE);
    },
  });

  const [updateExchange, { loading: updateLoading }] =
    useUpdateExchangeWithApiKeyMutation({
      onCompleted: (data) => {
        if (data.updateExchangeWithApiKey.success) {
          showNotification({
            status: Status.Success,
            message: `Updated exchange details`,
          });
          onSuccess(
            data.updateExchangeWithApiKey.updatedExchange,
            props.redirectUrl,
          );
          props.onCloseModal?.();
          return;
        }

        if (
          data.updateExchangeWithApiKey.error ===
          ExchangeMutationErrors.AccountAlreadyLinked
        ) {
          setErrorText('Account is already linked');
        } else if (
          data.updateExchangeWithApiKey.error ===
          ExchangeMutationErrors.InvalidAccount
        ) {
          setErrorText('Account is invalid');
        } else if (
          data.updateExchangeWithApiKey.error ===
          ExchangeMutationErrors.InvalidApiKey
        ) {
          setErrorText('API key is invalid');
        } else {
          setErrorText(GENERIC_ERROR_MESSAGE);
        }
      },
      update: (cache, { data }) => {
        if (!data.updateExchangeWithApiKey.success) {
          return;
        }
        const walletsData: GetWalletsQuery =
          cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newExchanges = walletsData.exchanges.map((exchange) => {
          if (
            exchange.id === data.updateExchangeWithApiKey.updatedExchange.id
          ) {
            return data.updateExchangeWithApiKey.updatedExchange;
          }
          return exchange;
        });

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      },
      onError: () => {
        setErrorText(GENERIC_ERROR_MESSAGE);
      },
    });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const apiKey = formData.get('apiKey') as string;
      if (props.isEditMode && props.exchangeId) {
        updateExchange({
          variables: {
            updateExchangeInput: {
              apiKey,
              publicId: props.exchangeId,
            },
          },
        });
      } else {
        addExchange({
          variables: {
            addExchangeInput: {
              apiKey,
              accountTypeSlug: integration.slug,
            },
          },
        });
      }
    },
    [
      integration,
      addExchange,
      props.exchangeId,
      props.isEditMode,
      updateExchange,
    ],
  );

  const hasApiKeyError = !isEmpty(errors) && errors.apiKeyMissing;

  const isFormInvalid = !isEmpty(errors) || !!errorText || !apiKey.trim();
  const onApiKeyChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setApiKey(e.target.value);
    setErrors(validate({ apiKey: e.target.value }));
  }, []);

  const isLoading = loading || updateLoading;

  return (
    <form onSubmit={onSubmit}>
      {syncIntegrationCoverageInfo && (
        <CoverageInfoContainer html={syncIntegrationCoverageInfo} />
      )}
      <div className="flex flex-col gap-24">
        {errorText && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>{errorText}</InlineBannerContent>
          </InlineBanner>
        )}
        <TextInput
          name="apiKey"
          onChange={onApiKeyChange}
          value={apiKey}
          label="API Key"
          hasError={hasApiKeyError}
          errorMessage={'Field is required'}
          autoFocus={true}
        />
        <Instructions integration={integration} showCSVInstructions={false} />
      </div>

      <Modal.ContentFooter className="sticky bottom-0 bg-background-card">
        <Button
          variant="primary"
          disabled={isFormInvalid || isLoading}
          type="submit"
          size="large"
        >
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </Modal.ContentFooter>
    </form>
  );
};
