import {
  useGetConnectedCtUsersQuery,
  useResetCbeDemoUserMutation,
} from '@cointracker/graphql-types';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Separator,
} from '@cointracker/styleguide';
import { Body, Button } from '@cointracker/styleguide/src/LoggedIn';
import { CheckCircle, Recycle } from '@phosphor-icons/react';
import { AccountNavItem } from 'components/Rebrand/Sidebar/AccountBar/AccountBar';
import { useOnLogout } from 'components/Rebrand/Sidebar/hooks/useOnLogout';
import {
  getAccountNavItem,
  isAuthenticatedUser,
} from 'components/Rebrand/Sidebar/utils';
import { useContext } from 'react';
import { UserContext } from 'src/app/user/context';
import { useCbeDemo } from '../cbeDemoContext';

export function CpiTobarStatus() {
  const user = useContext(UserContext);
  const onLogout = useOnLogout();
  const { enableCbe, isBroker, changeViewMode, changeCbeSettings } =
    useCbeDemo();
  const { data, loading } = useGetConnectedCtUsersQuery({
    fetchPolicy: 'network-only',
  });
  const [resetDemoUser] = useResetCbeDemoUserMutation({
    onCompleted: () => {},
    onError: (error) => {
      console.error(error);
    },
  });
  const isConnected = data?.connectedCtUsers?.length > 0;
  if (!isAuthenticatedUser(user)) {
    return null;
  }
  const accountNavItems = getAccountNavItem({
    userEmail: user.displayUser.email,
    userIsAdmin: user.isAdmin,
    onResetDemoUser: () => {
      resetDemoUser({ variables: { input: { brokerId: 'cbe_demo' } } });
      changeCbeSettings({ allowUserWalletConnect: false });
    },
    currentPathName: location.pathname,
    cbeEnabled: enableCbe,
    isBroker,
    onNavigateToSupport: () => null,
    onClickChatWithSupport: () => null,
    onClickViewAs: () => null,
    onLogout,
    onClickToggleDemoViewMode: (mode) => {
      changeViewMode(mode);
    },
  });
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="flex flex-row place-items-center gap-4"
        asChild={!isConnected}
      >
        {isConnected ? (
          <>
            <Body
              className="text-text-secondary"
              variant="body3"
            >{`${loading ? 'Syncing' : 'Up to date'} `}</Body>
            {loading ? (
              <Recycle
                size={16}
                weight="bold"
                className="text-accent-bold-yellow"
              />
            ) : (
              <CheckCircle
                weight="fill"
                size={16}
                className="text-text-positive"
              />
            )}
          </>
        ) : (
          <Button variant="line" size="small">
            <Body variant="body3">Connect to CoinTracker</Body>
          </Button>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="group"
        data-state={'expanded'}
        side={'bottom'}
        align="end"
        sideOffset={24}
      >
        {accountNavItems.subItems.map((item) =>
          item.break ? (
            <Separator
              key={item.slug}
              orientation="horizontal"
              className="my-0"
            />
          ) : (
            <AccountNavItem key={item.title} item={item} />
          ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
