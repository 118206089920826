import { useApolloClient } from '@apollo/client';
import { Body3 } from '@cointracker/styleguide/src/LoggedIn';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { LoadingContainer } from 'src/app/router/LoadingContainer';
import { AuthenticatedUser, UserContext } from 'src/app/user/context';
import {
  clearThemeCookie,
  deleteAllDataCookies,
  parseCookie,
} from 'src/app/utils';
import { IS_AUTHENTICATED } from 'src/common/session_keys';
import { URLS } from 'src/common/urls';
import { clearTaxReadinessStore } from 'src/components/Rebrand/Sidebar/TaxReadinessSection/utils';
import { clearStoredViewMode } from 'src/pages/Rebrand/CostBasisEngineDemo/utils';
import { AppInfoContext } from '../../context/AppInfoContext';

/**
 * To allow us to know if we are authenticated or not, the server
 * sends a cookie for us.
 *
 * More discussion here on why we added this:
 * https://github.com/coin-tracker/coin-tracker-server/pull/10117
 */
export const isUserAuthenticated = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[IS_AUTHENTICATED] === 'True';
};

export const logout = (withRedirect = false) => {
  if (window?.analytics) {
    window.analytics.track('Signed Out');
    window.analytics.reset();
    window.analytics.ready(function () {
      const mixpanel = window?.mixpanel;
      if (mixpanel?.cookie?.clear) {
        mixpanel.cookie.clear();
      }
    });
  }
  if (window?.Cello) {
    // https://docs.cello.so/docs/javascript-browser#shutdown
    window.Cello('shutdown');
  }
  deleteAllDataCookies();
  // clear the theme cookie FST-4107
  clearThemeCookie();
  // clear Cost Basis Engine Demo view mode.
  clearStoredViewMode();
  //clear user local storage
  clearTaxReadinessStore();
  if (withRedirect) {
    redirectToAuth(true, false);
  } else {
    window.location.href = URLS.AUTH_LOGOUT;
  }
};

export const redirectToAuth = (login = false, replaceCurrentUrl = false) => {
  const authUrl = login ? URLS.AUTH_LOGIN : URLS.AUTH_SIGNUP;
  // Redirect to the login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login
  const currentUrl = encodeURIComponent(window.location.href);
  const url = `${authUrl}?next=${currentUrl}`;
  if (replaceCurrentUrl) {
    window.location.replace(`${url}`);
  } else {
    window.location.href = url;
  }
};

interface AuthContextType {
  isAuthenticated: boolean;
  startedRedirectToLogin: boolean;
  setStartedRedirectToLogin: React.Dispatch<React.SetStateAction<boolean>>;
}
const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: isUserAuthenticated(),
  startedRedirectToLogin: false,
  setStartedRedirectToLogin: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const isUserLoggedIn = isUserAuthenticated();
  const client = useApolloClient();
  const [isAuthenticated, setIsAuthenticated] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.useState<any>(isUserLoggedIn);
  const [startedRedirectToLogin, setStartedRedirectToLogin] =
    React.useState(false);
  useEffect(() => {
    setIsAuthenticated(isUserLoggedIn);
    client?.cache?.reset();
  }, [isUserLoggedIn, client]);
  const value = {
    isAuthenticated,
    startedRedirectToLogin,
    setStartedRedirectToLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

interface RequireAuthProps {
  children: ReactNode;
  requiresAuth: boolean;
  allowOnlyAdmins?: boolean;
  forbidViewAs?: boolean;
}

export const RequireAuth = ({
  children,
  requiresAuth = true,
  allowOnlyAdmins = false,
  forbidViewAs = false,
}: RequireAuthProps) => {
  const [isLoading, setIsLoading] = useState(
    requiresAuth && (allowOnlyAdmins || forbidViewAs),
  );

  const { isViewAsUser, isLoaded } = useContext(AppInfoContext);

  const { isAuthenticated, startedRedirectToLogin, setStartedRedirectToLogin } =
    useAuth();
  const user = useContext(UserContext);
  useEffect(() => {
    if (requiresAuth && !isAuthenticated) {
      // PPP-956: Strange case where if there are graphql requests in flight during redirection, thus corrupting the auth state cookie. For now just force a logout, until we figure out a future solution. Also there is a chance of a double redirection because RequireAuth is rerendered. So we need to make sure that we only redirect once.
      if (!startedRedirectToLogin) {
        setStartedRedirectToLogin(true);
        logout(true);
      }
    }
  }, [
    isAuthenticated,
    startedRedirectToLogin,
    setStartedRedirectToLogin,
    requiresAuth,
  ]);

  useEffect(() => {
    if (user.hasFetched && allowOnlyAdmins) {
      if ((user as AuthenticatedUser).isAdmin) {
        setIsLoading(false);
      } else {
        window.location.replace(URLS.DASHBOARD_PAGE);
      }
    }

    if (!isLoaded) {
      return;
    }
    setIsLoading(false);
  }, [user, isViewAsUser, forbidViewAs, allowOnlyAdmins, isLoaded]);

  if (isLoading) {
    return <LoadingContainer dataTestId="auth-loading-container" />;
  }

  if (requiresAuth && !isAuthenticated) {
    return <LoadingContainer dataTestId="auth-loading-container" />;
  }

  if (isViewAsUser && forbidViewAs) {
    return (
      <LoadingContainer dataTestId="auth-loading-container">
        <Body3>{`You can't view this page on behalf of a user`}</Body3>
      </LoadingContainer>
    );
  }

  return children;
};
