import classNames from 'classnames';
import {
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
} from 'react';
import { Grid } from '../Grid';
import { H2, H6 } from '../typography';
import {
  cardContainer,
  container,
  styledSubtitle,
  styledTitle,
} from './StatisticsCard.css';

interface StatisticsCardProps {
  className?: string;
  title: string;
  subtitle: string;
}

interface StatisticsBlockProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

export const StatisticsCard = forwardRef(
  (props: StatisticsCardProps, ref: Ref<HTMLDivElement>) => {
    const { className, title, subtitle, ...rest } = props;
    return (
      <div className={classNames(cardContainer, className)} ref={ref} {...rest}>
        <H2 className={styledTitle}>{title}</H2>
        <H6 className={styledSubtitle}>{subtitle}</H6>
      </div>
    );
  },
);

StatisticsCard.displayName = 'StatisticsCard';

export const StatisticsBlock = forwardRef(
  (props: StatisticsBlockProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;
    return (
      <Grid className={classNames(container, className)} ref={ref} {...rest}>
        {children}
      </Grid>
    );
  },
);

StatisticsBlock.displayName = 'StatisticsBlock';
