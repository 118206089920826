import {
  cn,
  ScrollDirection,
  useScrollDirection,
  useSidebar,
} from '@cointracker/styleguide';
import RebrandLogoIcon from '@cointracker/styleguide/src/icons/logo-icon.svg?react';
import { Button } from '@cointracker/styleguide/src/LoggedIn/Button';
import { REBRAND_URLS } from '@cointracker/ui';
import { List, X } from '@phosphor-icons/react';
import { NavLink } from 'react-router-dom';
import { useIsMobileWebview } from '../../../../hooks/useIsMobileWebview';
import { ReferralButton } from '../ReferralButton';
import { Status } from '../StatusSection';

interface TopBarProps {
  className?: string;
  shouldBeFixed?: boolean;
}
export function TopBar({ className, shouldBeFixed = false }: TopBarProps) {
  const { openMobile, setOpenMobile, isMobile } = useSidebar();
  const scrollDirection = useScrollDirection({ threshold: 25 });
  const isMobileWebview = useIsMobileWebview();

  if (isMobileWebview) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex h-topbar-height w-full flex-row place-items-center justify-between gap-8 border-sidebar-border bg-background-default px-16 text-text-primary md:hidden',
        openMobile && 'border-b-solid border-b border-line-tertiary',
        shouldBeFixed &&
          'transition-translate fixed left-0 right-0 top-0 z-20 translate-y-0 shadow-elevation-1 duration-150 ease-linear',
        shouldBeFixed &&
          scrollDirection === ScrollDirection.DOWN &&
          'translate-y-[-100%]',
        className,
      )}
    >
      <div className="flex flex-row place-items-center gap-20">
        <NavLink to={REBRAND_URLS.HOME}>
          <RebrandLogoIcon className="h-32 w-32 flex-shrink-0" />
        </NavLink>
        {isMobile && (
          <div className="flex items-center gap-8">
            <Status />
            <ReferralButton iconOnly />
          </div>
        )}
      </div>
      <Button
        variant="line"
        size="small"
        transparent
        onClick={() => setOpenMobile(!openMobile)}
      >
        {openMobile ? (
          <X weight="light" size={32} />
        ) : (
          <List weight="light" size={32} />
        )}
      </Button>
    </div>
  );
}
