import ArrowLeftIcon from '@cointracker/styleguide/src/icons/arrow-left.svg?react';
import CloseIcon from '@cointracker/styleguide/src/icons/close-modal.svg?react';
import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import React, { useRef, type ReactNode } from 'react';
import { Grid } from '../Grid';
import { Body1, Body3 } from '../LoggedIn';
import { useIsMobileWebview } from '../hooks/useIsMobileWebview';
import { useOverflowGradient } from '../hooks/useOverflowGradient';
import { cn } from '../utils';
import {
  body,
  content,
  footer,
  fullscreenGrid,
  header,
  headerButton,
  overlay,
  type ContentVariant,
} from './Modal.css';

export const Trigger = (props: RadixDialog.DialogTriggerProps) => {
  const { children, ...rest } = props;

  return <RadixDialog.Trigger {...rest}>{children}</RadixDialog.Trigger>;
};

Trigger.displayName = 'Trigger';

export const Root = (props: RadixDialog.DialogProps) => {
  const { children, ...rest } = props;

  return (
    <RadixDialog.Root modal {...rest}>
      {children}
    </RadixDialog.Root>
  );
};

Root.displayName = 'Root';

type ModalContentProps = {
  disableOverlay?: boolean; // if true, the overlay will not be shown - useful when you want to stack modals and not have multiple overlays
} & RadixDialog.DialogContentProps &
  ContentVariant;

export const Content = (props: ModalContentProps) => {
  const { children, fullscreen, className, disableOverlay, ...rest } = props;
  return (
    <>
      {!disableOverlay && (
        <RadixDialog.Overlay
          className={cn(
            overlay,
            'bg-background-scrim-dark backdrop-blur-[8px]',
          )}
        />
      )}
      <RadixDialog.Content
        className={cn(content({ fullscreen }), className)}
        {...rest}
      >
        {fullscreen ? (
          <Grid className={fullscreenGrid}>{children}</Grid>
        ) : (
          children
        )}
      </RadixDialog.Content>
    </>
  );
};

Content.displayName = 'Content';

export const Portal = RadixDialog.Portal;
export const Close = RadixDialog.Close;

export type ContentHeaderProps = {
  onClickBack?: React.MouseEventHandler<HTMLButtonElement>;
  title?: ReactNode | string;
  children?: ReactNode;
  className?: string;
};

export const ContentHeader = (props: ContentHeaderProps) => {
  const { onClickBack, title, children, className, ...rest } = props;

  const isMobileWebview = useIsMobileWebview();

  return (
    <header
      className={cn(header, { '!relative': isMobileWebview }, className)}
      {...rest}
    >
      {!!onClickBack && (
        <button
          type="button"
          onClick={onClickBack}
          className={headerButton}
          disabled={!onClickBack}
          data-testid="modal-header-back-button"
        >
          <ArrowLeftIcon width="20" height="20" />
        </button>
      )}
      {title && (
        <RadixDialog.Title asChild>
          <Body1 weight="bold" role="heading">
            {title}
          </Body1>
        </RadixDialog.Title>
      )}
      {children}
      <RadixDialog.Close
        className={cn(headerButton, 'ml-auto')}
        aria-label="Close"
      >
        <CloseIcon width="16" height="16" />
      </RadixDialog.Close>
    </header>
  );
};

ContentHeader.displayName = 'ContentHeader';

export interface ContentBodyProps {
  children?: React.ReactNode;
  className?: string;
  hideTopOverflowGradient?: boolean;
  hideBottomOverflowGradient?: boolean;
}

export const ContentBody = (props: ContentBodyProps) => {
  const {
    children,
    className,
    hideTopOverflowGradient,
    hideBottomOverflowGradient,
    ...rest
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const { isScrollable, isScrolledToEnd, isNotScrolled } = useOverflowGradient({
    containerRef,
    vertical: true,
  });

  return (
    <div className="relative flex flex-col overflow-hidden">
      <div
        className={cn('bg-line-tertiary invisible absolute top-0 h-1 w-full', {
          visible: !hideTopOverflowGradient && isScrollable && !isNotScrolled,
        })}
      />
      <div className={classNames(body, className)} {...rest} ref={containerRef}>
        {children}
      </div>
      <div
        className={cn(
          'bg-line-tertiary invisible absolute bottom-0 h-1 w-full',
          {
            visible:
              !hideBottomOverflowGradient && isScrollable && !isScrolledToEnd,
          },
        )}
      />
    </div>
  );
};

ContentBody.displayName = 'ContentBody';

export interface ContentFooterProps {
  children?: React.ReactNode;
  className?: string;
}

export const ContentFooter = (props: ContentFooterProps) => {
  const { children, className, ...rest } = props;

  return (
    <footer className={cn(footer, className)} {...rest}>
      {children}
    </footer>
  );
};

ContentFooter.displayName = 'ContentFooter';

export const Description = (props: RadixDialog.DialogDescriptionProps) => {
  const { children, className } = props;

  return (
    <RadixDialog.Description className={cn('text-text-secondary mb-16')}>
      <Body3 className={className}>{children}</Body3>
    </RadixDialog.Description>
  );
};

Description.displayName = 'ModalDescription';
