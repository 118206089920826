import classNames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type Ref,
} from 'react';
import { Button, type ButtonProps } from '../Button';
import { Grid } from '../Grid';

import { SnapState, useSnapState } from '../SnapPoint/context';
import { type ChildAndClassNameProps } from '../types';
import { H1Landing, H6 } from '../typography';
import {
  container,
  imageContainer,
  spaceFromSubtitle,
  styledImage,
  styledSubtitle,
  textContainer,
} from './LandingHeader.css';

export const LandingHeaderContainer = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Grid className={classNames(container, className)} ref={ref} {...props}>
      {children}
    </Grid>
  ),
);

LandingHeaderContainer.displayName = 'LandingHeaderContainer';

export const LandingTitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLHeadingElement>,
  ) => (
    <H1Landing className={className} ref={ref} {...props}>
      {children}
    </H1Landing>
  ),
);

LandingTitle.displayName = 'LandingTitle';

export const LandingSubtitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLHeadingElement>,
  ) => (
    <H6 className={classNames(styledSubtitle, className)} ref={ref} {...props}>
      {children}
    </H6>
  ),
);

LandingSubtitle.displayName = 'LandingSubtitle';

export const LandingDiscount = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLHeadingElement>,
  ) => (
    <H6 className={classNames(className)} ref={ref} {...props}>
      {children}
    </H6>
  ),
);

LandingDiscount.displayName = 'LandingDiscount';

export const LandingButton = forwardRef(
  (
    { children, className, ...props }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <Button
      className={classNames(spaceFromSubtitle, className)}
      ref={ref}
      {...props}
    >
      {children}
    </Button>
  ),
);

LandingButton.displayName = 'LandingButton';

export interface LandingCopyContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const LandingCopyContainer = forwardRef(
  (props: LandingCopyContainerProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={classNames(textContainer, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

LandingCopyContainer.displayName = 'LandingCopyContainer';

export const LandingImage = forwardRef(
  (
    {
      className,
      imgSrc,
      imgAlt,
      ...props
    }: {
      imgSrc: string;
      imgAlt: string;
      className?: string;
    },
    ref: Ref<HTMLImageElement>,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const [isFirstFrame, setIsFirstFrame] = useState(true);

    useEffect(() => {
      setIsFirstFrame(false);
    }, []);

    const snapState = useSnapState();

    return (
      <div
        className={classNames(
          imageContainer({
            active:
              snapState !== SnapState.BEFORE &&
              snapState !== undefined &&
              !isFirstFrame,
          }),
          className,
        )}
        ref={containerRef}
        {...props}
      >
        <img
          className={styledImage({
            active:
              snapState !== SnapState.BEFORE &&
              snapState !== undefined &&
              !isFirstFrame,
          })}
          src={imgSrc}
          alt={imgAlt}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

LandingImage.displayName = 'LandingImage';
