import {
  SpamAsset,
  UnifiedPlanDetails,
  useGetPlansForSidebarQuery,
  useGetSpamAssetsForReviewQuery,
} from '@cointracker/graphql-types';
import {
  Sidebar as SidebarComponent,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarSeparator,
  SidebarTrigger,
  cn,
  useOverflowGradient,
  useSidebar,
} from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import RebrandLogoIcon from '@cointracker/styleguide/src/icons/logo-icon.svg?react';
import { ScrollArea } from '@cointracker/styleguide/src/sidebar/scroll-area';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@cointracker/styleguide/src/sidebar/tooltip';
import { CaretRight } from '@phosphor-icons/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AuthenticatedUser, UserContext } from 'src/app/user/context';
import { useCbeDemo } from 'src/pages/Rebrand/CostBasisEngineDemo/cbeDemoContext';
import { useGate } from 'statsig-react';
import { GetResolvedAndUnresolvedSpamAssets } from '../../../pages/Rebrand/Spam/utils';
import { AccountBar } from './AccountBar';
import { DemoArea } from './DemoArea';
import { ReferralButton } from './ReferralButton';
import { Status } from './StatusSection';
import { TaxReadinessStatus } from './TaxReadinessSection';
import { TopBar } from './TopBar';
import { TransactionLimitCard } from './TransactionLimitCard/TransactionLimitCard';
import {
  getNavigationItems,
  getTaxPageUrl,
  isAuthenticatedUser,
} from './utils';

export const Sidebar = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  const { state, isMobile, setOpenMobile } = useSidebar();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { isNotScrolled, isScrollable, isScrolledToEnd } = useOverflowGradient({
    vertical: true,
    containerRef: sidebarRef,
  });

  const [unresolvedSpamAssets, setUnresolvedSpamAssets] =
    useState<SpamAsset[]>(undefined);
  const spamAssetsQuery = useGetSpamAssetsForReviewQuery();

  useEffect(() => {
    if (!spamAssetsQuery.data) return;
    const spamAssetsResult = spamAssetsQuery.data?.spamAssets || [];
    const { unresolvedSpamAssets } =
      GetResolvedAndUnresolvedSpamAssets(spamAssetsResult);
    setUnresolvedSpamAssets(unresolvedSpamAssets);
  }, [spamAssetsQuery.data]);

  const taxReadinessGateOn = useGate('tax_readiness').value;
  const shouldShowTaxReadiness =
    taxReadinessGateOn && user.isAuthenticated && user.country.code === 'US';

  const { isBroker } = useCbeDemo();

  const { loading: plansQueryLoading, data } = useGetPlansForSidebarQuery();

  if (!isAuthenticatedUser(user)) {
    // if user isn't authenticated, we don't render the sidebar as it's never needed in logged out pages.
    return null;
  }
  const taxPageURL = getTaxPageUrl(
    (user as AuthenticatedUser)?.displayUser?.taxYearToUse,
  );
  const navigationItems = getNavigationItems({
    taxPageUrl: taxPageURL,
    currentPathName: location.pathname,
    isBroker,
    cbeEnabled: user.enableCbe,
  });
  if (user.enableCbe && !isBroker) return null;
  return (
    <SidebarComponent
      collapsible="icon"
      className={
        user.enableCbe ? '[&>div]:bg-background-alternate-lighter' : ''
      }
    >
      <SidebarHeader>
        <SidebarTrigger className="mx-4 rounded-4 p-4 text-icon-subtle hover:bg-background-default-hover" />
        {user.enableCbe ? (
          <Body className="weight-medium self-start text-text-link">
            {isBroker ? 'Broker Portal' : 'User Portal'}
          </Body>
        ) : (
          <NavLink
            className="flex flex-row place-items-center gap-8 self-start"
            to="/"
          >
            <RebrandLogoIcon className="h-34 w-34 text-text-primary" />
          </NavLink>
        )}
      </SidebarHeader>
      <TopBar />
      <SidebarContent>
        <ScrollArea
          className={cn(
            'h-full overflow-x-hidden border-t border-transparent px-32',
            {
              'border-line-tertiary': isScrollable && !isNotScrolled,
            },
          )}
          viewportRef={sidebarRef}
        >
          <SidebarMenu className="pb-16 pt-24 md:pb-12 md:pt-0">
            {!user.enableCbe && (
              <div>
                <SidebarMenuItem className="hidden md:block">
                  <Status className="h-22" />
                  {shouldShowTaxReadiness && (
                    <TaxReadinessStatus className="h-22" />
                  )}
                </SidebarMenuItem>
                <SidebarMenuItem className="hidden md:block">
                  <ReferralButton />
                </SidebarMenuItem>
                <div className="mt-8 h-1 w-full bg-line-tertiary" />
              </div>
            )}
            {navigationItems.map((item) =>
              item.slug === 'break' ? (
                <SidebarSeparator
                  key={item.title}
                  orientation="horizontal"
                  className="-mx-[6px] w-auto min-w-30 bg-line-tertiary group-data-[state=collapsed]:w-full"
                />
              ) : item.slug === 'demo' ? (
                <DemoArea
                  key={item.slug}
                  title={item.title}
                  subItems={item.subItems}
                  className={
                    state === 'expanded' ? 'px-4' : 'bg-transparent px-0'
                  }
                />
              ) : (
                <SidebarMenuItem key={item.title}>
                  <NavLink
                    to={item.slug}
                    onClick={(e) => {
                      !item.slug && e.preventDefault();
                      if (isMobile) {
                        setOpenMobile(false);
                      }
                    }}
                    aria-label={item.title}
                    className={cn(
                      'flex h-22 cursor-pointer flex-row place-items-center gap-16 text-text-secondary hover:text-text-primary-hover active:text-accent-bold-blue md:gap-8',
                      item.isActive && 'text-text-primary',
                    )}
                  >
                    <Tooltip>
                      <TooltipTrigger>
                        {item.icon({
                          weight: item.isActive ? 'fill' : 'regular',
                        })}
                        {item.slug === '/spam-center' &&
                          unresolvedSpamAssets?.length > 0 && (
                            <span className="absolute left-0 top-1 h-6 w-6 rounded-full bg-accent-bold-blue" />
                          )}
                      </TooltipTrigger>
                      <TooltipContent
                        side="right"
                        align="center"
                        className={state === 'expanded' ? 'hidden' : ''}
                      >
                        <Body
                          className="text-text-primary-foreground-inverse"
                          variant="body5"
                        >
                          {item.title}
                        </Body>
                        <TooltipArrow width={11} height={5} />
                      </TooltipContent>
                    </Tooltip>
                    <Body
                      variant={isMobile ? 'body1' : 'body3'}
                      className={cn(
                        'whitespace-nowrap transition-opacity group-data-[state=collapsed]:opacity-0',
                        // fade animation timing
                        'relative delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
                      )}
                    >
                      {item.title}
                    </Body>
                    <CaretRight
                      className="ml-auto text-text-primary-foreground md:hidden"
                      weight="bold"
                      size={20}
                    />
                  </NavLink>
                </SidebarMenuItem>
              ),
            )}
            <SidebarSeparator
              orientation="horizontal"
              className="bg-line-tertiary md:hidden"
            />
          </SidebarMenu>
        </ScrollArea>
      </SidebarContent>
      <SidebarFooter
        className={cn(
          'border-t border-transparent !pt-12',
          {
            'border-line-tertiary': isScrollable && !isScrolledToEnd,
          },
          isMobile && 'flex flex-col-reverse gap-[24px]',
        )}
      >
        <TransactionLimitCard
          loading={plansQueryLoading}
          transactionCount={
            data?.unifiedSubscription?.transactionCounts.netTransactionCount
          }
          transactionLimit={data?.unifiedSubscription?.transactionLimit}
          recommendedPlanForTax={
            data?.unifiedSubscription
              ?.recommendedPlanForTax as UnifiedPlanDetails
          }
        />
        <AccountBar />
      </SidebarFooter>
    </SidebarComponent>
  );
};
