import { isEmpty } from 'lodash';

export type ValidationErrors = {
  fileMissing?: boolean;
  walletNameMissing?: boolean;
};

interface validateProps {
  fileUrl: string;
  walletName: string;
}

export const isStringValid = (value: string) => !!value?.trim();

export const validate = ({ fileUrl, walletName }: validateProps) => {
  const errors: ValidationErrors = {};
  if (!isStringValid(walletName)) {
    errors.walletNameMissing = true;
  }

  if (!isStringValid(fileUrl)) {
    errors.fileMissing = true;
  }

  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
