import { Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { forwardRef, type Ref } from 'react';
import { Body2, Number } from '../LoggedIn';
import { Button } from '../LoggedIn/Button';
import { SkeletonLine } from '../Skeleton';
import { Body4 } from '../typography';
import {
  resultBalanceContainer,
  resultButton,
  resultButtonBelowTablet,
  resultCardContainer,
  resultDescription,
  secondaryText,
} from './ResultCard.css';

interface ResultCardProps {
  addWalletHref: string;
  className?: string;
  isShown?: boolean;
  description: string;
  coinBalance: string;
  usdBalance: string;
  walletAlreadyLinked: boolean;
  date: string;
  loading?: boolean;
}

export const ResultCard = forwardRef(
  (props: ResultCardProps, ref: Ref<HTMLDivElement>) => {
    const {
      addWalletHref,
      className,
      isShown,
      description,
      coinBalance,
      usdBalance,
      walletAlreadyLinked,
      date,
      loading,
      ...rest
    } = props;

    return (
      <div
        className={classNames(resultCardContainer({ isShown }), className)}
        ref={ref}
        {...rest}
      >
        {loading && (
          <>
            <Body4 className={resultDescription}>
              <SkeletonLine width={200} height={15} />
            </Body4>
            <div className={resultBalanceContainer}>
              <SkeletonLine width={300} height={48} />
            </div>
            <Body4 className={secondaryText}>
              <SkeletonLine width={80} height={15} />
            </Body4>
          </>
        )}
        {!loading && (
          <>
            <Body4 className={resultDescription}>{description}</Body4>
            <div className={resultBalanceContainer}>
              <Number variant="h4">{coinBalance}</Number>
              <Number variant="h6" className={secondaryText}>
                {usdBalance}
              </Number>
            </div>
            <Body4 className={secondaryText}>
              {dayjs(new Date(date)).format('MMM D, YYYY h:mm A')}
            </Body4>
          </>
        )}
        {!walletAlreadyLinked && (
          <>
            <div className={resultButton}>
              <Button variant="primary" href={addWalletHref} loading={loading}>
                Add wallet to CoinTracker
              </Button>
            </div>
            <div className={resultButtonBelowTablet}>
              <Button
                variant="primary"
                fluid
                href={addWalletHref}
                loading={loading}
              >
                Add wallet to CoinTracker
              </Button>
            </div>
          </>
        )}
        {walletAlreadyLinked && (
          <>
            <Body2 className="mb-16 mt-32 flex items-center gap-8">
              Connected with CoinTracker{' '}
              <Check weight="bold" className="text-text-positive" />
            </Body2>
            <div className={resultButton}>
              <Button
                variant="primary"
                href="/wallets?add_integration="
                loading={loading}
              >
                Add another wallet
              </Button>
            </div>
            <div className={resultButtonBelowTablet}>
              <Button
                variant="primary"
                fluid
                href="/wallets?add_integration="
                loading={loading}
              >
                Add another wallet
              </Button>
            </div>
          </>
        )}
      </div>
    );
  },
);

ResultCard.displayName = 'ResultCard';
