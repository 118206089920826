import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';

import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { CryptoSelect } from '../CryptoSelect';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';

import { Info } from '@phosphor-icons/react';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsQuery,
  Integration,
  useAddTwoXpubMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ViewPermissions } from '../ViewPermissions';
import { emptyCacheQuery } from '../util';
import {
  ValidationErrors,
  validate,
  validateCryptoSelector,
  validateXpub,
} from './validate';

interface TwoXPubFormProps {
  integration: Integration;
  cryptoIntegrations: Integration[];
  onCloseModal?: () => void;
}

export const TwoXPubForm = (props: TwoXPubFormProps) => {
  const integration = props.integration;

  const [selectedCrypto, setSelectedCrypto] = useState<Integration>(null);
  const [receiveAddressXpub, setReceiveAddressXpub] = useState('');
  const [changeAddressXpub, setChangeAddressXpub] = useState('');

  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [errors, setErrors] = useState<ValidationErrors>();

  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onReceiveAddressXpubChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReceiveAddressXpub(e.target.value);
      setErrors({
        ...errors,
        receiveAddressXpub: validateXpub(e.target.value, changeAddressXpub),
      });
    },
    [errors, changeAddressXpub],
  );

  const onChangeAddressXpubChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChangeAddressXpub(e.target.value);
      setErrors({
        ...errors,
        changeAddressXpub: validateXpub(receiveAddressXpub, e.target.value),
      });
    },
    [errors, receiveAddressXpub],
  );

  const updateSelectedCrypto = useCallback(
    (integration: Integration) => {
      setSelectedCrypto(integration);
      setErrors({
        ...errors,
        cryptoSelect: validateCryptoSelector(integration),
      });
    },
    [errors],
  );

  const [addTwoXPub, { loading }] = useAddTwoXpubMutation({
    onCompleted: (data) => {
      if (data.addTwoXpub.success) {
        showNotification({
          message: data.addTwoXpub.message,
          status: Status.Success,
        });
        onSuccess(data.addTwoXpub.createdWallet);
        props.onCloseModal?.();
        return;
      }

      if (data.addTwoXpub.error) {
        setSubmitErrorMessage(data.addTwoXpub.error);
      } else {
        setSubmitErrorMessage(AddWalletErrors.UnknownError);
      }
    },
    update: (cache, { data }) => {
      if (!data.addTwoXpub.success) {
        return;
      }
      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newLocalWallets = [
        ...walletsData.localWallets,
        data.addTwoXpub.createdWallet,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, localWallets: newLocalWallets },
      });
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const errors = validate({
        receiveAddressXpub,
        changeAddressXpub,
        selectedCrypto,
      });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      addTwoXPub({
        variables: {
          twoXpubInput: {
            coinSymbol: selectedCrypto.info.symbol,
            cryptoNetwork: selectedCrypto.info.cryptoNetwork,
            receiveAddressXpub: receiveAddressXpub,
            changeAddressXpub: changeAddressXpub,
            accountTypeSlug: integration.slug,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      receiveAddressXpub,
      changeAddressXpub,
      selectedCrypto,
      addTwoXPub,
      integration.slug,
      startFasterPollingForSyncCompletion,
    ],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        <ViewPermissions />
        {submitErrorMessage && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitErrorMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}
        <CryptoSelect
          cryptoIntegrations={props.cryptoIntegrations}
          setSelectedCrypto={updateSelectedCrypto}
          selectedCrypto={selectedCrypto}
          error={errors ? errors.cryptoSelect : null}
        />

        <TextInput
          id="receive_address_xpub"
          name="receive_address_xpub"
          value={receiveAddressXpub}
          onChange={onReceiveAddressXpubChange}
          label={'HD Wallet address [0]'}
          placeholder={'The xPub/yPub/zPub key for receive addresses'}
          hasError={errors && errors.receiveAddressXpub !== undefined}
          errorMessage={errors?.receiveAddressXpub}
        />

        <TextInput
          id="change_address_xpub"
          name="change_address_xpub"
          value={changeAddressXpub}
          onChange={onChangeAddressXpubChange}
          label={'HD Wallet address [1]'}
          placeholder={'The xPub/yPub/zPub key for change addresses'}
          hasError={errors && errors.changeAddressXpub !== undefined}
          errorMessage={errors?.changeAddressXpub}
        />
        <Instructions integration={integration} />
      </div>

      <IntegrationFormModalFooter>
        <Button variant="primary" loading={loading} type="submit" size="large">
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
