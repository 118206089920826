import { createContext } from 'react';
import { Status } from './constants';

export interface ToastNotificationProps {
  id?: string;
  message: string;
  status: Status;
  delay?: number; // The time in ms to show the notification. Set to -1 to never automatically hide.
  isClosable?: boolean;
  suffix?: React.ReactNode;
}

export interface ToastContextType {
  showNotification: (notificationProps: ToastNotificationProps) => void;
  updateNotification: (notificationProps: ToastNotificationProps) => void;
  removeNotification: (notificationId: string) => void;
  containerHeight: number;
}

export const ToastContext = createContext<ToastContextType>({
  showNotification: () => {},
  updateNotification: () => {},
  removeNotification: () => {},
  containerHeight: 0,
});
