import {
  FileFormats,
  GetValidationErrorsForCsvQuery,
  GetValidationErrorsForCsvQueryVariables,
} from 'src/types/graphql-types';

import { LazyQueryHookOptions, LazyQueryResult } from '@apollo/client';
import { Progress, cn } from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { File, Trash } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { uploadStatusString } from './utils';

export interface FileInputProps {
  isImporting?: boolean;
  isGeneric?: boolean;
  onChange?: (filepath?: string) => void;
  fileFormats?: FileFormats[];
  setIsImportDisabled?: (isDisabled: boolean) => void;
  upload: (file: File, filename: string) => Promise<string>;
  isUploading: boolean;
  isValidating: boolean;
  hasError?: boolean;
  setIsValidating: (isValidating: boolean) => void;
  getValidationErrorsForCsv?: (
    options?: LazyQueryHookOptions<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >,
  ) => Promise<
    LazyQueryResult<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >
  >;
  integrationSlug?: string;
}

export const FileInput = (props: FileInputProps) => {
  const {
    isImporting,
    isGeneric,
    onChange,
    setIsImportDisabled,
    upload,
    isUploading,
    isValidating,
    setIsValidating,
    getValidationErrorsForCsv,
    integrationSlug,
    hasError,
  } = props;
  const [droppedFiles, setDroppedFiles] = useState(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [validationError, setValidationError] = useState<string>('');
  const [uploadError, setUploadError] = useState<string>('');

  const [progressBarValue, setProgressBarValue] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isImporting) {
      // increase the progress bar value every 2 seconds when importing
      intervalId = setInterval(() => {
        setProgressBarValue((prevProgress) => {
          const newProgress = prevProgress + 10;
          return newProgress <= 100 ? newProgress : 100;
        });
      }, 2000);
    }

    // cleanup the interval when isImporting becomes false
    return () => {
      clearInterval(intervalId);
    };
  }, [isImporting]);

  const onDragEnterOrOver = (event: React.DragEvent<HTMLDivElement>) => {
    limitEvent(event);
    setIsDraggedOver(true);
  };

  const onDragLeaveOrEnd = (event: React.DragEvent<HTMLDivElement>) => {
    limitEvent(event);
    setIsDraggedOver(false);
  };

  const limitEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDropRestrictedExtensions = (
    event: React.DragEvent<HTMLDivElement>,
    allowedExtensions: string,
  ) => {
    limitEvent(event);
    const files = event.dataTransfer.files;
    const allowedExtensionsArray = allowedExtensions
      .split(',')
      .map((ext) => ext.trim().toLowerCase());
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedExtensionsArray.includes(fileExtension)) {
        submitUpload(files);
      } else {
        const formattedExtensions = allowedExtensionsArray
          .map((ext) => ext.replace(/^\./, ' '))
          .join(',')
          .slice(0, -1);
        setUploadError(
          `Only ${formattedExtensions} files are allowed for this CSV import.`,
        );
      }
    }
  };

  const submitUpload = async (files: FileList) => {
    setIsDraggedOver(false);
    setDroppedFiles(files);
    setUploadError('');
    setValidationError('');
    setIsImportDisabled(true);

    const file = files[0];
    try {
      setProgressBarValue(2);
      const fileUrl = await upload(file, file.name);
      if (!fileUrl) {
        setUploadError('There was an issue while trying to upload the file');
        return;
      }
      setFileUrl(fileUrl);
      onChange(fileUrl);
      setIsValidating(true);
      setProgressBarValue(50);

      const validationResponse = await getValidationErrorsForCsv({
        variables: {
          csvFileUrl: fileUrl,
          isGeneric: isGeneric,
          accountTypeSlug: integrationSlug,
        },
      });
      if (validationResponse?.data) {
        if (validationResponse?.data?.csvValidationErrors?.length > 0) {
          setValidationError(
            validationResponse.data.csvValidationErrors.join('\n'),
          );
        } else {
          setValidationError('');
          setIsImportDisabled(false);
        }
        setIsValidating(false);
      }
      if (validationResponse?.error) {
        setIsValidating(false);
        setValidationError(
          "There was an issue while trying to validate the file's format",
        );
      }
    } catch (e) {
      setUploadError(
        'There was an issue while trying to upload and validate the file',
      );
    }
    setIsValidating(false);
    setProgressBarValue(0);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files.length > 0) {
      submitUpload(files);
    }
  };

  const onRemoveFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDroppedFiles(null);
    setFileUrl('');
    setUploadError('');
    setValidationError('');
    onChange('');
    setProgressBarValue(0);
  };

  const isLoading = isImporting || isUploading || isValidating;
  let state = 'default';
  const isValidationComplete =
    fileUrl && !isUploading && !isValidating && !isImporting;
  if (isValidationComplete) {
    state = !validationError && !uploadError ? 'success' : 'error';
  }

  if (validationError || uploadError) {
    state = 'error';
  }

  return (
    <div className="flex flex-col gap-2">
      <input
        type="hidden"
        id="file_url"
        name="file_url"
        value={fileUrl}
        style={{ display: 'none' }}
      />
      <div
        className={cn(
          'flex min-h-[125px] items-center justify-center gap-10 rounded-8 border border-line-secondary p-2 hover:border-line-secondary-hover',
          isImporting && 'border-line-secondary-pressed',
          isValidating && 'border-line-secondary-pressed',
          isDraggedOver && 'border-line-secondary-pressed',
          hasError && 'border-line-negative',
          state === 'success' &&
            'border-background-positive hover:border-background-positive-hover',
          state === 'error' &&
            'border-line-negative hover:border-text-negative-foreground-hover',
        )}
      >
        {(isLoading || fileUrl) && (
          <div className="flex flex-row items-center gap-10">
            <div className="flex min-w-[170px] items-center gap-10">
              {fileUrl && (
                <File
                  size={34}
                  className={cn(
                    'text-text-secondary',
                    state === 'error' && 'text-text-negative',
                  )}
                />
              )}
              <div className="flex flex-col">
                <Body
                  variant="body4"
                  className={cn(
                    'text-text-secondary',
                    state === 'error' && 'text-text-negative',
                  )}
                >
                  {droppedFiles ? droppedFiles[0].name : 'transactions.csv'}
                </Body>
                {isLoading && (
                  <div className="my-10">
                    <Progress value={progressBarValue} />
                  </div>
                )}
                {validationError ? (
                  <Body variant="body4" className="text-text-negative">
                    Validation errors.
                  </Body>
                ) : (
                  <Body variant="body4" className="text-text-secondary">
                    {uploadStatusString(
                      isUploading,
                      isValidating,
                      isImporting,
                      validationError,
                      isValidationComplete,
                    )}
                  </Body>
                )}
              </div>
            </div>
            {fileUrl && !isLoading && (
              <button
                onClick={onRemoveFile}
                className={cn(
                  'text-text-secondary',
                  state === 'error' && 'text-text-negative',
                )}
                data-testid="remove-file-button"
              >
                <Trash size={18} weight="bold" />
              </button>
            )}
          </div>
        )}
        {!isLoading && !fileUrl && (
          <div
            className="flex min-h-[120px] w-full flex-row items-center justify-center gap-10"
            id="file-input-box"
            onDragEnter={onDragEnterOrOver}
            onDragOver={onDragEnterOrOver}
            onDragEnd={onDragLeaveOrEnd}
            onDragLeave={onDragLeaveOrEnd}
            onDrag={limitEvent}
            onDragStart={limitEvent}
            onDrop={(event) =>
              onDropRestrictedExtensions(
                event,
                getFileExtensions(props.fileFormats),
              )
            }
          >
            <File
              size={34}
              className={cn(
                'text-text-secondary',
                state === 'error' && 'text-text-negative',
              )}
            />
            <div>
              <input
                type="file"
                className="absolute -z-10 h-0 w-0 overflow-hidden opacity-0"
                name="file-input"
                id="file-input"
                onChange={onInputChange}
                accept={getFileExtensions(props.fileFormats)}
              />
              <label
                htmlFor="file-input"
                className="cursor-pointer hover:underline"
              >
                <Body
                  variant="body4"
                  className={cn(
                    'text-text-secondary',
                    state === 'error' && 'text-text-negative',
                  )}
                >
                  Select a CSV file to upload <br />
                  or drag and drop it here
                </Body>
              </label>
            </div>
          </div>
        )}
      </div>
      {uploadError && (
        <Body variant="body4" className="text-text-negative">
          {uploadError}
        </Body>
      )}
      {validationError &&
        validationError.split('\n').map((error, i) => (
          <Body variant="body4" className="text-text-negative" key={i}>
            {error}
          </Body>
        ))}
    </div>
  );
};

const getFileExtensions = (fileFormats: FileFormats[]) => {
  if (!fileFormats || fileFormats.length === 0) {
    return '.csv, .xls, .xlsx';
  }

  let acceptableFileFormats = '';
  fileFormats.map((fileFormat) => {
    acceptableFileFormats += '.' + fileFormat.toString().toLowerCase() + ', ';
  });
  return acceptableFileFormats;
};
