import { parseCookie } from 'src/app/utils';
import dayjs from 'src/app/utils/dayjsConfig';
import {
  ANONYMOUS_ID,
  CREATED_AT,
  IS_EMPLOYEE,
  PUBLIC_ID,
  TAX_PROS_RETURN_PUBLIC_ID,
} from 'src/common/session_keys';

export const getTaxProsReturnPublicId = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[TAX_PROS_RETURN_PUBLIC_ID];
};

export const getUserCreatedAtFromCookies = () => {
  const cookie = parseCookie(document.cookie);
  const value = cookie?.[CREATED_AT];
  // this branch helps fix an issue that was deployed on production. Some cookies contain the wrong values
  // that have double quotes in them. It should be okay to deprecate this after January 2024
  if (value && value.includes('"')) {
    const stringWithoutQuotes = value.replace(/"/g, '');
    return dayjs(stringWithoutQuotes).toISOString();
  }
  if (value) {
    return dayjs(value).toISOString();
  }
};

export const getUserIsEmployeeFromCookies = () => {
  const cookie = parseCookie(document.cookie);
  const value = cookie?.[IS_EMPLOYEE];
  return value === 'True';
};

export const getUserPublicIdFromCookies = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[PUBLIC_ID];
};

export const getUserAnonymousIdFromCookies = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[ANONYMOUS_ID];
};

export const getUserDataFromCookies = () => ({
  taxProsReturnPublicId: getTaxProsReturnPublicId(),
  userId: getUserPublicIdFromCookies(),
  anonymousId: getUserAnonymousIdFromCookies(),
  createdAt: getUserCreatedAtFromCookies(),
  isEmployee: getUserIsEmployeeFromCookies(),
});
