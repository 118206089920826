import {
  QueuePriority,
  useEnqueueCostBasisMutation,
  UserFreezingStatus,
} from '@cointracker/graphql-types';
import { Status, ToastContext } from '@cointracker/styleguide';
import {
  Body,
  Body3,
  Button,
  Heading,
} from '@cointracker/styleguide/src/LoggedIn';
import { Avatar } from '@cointracker/styleguide/src/LoggedIn/Avatar';
import { URLS } from '@cointracker/ui';
import { CardsThree, Snowflake } from '@phosphor-icons/react';
import { useCallback, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import dayjs from 'src/app/utils/dayjsConfig';
import { formatWalletLabel } from 'src/common/wallet-address';
import AddWalletButton from 'src/pages/Rebrand/Wallets/WalletsHeader/AddWalletButton';
import { URLS as ConsumerURLS } from '../../../../../common/urls';
import { FreezingStatusContext } from '../../../../FreezingStatusProvider/context';
import {
  StatusReadyState,
  SyncAssetItem,
  useGetStatus,
} from '../hooks/useGetStatus';
import { StatusIndicator } from '../StatusIndicator';
import { getExpandedStatusImage, getExpandedStatusText } from '../utils';

export function StatusSheetContent() {
  const { status } = useGetStatus();
  const { status: freezingStatus } = useContext(FreezingStatusContext);
  const image = getExpandedStatusImage(status, freezingStatus);
  return (
    <div className="flex h-[calc(100%_-_var(--topbar-height))] w-full flex-col overflow-auto md:h-full">
      <div className="flex w-full max-w-[--sidebar-width-status] flex-col gap-8 p-16 md:p-40">
        {image && (
          <div className="mb-16 w-full overflow-hidden rounded-16 md:w-auto">
            <img src={image.src} alt={image.src} className="w-full" />
          </div>
        )}
        <Heading variant="h5" className="mb-8 text-text-primary">
          {getExpandedStatusText(status, freezingStatus)}
        </Heading>
        <StatusSheetDescription
          status={status}
          freezingStatus={freezingStatus}
        />
      </div>
      <div className="mt-auto px-40 py-16">
        <StatusSheetAction />
      </div>
    </div>
  );
}

interface StatusSheetDescriptionProps {
  status: StatusReadyState;
  freezingStatus: UserFreezingStatus;
}
function StatusSheetDescription({
  status,
  freezingStatus,
}: StatusSheetDescriptionProps) {
  switch (status) {
    case StatusReadyState.Calculating:
      return (
        <Body3 className="text-text-secondary">{`We're reviewing your transactions and calculating your tax, portfolio, and performance values. Your balances and cost basis may change while this is happening.`}</Body3>
      );
    case StatusReadyState.Syncing:
      return (
        <Body3 className="text-text-secondary">
          Your wallets and exchanges are regularly synced so your CoinTracker
          account can reflect your latest transactions and balances.
        </Body3>
      );
    case StatusReadyState.EmptyData:
      return (
        <Body3 className="text-text-secondary">
          Add your first wallet or exchange in just a couple clicks. We only
          need read access.
        </Body3>
      );
    case StatusReadyState.Failed:
      return (
        <Body3 className="text-text-secondary">
          We were not able to calculate your tax, portfolio, and performance
          values. Your balances and cost basis may not be up-to-date right now.
        </Body3>
      );
    case StatusReadyState.Paused:
      return (
        <>
          <Body3 className="text-text-secondary">
            Cost basis calculations are paused and updates will not be
            calculated for your tax, portfolio, and performance values. Your
            balances and cost basis may not be up-to-date right now.
          </Body3>
          <Body3 className="text-text-secondary">
            You can unpause cost basis jobs and start receiving updates again{' '}
            from your{' '}
            <NavLink
              to={ConsumerURLS.EDIT_USER_PROFILE_TAX}
              className="text-text-link"
            >
              tax settings
            </NavLink>
            .
          </Body3>
        </>
      );
    case StatusReadyState.Ready:
    default: {
      if (freezingStatus === UserFreezingStatus.FreezingInProgress) {
        return (
          <div className="flex flex-col gap-16">
            <Body3 className="text-text-secondary">
              {`Freezing prevents changes to transactions once you've reconciled
              and generated tax forms.`}
            </Body3>
            <div>
              <Body3 className="mb-4 text-text-secondary">{`While frozen, you can't:`}</Body3>
              <ul className="flex list-disc flex-col gap-2 pl-16">
                <li>
                  <Body3 className="text-text-secondary">
                    Edit transaction details (amounts, dates, etc.)
                  </Body3>
                </li>
                <li>
                  <Body3 className="text-text-secondary">
                    Link or unlink transactions
                  </Body3>
                </li>
                <li>
                  <Body3 className="text-text-secondary">
                    Delete transactions
                  </Body3>
                </li>
              </ul>
            </div>
            <Body3 className="text-text-secondary">
              {`To make changes, you'll need to unfreeze the transaction first.`}
            </Body3>
          </div>
        );
      }
      if (freezingStatus === UserFreezingStatus.UnfreezingInProgress) {
        return (
          <div className="flex flex-col gap-16">
            <Body3 className="text-text-secondary">
              {`Unfreezing allows you to make changes to your transactions that were previously locked.`}
            </Body3>
            <div>
              <Body3 className="mb-4 text-text-secondary">{`Once unfrozen, you'll be able to:`}</Body3>
              <ul className="flex list-disc flex-col gap-2 pl-16">
                <li>
                  <Body3 className="text-text-secondary">
                    Edit transaction details (amounts, dates, etc.)
                  </Body3>
                </li>
                <li>
                  <Body3 className="text-text-secondary">
                    Link or unlink transactions
                  </Body3>
                </li>
                <li>
                  <Body3 className="text-text-secondary">
                    Delete transactions
                  </Body3>
                </li>
              </ul>
            </div>
            <Body3 className="text-text-secondary">
              {`Note: Making changes to unfrozen transactions may affect your tax calculations.`}
            </Body3>
          </div>
        );
      }
      if (freezingStatus === UserFreezingStatus.ErroredWithFrozenPeriods) {
        return (
          <div className="flex flex-col gap-16">
            <Body3 className="text-text-secondary">
              {`We weren't able to unfreeze your transactions. Please try again later or contact us if you continue to experience problems.`}
            </Body3>
            <a href={URLS.SUPPORT} target="_blank" rel="noreferrer">
              <Button size="medium">Contact support</Button>
            </a>
          </div>
        );
      }
      if (freezingStatus === UserFreezingStatus.ErroredWithUnfrozenPeriods) {
        return (
          <div className="flex flex-col gap-16">
            <Body3 className="text-text-secondary">
              {`We weren't able to freeze your transactions. Please try again later or contact us if you continue to experience problems.`}
            </Body3>
            <a href={URLS.SUPPORT} target="_blank" rel="noreferrer">
              <Button size="medium">Contact support</Button>
            </a>
          </div>
        );
      }
      return (
        <Body3 className="text-text-secondary">
          Your balances and cost basis are up-to-date.
        </Body3>
      );
    }
  }
}

function StatusSheetAction() {
  const { status, syncingAssets, shouldAllowUserToResync } = useGetStatus();
  const {
    status: freezingStatus,
    startTime: freezingStartTime,
    endTime: freezingEndTime,
  } = useContext(FreezingStatusContext);
  const { showNotification } = useContext(ToastContext);
  const analytics = useAnalytics();
  const onSupportButtonClicked = () => {
    analytics.track('Sidebar Support Button Clicked');
    window.open(URLS.SUPPORT, '_blank', 'noopener,noreferrer');
  };
  const [mutate, { loading }] = useEnqueueCostBasisMutation({
    onCompleted: (data) => {
      if (data.enqueueCostBasis.success) {
        showNotification({
          status: Status.Success,
          message: 'Enqueued portfolio and tax calculations.',
          delay: 4000,
        });
      } else {
        showNotification({
          status: Status.Error,
          message:
            'There was a problem while enqueuing portfolio and tax calculations. Please try again',
        });
      }
    },
    onError: () => {
      showNotification({
        status: Status.Error,
        message:
          'There was a problem while enqueuing portfolio and tax calculations. Please try again',
      });
    },
  });

  const resync = useCallback(() => {
    if (loading) {
      return;
    }
    analytics.track('Sidebar Resync Button Clicked');
    mutate({ variables: { priority: QueuePriority.High } });
  }, [mutate, loading, analytics]);

  switch (status) {
    case StatusReadyState.Calculating:
      return (
        <div className="flex w-full flex-row place-items-center gap-12">
          <div className="flex h-40 w-40 flex-col place-items-center justify-center rounded-full bg-background-alternate-consistent text-text-primary-foreground-inverse-consistent">
            <CardsThree size={18} />
          </div>
          <Body
            weight="bold"
            variant="body2"
            className="flex-1 truncate text-text-primary"
          >
            All wallets and exchanges
          </Body>
          {/* design shows 40px but it takes a bit of extra space so 38 seems to cover the avatar on the left better */}
          <StatusIndicator
            status={status}
            freezingStatus={freezingStatus}
            circleClass="h-[38px]"
          />
        </div>
      );
    case StatusReadyState.Syncing:
      if (syncingAssets.length === 0) return null;
      return (
        <ul className="flex max-h-[80dvh] max-w-[352px] flex-col">
          {syncingAssets.map((asset) => (
            <li key={asset.url} className="py-8 pr-8">
              <SyncedAsset asset={asset} />
            </li>
          ))}
        </ul>
      );
    case StatusReadyState.Failed:
      return (
        <div className="flex flex-row gap-8">
          <Button onClick={onSupportButtonClicked}>Contact Support</Button>
          {shouldAllowUserToResync && (
            <Button variant="line" onClick={resync} disabled={loading}>
              Resync
            </Button>
          )}
        </div>
      );
    case StatusReadyState.EmptyData:
      return <AddWalletButton />;
    case StatusReadyState.Ready:
    default: {
      if (
        freezingStatus === UserFreezingStatus.FreezingInProgress ||
        freezingStatus === UserFreezingStatus.UnfreezingInProgress
      ) {
        return (
          <div className="flex w-full flex-row place-items-center gap-12">
            <div className="flex h-40 w-40 flex-col place-items-center justify-center rounded-full bg-background-alternate-consistent text-accent-bold-blue">
              <Snowflake size={18} />
            </div>
            <Body
              weight="bold"
              variant="body2"
              className="flex-1 truncate text-text-primary"
            >
              {`Transactions from ${dayjs(freezingStartTime).year()}-${dayjs(freezingEndTime).year()}`}
            </Body>
            {/* design shows 40px but it takes a bit of extra space so 38 seems to cover the avatar on the left better */}
            <StatusIndicator
              status={status}
              freezingStatus={freezingStatus}
              circleClass="h-[38px]"
            />
          </div>
        );
      }
      return null;
    }
  }
}

function SyncedAsset({ asset }: { asset: SyncAssetItem }) {
  return (
    <div className="flex flex-row items-center gap-12">
      <Avatar
        src={asset.imgUrl}
        fallbackText={asset.title}
        alt={`${asset.title} logo`}
        size="sm"
        variant="image"
        className="rounded-8"
      />
      <div className="flex flex-1 flex-col">
        <Body
          weight="bold"
          variant="body2"
          className="inline truncate text-text-primary"
        >
          {asset.title}
        </Body>
        <Body variant="body4" className="inline truncate text-text-secondary">
          {formatWalletLabel(asset.subtitle)}
        </Body>
      </div>
      <StatusIndicator
        status={
          asset.isSyncing ? StatusReadyState.Syncing : StatusReadyState.Ready
        }
        freezingStatus={null}
      />
    </div>
  );
}
