import { Body } from '@cointracker/styleguide/src/LoggedIn';

interface HighlightedIntegrationNameProps {
  integrationName: string;
  searchQuery: string;
}

export const HighlightedIntegrationName = (
  props: HighlightedIntegrationNameProps,
) => {
  const integrationName = props.integrationName;
  const searchQuery = props.searchQuery.toUpperCase();
  const upperIntegration = integrationName.toUpperCase();
  if (upperIntegration.includes(searchQuery)) {
    const index = upperIntegration.indexOf(searchQuery);
    const nonHighlightStart = integrationName.substring(0, index);
    const highlight = integrationName.substring(
      index,
      index + searchQuery.length,
    );
    const nonHighlightEnd = integrationName.substring(
      index + searchQuery.length,
    );
    return (
      <Body variant="body2" className="whitespace-break">
        {nonHighlightStart}
        <span className="whitespace-pre bg-background-alternate">
          {highlight}
        </span>
        {nonHighlightEnd}
      </Body>
    );
  }
  return <Body variant="body2">{integrationName}</Body>;
};
