import adaEmbed from '@ada-support/embed2';
import { useApolloClient } from '@apollo/client';
import { useRequestTaxRecalculationMutation } from '@cointracker/graphql-types';
import {
  Checkbox,
  Status,
  ToastContext,
  cn,
  useSidebar,
} from '@cointracker/styleguide';
import {
  Body3,
  Body4,
  Body5,
  Button,
  H5,
} from '@cointracker/styleguide/src/LoggedIn';
import { REBRAND_URLS } from '@cointracker/ui';
import {
  ArrowClockwise,
  ArrowLeft,
  Files,
  IdentificationBadge,
  QuestionMark,
  SealCheck,
  Stethoscope,
} from '@phosphor-icons/react';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { UserContext } from 'src/app/user/context';
import { URLS } from 'src/common/urls';
import { getCostBasisStatus } from 'src/components/CostBasisProvider/queries/getCostBasisStatus.graphql'; // use src/
import { useTaxReadiness } from '../../hooks/useTaxReadiness';
import { isAuthenticatedUser } from '../../utils';
import { useTaxReadinessStore } from '../utils';

const GatherCryptoContent = ({
  onClickPrimary,
}: {
  onClickPrimary: () => void;
}) => {
  return (
    <div className="flex flex-col gap-16">
      <Body4>
        Make sure you&apos;ve added all your wallets and exchanges, and that
        your CSVs are up to date. We&apos;ll take care of the rest.
      </Body4>
      <Body4>
        Note: There&apos;s no need to input forms from your exchanges or
        manually enter transactions if you&apos;ve added all your crypto.
      </Body4>
      <div className="flex gap-10">
        <Button size="small" variant="highEmphasis" onClick={onClickPrimary}>
          Review added wallets
        </Button>
      </div>
    </div>
  );
};

const GetInSyncContent = ({
  onClickPrimary,
  automaticallySyncedWalletsCount,
  syncIssuesCount,
}: {
  onClickPrimary: () => void;
  automaticallySyncedWalletsCount: number;
  syncIssuesCount: number;
}) => {
  return (
    <div className="flex flex-col gap-16 pt-16">
      <Body4>
        Re-authenticate your wallets to make sure we have the latest data for
        your transactions.
      </Body4>
      <div className="flex flex-row justify-between">
        <Body4>Wallets and exchanges to re-authenticate</Body4>
        <Body4>{syncIssuesCount}</Body4>
      </div>
      <div className="flex flex-row justify-between">
        <Body4>Automatically synced</Body4>
        <Body4>{automaticallySyncedWalletsCount}</Body4>
      </div>
      <div className="flex gap-10">
        <Button size="small" variant="highEmphasis" onClick={onClickPrimary}>
          Review account health
        </Button>
      </div>
    </div>
  );
};

const OptimizeTaxSavingsContent = ({
  onClickPrimary,
  onClickSecondary,
  transactionCount,
  errorsCount,
}: {
  onClickPrimary: () => void;
  onClickSecondary: () => void;
  transactionCount: number;
  errorsCount: number;
}) => {
  const [isRecalculating, setIsRecalculating] = useState(false);

  const handleRecalculate = () => {
    setIsRecalculating(true);
    onClickSecondary();
    setTimeout(() => setIsRecalculating(false), 2000);
  };

  const errorPercentage = Math.round(
    errorsCount > 0 ? Math.max((errorsCount / transactionCount) * 100, 3) : 0,
  );
  const accuratePercentage = 100 - errorPercentage;

  return (
    <div className="flex flex-col gap-24 pt-16">
      <Body4>
        We found {errorsCount} transactions that may have an inaccurate cost
        basis out of {transactionCount} recorded this year.
      </Body4>

      <div className="rounded-8 bg-background-alternate-lighter p-16">
        <div className="relative flex h-16 w-full rounded-full">
          <div
            className="h-full rounded-l-8 bg-green-15"
            style={{ width: `${accuratePercentage}%` }}
            aria-label="Transactions with accurate cost basis"
          />
          <div
            className="h-full rounded-r-8 bg-yellow-20"
            style={{ width: `${errorPercentage}%` }}
            aria-label="Transactions with cost basis issues"
          />
          <div
            className="border-blue-400 absolute -bottom-10 -top-10 border-l-2 border-dashed"
            style={{ left: `${accuratePercentage}%` }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-8">
        <Body4>
          For further support on how to understand reviewing suggested
          transactions, read our{' '}
          <a
            href="https://support.cointracker.io/hc/en-us/articles/14327061214865-Understand-review-suggested-flags"
            target="_blank"
            className="text-text-link"
            rel="noopener noreferrer"
          >
            guide
          </a>
          .
        </Body4>

        <ol className="flex flex-col gap-8">
          <li className="flex gap-8">
            <Body4>1. First, review and update these transactions</Body4>
          </li>
          <li className="flex gap-8">
            <Body4>
              2. Then, recalculate the cost basis to run another accuracy check
            </Body4>
          </li>
        </ol>
      </div>

      <div className="flex w-full gap-16">
        <Button size="small" onClick={onClickPrimary} className="flex-1">
          Review transactions
        </Button>
        <Button
          size="small"
          variant="highEmphasis"
          className="flex flex-1 items-center justify-center gap-8"
          onClick={handleRecalculate}
          disabled={isRecalculating}
        >
          {isRecalculating ? (
            <ArrowClockwise size={18} weight="bold" className="animate-spin" />
          ) : (
            <>
              <ArrowClockwise size={18} weight="bold" />
              Recalculate
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

const GenerateFormsContent = ({
  onClickPrimary,
}: {
  onClickPrimary: () => void;
}) => {
  return (
    <div className="flex flex-col gap-16 pt-16">
      <Body4>
        Review your calculated tax estimate and generate tax forms when
        you&apos;re ready to file.
      </Body4>
      <div className="flex gap-16">
        <Button size="small" variant="highEmphasis" onClick={onClickPrimary}>
          Generate forms
        </Button>
      </div>
    </div>
  );
};

interface StepProps {
  id: number;
  title: string;
  isCompleted?: boolean;
  isExpanded?: boolean;
  children: React.ReactNode;
  setIsCompleted: (isCompleted: boolean) => void;
}

const TaxReadinessStep = ({
  id,
  title,
  isCompleted = false,
  isExpanded = false,
  children,
  setIsCompleted,
}: StepProps) => {
  return (
    <div className="rounded-[14px] border border-line-card p-16">
      <div className="flex items-center justify-between">
        <Body3>
          {id}. {title}
        </Body3>
        <Checkbox
          checked={isCompleted}
          onClick={() => setIsCompleted(!isCompleted)}
        />
      </div>
      <div
        className={cn(
          'overflow-hidden',
          isExpanded
            ? 'mt-16 max-h-[1000px] opacity-100 transition-all duration-300 ease-in'
            : 'mt-0 max-h-0 opacity-0 transition-all duration-200 ease-out',
        )}
      >
        {children}
      </div>
    </div>
  );
};

const HelpScreen = ({ onBack }: { onBack: () => void }) => {
  const user = useContext(UserContext);
  const {
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
  } = useTaxReadiness();
  const analytics = useAnalytics();

  const onClickChatWithSupport = useCallback(async () => {
    if (!isAuthenticatedUser(user)) return;
    analytics.track('Tax Readiness Clicked', {
      action: 'Chat With Support',
      step_1_completed: addWalletsStepChecked,
      step_2_completed: syncWalletsStepChecked,
      step_3_completed: optimizeTaxSavingsStepChecked,
      step_4_completed: reviewTaxFormsStepChecked,
    });
    try {
      if ((await adaEmbed.getInfo()).hasActiveChatter) return adaEmbed.stop();
    } catch {
      // can't get info on adaEmbed if it isn't started
    }
    const subscriptionPlan = user.unifiedSubscription?.subscriptionPlan ?? '';
    await adaEmbed?.start({
      handle: 'cointracker',
      metaFields: {
        meta_user_authorized: user.isAuthenticated,
        meta_ct_user_2FA_enabled: user.isTwoStepVerificationEnabled,
        meta_ct_user_email: user.email,
        meta_ct_portfolio_subscription: user.portfolioSubscription,
        meta_ct_unified_plan:
          subscriptionPlan.charAt(0).toUpperCase() +
          subscriptionPlan.substring(1).toLowerCase(),
      },
    });
    await adaEmbed.toggle();
  }, [
    user,
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
    analytics,
  ]);

  const trackAndOpenLink = (url: string, action: string) => {
    analytics.track('Tax Readiness Clicked', {
      action,
      step_1_completed: addWalletsStepChecked,
      step_2_completed: syncWalletsStepChecked,
      step_3_completed: optimizeTaxSavingsStepChecked,
      step_4_completed: reviewTaxFormsStepChecked,
    });
    window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col gap-48">
      <div className="flex flex-col gap-8">
        <button
          onClick={onBack}
          className="flex items-center gap-8 text-text-primary"
        >
          <ArrowLeft size={20} weight="bold" /> Back
        </button>

        <H5 className="mb-8 mt-8 text-text-primary-foreground">
          How can we help?
        </H5>

        <Body4 className="text-text-secondary">
          Find answers to questions, troubleshoot any issues, or even upgrade to
          a bespoke full service experience.
        </Body4>
      </div>

      <div className="grid grid-cols-2 gap-16">
        <button
          className="flex flex-col items-center rounded-16 border border-line-card pb-32 pl-24 pr-24 pt-32 hover:bg-line-secondary focus:border-line-card-highlight focus:bg-background-card-highlight"
          onClick={() =>
            trackAndOpenLink(REBRAND_URLS.BLOG_TAX_GUIDE, 'Read Guide')
          }
        >
          <div className="mb-12 flex h-48 w-48 items-center justify-center rounded-8 bg-background-alternate-consistent">
            <Files
              size={24}
              weight="regular"
              className="text-text-primary-foreground-inverse-consistent"
            />
          </div>
          <Body4 className="text-text-primary-foreground">
            Finish your taxes
          </Body4>
          <Body5 className="text-text-secondary">Read our guide</Body5>
        </button>

        <button
          className="flex flex-col items-center rounded-16 border border-line-card pb-32 pl-24 pr-24 pt-32 hover:bg-line-secondary focus:border-line-card-highlight focus:bg-background-card-highlight"
          onClick={onClickChatWithSupport}
        >
          <div className="mb-12 flex h-48 w-48 items-center justify-center rounded-8 bg-background-alternate-consistent">
            <IdentificationBadge
              size={24}
              weight="regular"
              className="text-text-primary-foreground-inverse-consistent"
            />
          </div>
          <Body4 className="text-text-primary-foreground">
            Contact Support
          </Body4>
          <Body5 className="text-text-secondary">Get help</Body5>
        </button>

        <button
          className="flex flex-col items-center rounded-16 border border-line-card pb-32 pl-24 pr-24 pt-32 hover:bg-line-secondary focus:border-line-card-highlight focus:bg-background-card-highlight"
          onClick={() =>
            trackAndOpenLink(
              'https://webforms.pipedrive.com/f/clNHcKjtzUSG7y3o8wT3ESOfeHpaFtfCzVL55DXlT78FImcNh0tGmb67bJfldlqKJR',
              'Get Reconciliation Support',
            )
          }
        >
          <div className="mb-12 flex h-48 w-48 items-center justify-center rounded-8 bg-background-alternate-consistent">
            <Stethoscope
              size={24}
              weight="regular"
              className="text-text-primary-foreground-inverse-consistent"
            />
          </div>
          <Body4 className="text-text-primary-foreground">
            Get reconciliation support
          </Body4>
          <Body5 className="text-text-secondary">Hire an expert</Body5>
        </button>

        <button
          className="flex flex-col items-center rounded-16 border border-line-card pb-32 pl-24 pr-24 pt-32 hover:bg-line-secondary focus:border-line-card-highlight focus:bg-background-card-highlight"
          onClick={() =>
            trackAndOpenLink(URLS.FULL_SERVICE_PAGE, 'Go Full Service')
          }
        >
          <div className="mb-12 flex h-48 w-48 items-center justify-center rounded-8 bg-background-alternate-consistent">
            <SealCheck
              size={24}
              weight="regular"
              className="text-text-primary-foreground-inverse-consistent"
            />
          </div>
          <Body4 className="text-text-primary-foreground">
            Go full service
          </Body4>
          <Body5 className="text-text-secondary">Learn more</Body5>
        </button>
      </div>
    </div>
  );
};

export const TaxReadinessStatusSheetContent = () => {
  const analytics = useAnalytics();
  const {
    currentActiveStepNumber,
    completedStepsCount,
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
    errorsCount,
    transactionCount,
    automaticallySyncedWalletsCount,
    syncIssuesCount,
    taxYear,
  } = useTaxReadiness();
  const { setIsTaxReadinessSheetOpen } = useSidebar();
  const {
    setAddWalletsCheckedByUser,
    setSyncWalletsCheckedByUser,
    setOptimizeTaxSavingsCheckedByUser,
    setReviewTaxFormsCheckedByUser,
  } = useTaxReadinessStore();
  const navigate = useNavigate();
  const [showHelpScreen, setShowHelpScreen] = useState(false);

  const onMarkAsDone = () => {
    analytics.track('Tax Readiness Clicked', {
      action: 'Mark all as done',
      step_1_completed: addWalletsStepChecked,
      step_2_completed: syncWalletsStepChecked,
      step_3_completed: optimizeTaxSavingsStepChecked,
      step_4_completed: reviewTaxFormsStepChecked,
    });
    setAddWalletsCheckedByUser(true);
    setSyncWalletsCheckedByUser(true);
    setOptimizeTaxSavingsCheckedByUser(true);
    setReviewTaxFormsCheckedByUser(true);
    window.location.href = URLS.TAX_PAGE;
  };
  const { showNotification } = useContext(ToastContext);
  const client = useApolloClient();
  const [recalculateTaxes] = useRequestTaxRecalculationMutation({
    onCompleted: (data) => {
      if (data.requestTaxRecalculation?.success) {
        showNotification({
          message: 'Recalculating your taxes...',
          status: Status.Success,
        });
      } else {
        showNotification({
          message:
            'There was an error while trying to request the recalculation. Please try again later.',
          status: Status.Error,
        });
      }
    },
  });

  const onContinue = () => {
    analytics.track('Tax Readiness Clicked', {
      action: 'Continue',
      step_1_completed: addWalletsStepChecked,
      step_2_completed: syncWalletsStepChecked,
      step_3_completed: optimizeTaxSavingsStepChecked,
      step_4_completed: reviewTaxFormsStepChecked,
    });
    if (completedStepsCount === 4 || currentActiveStepNumber === 4)
      return setIsTaxReadinessSheetOpen(false);

    switch (currentActiveStepNumber) {
      case 1:
        setAddWalletsCheckedByUser(true);
        break;
      case 2:
        setSyncWalletsCheckedByUser(true);
        break;
      case 3:
        setOptimizeTaxSavingsCheckedByUser(true);
        break;
      case 4:
        setReviewTaxFormsCheckedByUser(true);
        break;
    }
  };

  const navigateToPageAndCloseSheet = (page: string) => {
    navigate(page);
    setIsTaxReadinessSheetOpen(false);
  };

  const onCheckLastStep = (checked: boolean) => {
    setReviewTaxFormsCheckedByUser(checked);
    if (checked && currentActiveStepNumber === 4) {
      setIsTaxReadinessSheetOpen(false);
      window.location.href = URLS.TAX_PAGE;
    }
  };

  const onRecalculate = async () => {
    await recalculateTaxes();
    await client.refetchQueries({
      include: [getCostBasisStatus],
    });
  };

  const handleHelpClick = () => {
    setShowHelpScreen(true);
  };

  const trackAndSetIsCompleted = (
    setIsCompleted: (isCompleted: boolean) => void,
    stepName: string,
  ) => {
    return (isCompleted: boolean) => {
      analytics.track('Tax Readiness Clicked', {
        action: `User Checked Step - ${stepName} - ${isCompleted}`,
        step_1_completed: addWalletsStepChecked,
        step_2_completed: syncWalletsStepChecked,
        step_3_completed: optimizeTaxSavingsStepChecked,
        step_4_completed: reviewTaxFormsStepChecked,
      });
      setIsCompleted(isCompleted);
    };
  };

  return (
    <div className="flex flex-col gap-48 p-40">
      {showHelpScreen ? (
        <HelpScreen onBack={() => setShowHelpScreen(false)} />
      ) : (
        <>
          <div className="flex flex-col gap-8">
            <H5>{taxYear} taxes</H5>

            <Body4 className="my-4 text-text-secondary">
              Ready to save time and money on your taxes? Just complete these 4
              steps and you&apos;ll be good to go.
            </Body4>

            <Body4 className="mb-6 mt-2 text-text-secondary">
              Disclaimer: None of the above is or should be construed as legal,
              tax, audit, accounting, or brokerage advice. All information is
              provided for informational purposes only.
            </Body4>
          </div>

          <div className="mb-8 flex flex-col gap-12">
            <TaxReadinessStep
              id={1}
              title="Gather all your crypto"
              isCompleted={addWalletsStepChecked}
              isExpanded={currentActiveStepNumber === 1}
              setIsCompleted={trackAndSetIsCompleted(
                setAddWalletsCheckedByUser,
                'Gather all your crypto',
              )}
            >
              <GatherCryptoContent
                onClickPrimary={() => {
                  analytics.track('Tax Readiness Clicked', {
                    action: 'Review added wallets',
                    step_1_completed: addWalletsStepChecked,
                    step_2_completed: syncWalletsStepChecked,
                    step_3_completed: optimizeTaxSavingsStepChecked,
                    step_4_completed: reviewTaxFormsStepChecked,
                  });
                  navigateToPageAndCloseSheet(URLS.WALLETS_PAGE);
                }}
              />
            </TaxReadinessStep>

            <TaxReadinessStep
              id={2}
              title="Get in sync"
              isCompleted={syncWalletsStepChecked}
              isExpanded={currentActiveStepNumber === 2}
              setIsCompleted={trackAndSetIsCompleted(
                setSyncWalletsCheckedByUser,
                'Get in sync',
              )}
            >
              <GetInSyncContent
                onClickPrimary={() => {
                  analytics.track('Tax Readiness Clicked', {
                    action: 'Review account health',
                    step_1_completed: addWalletsStepChecked,
                    step_2_completed: syncWalletsStepChecked,
                    step_3_completed: optimizeTaxSavingsStepChecked,
                    step_4_completed: reviewTaxFormsStepChecked,
                  });
                  navigateToPageAndCloseSheet(URLS.RECONCILIATION);
                }}
                automaticallySyncedWalletsCount={
                  automaticallySyncedWalletsCount
                }
                syncIssuesCount={syncIssuesCount}
              />
            </TaxReadinessStep>

            <TaxReadinessStep
              id={3}
              title="Optimize tax savings"
              isCompleted={optimizeTaxSavingsStepChecked}
              isExpanded={currentActiveStepNumber === 3}
              setIsCompleted={trackAndSetIsCompleted(
                setOptimizeTaxSavingsCheckedByUser,
                'Optimize tax savings',
              )}
            >
              <OptimizeTaxSavingsContent
                onClickPrimary={() => {
                  analytics.track('Tax Readiness Clicked', {
                    action: 'Review transactions',
                    step_1_completed: addWalletsStepChecked,
                    step_2_completed: syncWalletsStepChecked,
                    step_3_completed: optimizeTaxSavingsStepChecked,
                    step_4_completed: reviewTaxFormsStepChecked,
                  });
                  navigateToPageAndCloseSheet(
                    URLS.TRANSACTIONS_PAGE_NEEDS_REVIEW +
                      `+from%3A${taxYear}-01-01+to%3A${taxYear}-12-31`,
                  );
                }}
                onClickSecondary={onRecalculate}
                transactionCount={transactionCount}
                errorsCount={errorsCount}
              />
            </TaxReadinessStep>

            <TaxReadinessStep
              id={4}
              title="Generate forms"
              isCompleted={reviewTaxFormsStepChecked}
              isExpanded={currentActiveStepNumber === 4}
              setIsCompleted={trackAndSetIsCompleted(
                onCheckLastStep,
                'Generate forms',
              )}
            >
              <GenerateFormsContent
                onClickPrimary={() => {
                  analytics.track('Tax Readiness Clicked', {
                    action: 'Generate forms',
                    step_1_completed: addWalletsStepChecked,
                    step_2_completed: syncWalletsStepChecked,
                    step_3_completed: optimizeTaxSavingsStepChecked,
                    step_4_completed: reviewTaxFormsStepChecked,
                  });
                  setReviewTaxFormsCheckedByUser(true);
                  window.location.href = URLS.TAX_PAGE;
                }}
              />
            </TaxReadinessStep>
          </div>

          <div className="mt-auto flex items-center justify-between gap-[12px] pb-16 pt-8">
            <button
              className="text-sm flex cursor-pointer items-center border-none bg-transparent text-text-link"
              onClick={handleHelpClick}
            >
              Get help{' '}
              <QuestionMark size={18} className="mb-4 ml-4" weight="bold" />
            </button>
            <div className="flex gap-12">
              {completedStepsCount < 4 && (
                <Button size="small" onClick={onMarkAsDone}>
                  Mark all as done
                </Button>
              )}
              <Button size="small" variant="line" onClick={onContinue}>
                {completedStepsCount < 4 ? 'Continue' : 'Done'}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
