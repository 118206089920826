import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Button } from '@cointracker/styleguide/src/LoggedIn/Button';
import base64 from 'base64-js';
import React, { useState } from 'react';
import nacl from 'tweetnacl';
import PublicKeyCopyButton from '../PublicKey';
import { downloadFile } from './util';

interface KeyPairGeneratorProps {
  onGenerate: (key: string) => void;
}

const KeyPairGenerator: React.FC<KeyPairGeneratorProps> = ({
  onGenerate,
}: KeyPairGeneratorProps) => {
  const [publicKey, setPublicKey] = useState('');
  const [error, setError] = useState<string | null>(null);

  const generateKeyPair = async () => {
    try {
      // Generate an Ed25519 key pair using tweetnacl
      const keyPair = nacl.sign.keyPair();

      // Convert keys to Base64
      const publicKeyBase64 = base64.fromByteArray(keyPair.publicKey);

      // TweetNaCl keyPair.secretKey is 32-byte seed + 32-byte public key - we only want the private key seed
      const privateKeySeed = keyPair.secretKey.slice(0, 32);
      const privateKeyBase64 = base64.fromByteArray(privateKeySeed);

      setPublicKey(publicKeyBase64);
      onGenerate(privateKeyBase64);

      // prompt user to download the public and private keys
      downloadFile('robinhood_private_key.txt', privateKeyBase64);
      downloadFile('robinhood_public_key.txt', publicKeyBase64);
    } catch (error) {
      setError('Failed to generate key pair. Please try again.');
    }
  };

  return (
    <div className="mb-24 mt-4 flex flex-col rounded-8 bg-background-alternate-lighter p-16 [&_.alert]:text-text-negative">
      <Body variant="body1" className="mb-8">
        Create a new key pair for Robinhood
      </Body>
      <Body variant="body2" className="mb-12 text-text-secondary">
        Robinhood requires a public and private key to generate an API key. To
        make this process seamless, we&apos;ve added a button that instantly
        creates a secure key pair for you.
      </Body>
      <Body variant="body2" className="text-text-secondary">
        Click the button below to generate your keys—they&apos;ll be
        automatically downloaded to your device. Then, follow the instructions
        below to add your Robinhood account.
      </Body>
      <Button
        onClick={generateKeyPair}
        aria-label="Generate a new key pair"
        variant="line"
        size="small"
        className="mt-24"
      >
        Generate Key Pair
      </Button>
      {error && (
        <div className="mt-24 flex items-center gap-8">
          <Body variant="body2" className="text-text-negative">
            Failed to generate key pair
          </Body>
        </div>
      )}
      {publicKey && (
        <div className="mt-24 flex items-center gap-8">
          <Body variant="body2" className="text-text-primary">
            Public key: {publicKey}
          </Body>
          <PublicKeyCopyButton publicKey={publicKey} />
        </div>
      )}
    </div>
  );
};

export default KeyPairGenerator;
