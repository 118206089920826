import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  type ReactNode,
  type Ref,
} from 'react';
import ArrowRight from '../icons/arrow-right.svg?react';
import { ButtonText, NavButtonText } from '../typography';
import {
  button,
  buttonIconStyle,
  buttonTextStyle,
  leftArrowStyle,
  rightArrowStyle,
  textLinkRightArrowStyle,
  textLinkTextStyle,
  type ButtonVariants,
} from './Button.css';

export type ButtonProps = {
  children?: ReactNode;
  href?: string;
  disabled?: boolean;
  className?: string;
  inNav?: boolean;
  fluid?: boolean;
  hideInitialIcon?: boolean;
  noAnimation?: boolean;
  style?: React.CSSProperties;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & ButtonVariants;

export const Button = forwardRef(
  (
    {
      children,
      className,
      style,
      onClick,
      disabled,
      href,
      variant,
      fluid,
      inNav,
      hideInitialIcon,
      noAnimation,
      icon,
      ...props
    }: ButtonProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ) => {
    const onClickHandler: React.MouseEventHandler<HTMLButtonElement> =
      useCallback(
        (e) => {
          if (typeof window === 'undefined') return;
          if (href) {
            window.location.href = href;
          } else if (onClick) {
            onClick(e);
          } else {
            console.warn('Button clicked, but no href or onClick provided!');
          }
        },
        [href, onClick],
      );

    return (
      <button
        ref={forwardedRef}
        type="button"
        disabled={disabled}
        onClick={onClickHandler}
        className={classNames(
          button({
            variant,
            fluid,
            inNav,
            hideInitialIcon,
            noAnimation,
          }),
          className,
        )}
        style={style}
        {...props}
      >
        {inNav && (
          <NavButtonText variant="inherit" className={buttonTextStyle}>
            <ArrowRight className={leftArrowStyle} />
            {icon && <span className={buttonIconStyle}>{icon}</span>}
            {children}
            <ArrowRight className={rightArrowStyle} />
          </NavButtonText>
        )}
        {!inNav && variant !== 'textLink' && (
          <ButtonText className={buttonTextStyle} variant="inherit">
            <ArrowRight className={leftArrowStyle} />
            {icon && <span className={buttonIconStyle}>{icon}</span>}
            {children}
            <ArrowRight className={rightArrowStyle} />
          </ButtonText>
        )}
        {!inNav && variant === 'textLink' && (
          <ButtonText className={textLinkTextStyle} variant="inherit">
            {icon && <span className={buttonIconStyle}>{icon}</span>}
            {children}
            <ArrowRight className={textLinkRightArrowStyle} />
          </ButtonText>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
