import { H3 } from '@cointracker/styleguide/src/LoggedIn';

interface ProvisionPageProps {
  isSuccess: boolean;
}
export default function ProvisionPage({ isSuccess }: ProvisionPageProps) {
  const message = isSuccess
    ? 'You are now correctly provisioned. Thank you!'
    : 'There was an unknown error provisioning your user. Contact Carbon.';
  return (
    <div className="flex flex-1 flex-col items-center justify-center text-center">
      <div className="mx-auto max-w-[600px]">
        <H3>{message}</H3>
      </div>
    </div>
  );
}
