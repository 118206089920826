import { Body, Button, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { UploadSimple } from '@phosphor-icons/react';
import React from 'react';
import CSV_ICON from 'src/assets/csv-icon.svg?url';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';

export const CSVIntegration: Integration = {
  slug: 'csv file import',
  name: 'CSV',
  rank: 0,
  info: {
    circleIconUrl: CSV_ICON,
  },
  category: null,
  syncMethod: IntegrationSyncMethod.CoinTrackerCSV,
};

interface BlankSearchResultsProps {
  setSelectedIntegration: React.Dispatch<React.SetStateAction<Integration>>;
}

export function BlankSearchResults(props: BlankSearchResultsProps) {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-12">
      <Heading variant="h6" className="text-text-secondary">
        No results found
      </Heading>
      <Body variant="body3" className="text-center text-text-secondary">
        We haven&apos;t found the integration you&apos;re looking for.
        <br />
        You can upload a CSV file instead.
      </Body>
      <Button
        onClick={() => props.setSelectedIntegration(CSVIntegration)}
        size="large"
        variant="line"
      >
        <UploadSimple size={18} weight="bold" />
        Upload CSV
      </Button>
    </div>
  );
}
