// TODO: move to backend enum, same as EVM_CHAINS in MultiAddressWalletParser (multi_address_wallet_parser.py)
export const SUPPORTED_EVM_CHAIN_SLUGS = [
  'ethereum',
  'arbitrum-one',
  'avalanche',
  'binance-smart-chain',
  'fantom',
  'optimism',
  'polygon',
  'base',
  'zksync-era',
];
