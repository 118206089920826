import {
  cn,
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
  useSidebar,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { useContext } from 'react';
import { FreezingStatusContext } from '../../../FreezingStatusProvider/context';
import { StaticIconSidebar } from '../StaticIconSidebar';
import { useGetStatus } from './hooks/useGetStatus';
import { StatusButton } from './StatusButton';
import { StatusIndicator } from './StatusIndicator';
import { StatusSheetContent } from './StatusSheetContent';

interface StatusProps {
  closeOnly?: boolean;
  className?: string;
}

export const Status = ({ closeOnly = false, className }: StatusProps) => {
  const { status } = useGetStatus();
  const { status: freezingStatus } = useContext(FreezingStatusContext);
  const {
    isMobile,
    toggleStatusSheet,
    isStatusSheetOpen,
    setIsStatusSheetOpen,
  } = useSidebar();
  if (closeOnly) {
    return (
      <SheetTrigger asChild>
        <Button
          variant="line"
          size="small"
          className={cn(
            'flex flex-row place-items-center gap-8 md:p-0',
            className,
          )}
          transparent={!isMobile}
          onClick={() => {
            toggleStatusSheet();
          }}
        >
          <StatusIndicator status={status} freezingStatus={freezingStatus} />
        </Button>
      </SheetTrigger>
    );
  }

  return (
    <Sheet
      modal={true}
      onOpenChange={setIsStatusSheetOpen}
      open={isStatusSheetOpen}
    >
      <StatusButton />
      <SheetContent
        side={isMobile ? 'bottom' : 'left'}
        className={cn(
          'z-50 h-full max-w-[--sidebar-width-status-open] overscroll-contain border-sidebar-border bg-sidebar p-0 text-sidebar-foreground md:z-10',
          {
            'top-topbar-height !max-w-full': isMobile,
          },
        )}
        statusSlider
      >
        <SheetTitle className="sr-only">Account Status</SheetTitle>
        <div className="flex h-full w-full flex-row">
          <StaticIconSidebar className="hidden md:flex" />
          <StatusSheetContent />
        </div>
      </SheetContent>
    </Sheet>
  );
};
