export const CoverageInfoContainer = ({
  html,
}: {
  html: string | TrustedHTML;
}) => {
  return (
    <div
      className="flex flex-col rounded-8 bg-background-alternate-lighter p-16 [&_.alert]:text-text-negative"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
