import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { SolanaAdapter } from '@reown/appkit-adapter-solana/react';
import {
  arbitrum,
  avalanche,
  base,
  mainnet,
  optimism,
  polygon,
  solana,
} from '@reown/appkit/networks';
import { createAppKit } from '@reown/appkit/react';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { iconsImagesURL } from 'src/common/constants';

const initAppKit = () => {
  const solanaWeb3JsAdapter = new SolanaAdapter({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    wallets: [new PhantomWalletAdapter(), new SolflareWalletAdapter()],
  });

  const projectId = process.env.PUBLIC_WALLET_CONNECT_PROJECT_ID;

  const metadata = {
    name: 'Cointracker',
    description: 'Crypto Taxes and Portfolio Tracker',
    url: 'https://cointracker.com', // origin must match your domain & subdomain
    icons: [`${iconsImagesURL}/logo_white.svg`],
  };

  const appKit = createAppKit({
    adapters: [solanaWeb3JsAdapter, new EthersAdapter()],
    networks: [mainnet, arbitrum, optimism, base, avalanche, polygon, solana],
    featuredWalletIds: [
      'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // Coinbase Wallet
    ],
    projectId,
    metadata,
    enableWalletConnect: true,
    enableCoinbase: true,
    coinbasePreference: 'smartWalletOnly',
    features: {
      onramp: false,
      swaps: false,
      email: false,
      socials: false,
    },
  });

  return appKit;
};

initAppKit();

interface AppKitProviderProps {
  children: React.ReactNode;
}

export const AppKitProvider = ({ children }: AppKitProviderProps) => {
  return <>{children}</>;
};
