// Helper function to get a cookie by name
export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i]?.trim();
    if (cookie && cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
};

// Helper function to set a cookie
export const setCookie = (
  name: string,
  data: object | string,
  days: number,
) => {
  const value = typeof data === 'string' ? data : JSON.stringify(data);
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Strict; Secure`;
};
