import React, { useEffect } from 'react';
import useScript from 'src/hooks/useScript';
import { User, isAuthenticatedUser } from './user/context';

const ADORA_SCRIPT_URL = 'https://adora-cdn.com/adora-start.js';

interface AdoraProviderProps {
  children: React.ReactNode;
  adoraOrgId: string;
  user: User;
}

const AdoraProvider = ({ children, adoraOrgId, user }: AdoraProviderProps) => {
  const scriptStatus = useScript(ADORA_SCRIPT_URL);

  useEffect(() => {
    if (scriptStatus === 'ready') {
      const adoraStart = (
        window as typeof window & {
          adoraStart?: (config: { orgId: string }) => void;
        }
      ).adoraStart;
      if (adoraStart) {
        const props = { orgId: adoraOrgId };
        if (isAuthenticatedUser(user)) {
          const plan = user.unifiedSubscription?.subscriptionPlan;
          props['uid'] = user.publicId;
          props['userCohorts'] = {
            country: user.country.code,
            plan: plan,
          };
          // Bypass sampling for Ultra and Ultra Plus users
          props['bypassSampling'] =
            plan in ['ULTRA', 'ULTRA_PLUS', 'FULL_SERVICE'];
        }
        adoraStart(props);
      }
    }
  }, [scriptStatus, adoraOrgId, user]);

  return <>{children}</>;
};

const ConditionalAdoraProvider = ({
  children,
  adoraOrgId,
  user,
}: AdoraProviderProps) => {
  // Only include AdoraProvider in production
  if (!import.meta.env.PROD) {
    return <>{children}</>;
  }

  return (
    <AdoraProvider adoraOrgId={adoraOrgId} user={user}>
      {children}
    </AdoraProvider>
  );
};

export default ConditionalAdoraProvider;
