export const CBE_VIEW_MODE_KEY = 'CBE_VIEW_MODE' as const;
export const VIEW_MODES = {
  CONSUMER: 'consumer',
  BROKER: 'broker',
} as const;
export const CBE_SETTINGS_KEY = 'CBE_SETTINGS' as const;

export type ViewMode = (typeof VIEW_MODES)[keyof typeof VIEW_MODES];

export function getStoredViewMode() {
  return sessionStorage.getItem(CBE_VIEW_MODE_KEY);
}

export function setStoredViewMode(viewMode: ViewMode) {
  sessionStorage.setItem(CBE_VIEW_MODE_KEY, viewMode);
}

export function clearStoredViewMode() {
  sessionStorage.removeItem(CBE_VIEW_MODE_KEY);
}

export function getStoredCbeSettings() {
  return localStorage.getItem(CBE_SETTINGS_KEY);
}

export function setStoredCbeSettings(settings: string) {
  localStorage.setItem(CBE_SETTINGS_KEY, settings);
}

export function clearStoredCbeSettings() {
  localStorage.removeItem(CBE_SETTINGS_KEY);
}
