import { cva, type VariantProps } from 'class-variance-authority';

import { forwardRef } from 'react';
import { cn } from '../utils';

const inlineBannerVariants = cva(
  'bg-background-alternate-lighter rounded-8 relative flex min-h-64 w-full flex-row items-center gap-12 p-12 [&>svg]:mx-8',
  {
    variants: {
      variant: {
        info: 'text-text-primary',
        positive: 'text-text-positive',
        negative: 'text-text-negative',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
);

const InlineBanner = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof inlineBannerVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(inlineBannerVariants({ variant }), className)}
    {...props}
  />
));
InlineBanner.displayName = 'InlineBanner';

const InlineBannerContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} {...props} className={cn('flex flex-col', className)} />
));
InlineBannerContent.displayName = 'InlineBannerContent';

export { InlineBanner, InlineBannerContent };
