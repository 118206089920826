import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

interface UseOverflowGradientProps {
  containerRef: React.RefObject<HTMLDivElement>;
  vertical?: boolean;
}

// To be used with OverflowGradientLeft and OverflowGradientRight components
export const useOverflowGradient = ({
  containerRef,
  vertical = false,
}: UseOverflowGradientProps) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isNotScrolled, setIsNotScrolled] = useState(true);

  const checkScroll = useCallback(() => {
    if (!containerRef.current) return;
    const element = containerRef.current;
    if (element) {
      if (vertical) {
        setIsScrolledToEnd(
          !(
            element.scrollTop + element.clientHeight <
            element.scrollHeight - 1
          ),
        );
        setIsNotScrolled(element.scrollTop < 1);
        setIsScrollable(element.scrollHeight > element.clientHeight);
      } else {
        setIsScrolledToEnd(
          !(element.scrollLeft + element.clientWidth < element.scrollWidth - 1),
        );
        setIsNotScrolled(element.scrollLeft < 1);
        setIsScrollable(element.scrollWidth > element.clientWidth);
      }
    }
  }, [containerRef, vertical]);

  useEffect(() => {
    checkScroll();
    if (!containerRef.current) return;
    const element = containerRef.current;

    const debouncedCheckScroll = debounce(checkScroll, 100, {
      leading: true,
      trailing: true,
    });
    window.addEventListener('resize', debouncedCheckScroll);
    element.addEventListener('scroll', debouncedCheckScroll);

    return () => {
      window.removeEventListener('resize', debouncedCheckScroll);
      element.removeEventListener('scroll', debouncedCheckScroll);
      debouncedCheckScroll.cancel();
    };
  }, [containerRef, checkScroll]);

  return { isScrollable, isScrolledToEnd, isNotScrolled, checkScroll };
};
