import { ComponentType, lazy, Suspense, useEffect, useState } from 'react';
import { Spinner } from 'src/common/Spinner/Spinner';
import { RequireAuth } from '../Auth';
import { LoadingContainer } from '../LoadingContainer';

const RouteLoading = () => {
  // Only show loader if content is taking more than 1s to load
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadingContainer dataTestId="route-loading-container">
      {showLoading && <Spinner role="status" />}
    </LoadingContainer>
  );
};

interface RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: () => Promise<{ default: ComponentType<any> }>;
  isPublic?: boolean;
  allowOnlyAdmins?: boolean;
  forbidViewAs?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export const Lazy = (props: RouteProps) => {
  const Component = lazy(props.page);
  // By default a Route should require auth, only if explicity set will not check auth
  const requiresAuth = props.isPublic === true ? false : true;

  return (
    <RequireAuth
      requiresAuth={requiresAuth}
      allowOnlyAdmins={props.allowOnlyAdmins ?? false}
      forbidViewAs={props.forbidViewAs ?? false}
    >
      <Suspense fallback={<RouteLoading />}>
        <Component {...props} />
      </Suspense>
    </RequireAuth>
  );
};
