import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { cn } from '../utils';

export type TagsProps = {
  color?: variantProps['color'];
  filled?: boolean;
  condensed?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const tagsVariants = cva(
  'text-body5 md:text-body5-md lg:text-body5-lg flex w-fit items-center justify-center border border-solid',
  {
    variants: {
      condensed: {
        true: 'h-24 min-h-24 rounded-[8px] px-8 py-4',
        false: 'h-36 min-h-36 rounded-full px-12 py-4',
      },
      color: {
        positive: '',
        negative: '',
        negativeWash: '',
        blue: '',
        lilac: '',
        neon: '',
        black: '',
        grey: '',
        transparent: '',
        modeAccents: '',
      },
      filled: {
        true: '',
        false: 'bg-transparent',
      },
    },
    compoundVariants: [
      {
        color: 'modeAccents',
        filled: true,
        className:
          'bg-background-mode-accents text-text-primary-foreground-inverse',
      },
      {
        color: 'black',
        filled: false,
        className:
          'border-text-primary-foreground text-text-primary-foreground',
      },
      {
        color: 'black',
        filled: true,
        className:
          'border-text-primary-foreground bg-text-primary-foreground text-text-primary-foreground-inverse',
      },
      {
        color: 'positive',
        filled: true,
        className:
          'text-text-primary-foreground-inverse-consistent bg-background-positive border-background-positive',
      },
      {
        color: 'positive',
        filled: false,
        className: 'border-background-positive text-text-positive',
      },
      {
        color: 'negative',
        filled: true,
        className:
          'text-text-primary-foreground-inverse-consistent bg-background-negative border-accent-bold-red',
      },
      {
        color: 'negativeWash',
        filled: true,
        className:
          'text-text-negative-tag bg-background-negative-wash border-transparent',
      },
      {
        color: 'negativeWash',
        filled: false,
        className: 'border-accent-bold-red text-text-negative',
      },
      {
        color: 'blue',
        filled: true,
        className:
          'border-accent-bold-blue bg-accent-bold-blue text-accent-white',
      },
      {
        color: 'blue',
        filled: false,
        className: 'text-accent-bold-blue border-accent-bold-blue',
      },
      {
        color: 'lilac',
        filled: true,
        className:
          'border-accent-bold-lilac bg-accent-bold-lilac text-accent-black',
      },
      {
        color: 'lilac',
        filled: false,
        className: 'text-accent-bold-lilac border-accent-bold-lilac',
      },
      {
        color: 'neon',
        filled: true,
        className:
          'border-accent-bold-lime bg-accent-bold-lime text-accent-black',
      },
      {
        color: 'neon',
        filled: false,
        className: 'text-accent-bold-lime border-accent-bold-lime',
      },
      {
        color: 'grey',
        filled: true,
        className:
          'bg-accent-subtle-grey text-text-primary-consistent border-accent-subtle-grey',
      },
      {
        color: 'transparent',
        className: 'border-transparent bg-transparent',
      },
    ],
    defaultVariants: {
      color: 'black',
      filled: false,
      condensed: false,
    },
  },
);

export type variantProps = VariantProps<typeof tagsVariants>;

export const Tags = forwardRef(
  (
    { className, color, filled, condensed, children, ...props }: TagsProps,
    forwardedRef: React.Ref<HTMLSpanElement>,
  ) => {
    return (
      <span
        ref={forwardedRef}
        className={cn(tagsVariants({ color, filled, condensed }), className)}
        {...props}
      >
        {children}
      </span>
    );
  },
);

Tags.displayName = 'Tags';
