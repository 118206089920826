import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Integration } from 'src/types/graphql-types';

interface DefaultGenericInstructionsProps {
  integration: Integration;
}
export const DefaultGenericInstructions = ({
  integration,
}: DefaultGenericInstructionsProps) => {
  return (
    <>
      <Body variant="body2" className="text-text-secondary">
        How to add your {integration.name} wallet
      </Body>
      <ul className="list-decimal pl-24">
        <li className="mb-2 marker:text-text-secondary">
          <Body variant="body2" className="text-text-secondary">
            Paste your public address
          </Body>
        </li>
        <li className="mb-2 marker:text-text-secondary">
          <Body variant="body2" className="text-text-secondary">
            {`Click "Add ${integration.name}"`}
          </Body>
        </li>
      </ul>
    </>
  );
};
