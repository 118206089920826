import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { cn } from '@cointracker/styleguide/src/utils';
import { useEffect, useState } from 'react';
import { Integration } from 'src/types/graphql-types';
import { getIconURL, isOverrideIcon, setImgURL } from '../AddWalletCard/util';

export const getFirstLetterOfSlug = (slug = '') => {
  return slug?.substring(0, 1).toUpperCase();
};

interface WalletIconProps {
  integration: Integration;
  size?: 'small' | 'large';
}
export const WalletIcon = ({
  integration,
  size = 'large',
}: WalletIconProps) => {
  const isOverideIcon = integration ? isOverrideIcon(integration) : false;
  const [icon, setIcon] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const iconUrl = integration ? getIconURL(integration) : '';
    if (isOverideIcon) {
      setIcon(iconUrl);
      setIsLoading(false);
    } else {
      setImgURL(iconUrl, setIcon, setIsLoading, controller, isOverideIcon);
    }

    return () => {
      controller.abort();
    };
  }, [isOverideIcon, integration]);

  if (isLoading) {
    return (
      <img
        className={cn(
          'rounded-8 bg-transparent opacity-0',
          size === 'small' ? 'h-[27px] w-[27px]' : 'h-[40px] w-[40px]',
        )}
        alt="loading icon"
      />
    );
  }
  // If we have a known icon, we should use the full sized icon
  if (isOverideIcon && icon) {
    return (
      <img
        className={cn(
          'rounded-8 bg-transparent',
          size === 'small' ? 'h-[27px] w-[27px]' : 'h-[40px] w-[40px]',
        )}
        src={icon}
        alt="icon"
      />
    );
  } else if (icon) {
    // If we can find a favicon for the slug, we will put that in a circle
    return (
      <div
        className={cn(
          'flex items-center justify-center rounded-8 bg-transparent',
          size === 'small' ? 'h-[27px] w-[27px]' : 'h-[40px] w-[40px]',
        )}
      >
        <img
          className="h-20 w-20 rounded-8 bg-transparent"
          src={icon}
          alt="icon"
        />
      </div>
    );
  } else {
    // If we don't have a preloaded image, we can just use the first letter of the integration in a cricle
    return (
      <div
        className={cn(
          'flex items-center justify-center rounded-8 bg-background-alternate',
          size === 'small' ? 'h-[27px] w-[27px]' : 'h-[40px] w-[40px]',
        )}
      >
        <Body variant="body1">{getFirstLetterOfSlug(integration.slug)}</Body>
      </div>
    );
  }
};
