import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const TAX_READINESS_STORAGE_KEY = 'tax_readiness_stage';

export enum TaxReadinessStage {
  NOT_STARTED = 'not_started',
  ADD_WALLETS = 'add_wallets',
  SYNC_WALLETS = 'sync_wallets',
  OPTIMIZE_TAX_SAVINGS = 'optimize_tax_savings',
  REVIEW_TAX_FORMS = 'review_tax_forms',
  COMPLETED = 'completed',
}

interface TaxReadinessStore {
  addWalletsCheckedByUser: boolean | null;
  syncWalletsCheckedByUser: boolean | null;
  optimizeTaxSavingsCheckedByUser: boolean | null;
  reviewTaxFormsCheckedByUser: boolean | null;
  errorsCount: number;
  syncIssuesCount: number;
  walletsCount: number;
  autoResolvedSpamAssetsCount: number;
  setAddWalletsCheckedByUser: (checked: boolean) => void;
  setSyncWalletsCheckedByUser: (checked: boolean) => void;
  setOptimizeTaxSavingsCheckedByUser: (checked: boolean) => void;
  setReviewTaxFormsCheckedByUser: (checked: boolean) => void;
  setErrorsCount: (count: number) => void;
  setSyncIssuesCount: (count: number) => void;
  setWalletsCount: (count: number) => void;
  setAutoResolvedSpamAssetsCount: (count: number) => void;
}

export const useTaxReadinessStore = create<TaxReadinessStore>()(
  persist(
    (set) => ({
      addWalletsCheckedByUser: null,
      syncWalletsCheckedByUser: null,
      optimizeTaxSavingsCheckedByUser: null,
      reviewTaxFormsCheckedByUser: null,
      errorsCount: 0,
      syncIssuesCount: 0,
      walletsCount: 0,
      autoResolvedSpamAssetsCount: 0,
      setAddWalletsCheckedByUser: (checked: boolean) =>
        set({ addWalletsCheckedByUser: checked }),
      setSyncWalletsCheckedByUser: (checked: boolean) =>
        set({ syncWalletsCheckedByUser: checked }),
      setOptimizeTaxSavingsCheckedByUser: (checked: boolean) =>
        set({ optimizeTaxSavingsCheckedByUser: checked }),
      setReviewTaxFormsCheckedByUser: (checked: boolean) =>
        set({ reviewTaxFormsCheckedByUser: checked }),
      setErrorsCount: (count: number) => set({ errorsCount: count }),
      setSyncIssuesCount: (count: number) => set({ syncIssuesCount: count }),
      setWalletsCount: (count: number) => set({ walletsCount: count }),
      setAutoResolvedSpamAssetsCount: (count: number) =>
        set({ autoResolvedSpamAssetsCount: count }),
    }),
    {
      name: TAX_READINESS_STORAGE_KEY,
    },
  ),
);

export const getStatusText = (year: number) => {
  return `${year} Taxes in progress`;
};

export const clearTaxReadinessStore = () => {
  localStorage.removeItem(TAX_READINESS_STORAGE_KEY);
};
