import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { UserContext } from 'src/app/user/context';
import { URLS, isURLActive } from 'src/common/urls';
import { useIsMobile } from 'src/hooks/useIsMobile';

import { Textarea } from '@cointracker/styleguide';
import {
  Body,
  Body2,
  Body3,
  Body4,
  Button,
} from '@cointracker/styleguide/src/LoggedIn';
import { X } from '@phosphor-icons/react';
import { useCreateUserFeedbackMessageMutation } from 'src/types/graphql-types';

const TP_PROMPT_STORAGE = 'trustpilot_prompt_dismissed';
const NPS_PROMPT_STORAGE = 'prompt_nps_dismissed';

const ONE_MONTH_AGO = Date.now() - 30 * 24 * 60 * 60 * 1000;

enum STATES {
  YES_OR_NO,
  NPS_SCORE,
  FEEDBACK,
  FEEDBACK_SUBMITTED,
  PROMPT_TRUSTPILOT,
}

const analyticsDetails = {
  platform: 'web',
  version: undefined,
  appVersion: undefined,
};

export const TrustPilotPrompt = () => {
  const analytics = useAnalytics();
  const isMobile = useIsMobile();
  const [isOnEnabledPage, setIsOnEnabledPage] = useState(false);
  const tpPromptDismissed = localStorage.getItem(TP_PROMPT_STORAGE);
  const npsPromptDismissed = localStorage.getItem(NPS_PROMPT_STORAGE);
  const [isDismissed, setIsDismissed] = useState(
    npsPromptDismissed || tpPromptDismissed,
  );
  const user = useContext(UserContext);
  const location = useLocation();
  const [feedback, setFeedback] = useState('');
  const [createUserFeedbackMessage] = useCreateUserFeedbackMessageMutation();
  const [score, setScore] = useState(null);

  const [state, setState] = useState(STATES.YES_OR_NO);

  useEffect(() => {
    const isOnActivePage =
      isURLActive(URLS.MAIN_PAGE) ||
      isURLActive(URLS.TAX_PAGE) ||
      isURLActive(URLS.WALLETS) ||
      isURLActive(URLS.VIEW_TAX_PRO_DASHBOARD) ||
      isURLActive(URLS.TAX_PRO_FIRM_VIEW) ||
      isURLActive(URLS.VIEW_TAX_PRO);
    const isUserOlderThanOneMonth =
      user.isAuthenticated &&
      user?.createdAt &&
      new Date(user.createdAt).getTime() < ONE_MONTH_AGO;

    setIsOnEnabledPage(isOnActivePage && isUserOlderThanOneMonth);
  }, [location, user]);

  useEffect(() => {
    const isUserATaxPro =
      user?.isAuthenticated && user?.displayUser?.registeredAsTaxPro;

    if (isUserATaxPro) {
      setState(STATES.NPS_SCORE);
    } else {
      setState(STATES.YES_OR_NO);
    }
  }, [user]);

  const onDismiss = () => {
    analytics.track('User Feedback Prompt Pressed', {
      action: 'dismiss',
      ...analyticsDetails,
    });
    localStorage.setItem(TP_PROMPT_STORAGE, 'true');
    setIsDismissed('true');
    if (score && state === STATES.FEEDBACK) {
      createUserFeedbackMessage({
        variables: {
          message: null,
          rating: score,
        },
      });
    }
  };

  const onYesClicked = () => {
    analytics.track('User Feedback Prompt Pressed', {
      action: 'like',
      ...analyticsDetails,
    });
    localStorage.setItem(TP_PROMPT_STORAGE, 'true');
    setState(STATES.PROMPT_TRUSTPILOT);
  };

  const onNoClicked = () => {
    analytics.track('User Feedback Prompt Pressed', {
      action: 'dislike',
      ...analyticsDetails,
    });
    localStorage.setItem(TP_PROMPT_STORAGE, 'true');
    setState(STATES.FEEDBACK);
  };

  const onSubmitFeedback = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createUserFeedbackMessage({
      variables: {
        message: feedback,
        rating: score,
      },
    });
    setState(STATES.FEEDBACK_SUBMITTED);
    setTimeout(() => {
      setIsDismissed('true');
      localStorage.setItem(TP_PROMPT_STORAGE, 'true');
    }, 5000);
  };

  const onNPSScore = (score: number) => () => {
    setScore(score);
    if (tpPromptDismissed) {
      localStorage.setItem(NPS_PROMPT_STORAGE, 'true');
      setIsDismissed('true');
    } else {
      if (score <= 7) {
        setState(STATES.FEEDBACK);
      } else {
        setState(STATES.PROMPT_TRUSTPILOT);
        createUserFeedbackMessage({
          variables: {
            message: null,
            rating: score,
          },
        });
      }
    }
  };

  if (isDismissed || isMobile || !isOnEnabledPage || !user?.isAuthenticated) {
    return null;
  }

  const titles = {
    [STATES.YES_OR_NO]: 'Are you enjoying CoinTracker?',
    [STATES.NPS_SCORE]: user?.displayUser?.registeredAsTaxPro
      ? 'How likely are you to recommend a client to use CoinTracker?'
      : 'How likely are you to recommend CoinTracker to a friend?',
    [STATES.FEEDBACK]: 'Have any feedback for us?',
    [STATES.FEEDBACK_SUBMITTED]: 'Thanks for your feedback!',
    [STATES.PROMPT_TRUSTPILOT]: 'Rate CoinTracker',
  };

  return (
    <div className="fixed bottom-32 right-24 z-50">
      <div className="flex min-w-[370px] flex-col gap-16 rounded-8 border border-line-primary bg-background-default p-24 shadow-elevation-1">
        <div className="flex items-center justify-between gap-16">
          <Body variant="body2">{titles[state]}</Body>
          <button onClick={onDismiss}>
            <X weight="bold" />
          </button>
        </div>
        {state === STATES.YES_OR_NO && (
          <div className="flex justify-between gap-16">
            <Button onClick={onYesClicked} size="small" fluid variant="line">
              Yes
            </Button>
            <Button onClick={onNoClicked} size="small" fluid variant="line">
              No
            </Button>
          </div>
        )}
        {state === STATES.NPS_SCORE && (
          <div className="flex flex-col gap-8">
            <div className="flex justify-between gap-8">
              {Array.from(Array(11).keys()).map((i) => (
                <Button
                  key={i}
                  onClick={onNPSScore(i)}
                  size="small"
                  variant="line"
                >
                  {i}
                </Button>
              ))}
            </div>
            <div className="flex justify-between">
              <Body4 className="text-text-secondary">Not at all likely</Body4>
              <Body4 className="text-text-secondary">Extremely likely</Body4>
            </div>
          </div>
        )}
        {state === STATES.FEEDBACK && (
          <form
            onSubmit={onSubmitFeedback}
            className="flex max-w-[370px] flex-col gap-8"
          >
            <Body3 className="block">
              Need support? We&apos;re happy to help you{' '}
              <a
                href="https://support.cointracker.io/hc/en-us?ref=feedbackRedirect"
                className="text-text-link"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </Body3>
            <Textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please share your feedback"
              required
              inputClassName="!h-[100px] !resize-none"
            />
            <div className="flex justify-end">
              <Button type="submit" size="small">
                Submit
              </Button>
            </div>
          </form>
        )}
        {state === STATES.FEEDBACK_SUBMITTED && (
          <Body2>We appreciate it!</Body2>
        )}
        {state === STATES.PROMPT_TRUSTPILOT && (
          <>
            <Body3>
              Glad to hear it! We&apos;d appreciate it if you could rate us on
              Trustpilot
            </Body3>
            <a
              href={URLS.TRUSTPILOT_REVIEW_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small" fluid>
                Rate CoinTracker
              </Button>
            </a>
          </>
        )}
      </div>
    </div>
  );
};
