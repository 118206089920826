import { gql } from '@apollo/client';
export const getCostBasisStatus = gql`
  query GetCostBasisStatus {
    userInfo {
      publicId
      isUndergoingAllocation
      isCostBasisRunning
      costBasisLastSyncedAt
      costBasisComputeStartTime
      costBasisComputeEndTime
      costBasisStatus {
        state
        priority
      }
      costBasisRecomputationDate
      costBasisPaused
    }
  }
`;
