import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { cn } from '../../utils';
import { headingVariants, type HeadingVariants } from './variants';

export interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    HeadingVariants {
  asChild?: boolean;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, variant, asChild = false, as, ...props }, ref) => {
    const Comp = asChild ? Slot : (as ?? variant ?? 'h1');
    return (
      <Comp
        ref={ref}
        className={cn(headingVariants({ className, variant, as }))}
        {...props}
      />
    );
  },
);

Heading.displayName = 'Heading';

export const H1 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h1" {...props} />
));
H1.displayName = 'H1';

export const H2 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h2" {...props} />
));

H2.displayName = 'H2';

export const H3 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h3" {...props} />
));

H3.displayName = 'H3';

export const H4 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h4" {...props} />
));

H4.displayName = 'H4';

export const H5 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h5" {...props} />
));

H5.displayName = 'H5';

export const H6 = forwardRef<HTMLHeadingElement, HeadingProps>((props, ref) => (
  <Heading ref={ref} variant="h6" {...props} />
));

H6.displayName = 'H6';
