import classNames from 'classnames';
import { forwardRef } from 'react';
import { type ChildAndClassNameProps } from '../types';
import { grid } from './Grid.css';

export const Grid = forwardRef<HTMLDivElement, ChildAndClassNameProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={classNames(grid, className)}
        {...props}
      >
        {children}
      </div>
    );
  },
);

Grid.displayName = 'Grid';
