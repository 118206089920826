import {
  darkThemeClass as darkThemeRebrandClass,
  lightThemeClass as lightThemeRebrandClass,
} from '@cointracker/styleguide';
import { ReactNode, useContext, useMemo } from 'react';
import { UserContext } from 'src/app/user/context';
import { useThemeDetector } from 'src/hooks/useThemeDetector';
import { UiTheme } from 'src/types/graphql-types';
import { parseCookie } from '../utils';

const uiThemeCookie = 'ui_theme';
/**
 * To allow us to know as quickly as possible the users' theme preference
 * we use a cookie
 *
 * More discussion here on why we added this:
 * https://github.com/coin-tracker/coin-tracker-server/pull/11815
 */
export const getUserThemePreferenceFromCookie = () => {
  const cookie = parseCookie(document.cookie);
  return cookie?.[uiThemeCookie];
};

export default function AppThemeProvider({
  children,
}: {
  children: ReactNode;
}) {
  const userPrefersDarkTheme = useThemeDetector();
  const user = useContext(UserContext);
  const userThemePreference = getUserThemePreferenceFromCookie();

  const theme = useMemo(() => {
    const themes = {
      dark: {
        className: `${darkThemeRebrandClass} dark rebrand bg-background-default text-text-primary`,
      },
      light: {
        className: `${lightThemeRebrandClass} light rebrand bg-background-default text-text-primary`,
      },
    };
    const selectedTheme = !user.hasFetched
      ? UiTheme.Light.valueOf()
      : userThemePreference;

    switch (selectedTheme) {
      case UiTheme.Dark:
        return themes.dark;
      case UiTheme.Light:
        return themes.light;
      case UiTheme.System:
        if (userPrefersDarkTheme) {
          return themes.dark;
        }
        return themes.light;
      default:
        return themes.light;
    }
  }, [user.hasFetched, userPrefersDarkTheme, userThemePreference]);

  if (document?.body) {
    // apply the @cointracker/ui theme class to the body so it also effects dialog components.
    // also applies rebrand class conditionally
    // also applies explicit dark/light classes for tailwind
    document.body.className = theme.className;
  }

  return <>{children}</>;
}
