import {
  type EventProperties,
  type SegmentEvent,
  type UserTraits,
} from '@segment/analytics-next';
import { type Maybe } from './types';
import { getPageContext, post } from './utils';

/**
 * A wrapper to proxy all analytics calls to the server
 */
export default class AnalyticsProxy {
  userId: Maybe<string> = null;
  anonymousId: Maybe<string> = null;

  setAnonymousId(anonymousId: string) {
    this.anonymousId = anonymousId;
  }

  get commonAttributes() {
    const attr: EventProperties = {
      anonymous_id: this.anonymousId,
      context: getPageContext(),
    };
    if (this.userId) {
      attr['user_id'] = this.userId;
    }

    return attr;
  }

  /**
   * This is used to add other values to the event properties for
   * the "hybrid segment" as it happens for statsig
   */
  attachMiddlewareValuesForForwardEvents(eventProperties?: EventProperties) {
    return {
      ...eventProperties,
      event_forwarded: true,
    };
  }

  track(eventName: string | SegmentEvent, eventProperties: EventProperties) {
    post('/save_event', {
      ...this.commonAttributes,
      event_name: eventName,
      event_properties:
        this.attachMiddlewareValuesForForwardEvents(eventProperties),
    });
  }

  page(
    pageCategory?: string,
    pageName?: string,
    eventProperties?: EventProperties,
  ) {
    post('/save_page_view', {
      ...this.commonAttributes,
      page_category: pageCategory,
      page_name: pageName,
      event_properties:
        this.attachMiddlewareValuesForForwardEvents(eventProperties),
    });
  }

  identify(userId: string, userTraits: UserTraits) {
    post('/save_user_properties', {
      ...this.commonAttributes,
      user_id: userId,
      user_traits: userTraits,
    });
  }
}
