import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Info } from '@phosphor-icons/react';
import { isEmpty } from 'lodash-es';
import { useAnalytics } from 'src/app/analytics';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { useMetaMask } from 'src/hooks/useMetaMask';
import { usePhantom } from 'src/hooks/usePhantom';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsQuery,
  Integration,
  useAddPublicXPubMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { CryptoSelect } from '../CryptoSelect';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ErrorMessage } from '../PublicAddressForm/ErrorMessage';
import { ViewPermissions } from '../ViewPermissions';
import { chainIds, emptyCacheQuery, getWalletInstructions } from '../util';
import {
  ValidationErrors,
  validateCryptoSelector,
  validateWalletAddress,
} from './validate';

interface PublicXPubFormProps {
  integration: Integration;
  cryptoIntegrations: Integration[];
  onCloseModal?: () => void;
}

export const PublicXPubForm = (props: PublicXPubFormProps) => {
  const integration = props.integration;
  const [selectedCrypto, setSelectedCrypto] = useState<Integration>(null);
  const { description, placeholder } = getWalletInstructions(
    selectedCrypto?.slug,
    selectedCrypto?.supportsHdWallet,
  );
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [walletAddress, setWalletAddress] = useState<string>();
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);

  const selectedCryptoRef = useRef<Integration>();
  selectedCryptoRef.current = selectedCrypto;
  const inputRef = useRef<HTMLInputElement>(null);
  const metamask = useMetaMask();
  const phantomProvider = usePhantom();
  const analytics = useAnalytics();
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const onValidateWalletAddress = useCallback(() => {
    const addressErrors = validateWalletAddress(walletAddress);
    setErrors({ ...errors, ...addressErrors });
  }, [walletAddress, errors]);

  const onValidateCryptoSelector = useCallback(
    (integration) => {
      const selectedCryptoErrors = validateCryptoSelector(integration);
      setErrors({ ...errors, ...selectedCryptoErrors });
    },
    [errors],
  );

  const onWalletAddressChange = useCallback(
    (e) => {
      setWalletAddress(e.target.value);
      onValidateWalletAddress();
    },
    [onValidateWalletAddress],
  );

  const updateSelectedCrypto = useCallback(
    (integration: Integration) => {
      setSelectedCrypto(integration);
      onValidateCryptoSelector(integration);
    },
    [onValidateCryptoSelector],
  );

  const getMetamaskAddress = useCallback(async () => {
    if (!selectedCryptoRef.current?.slug) return;
    try {
      const chainId = await metamask.request({ method: 'eth_chainId' });
      inputRef.current.focus();

      if (
        chainId !==
        chainIds[selectedCryptoRef.current.slug as keyof typeof chainIds]
      ) {
        await metamask.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId:
                chainIds[
                  selectedCryptoRef.current.slug as keyof typeof chainIds
                ],
            },
          ],
        });
      } else {
        const accounts: Array<string> = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        if (accounts[0]) {
          const newWalletAddress = accounts[0];
          setWalletAddress(newWalletAddress);
          const addressErrors = validateWalletAddress(newWalletAddress);
          setErrors({ ...errors, ...addressErrors });
          analytics.track('Metamask Wallet added using Metamask extension', {
            integration: selectedCryptoRef.current.slug,
          });
        }
      }
    } catch (e) {
      // user may not have chain on acct or some other error we don't want preventing the use of this page
    }
  }, [selectedCryptoRef.current?.slug]);
  const getPhantomAddress = useCallback(async () => {
    if (!selectedCryptoRef.current?.slug) return;
    try {
      const slug = selectedCryptoRef.current.slug.toLowerCase();
      let address: string;
      let accounts: string[];
      let btcAccounts: { address: string };
      let solAccounts: { publicKey: string };
      let suiAccount: { address: string };

      if (!window.phantom?.solana?.isConnected) {
        try {
          await window.phantom.solana.connect();
        } catch (connectionError) {
          console.error(
            'Failed to connect to Phantom wallet:',
            connectionError,
          );
          return;
        }
      }
      // Get address based on the cryptocurrency type
      switch (slug) {
        case 'ethereum':
        case 'polygon':
        case 'base':
        case 'arbitrum':
        case 'optimism':
          accounts = await window.phantom?.ethereum.request({
            method: 'eth_requestAccounts',
          });
          address = accounts?.[0];
          break;

        case 'bitcoin':
          btcAccounts = await window.phantom?.bitcoin.requestAccounts();
          address = btcAccounts?.[0]?.address;
          break;

        case 'solana':
          solAccounts = await window.phantom?.solana.request({
            method: 'connect',
          });
          address = solAccounts?.publicKey?.toString();
          break;

        case 'sui':
          suiAccount = await window.phantom?.sui.requestAccount();
          address = suiAccount?.address;
          break;

        default:
          console.warn(`Unsupported chain in Phantom wallet: ${slug}`);
          return;
      }

      if (address) {
        inputRef.current.focus();
        setWalletAddress(address);
        const addressErrors = validateWalletAddress(address);
        setErrors({ ...errors, ...addressErrors });
        analytics.track('Phantom Wallet added using Phantom extension', {
          integration: selectedCryptoRef.current.slug,
        });
      } else {
        console.warn(`No address found for ${slug} in Phantom wallet`);
      }
    } catch (e) {
      console.error(`Error getting Phantom wallet address: ${e.message}`, e);
      // user may have some error and we don't want preventing the use of this page
    }
  }, [selectedCryptoRef.current?.slug]);

  useEffect(() => {
    if (metamask) {
      metamask.on('chainChanged', getMetamaskAddress);
      metamask.on('accountsChanged', getMetamaskAddress);
    }
    if (phantomProvider) {
      phantomProvider.on('accountsChanged', getPhantomAddress);
    }
  }, [phantomProvider, metamask, getPhantomAddress, getMetamaskAddress]);

  useEffect(() => {
    if (metamask && integration?.slug === 'metamask') {
      getMetamaskAddress();
    }
    if (phantomProvider && integration?.slug === 'phantom') {
      getPhantomAddress();
    }
  }, [selectedCrypto]);

  const [mutate, { loading }] = useAddPublicXPubMutation({
    onCompleted: (data) => {
      if (data?.addPublicXpub?.success) {
        if (data?.addPublicXpub?.warning) {
          showNotification({
            message: `If you have previously added ${selectedCrypto.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${selectedCrypto.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicXpub.success,
          status: Status.Success,
        });
        onSuccess(data.addPublicXpub.createdWallet);
        props.onCloseModal?.();
      } else {
        if (data?.addPublicXpub?.error) {
          setSubmitErrorMessage(data?.addPublicXpub?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicXpub.success) {
        return;
      }
      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newLocalWallets = [
        ...walletsData.localWallets,
        data.addPublicXpub.createdWallet,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, localWallets: newLocalWallets },
      });
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const addressErrors = validateWalletAddress(walletAddress);
      const cryptoSelectorErrors = validateCryptoSelector(selectedCrypto);

      if (!isEmpty(addressErrors) || !isEmpty(cryptoSelectorErrors)) {
        setErrors({ ...errors, ...addressErrors, ...cryptoSelectorErrors });
        return;
      }

      mutate({
        variables: {
          publicXpubInput: {
            coinSymbol: selectedCrypto.info.symbol,
            cryptoNetwork: selectedCrypto.info.cryptoNetwork,
            xpubKey: walletAddress,
            accountTypeSlug: integration.slug,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      errors,
      integration,
      selectedCrypto,
      mutate,
      walletAddress,
      startFasterPollingForSyncCompletion,
    ],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        <ViewPermissions />
        {submitErrorMessage && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitErrorMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}
        <CryptoSelect
          cryptoIntegrations={props.cryptoIntegrations}
          setSelectedCrypto={updateSelectedCrypto}
          selectedCrypto={selectedCrypto}
          error={errors ? errors.cryptoSelect : null}
        />
        <TextInput
          name="wallet_address"
          value={walletAddress}
          onChange={onWalletAddressChange}
          label={description}
          placeholder={placeholder}
          hasError={errors && errors.walletAddress !== undefined}
          errorMessage={errors?.walletAddress}
          onBlur={onValidateWalletAddress}
          autoFocus={true}
          ref={inputRef}
        />
        <Instructions integration={integration} />
      </div>

      <IntegrationFormModalFooter>
        <Button variant="primary" loading={loading} type="submit" size="large">
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
