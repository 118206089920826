import classNames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ReactNode,
  type Ref,
} from 'react';
import { themeVars } from '../contract.css';
import ExcludeIcon from '../icons/exclude.svg?react';
import ClearIcon from '../icons/x-circle.svg?react';
import { Body2, Body3 } from '../typography';
import { body1, type Body1Variant } from '../typography/Typography.css';
import {
  errorInput,
  errorInputContainer,
  errorMessageText,
  input,
  inputButton,
  inputContainer,
  inputGroup,
  labelText,
  supportTextBody,
} from './Input.css';

interface InputProps {
  children?: ReactNode;
  disabled?: boolean;
  hasError?: boolean;
  className?: string;
  style?: React.CSSProperties;
  numeric?: boolean;
  placeholder?: string;
  supportText?: string;
  label?: string;
  variant?: Body1Variant;
  value?: string;
  errorMessage?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  prefix?: string;
}

export const Input = forwardRef(
  (
    {
      children,
      className,
      style,
      onChange,
      disabled,
      numeric,
      placeholder,
      hasError = false,
      label,
      supportText,
      errorMessage,
      variant,
      value,
      prefix,
      ...props
    }: InputProps,
    forwardedRef: Ref<HTMLInputElement>,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const [hasValue, setHasValue] = useState(false);

    useImperativeHandle(forwardedRef, () => innerRef.current!);
    const handleClearClick = () => {
      if (innerRef.current) {
        innerRef.current.value = '';
        setHasValue(false);
        onChange?.({
          target: innerRef.current,
        } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHasValue(e.target.value.length > 0);
      onChange?.(e);
    };

    useEffect(() => {
      if (innerRef.current) {
        setHasValue(innerRef.current.value.length > 0);
      }
    }, [value]);

    return (
      <div className={inputGroup}>
        {label && <Body3 className={labelText}>{label}</Body3>}
        {supportText && (
          <Body3 className={supportTextBody}>{supportText}</Body3>
        )}
        <div className={hasError ? errorInputContainer : inputContainer}>
          {prefix}
          <input
            ref={innerRef}
            disabled={disabled}
            onChange={handleInputChange}
            className={classNames(
              body1(variant),
              input({ numericLetterSpacing: numeric }),
              hasError ? errorInput : null,
              className,
            )}
            style={style}
            placeholder={placeholder}
            value={value}
            type={numeric ? 'number' : undefined}
            {...props}
          >
            {children}
          </input>
          {hasValue && (
            <button className={inputButton} onClick={handleClearClick}>
              <ClearIcon
                width={24}
                height={24}
                color={hasError ? themeVars.error : undefined}
              />
            </button>
          )}
        </div>
        {hasError && (
          <Body2 className={errorMessageText}>
            <ExcludeIcon width={14} height={14} color={themeVars.error} />
            {errorMessage}
          </Body2>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
