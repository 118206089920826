import { Footer, SidebarInset, TopBar } from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn/Button';
import { Body, Heading } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { SmileyMelting } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import {
  TopBar as RebrandAuthTopbar,
  Sidebar,
} from 'components/Rebrand/Sidebar';
import { useEffect } from 'react';
import { NavLink, useRouteError } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { LocalStorageUtils } from 'src/app/utils';
import { Spinner } from 'src/common/Spinner/Spinner';
import { useAuth } from '../Auth';
import { isMessageMatchingModuleError } from './utils';

const TEN_SECONDS = 10000;

export default function ErrorPage() {
  const error = useRouteError() as Error;
  const analytics = useAnalytics();
  const { isAuthenticated } = useAuth();

  const hasChunkLoadError = isMessageMatchingModuleError(error);

  useEffect(() => {
    if (hasChunkLoadError) {
      // eslint-disable-next-line no-console
      console.warn(error); // Log the error to the console so we can see what's going on.

      // This is to prevent an infinite reload problem in case the asset actually doesn't exist.
      // If the error happened within 10s then the asset most likely actually doesn't exist.
      if (!LocalStorageUtils.getWithExpiration('page_chunk_failed')) {
        LocalStorageUtils.setWithExpiration(
          'page_chunk_failed',
          'true',
          TEN_SECONDS,
        );
        window.location.reload();
      }
    } else {
      analytics.track('User Viewed Error Page', {
        error: error.message,
        pathname: window.location.pathname,
      });

      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
    }
  }, [error, hasChunkLoadError, analytics]);

  const Wrapper = isAuthenticated ? RebrandedAuthErrorPage : RebrandedErrorPage;
  return (
    <Wrapper>
      <div className="my-auto h-full text-center">
        {hasChunkLoadError ? (
          <div
            className="flex place-items-center justify-center"
            data-testid="loading-spinner"
          >
            <Spinner className="text-text-primary" />
          </div>
        ) : (
          <div className="flex flex-col place-items-center justify-center">
            <div className="m-30 flex h-[180px] w-[180px] place-items-center justify-center rounded-full bg-background-card-highlight">
              <SmileyMelting className="text-text-primary" size={64} />
            </div>
            <div className="flex flex-col place-items-center gap-8 py-32 text-center">
              <Heading variant="h5" className="text-text-secondary">
                Oops! This is unexpected.
              </Heading>
              <Body variant="body3" className="text-text-secondary">
                An error has occurred and we’re working to fix the problem.
                Check back later.
              </Body>
            </div>
            {isAuthenticated && (
              <NavLink to="/">
                <Button>Back to dashboard</Button>
              </NavLink>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

function RebrandedAuthErrorPage({ children }: { children: React.ReactNode }) {
  return (
    <>
      <RebrandAuthTopbar shouldBeFixed />
      <Sidebar />
      <SidebarInset className="max-auto h-full max-w-full justify-between bg-background-default pt-80 md:max-w-[calc(100dvw-var(--sidebar-width))] md:pt-0 peer-data-[state=collapsed]:md:max-w-[calc(100dvw-var(--sidebar-width-icon))]">
        {children}
      </SidebarInset>
    </>
  );
}

function RebrandedErrorPage({ children }: { children: React.ReactNode }) {
  const analytics = useAnalytics();
  return (
    <>
      <TopBar analytics={analytics} />
      {children}
      <Footer />
    </>
  );
}
