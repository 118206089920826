import { isServer } from './environment';

export function isSafariBrowser() {
  if (isServer()) {
    return false;
  }
  return /^((?!chrome|android|crios|edg|firefox|fxios|opr|samsung).)*safari/i.test(
    navigator?.userAgent ?? '',
  );
}
