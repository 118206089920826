import * as SliderPrimitive from '@radix-ui/react-slider';
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react';

import { cn } from '../utils';

const Slider = forwardRef<
  ElementRef<typeof SliderPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="bg-background-card-surface relative h-12 w-full grow overflow-hidden rounded-full">
      <SliderPrimitive.Range className="bg-background-high-emphasis absolute h-full" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="border-background-high-emphasis-pressed bg-background-high-emphasis-hover ring-offset-background focus-visible:ring-ring block h-24 w-24 rounded-full border-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
