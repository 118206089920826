import { getCookie } from '../../../../../packages/utils/cookies';

interface CelloAttributionCommand {
  command: string;
  args: unknown[];
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
}

/**
 * Fetches the UCC from CelloAttribution and stores it in cookies.
 * Check the docs: https://docs.cello.so/docs/stripe-conversion-tracking#2-track-signups-with-stripe
 * NOTE that the cello attribution library should already store the UCC in the cookies under `cello-referral`
 * cookies; but it doesn't do it if the URL is missing the product-id -> hence this function.
 * @returns {Promise<string | null>} The UCC value or null if an error occurs.
 */
export async function storeUccInCookiesUsingCelloLib(): Promise<string | null> {
  return storeUccInCookies(true);
}

/**
 * Store the ucc cello referral code in the cookies.
 * With $useCelloLib = False; it will not use the cello library to fetch the ucc - it will just look at the url or
 * search for the cello-referral cookie. This is useful when the user has not yet given consent use cookies.
 * With $useCelloLib = True; it will use the cello library to fetch the ucc. This is useful when the user has given
 * consent to use cookies. In this case, we overwrite the ucc value in the cookies with the one fetched from the library
 * (which should be the same but we want to be sure).
 * @param {boolean} useCelloLib - Whether to use CelloAttribution to fetch the UCC.
 * @returns {Promise<string | null>} The stored UCC value or null if not found.
 */
export async function storeUccInCookies(
  useCelloLib: boolean,
): Promise<string | null> {
  try {
    // Retrieve existing UCC from cookies
    let ucc = getCookie('cello-referral');

    if (!ucc) {
      try {
        const win = window as unknown as {
          CelloAttribution?: (
            command: string,
            ...args: unknown[]
          ) => Promise<unknown>;
          CelloAttributionCmd?: CelloAttributionCommand[];
        };

        if (useCelloLib && win.CelloAttribution) {
          ucc = (await win.CelloAttribution('getUcc')) as string;
        }
      } catch (err) {
        console.warn('⚠️ Failed to fetch UCC from CelloAttribution:', err);
      }

      // If UCC is still missing, try getting it from the URL params
      if (!ucc) {
        const urlParams = new URLSearchParams(window.location.search);
        ucc = urlParams.get('ucc') || '';
      }

      if (!ucc) {
        return null;
      }
    }

    // Store UCC in cookies (expires in 7 days) only if a valid value is found
    document.cookie = `ucc=${ucc}; path=/; max-age=${7 * 24 * 60 * 60}; Secure; SameSite=Strict`;
    return ucc;
  } catch (error) {
    console.error('❌ Error in storeUccInCookies:', error);
    return null;
  }
}
