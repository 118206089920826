import { cn } from '@cointracker/styleguide';
import { Body, buttonVariants } from '@cointracker/styleguide/src/LoggedIn';
import { DownloadSimple, Link } from '@phosphor-icons/react';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';
import { InstructionCard } from '../../../Instructions';
import { parseInstructions } from '../../../Instructions/util';
export interface GenericInstructionProps {
  integration: Integration;
}
export const GenericInstructions = ({
  integration,
}: GenericInstructionProps) => {
  if (integration?.instructions) {
    const { fileInstructions } = parseInstructions(
      integration.instructions,
      integration.syncMethod as IntegrationSyncMethod,
    );
    if (fileInstructions) {
      return (
        <InstructionCard>
          <Body variant="body2" className="text-text-secondary">
            Steps to add your {integration.name} transactions:
          </Body>
          <div
            className={cn(
              'group',
              'prose max-w-none prose-headings:text-text-primary prose-strong:text-text-primary prose-ul:text-text-secondary',
              'prose-headings:text-text-primary',
              'prose-h3:text-heading6 md:prose-h3:text-heading6-md lg:prose-h3:text-heading6-lg',
              'prose-h4:text-heading6 md:prose-h4:text-heading6-md lg:prose-h4:text-heading6-lg',
              'prose-p:text-body3 prose-p:text-text-secondary md:prose-p:text-body3-md lg:prose-p:text-body3-lg',
              'prose-div:text-text-secondary prose-a:text-text-link prose-a:hover:text-text-link-hover prose-li:text-text-secondary',
              '[&_.text-muted]:!text-text-secondary',
            )}
            dangerouslySetInnerHTML={{ __html: fileInstructions }}
          ></div>
        </InstructionCard>
      );
    }
  }
  return (
    <InstructionCard>
      <Body variant="body2" className="text-text-secondary">
        Preparing your CoinTracker CSV
      </Body>
      <Body variant="body2" className="text-text-secondary">
        <ol className="list-outside list-decimal pl-16">
          <li>Export transactions file from your exchange or wallet</li>
          <li>Re-format it using our CoinTracker CSV template and guide</li>
        </ol>
      </Body>
      <div className="flex gap-4">
        <a
          href="https://support.cointracker.io/hc/en-us/article_attachments/19757270031505"
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            buttonVariants({
              variant: 'line',
              transparent: false,
              size: 'small',
            }),
          )}
        >
          CSV template
          <DownloadSimple size={18} weight="bold" className="ml-2" />
        </a>
        <a
          href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format"
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            buttonVariants({
              variant: 'line',
              transparent: false,
              size: 'small',
            }),
          )}
        >
          CoinTracker CSV article
          <Link size={18} weight="bold" className="ml-2" />
        </a>
      </div>
    </InstructionCard>
  );
};
