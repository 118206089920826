import { createStore } from 'zustand/vanilla';

interface AnalyticsStoreProps {
  hasUserGivenConsent: boolean;
  areOptionalAnalyticsAllowed: boolean;
  setUserConsent: (allowedCategories: string[]) => void;
}

export const AnalyticsStore = createStore<AnalyticsStoreProps>((set) => ({
  hasUserGivenConsent: false,
  areOptionalAnalyticsAllowed: false,
  setUserConsent: (allowedCategories) => {
    const areOptionalAnalyticsAllowed = allowedCategories.includes('analytics');
    set(() => ({
      areOptionalAnalyticsAllowed,
      hasUserGivenConsent: true,
    }));
  },
}));
