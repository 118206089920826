import { User } from '@phosphor-icons/react';
import * as RadixAvatar from '@radix-ui/react-avatar';
import { cva, VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { cn, isChromaticEnv } from '../../utils';
import { Body4 } from '../Typography';

export type AvatarVariants = VariantProps<typeof avatarVariants>;
type AvatarProps = {
  src?: string;
  alt?: string;
  className?: string;
  fallbackText?: string;
  fallbackClassName?: string;
  delayMs?: number;
} & AvatarVariants;

const avatarVariants = cva(
  'text-icon-primary-consistent flex items-center justify-center rounded-full',
  {
    variants: {
      variant: {
        color: 'bg-accent-subtle-lime',
        image: 'bg-transparent',
        iconOnly: 'bg-accent-subtle-lime border-line-secondary border',
      },
      size: {
        xtiny: 'h-18 w-18 min-h-18 min-w-18',
        tiny: 'h-24 min-h-24 w-24 min-w-24',
        xxs: 'h-28 min-h-28 w-28 min-w-28',
        xs: 'h-32 min-h-32 w-32 min-w-32',
        sm: 'h-40 min-h-40 w-40 min-w-40',
        md: 'h-60 min-h-60 w-60 min-w-60',
        lg: 'h-80 min-h-80 w-80 min-w-80',
        xl: 'w-104 h-104 min-w-104 min-h-104',
        '2xl': 'w-120 h-120 min-w-120 min-h-120',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'image',
    },
  },
);

export const Avatar = forwardRef(
  (
    {
      variant,
      className,
      src,
      alt,
      fallbackText = '',
      fallbackClassName,
      size,
      delayMs = 0,
    }: AvatarProps,
    forwardedRef: React.Ref<HTMLSpanElement>,
  ) => {
    // we are forcing avatars to load up their fallbackTexts instead of waiting because they create flaky tests
    if (isChromaticEnv()) {
      return (
        <div className={avatarVariants({ variant, size })}>
          {variant === 'iconOnly' ? (
            <User type="regular" />
          ) : (
            <Body4 className="text-text-secondary">{fallbackText}</Body4>
          )}
        </div>
      );
    }
    return (
      <RadixAvatar.Root
        className={cn(avatarVariants({ variant, size }), className)}
        ref={forwardedRef}
      >
        <RadixAvatar.Image
          src={src}
          alt={alt}
          className="rounded-full object-cover"
        />
        <RadixAvatar.Fallback
          delayMs={delayMs}
          className={cn(
            'text-text-secondary flex h-full w-full items-center justify-center rounded-full',
            fallbackClassName,
          )}
        >
          {variant === 'iconOnly' ? (
            <User type="regular" />
          ) : (
            <Body4 className="text-inherit">{fallbackText}</Body4>
          )}
        </RadixAvatar.Fallback>
      </RadixAvatar.Root>
    );
  },
);

Avatar.displayName = 'Avatar';
