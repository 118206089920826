import { Body } from '@cointracker/styleguide/src/LoggedIn';

export const ViewPermissions = () => {
  return (
    <Body variant="body2" className="text-text-secondary">
      We are only requesting view permissions. This does not give us access to
      your private keys nor the ability to move your funds.
    </Body>
  );
};
