import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { NavLink } from '../../NavLink';
import { Body3 } from '../../typography';
import { hideBelowDesktop } from '../TopBar.css';
import {
  imageContainer,
  navDropdownTitle,
  navigationMenuContent,
  navigationMenuList,
  navigationMenuTrigger,
  navigationMenuViewport,
  navigationRoot,
  navItemsContainer,
  styledImg,
  viewportPosition,
} from './NavigationMenu.css';

export const NavigationMenu = forwardRef(
  (
    { children, ...props }: NavigationMenuPrimitive.NavigationMenuProps,
    forwardedRef: Ref<HTMLDivElement>,
  ) => {
    return (
      <NavigationMenuPrimitive.Root
        {...props}
        ref={forwardedRef}
        className={navigationRoot}
      >
        <NavigationMenuPrimitive.List className={navigationMenuList}>
          {children}
        </NavigationMenuPrimitive.List>
        <div className={viewportPosition}>
          <NavigationMenuPrimitive.Viewport
            className={navigationMenuViewport}
          />
        </div>
      </NavigationMenuPrimitive.Root>
    );
  },
);

NavigationMenu.displayName = 'NavigationMenu';

export const NavigationMenuItem = NavigationMenuPrimitive.Item;

export const NavigationMenuTrigger = ({
  children,
}: NavigationMenuPrimitive.NavigationMenuItemProps) => {
  return (
    <NavigationMenuPrimitive.Trigger asChild className={navigationMenuTrigger}>
      <NavLink withIcon variant="primary" className={hideBelowDesktop}>
        {children}
      </NavLink>
    </NavigationMenuPrimitive.Trigger>
  );
};

interface NavigationMenuItemContentProps
  extends NavigationMenuPrimitive.NavigationMenuContentProps {
  imgSrc?: string;
  imgAlt?: string;
  title: string;
}
export const NavigationMenuItemContent = forwardRef(
  (
    {
      children,
      imgAlt,
      imgSrc,
      title,
      ...props
    }: NavigationMenuItemContentProps,
    forwardedRef: Ref<HTMLDivElement>,
  ) => {
    return (
      <NavigationMenuPrimitive.Content
        {...props}
        ref={forwardedRef}
        className={navigationMenuContent}
      >
        <div className={imageContainer}>
          <img src={imgSrc} alt={imgAlt} className={styledImg} />
        </div>
        <div className={navItemsContainer}>
          <div className={navDropdownTitle}>
            <Body3 caps>{title}</Body3>
          </div>
          {children}
        </div>
      </NavigationMenuPrimitive.Content>
    );
  },
);

NavigationMenuItemContent.displayName = 'NavigationMenuItemContent';

interface NavigationMenuLinkProps {
  href?: string;
  children: ReactNode;
}
export const NavigationMenuLink = ({
  children,
  href,
}: NavigationMenuLinkProps) => {
  return (
    <NavLink variant="secondary" href={href}>
      {children}
    </NavLink>
  );
};
