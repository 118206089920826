import { URLS } from 'src/common/urls';

export const CBO_PROMO_KEY = 'CoinbaseOnePromotion';
export const BASE_CHECKOUT_URL = `${URLS.UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE}?plan=base`;
export const PRIME_CHECKOUT_URL = `${URLS.UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE}?plan=prime`;
export const CBO_PROMO_ERROR_INVALID = 'Invalid code.';
export const CBO_PROMO_ERROR_NO_CODE = 'No code provided.';
export const CBO_PROMO_ERROR_ALREADY_CLAIMED =
  'Code is associated with another user.';
export const CBO_PROMO_ERROR_PREMIUM_ALREADY_CLAIMED =
  'Premium tier has already been claimed.';
