import * as SelectPrimitive from '@radix-ui/react-select';
import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import CheckIcon from '../icons/check.svg?react';
import ChevronDownIcon from '../icons/chevron-down.svg?react';
import ChevronUpIcon from '../icons/chevron-up.svg?react';
import {
  iconContainer,
  indicatorIcon,
  itemsContent,
  lineHeightClear,
  scrollDownButton,
  scrollUpButton,
  selectItem,
  selectTrigger,
  styledSelectTriggerValue,
} from './Select.css';

interface SelectProps extends SelectPrimitive.SelectProps {
  className?: string;
  'aria-label'?: string;
}

export const Select = forwardRef(
  (props: SelectProps, forwardedRef: Ref<HTMLButtonElement>) => {
    const {
      children,
      className,
      value,
      'aria-label': ariaLabel,
      ...rest
    } = props;

    return (
      <SelectPrimitive.Root {...rest} value={value}>
        <SelectPrimitive.Trigger
          ref={forwardedRef}
          aria-label={ariaLabel}
          className={classNames(selectTrigger, className)}
        >
          <SelectPrimitive.Value
            aria-label={value}
            className={styledSelectTriggerValue}
          />
          <SelectPrimitive.Icon className={iconContainer}>
            <ChevronDownIcon className={indicatorIcon} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            avoidCollisions={false}
            position="popper"
            side="bottom"
            className={itemsContent}
          >
            <SelectPrimitive.ScrollUpButton className={scrollUpButton}>
              <ChevronUpIcon />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className={scrollDownButton}>
              <ChevronDownIcon />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  },
);

Select.displayName = 'Select';

export const SelectItem = forwardRef(
  (
    { children, className, ...props }: SelectPrimitive.SelectItemProps,
    forwardedRef: Ref<HTMLDivElement>,
  ) => {
    return (
      <SelectPrimitive.Item
        {...props}
        ref={forwardedRef}
        className={classNames([selectItem, className])}
      >
        <SelectPrimitive.ItemText className={lineHeightClear}>
          {children}
        </SelectPrimitive.ItemText>

        <SelectPrimitive.ItemIndicator className={iconContainer}>
          <CheckIcon width="23.2px" height="23.2px" className={indicatorIcon} />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';
