import { Info, X } from '@phosphor-icons/react';
import { useCallback, useState, type ReactNode } from 'react';
import { Body2 } from '../LoggedIn';
import { cn } from '../utils';

// needed to counterbalance the center/right flex grid
const FlexItem = (props: { children?: ReactNode }) => (
  <div className="flex flex-1 items-center">{props.children}</div>
);

export interface BannerProps {
  children?: ReactNode;
  href?: string;
  type?: 'success' | 'warning' | 'error';
  closable?: boolean;
  withPrefixIcon?: boolean;
  className?: string;
  onBannerClose?: () => void;
  rounded?: boolean;
}

export const Banner = ({
  children,
  href,
  type,
  className,
  closable = true,
  withPrefixIcon = true,
  rounded = true,
  onBannerClose,
}: BannerProps) => {
  const [open, setOpen] = useState(true);
  const onClose = useCallback(() => {
    setOpen(false);
    if (onBannerClose) {
      onBannerClose();
    }
  }, [onBannerClose]);
  if (!open) return null;

  return (
    <div
      className={cn(
        'flex w-full items-center justify-center px-16 py-24 md:px-32 lg:px-64',
        {
          'text-text-negative-foreground bg-background-negative':
            type === 'error',
          'text-text-positive-foreground bg-background-positive':
            type === 'success',
          'text-text-primary-foreground bg-background-warning':
            type === 'warning',
          'rounded-8': rounded,
        },
        className,
      )}
    >
      {closable && <FlexItem />}
      {href ? (
        <a href={href} className="flex items-center gap-8">
          {withPrefixIcon && <Info size={16} weight="bold" />}
          <Body2 className="text-inherit">{children}</Body2>
        </a>
      ) : (
        <span className="flex items-center gap-8">
          {withPrefixIcon && <Info size={16} weight="bold" />}
          <Body2 className="text-inherit">{children}</Body2>
        </span>
      )}
      {closable && (
        <FlexItem>
          <button
            onClick={onClose}
            aria-label="close-banner-button"
            className="ml-auto"
          >
            <X weight="bold" />
          </button>
        </FlexItem>
      )}
    </div>
  );
};

export default Banner;
