import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { GENERATE_CELLO_TOKEN } from 'src/app/cello/celloMutuation';
import useScript from '../../hooks/useScript';
import { User } from '../user/context';

interface Cello {
  cmd: Array<
    (cello: { boot?: (config: CelloBootConfig) => Promise<void> }) => void
  >;
}

interface CelloBootConfig {
  productId: string;
  token: string;
  language: string;
  productUserDetails: {
    email: string;
  };
}

export const useCello = (
  user?: User,
  celloScriptUrl?: string,
  celloProductId?: string,
) => {
  const [celloToken, setCelloToken] = useState<string | null>(null);

  // Load Cello script dynamically using useScript hook
  const scriptLoaded =
    useScript(celloScriptUrl, { type: 'module' }) === 'ready';

  const [generateCelloToken, { data, loading, error }] =
    useMutation(GENERATE_CELLO_TOKEN);

  // Fetch token when the user is authenticated and script is loaded
  useEffect(() => {
    if (user?.isAuthenticated && scriptLoaded) {
      generateCelloToken();
    }
  }, [user, scriptLoaded, generateCelloToken]);

  // Store token when mutation returns data
  useEffect(() => {
    if (data?.generateCelloToken?.success) {
      setCelloToken(data.generateCelloToken.celloToken);
    }
  }, [data]);

  // Initialize Cello when script and token are ready
  const initializeCello = useCallback(() => {
    if (!scriptLoaded || !celloToken || !user?.isAuthenticated) return;

    if (typeof window !== 'undefined') {
      const celloInstance = window as unknown as { cello?: Cello };
      celloInstance.cello = celloInstance.cello ?? { cmd: [] };

      celloInstance.cello.cmd.push(async function (cello) {
        try {
          await cello.boot({
            productId: celloProductId!,
            token: celloToken!,
            language: 'en',
            productUserDetails: {
              email: user?.email,
            },
          });
        } catch (error) {
          console.error('❌ Failed to boot Cello:', error);
        }
      });
    }
  }, [user, celloProductId, celloToken, scriptLoaded]);

  useEffect(() => {
    if (celloToken && scriptLoaded) {
      initializeCello();
    }
  }, [celloToken, scriptLoaded, initializeCello]);

  return { loading, error };
};
