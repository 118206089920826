import { noop } from 'lodash';
import { createContext } from 'react';
import { NavigateOptions } from 'react-router-dom';
import {
  CustomWallet,
  Exchange,
  Integration,
  Wallet,
} from 'src/types/graphql-types';
import { onOpenAddWalletModalProps, onSuccessType } from './types';
import { getNavigationUrlByWalletOrExchangeType } from './utils';

export type AddWalletFlow = {
  onCloseAddWalletModal: () => void;
  isModalOpen: boolean;
  onOpenAddWalletModal: ({ onSuccess }?: onOpenAddWalletModalProps) => void;
  metadata: { publicKeys: string };
  selectedIntegration: Integration;
  onSelectIntegration: (integration: Integration) => void;
  integrations: Integration[];
  loading: boolean;
  onSuccess: onSuccessType;
  setOnSuccess: (onSuccess: onSuccessType) => void;
  refetch: () => void;
  setIsHidden: (isHidden: boolean) => void;
};

export const initialAddWalletContext: AddWalletFlow = {
  onCloseAddWalletModal: noop,
  onOpenAddWalletModal: noop,
  isModalOpen: false,
  metadata: { publicKeys: null },
  selectedIntegration: null,
  onSelectIntegration: noop,
  integrations: [],
  loading: false,
  onSuccess: noop,
  setOnSuccess: noop,
  refetch: noop,
  setIsHidden: noop,
};

export const AddWalletContext = createContext<AddWalletFlow>(
  initialAddWalletContext,
);

export const defaultOnSuccess: (
  navigate: (url: string, options?: NavigateOptions) => void,
) => onSuccessType =
  (navigate) =>
  (createdWalletOrExchange: Exchange | Wallet | CustomWallet, redirectUrl) => {
    if (createdWalletOrExchange) {
      const navigateToUrl = getNavigationUrlByWalletOrExchangeType(
        createdWalletOrExchange,
      );
      navigate(redirectUrl || navigateToUrl, {
        state: { scrollToNavLinkWithURL: navigateToUrl },
      });
    }
  };
