import { AnalyticsClient } from '@cointracker/analytics';
import { createContext } from 'react';

export interface AnalyticsForComponents {
  track: AnalyticsClient['track'];
  page: AnalyticsClient['page'];
  identify: AnalyticsClient['identify'];
}

export const AnalyticsContext = createContext<{
  analytics: AnalyticsForComponents;
}>(null);
