import { SkeletonCircle, SkeletonLine } from '@cointracker/styleguide';

export default function AddWalletLoadingCard() {
  return (
    <div className="flex h-[73px] w-full items-center gap-12 rounded-8 border border-line-secondary px-16 py-8">
      <SkeletonCircle size={40}></SkeletonCircle>
      <SkeletonLine width={100} height={10}></SkeletonLine>
    </div>
  );
}
