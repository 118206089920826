import { InlineBanner, InlineBannerContent } from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Info } from '@phosphor-icons/react';
interface DuplicateTransactionsCalloutProps {
  integrationName: string;
}

export const DuplicateTransactionsCallout = ({
  integrationName,
}: DuplicateTransactionsCalloutProps) => {
  return (
    <InlineBanner variant="negative">
      <Info size={25} />
      <InlineBannerContent>
        <Body variant="body3" className="inline">
          {`Importing additional ${integrationName} transactions could result in duplicates. Please review our guide on handling duplicate transactions `}
          <a
            href="https://support.cointracker.io/hc/en-us/articles/23443618413969-Importing-transactions-into-a-synced-exchange-or-wallet"
            target="_blank"
            rel="noopener noreferrer"
            className="inline text-text-link"
          >
            here
          </a>
          .
        </Body>
      </InlineBannerContent>
    </InlineBanner>
  );
};
