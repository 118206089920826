import classNames from 'classnames';
import { forwardRef } from 'react';
import { type ChildAndClassNameProps } from '../types';
import { Body1 } from '../typography';
import { body, card, footer, header } from './TestimonialCard.css';

export const TestimonialCard = forwardRef<
  HTMLDivElement,
  ChildAndClassNameProps
>(({ children, className, ...props }, ref) => (
  <div className={classNames(card, className)} ref={ref} {...props}>
    {children}
  </div>
));
TestimonialCard.displayName = 'TestimonialCard';

export const TestimonialHeader = forwardRef<
  HTMLDivElement,
  ChildAndClassNameProps
>(({ children, className, ...props }, ref) => (
  <div className={classNames(header, className)} ref={ref} {...props}>
    {children}
  </div>
));
TestimonialHeader.displayName = 'TestimonialHeader';

export const TestimonialFooter = forwardRef<
  HTMLDivElement,
  ChildAndClassNameProps
>(({ children, className, ...props }, ref) => (
  <div className={classNames(footer, className)} ref={ref} {...props}>
    {children}
  </div>
));
TestimonialFooter.displayName = 'TestimonialFooter';

export const TestimonialBody = forwardRef<
  HTMLDivElement,
  ChildAndClassNameProps
>(({ children, className, ...props }, ref) => (
  <Body1 ref={ref} className={classNames(body, className)} {...props}>
    {children}
  </Body1>
));
TestimonialBody.displayName = 'TestimonialBody';
