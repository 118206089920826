import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import { Grid } from '../Grid';
import { type ChildAndClassNameProps } from '../types';
import {
  container,
  stylediframe,
  subtitle,
  title,
  videoFrame,
} from './VideoModule.css';

export const VideoContainer = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;
  return (
    <Grid className={classNames(container, className)} {...rest}>
      {children}
    </Grid>
  );
};

VideoContainer.displayName = 'VideoContainer';

export const VideoTitle = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { className, children, ...rest } = props;
    return (
      <div className={classNames(title, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

VideoTitle.displayName = 'VideoTitle';

export const VideoSubtitle = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { className, children, ...rest } = props;
    return (
      <div className={classNames(subtitle, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

VideoSubtitle.displayName = 'VideoSubtitle';

interface VideoProps {
  videoSrc: string;
  videoTitle: string;
}

export const VideoFrame = forwardRef(
  (props: VideoProps, ref: Ref<HTMLDivElement>) => {
    const { videoSrc, videoTitle, ...rest } = props;
    return (
      <div className={videoFrame} ref={ref} {...rest}>
        <iframe
          className={stylediframe}
          src={videoSrc}
          title={videoTitle}
          allowFullScreen
        />
      </div>
    );
  },
);

VideoFrame.displayName = 'VideoFrame';
