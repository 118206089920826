import { useEffect, useState } from 'react';
import { Integration } from 'src/types/graphql-types';
import AddWalletLoadingCard from '../AddWalletLoadingCard';
import { WalletIcon } from '../WalletIcon';
import { HighlightedIntegrationName } from './HighlightedIntegrationName';
import { getIconURL, isOverrideIcon, setImgURL } from './util';

interface AddWalletCardProps {
  integration: Integration;
  setSelectedIntegration: (integration: Integration) => void;
  searchQuery?: string;
}

export const AddWalletCard = (props: AddWalletCardProps) => {
  const integration = props.integration;
  const [isLoading, setIsLoading] = useState(true);
  const isOverideIcon = isOverrideIcon(integration);
  const iconUrl = getIconURL(integration);

  useEffect(() => {
    const controller = new AbortController();
    setImgURL(iconUrl, () => {}, setIsLoading, controller, isOverideIcon);
    return () => {
      controller.abort();
    };
  }, [iconUrl, isOverideIcon]);

  const onClick = () => {
    props.setSelectedIntegration(integration);
  };

  if (isLoading) {
    return <AddWalletLoadingCard />;
  }
  return (
    <button
      className="flex h-[73px] w-full items-center gap-12 rounded-8 border border-line-secondary px-16 py-8"
      key={integration.slug + '_card'}
      data-testid={`${integration.slug}_card`}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
    >
      <WalletIcon integration={integration} />
      <HighlightedIntegrationName
        integrationName={integration.name}
        searchQuery={props.searchQuery ?? ''}
      />
    </button>
  );
};
