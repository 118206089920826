import CheckIcon from '@cointracker/styleguide/src/icons/check.svg?react';
import classNames from 'classnames';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { Grid } from '../Grid';
import { type ChildAndClassNameProps } from '../types';
import { Body1, Body2, ButtonText, H3, H6 } from '../typography';
import {
  container,
  featureItem,
  featureListContainer,
  styledFeatureTitle,
  styledSummarySubtitle,
  styledSummaryTitle,
  styleFeatureSubtitle,
  summaryContainer,
  summaryHeader,
  tabletLink,
  textContainer,
  webLink,
} from './FeaturesList.css';

interface FeatureItemProps {
  className?: string;
  title: string;
  subtitle?: string;
  children?: ReactNode;
}

export const FeatureItem = forwardRef(
  (props: FeatureItemProps, ref: Ref<HTMLDivElement>) => {
    const { children, title, subtitle, className, ...rest } = props;
    return (
      <div className={classNames(featureItem, className)} ref={ref} {...rest}>
        <CheckIcon />
        <div className={textContainer}>
          <H6 className={styledFeatureTitle}>{title}</H6>
          <Body2 className={styleFeatureSubtitle}>{subtitle}</Body2>
          <div>{children}</div>
        </div>
      </div>
    );
  },
);

FeatureItem.displayName = 'FeatureItem';

export const FeaturesContainer = forwardRef(
  (
    props: ChildAndClassNameProps & {
      withLink?: boolean;
      linkText?: string;
      linkHref?: string;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { children, className, withLink, linkText, linkHref, ...rest } =
      props;
    return (
      <div
        className={classNames(featureListContainer, className)}
        ref={ref}
        {...rest}
      >
        {children}
        {withLink && (
          <a href={linkHref} className={tabletLink}>
            <ButtonText>{linkText}</ButtonText>
          </a>
        )}
      </div>
    );
  },
);

FeaturesContainer.displayName = 'FeaturesContainer';

export const FeaturesSummarySubtitle = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <Body1
        className={classNames(styledSummarySubtitle, className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Body1>
    );
  },
);

FeaturesSummarySubtitle.displayName = 'FeaturesSummarySubtitle';

export const FeaturesSummaryTitle = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <H3
        className={classNames(styledSummaryTitle, className)}
        ref={ref}
        {...rest}
      >
        {children}
      </H3>
    );
  },
);

FeaturesSummaryTitle.displayName = 'FeaturesSummaryTitle';

export const FeaturesSummaryHeader = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={classNames(summaryHeader, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

FeaturesSummaryHeader.displayName = 'FeaturesSummaryHeader';

export const FeaturesSummary = forwardRef(
  (
    props: ChildAndClassNameProps & {
      withLink?: boolean;
      linkText?: string;
      linkVariant?: 'no-underline' | 'underline';
      linkHref?: string;
    },
    ref: Ref<HTMLHeadingElement>,
  ) => {
    const {
      children,
      className,
      linkHref,
      linkText,
      linkVariant = 'underline',
      withLink,
      ...rest
    } = props;

    return (
      <div
        className={classNames(summaryContainer, className)}
        ref={ref}
        {...rest}
      >
        {children}
        {withLink && (
          <a
            href={linkHref}
            className={webLink}
            data-underline={linkVariant !== 'no-underline'}
          >
            <ButtonText>{linkText}</ButtonText>
          </a>
        )}
      </div>
    );
  },
);

FeaturesSummary.displayName = 'FeaturesSummary';

export const FeaturesList = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLHeadingElement>) => {
    const { children, className, ...rest } = props;
    return (
      <Grid className={classNames(container, className)} ref={ref} {...rest}>
        {children}
      </Grid>
    );
  },
);

FeaturesList.displayName = 'FeaturesList';
