import classNames from 'classnames';
import React, { forwardRef, useCallback, type Ref } from 'react';
import { button, type ButtonVariants } from './Button.css';

type ButtonProps = {
  children?: React.ReactNode;
  href?: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & ButtonVariants;

export const Button = forwardRef(
  (
    {
      children,
      className,
      style,
      onClick,
      disabled,
      href,
      variant,
      size,
      ...props
    }: ButtonProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ) => {
    const onClickHander: React.MouseEventHandler<HTMLButtonElement> =
      useCallback(
        (e) => {
          if (typeof window === 'undefined') return;
          if (href) {
            window.location.href = href;
          } else if (onClick) {
            onClick(e);
          } else {
            console.warn('Button clicked, but no href or onClick provided!');
          }
        },
        [href, onClick],
      );
    return (
      <button
        ref={forwardedRef}
        type="button"
        disabled={disabled}
        onClick={onClickHander}
        className={classNames(button({ variant, size }), className)}
        style={style}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
