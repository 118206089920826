import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState, type Ref } from 'react';
import { Button } from '../Button';
import { Grid } from '../Grid';
import ArrowLeft from '../icons/arrow-left.svg?react';
import ArrowRight from '../icons/arrow-right.svg?react';
import {
  actionButtonsRow,
  carouselContainer,
  contentContainer,
  contentContainerWrapper,
} from './Carousel.css';

const OVERFLOW_DETECTION_MARGIN = 32;

export interface CarouselProps {
  children?: React.ReactNode;
  className?: string;
  contentContainerClassName?: string;
  onOverflowChange?: (
    hasLeftOverflow: boolean,
    hasRightOverflow: boolean,
  ) => void;
}

export const Carousel = forwardRef(
  (props: CarouselProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, contentContainerClassName, onOverflowChange } =
      props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [isLeftDisabled, setIsLeftDisabled] = useState(true);
    const [isRightDisabled, setIsRightDisabled] = useState(false);

    const [fadeLeft, setFadeLeft] = useState(false);
    const [fadeRight, setFadeRight] = useState(true);

    useEffect(() => {
      const checkScrollPosition = () => {
        if (contentRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = contentRef.current;

          const overflowLeft = scrollLeft > OVERFLOW_DETECTION_MARGIN;
          const overflowRight =
            scrollLeft + clientWidth < scrollWidth - OVERFLOW_DETECTION_MARGIN;

          setIsLeftDisabled(!overflowLeft);
          setIsRightDisabled(!overflowRight);

          const disableOverflow = clientWidth >= window.innerWidth;
          setFadeLeft(!disableOverflow && overflowLeft);
          setFadeRight(!disableOverflow && overflowRight);

          onOverflowChange?.(overflowLeft, overflowRight);
        }
      };

      checkScrollPosition();
      const currentRef = contentRef.current;
      if (currentRef) {
        currentRef.addEventListener('scroll', checkScrollPosition);
        window.addEventListener('resize', checkScrollPosition);
      }
      return () => {
        if (currentRef) {
          currentRef.removeEventListener('scroll', checkScrollPosition);
          window.removeEventListener('resize', checkScrollPosition);
        }
      };
    }, [onOverflowChange]);

    const scroll = (direction: 'left' | 'right') => {
      if (contentRef.current) {
        // 3 cards are visible at a time when the buttons are visible
        const scrollAmount =
          (
            contentRef.current.firstChild as HTMLElement
          )?.getBoundingClientRect()?.width || 326;
        contentRef.current.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    };

    return (
      <section
        ref={ref}
        className={classNames(carouselContainer({}), className)}
      >
        <Grid className={actionButtonsRow}>
          <Button
            hideInitialIcon
            noAnimation
            disabled={isLeftDisabled}
            onClick={() => scroll('left')}
          >
            <ArrowLeft />
          </Button>
          <Button
            hideInitialIcon
            noAnimation
            disabled={isRightDisabled}
            onClick={() => scroll('right')}
          >
            <ArrowRight />
          </Button>
        </Grid>
        <div
          className={contentContainerWrapper({
            hasLeftOverflow: fadeLeft,
            hasRightOverflow: fadeRight,
          })}
        >
          <Grid
            className={classNames(contentContainer, contentContainerClassName)}
            ref={contentRef}
          >
            {children}
          </Grid>
        </div>
      </section>
    );
  },
);

Carousel.displayName = 'Carousel';
