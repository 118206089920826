import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { useContext } from 'react';
import { UserContext } from 'src/app/user/context';
import {
  AddWalletErrors,
  PortfolioPlan,
  SubscriptionPlan,
} from 'src/types/graphql-types';
interface ErrorMessageProps {
  error: AddWalletErrors | string;
}

export const defaultErrorMessage = 'An error has occurred, please try again.';

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const user = useContext(UserContext);
  const isPaidUser =
    user?.isAuthenticated &&
    (user?.unifiedSubscription?.subscriptionPlan !== SubscriptionPlan.Free ||
      user?.portfolioSubscription.toUpperCase() !== PortfolioPlan.Free);

  if (error === AddWalletErrors.HasZeroTransactions) {
    return (
      <Body variant="body3">
        We could not add your wallet as it has zero transactions.{' '}
        <a
          target="_blank"
          href="https://support.cointracker.io/hc/en-us/articles/4413071373329-How-do-I-track-my-local-cold-hardware-wallet-Why-is-it-showing-zero-balance-"
          rel="noreferrer"
        >
          <Body variant="body3" className="text-text-link">
            Learn more about this error.
          </Body>
        </a>
      </Body>
    );
  } else if (error === AddWalletErrors.TooManyTransactions) {
    return (
      <Body variant="body3">
        {isPaidUser ? (
          <>
            This wallet exceeds the number of transactions supported by the
            platform.{' '}
            <a
              target="_blank"
              href="https://support.cointracker.io/hc/en-us/?generationErrorMsg"
              rel="noreferrer"
            >
              <Body variant="body3" className="text-text-link">
                Contact support
              </Body>
            </a>
            to request an increase.
          </>
        ) : (
          'This wallet exceeds the number of transactions allowed for your current plan.'
        )}
      </Body>
    );
  } else if (error === AddWalletErrors.WalletAlreadyLinked) {
    return (
      <Body variant="body3">
        This wallet has already been added to CoinTracker. Please see below.
      </Body>
    );
  } else if (error === AddWalletErrors.WalletValidationError) {
    return (
      <Body variant="body3">
        We could not add your wallet as this address is invalid. Please follow
        the instructions below and try again.
      </Body>
    );
  } else if (error === AddWalletErrors.InvalidCryptoCurrency) {
    return (
      <Body variant="body3">Wallets of this coin are not supported yet</Body>
    );
  } else if (
    error === AddWalletErrors.UnknownError ||
    error === AddWalletErrors.ValueError
  ) {
    return <Body variant="body3">{defaultErrorMessage}</Body>;
  } else {
    return <Body variant="body3">{error}</Body>;
  }
};
