import classNames from 'classnames';
import { forwardRef, useState, type Ref } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { NavLink } from '../NavLink';
import { Body2 } from '../typography';
import { shareLinks, shareLinksTitle } from './ShareLinks.css';

export interface ShareLinksProps {
  className?: string;
  title: string;
  to: string;
}

export const ShareLinks = forwardRef(
  (props: ShareLinksProps, ref: Ref<HTMLDivElement>) => {
    const { className, title, to, ...rest } = props;

    const [, copyToClipboard] = useCopyToClipboard();

    const [linkCopyTimeout, setLinkCopyTimeout] = useState<ReturnType<
      typeof setTimeout
    > | null>(null);
    const wasCopied = linkCopyTimeout !== null;

    const linkedIn = linkedInLink(to);
    const email = emailLink(to, title);
    const x = xLink(to, title);

    const onCopyLink = () => {
      if (linkCopyTimeout) clearTimeout(linkCopyTimeout);

      copyToClipboard(to);

      const timeout = setTimeout(() => {
        setLinkCopyTimeout(null);
      }, 1000);
      setLinkCopyTimeout(timeout);
    };

    return (
      <div className={classNames(shareLinks, className)} ref={ref} {...rest}>
        <Body2 className={shareLinksTitle}>Share article</Body2>
        {!wasCopied ? (
          <NavLink variant="secondary" onClick={onCopyLink}>
            Copy Link
          </NavLink>
        ) : (
          <NavLink variant="secondaryConfirm">Copied</NavLink>
        )}
        <NavLink variant="secondary" href={linkedIn} target="_blank">
          LinkedIn
        </NavLink>
        <NavLink variant="secondary" href={email} target="_blank">
          Email
        </NavLink>
        <NavLink variant="secondary" href={x} target="_blank">
          X
        </NavLink>
      </div>
    );
  },
);

ShareLinks.displayName = 'ShareLinks';

function linkedInLink(href: string): string {
  const encodedUrl = encodeURIComponent(href);
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
}

function emailLink(href: string, title: string): string {
  const subject = `Thought you'd enjoy this read: ${title}`;
  const body = `I just read this article, ${title}, on the CoinTracker blog and thought you might find it useful!\n\nTake a look here: ${href}`;

  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  return `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
}

function xLink(href: string, title: string): string {
  const text = `Highly recommend reading ${title} via @CoinTracker:`;

  const encodedUrl = encodeURIComponent(href);
  const encodedText = encodeURIComponent(text);
  return `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`;
}
