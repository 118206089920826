import { type VariantProps } from 'class-variance-authority';
import React, {
  forwardRef,
  type HTMLInputTypeAttribute,
  type Ref,
} from 'react';
import { Body4 } from '../LoggedIn';
import { bodyVariants } from '../LoggedIn/Typography/variants';
import { cn } from '../utils';
import { inputVariants, supportTextVariants } from './styles';

export type InputVariants = VariantProps<typeof inputVariants>;

type InputProps = {
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  id?: string;
  className?: string;
  placeholder?: string;
  name?: string;
  supportText?: string;
  label?: string;
  value?: string | number;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  hasError?: boolean;
  errorMessage?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
} & InputVariants &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
export const TextInput = forwardRef(
  (
    {
      id,
      className,
      onChange,
      disabled,
      placeholder,
      hasError = false,
      label,
      size,
      supportText,
      errorMessage,
      variant,
      value,
      align,
      prefix,
      suffix,
      type,
      ...props
    }: InputProps,
    forwardedRef: Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={cn(className)}>
        {label && (
          <label
            className={cn(
              bodyVariants({ variant: 'body4', weight: 'bold' }),
              'mb-space-8',
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div
          className={inputVariants({
            variant,
            size,
            hasError,
            disabled,
            align,
          })}
        >
          {prefix && <div className="">{prefix}</div>}
          <input
            id={id}
            ref={forwardedRef}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            type={type}
            className="w-full border-none bg-transparent outline-none"
            {...props}
          />
          {suffix && <div className="">{suffix}</div>}
        </div>
        {supportText && (
          <Body4 className={supportTextVariants({ variant, hasError })}>
            {supportText}
          </Body4>
        )}
        {hasError && (
          <Body4 className="mt-space-8 text-text-negative">
            {errorMessage}
          </Body4>
        )}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';
