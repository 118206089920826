import { cn, Modal } from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { DialogContentProps } from '@radix-ui/react-dialog';
import { useAppKitState } from '@reown/appkit/react';
import { isEmpty } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useDebounce from 'src/hooks/useDebounce';
import {
  CryptoNetworkResult,
  Integration,
  useGetDetectedCryptoNetworksLazyQuery,
} from 'src/types/graphql-types';
import { InstitutionalContact } from './InstitutionalContact';
import IntegrationForm from './IntegrationForm';
import { IntegrationsList } from './IntegrationsList';
import { CSVIntegration } from './SearchResults/BlankSearchResults';
import { getIntegrationNameParamFromURL } from './utils';
import { WalletIcon } from './WalletIcon';

export interface AddWalletModalProps {
  isOpen?: boolean;
  close?: () => void;
  loading?: boolean;
  onHide?: () => void;
  integrations: Integration[];
  selectedIntegration?: Integration;
  setSelectedIntegration?: Dispatch<SetStateAction<Integration>>;
  modalTitle?: string;
  isOnboarding?: boolean;
  isHidden?: boolean;
}

export const AddWalletModal = (props: AddWalletModalProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { open: appKitOpen } = useAppKitState();
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const debouncedSearchTerm = useDebounce(searchQuery, 100);
  const [isDetectedCryptoNetwork, setIsDetectedCryptoNetwork] =
    useState<boolean>(false);
  const [getDetectedCryptoNetworks, { loading }] =
    useGetDetectedCryptoNetworksLazyQuery();

  const getFilteredIntegrationsList = useCallback(
    async function getFilteredIntegrationsList(searchQuery: string) {
      const filteredIntegrationList = props.integrations.filter(
        (integration) => {
          return (
            integration.name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            integration.slug
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            integration?.info?.symbol
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase())
          );
        },
      );
      if (filteredIntegrationList.length) {
        filteredIntegrationList.sort((a, b) => a.rank - b.rank);
        setIntegrations(filteredIntegrationList);
        setIsDetectedCryptoNetwork(false);
      } else {
        let response;
        try {
          response = await getDetectedCryptoNetworks({
            variables: { address: searchQuery },
          });
        } catch (error) {
          console.error(error);
        }
        const detectedCryptoNetworks =
          response?.data?.detectedCryptoNetworks || [];
        const matchingIntegrationsFromDetectedCryptoNetworks =
          props.integrations.filter((integration) => {
            return detectedCryptoNetworks
              .map((cryptoNetwork: CryptoNetworkResult) =>
                cryptoNetwork.cryptoNetworkName.toLowerCase(),
              )
              .includes(integration.name.toLowerCase());
          });
        matchingIntegrationsFromDetectedCryptoNetworks.sort(
          (a, b) => a.rank - b.rank,
        );
        setIntegrations(matchingIntegrationsFromDetectedCryptoNetworks);
        setIsDetectedCryptoNetwork(true);
      }
    },
    [getDetectedCryptoNetworks, props.integrations],
  );

  useEffect(() => {
    getFilteredIntegrationsList(debouncedSearchTerm);
  }, [debouncedSearchTerm, getFilteredIntegrationsList]);

  useEffect(() => {
    if (!isEmpty(props.integrations)) {
      getFilteredIntegrationsList(searchQuery);
    }
  }, [props.integrations]);

  const clearIntegration = useCallback(() => {
    props.setSelectedIntegration(null);
  }, [props]);

  const searchOnTextChange = useCallback(
    (text: string) => {
      setSearchQuery(text);
    },
    [setSearchQuery],
  );

  const getTitle = useCallback((integration: Integration) => {
    if (integration.name === CSVIntegration.name) {
      return 'Import transactions from CSV';
    } else {
      return `Add ${integration.name}`;
    }
  }, []);

  const onOpenChange = () => {
    if (appKitOpen) return;
    props.onHide();
    setSearchQuery('');
  };

  const integrationNameFromURL = getIntegrationNameParamFromURL();

  const getWalletIcon = useCallback((integration: Integration) => {
    return integration.slug === CSVIntegration.slug ? null : (
      <WalletIcon integration={integration} size="small" />
    );
  }, []);

  const doNotCloseOnPointerDownOutside: DialogContentProps['onPointerDownOutside'] =
    (event) => {
      event.preventDefault();
    };

  return (
    <Modal.Root open={props.isOpen} onOpenChange={onOpenChange}>
      <Modal.Content
        className={cn('!max-w-[736px]')}
        disableOverlay={props.isHidden}
        onPointerDownOutside={
          props.isHidden ? doNotCloseOnPointerDownOutside : undefined
        }
      >
        {!props.selectedIntegration && !integrationNameFromURL && (
          <>
            <Modal.ContentHeader className="z-10">
              <div className="flex w-full items-center">
                <Body variant="body1" weight="bold">
                  Add your wallets
                </Body>
              </div>
            </Modal.ContentHeader>
            <Modal.ContentBody className="h-full">
              <IntegrationsList
                integrations={integrations}
                setSelectedIntegration={props.setSelectedIntegration}
                searchQuery={searchQuery}
                loading={props.loading || loading}
                searchOnTextChange={searchOnTextChange}
              />
            </Modal.ContentBody>
          </>
        )}

        {props.selectedIntegration && (
          <>
            <Modal.ContentHeader
              className="z-10"
              onClickBack={clearIntegration}
            >
              <div className="flex w-full items-center justify-center gap-10">
                {getWalletIcon(props.selectedIntegration)}
                <Body variant="body1" weight="bold">
                  {getTitle(props.selectedIntegration)}
                </Body>
              </div>
            </Modal.ContentHeader>
            <Modal.ContentBody className="p-16">
              {props.selectedIntegration.info?.isInstitutional ? (
                <InstitutionalContact slug={props.selectedIntegration.slug} />
              ) : (
                <IntegrationForm
                  integration={props.selectedIntegration}
                  defaultWalletAddress={
                    isDetectedCryptoNetwork ? searchQuery : null
                  }
                  integrations={props.integrations}
                  onCloseModal={onOpenChange}
                />
              )}
            </Modal.ContentBody>
          </>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
