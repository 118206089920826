import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

interface NavigateWithParamsProps extends NavigateProps {}

export function NavigateWithParams(props: NavigateWithParamsProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newUrl = `${props.to}?${searchParams.toString()}`;

  return <Navigate {...props} to={newUrl} />;
}
