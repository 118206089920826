import { type CheckedState } from '@radix-ui/react-checkbox';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React, { forwardRef, type Ref } from 'react';
import { Checkbox } from '../Checkbox';
import ChevronDownIcon from '../icons/chevron-down.svg?react';
import { Button, type ButtonProps } from '../LoggedIn/Button';
import { Body2, Body4 } from '../typography';
import { button, checkbox, item, itemRaw } from './FilterSelect.css';
import {
  iconContainer,
  indicatorIcon,
  itemsContent,
  selectTrigger,
  styledSelectTriggerValue,
} from './Select.css';

export interface FilterSelectProps extends DropdownPrimitive.DropdownMenuProps {
  className?: string;
  value?: string;
}

export const FilterSelect = forwardRef(
  (props: FilterSelectProps, forwardedRef: Ref<HTMLButtonElement>) => {
    const { children, className, value, ...rest } = props;

    return (
      <DropdownPrimitive.Root {...rest}>
        <DropdownPrimitive.Trigger
          ref={forwardedRef}
          className={classNames(selectTrigger, className)}
        >
          <Body2 className={styledSelectTriggerValue}>{value}</Body2>
          <div className={iconContainer}>
            <ChevronDownIcon className={indicatorIcon} />
          </div>
        </DropdownPrimitive.Trigger>
        <DropdownPrimitive.Content className={itemsContent}>
          {children}
        </DropdownPrimitive.Content>
      </DropdownPrimitive.Root>
    );
  },
);

FilterSelect.displayName = 'FilterSelect';

export interface FilterSelectItemProps
  extends DropdownPrimitive.DropdownMenuCheckboxItemProps {
  children?: React.ReactNode;
  title: string;
}

export const FilterSelectItem = forwardRef(
  (props: FilterSelectItemProps, forwardedRef: Ref<HTMLDivElement>) => {
    const { checked, children, className, ...rest } = props;

    return (
      <DropdownPrimitive.CheckboxItem
        className={classNames(className, item)}
        onSelect={(e) => e.preventDefault()}
        checked={checked}
      >
        <FilterSelectItemRaw checked={checked} ref={forwardedRef} {...rest}>
          {children}
        </FilterSelectItemRaw>
      </DropdownPrimitive.CheckboxItem>
    );
  },
);

FilterSelectItem.displayName = 'FilterSelectItem';

export interface FilterSelectItemRawProps {
  checked?: CheckedState;
  children?: React.ReactNode;
  className?: string;
  title: string;
  onCheckedChange?: (checked: boolean) => void;
}

export const FilterSelectItemRaw = forwardRef(
  (props: FilterSelectItemRawProps, forwardedRef: Ref<HTMLDivElement>) => {
    const { checked, children, className, title, onCheckedChange, ...rest } =
      props;

    return (
      <div
        ref={forwardedRef}
        className={classNames(itemRaw, className)}
        {...rest}
      >
        <Checkbox
          checked={checked}
          onCheckedChange={onCheckedChange}
          label={title}
          className={checkbox}
          tabIndex={-1}
        />
        <Body4 variant="secondary">{children}</Body4>
      </div>
    );
  },
);

FilterSelectItemRaw.displayName = 'FilterSelectItemRaw';

export const FilterSelectButton = forwardRef(
  (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { className, ...rest } = props;

    return (
      <Button className={classNames(button, className)} ref={ref} {...rest} />
    );
  },
);

FilterSelectButton.displayName = 'FilterSelectButton';
