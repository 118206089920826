import ArrowRight from '@cointracker/styleguide/src/icons/arrow-right.svg?react';
import ChevronDownIcon from '@cointracker/styleguide/src/icons/chevron-down.svg?react';
import { cva } from 'class-variance-authority';
import React, { forwardRef, type ReactNode, type Ref } from 'react';

import { cn } from '../../utils';
import { Body2, NavLinkText } from '../Typography';

type NavLinkProps = {
  children?: ReactNode;
  href?: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  withIcon?: boolean;
  variant?: 'primary' | 'secondary';
  to?: string;
};

const navLinkVariants = cva(
  'transition-border duration-250 text-decoration-none text-text-primary group -mb-2 inline-flex items-center justify-center gap-2 overflow-hidden border-b-2 border-transparent p-0 ease-in-out hover:cursor-pointer',
  {
    variants: {
      variant: {
        primary:
          'lg:hover:border-text lg:data-[state=open]:border-text justify-between lg:hover:border-b-2 lg:hover:border-solid lg:data-[state=open]:border-b-2 lg:data-[state=open]:border-solid',
        secondary: 'px-0 py-[5.5px]',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

// Navlink icon works best when paired with the Radix dropdown component
export const NavLink = forwardRef(
  (
    {
      children,
      className,
      style,
      onClick,
      disabled,
      href,
      variant = 'primary',
      withIcon,
      ...props
    }: NavLinkProps,
    forwardedRef: Ref<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    if (href) {
      return (
        <a
          ref={forwardedRef as Ref<HTMLAnchorElement>}
          className={cn(navLinkVariants({ variant }), className)}
          style={style}
          href={href}
          {...props}
        >
          <NavLinkContent variant={variant} withIcon={withIcon}>
            {children}
          </NavLinkContent>
        </a>
      );
    }
    return (
      <button
        ref={forwardedRef as Ref<HTMLButtonElement>}
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={cn(navLinkVariants({ variant }), className)}
        style={style}
        {...props}
      >
        <NavLinkContent variant={variant} withIcon={withIcon}>
          {children}
        </NavLinkContent>
      </button>
    );
  },
);

NavLink.displayName = 'NavLink';

function NavLinkContent({
  variant = 'primary',
  children,
  withIcon,
}: NavLinkProps) {
  if (variant !== 'secondary') {
    return (
      <>
        <NavLinkText>{children}</NavLinkText>
        {withIcon && (
          <ChevronDownIcon className="ease-simple text-text-primary h-24 w-24 -rotate-90 transition-transform duration-500 group-data-[state=open]:rotate-180 md:h-[25px] md:w-[25px]" />
        )}
      </>
    );
  }
  return (
    <>
      <Body2>{children}</Body2>
      <ArrowRight className="transition-translate ease-simple text-text-primary -translate-x-full opacity-0 duration-500 group-hover:translate-x-[0] group-hover:opacity-100" />
    </>
  );
}
