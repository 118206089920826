import React from 'react';
import { AddWalletCard } from '../AddWalletCard';

import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Integration } from 'src/types/graphql-types';
import AddWalletLoadingCard from '../AddWalletLoadingCard';
import { BlankSearchResults } from './BlankSearchResults';

interface SearchResultsProps {
  integrations: Integration[];
  loading: boolean;
  setSelectedIntegration: React.Dispatch<React.SetStateAction<Integration>>;
  searchQuery: string;
}
export const SearchResults = ({
  integrations,
  loading,
  setSelectedIntegration,
  searchQuery,
}: SearchResultsProps) => {
  if (!loading && integrations.length === 0) {
    return (
      <BlankSearchResults setSelectedIntegration={setSelectedIntegration} />
    );
  }
  if (loading && integrations.length === 0) {
    return (
      <div className="grid grid-cols-1 justify-between gap-12 lg:grid-cols-2">
        <AddWalletLoadingCard />
        <AddWalletLoadingCard />
        <AddWalletLoadingCard />
        <AddWalletLoadingCard />
        <AddWalletLoadingCard />
        <AddWalletLoadingCard />
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 justify-between gap-12 lg:grid-cols-2">
      {integrations.map((integration) => {
        return (
          <LazyLoadComponent
            key={`${integration.slug}_${integration.category}`}
            placeholder={<AddWalletLoadingCard />}
          >
            <AddWalletCard
              key={`${integration.slug}_${integration.category}_wallet_card`}
              integration={integration}
              setSelectedIntegration={setSelectedIntegration}
              searchQuery={searchQuery}
            />
          </LazyLoadComponent>
        );
      })}
    </div>
  );
};
