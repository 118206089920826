export function useIsMobileWebview(): boolean {
  const ua = window.navigator?.userAgent ?? '';
  const isMobileWebview = ua?.startsWith?.('CoinTracker/');
  return isMobileWebview;
}

declare global {
  interface Window {
    ReactNativeWebView?: { postMessage: (message: string) => void };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function postMessageToMobileWebview(message: any): void {
  if (
    window &&
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage
  ) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  }
}
