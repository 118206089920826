import { Body5 } from '@cointracker/styleguide';
import { Sparkle, X } from '@phosphor-icons/react';
import * as Toast from '@radix-ui/react-toast';
import { useGate } from 'statsig-react';
import { OfferToast } from '.';

export function WelcomeBackOfferToast() {
  const { value: isWelcomeBackToastEnabled } = useGate('welcome_back_30_2025');

  if (!isWelcomeBackToastEnabled) {
    return null;
  }

  return (
    <OfferToast
      className="bg-accent-subtle-lilac sm:bg-background-pop-up"
      showOnceWithKey="welcome_back_30_2025_toast_seen"
    >
      <div className="flex w-fit flex-1 items-stretch gap-12">
        <div className="hidden h-full shrink-0 items-center justify-center sm:flex">
          <div className="flex h-52 w-52 shrink-0 items-center justify-center rounded-4 bg-background-alternate-consistent">
            <Sparkle weight="regular" className="h-18 w-18 sm:h-24 sm:w-24" />
          </div>
        </div>
        <div className="w-[280px] flex-col items-center gap-4 sm:w-[255px] md:w-[280px]">
          <Toast.Title asChild>
            <Body5 className="inline-flex items-end gap-4 font-semibold !text-text-primary-consistent sm:!text-text-primary">
              <span className="sm:hidden">
                <Sparkle
                  weight="regular"
                  className="h-18 w-18 sm:h-24 sm:w-24"
                />
              </span>
              You&apos;ve unlocked 30% off your CoinTracker plan.
            </Body5>
          </Toast.Title>
          <Toast.Description asChild>
            <Body5 className="!text-text-secondary">
              Your discount will be applied automatically at checkout. Offer
              expires April 10, 2025.
            </Body5>
          </Toast.Description>
        </div>
        <Toast.Close className="mb-auto flex h-full flex-col text-text-primary-consistent sm:text-text-primary">
          <X weight="regular" className="h-18 w-18" />
        </Toast.Close>
      </div>
    </OfferToast>
  );
}
