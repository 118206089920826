import {
  CustomWallet,
  Exchange,
  OtherTransactions,
  Wallet,
} from '@cointracker/graphql-types';

export enum NavigationType {
  EXCHANGE = '1',
  WALLET = '2',
  CUSTOM_WALLET = '3',
  OTHER_TRANSACTION = '4',
}

export enum WalletsListSortBy {
  marketValueHiLo = 'marketvalue-hi-lo',
  marketValueLoHi = 'marketvalue-lo-hi',
}

export const DEFAULT_WALLET_PAGE_LIMIT = '50';

export interface useGetWalletDataReturn {
  data: {
    exchanges?: Exchange[];
    localWallets?: Wallet[];
    customWallets?: CustomWallet[];
    otherTransactions?: OtherTransactions;
    customWalletsPage: {
      totalCount: number;
      currentPage?: number;
      endPage?: number;
    };
    walletsPage: {
      totalCount: number;
      currentPage?: number;
      endPage?: number;
    };
    exchangesPage: {
      totalCount: number;
      currentPage?: number;
      endPage?: number;
    };
    otherTransactionsPage: {
      hasOtherTransactions: boolean;
    };
    hasAnyWallets: boolean;
  };
  loading?: boolean;
  error?: Error;
  startPolling?: (interval: number) => void;
  stopPolling?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousData?: any;
}
