import { gql } from '@apollo/client';

export const GetWallets = gql`
  query GetWalletsForWalletList($includeEmptyWallets: Boolean) {
    userInfo {
      publicId
      hasAnyWallet
    }
    localWallets(includeEmptyWallets: $includeEmptyWallets) {
      id
      imageUrl
      calculatedValue
      value
      computedTransactionCount
      address
      name
      isPortfolioValueDifferenceSignificant
      allowsHardResync
      syncInfo {
        lastSynced
        isSyncing
        willBeDeleted
        isDisabled
      }
      errors
      assetsCount
      walletNetwork
    }
    exchanges {
      id
      value
      calculatedValue
      imageUrl
      name
      defaultName
      syncInfo {
        lastSynced
        isSyncing
        willBeDeleted
        isDisabled
      }
      isPortfolioValueDifferenceSignificant
      allowsHardResync
      errors
      assetsCount
    }
    customWallets {
      id
      name
      value
      syncInfo {
        isSyncing
        willBeDeleted
        lastSynced @client
      }
      assetsCount
    }
    otherTransactions {
      id
      value
      assetsCount
    }
  }
`;
