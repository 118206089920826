import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Integration } from 'src/types/graphql-types';

const CARDANO_SUPPORT_ARTICLE_LINK =
  'https://support.cointracker.io/hc/en-us/articles/11169088389649-How-To-Add-My-Cardano-Wallet';
const HD_WALLET_SUPPORT_ARTICLE_LINK =
  'https://support.cointracker.io/hc/en-us/articles/4413071373329-How-do-I-track-my-local-cold-hardware-wallet-Why-is-it-showing-zero-balance-';

interface GenericInstructionsProps {
  integration: Integration;
}
export const GenericInstructions = ({
  integration,
}: GenericInstructionsProps) => {
  if (integration.slug === 'cardano') {
    return (
      <a
        href={CARDANO_SUPPORT_ARTICLE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Body variant="body2" className="text-text-link">
          Learn more about how to find your stake key for Cardano
        </Body>
      </a>
    );
  }
  if (integration.supportsHdWallet) {
    return (
      <a
        href={HD_WALLET_SUPPORT_ARTICLE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Body variant="body2" className="text-text-link">
          Learn more about how to find your xPub/yPub/zPub key
        </Body>
      </a>
    );
  }
  return null;
};
