import { TabsContent } from '@cointracker/styleguide/src/LoggedIn/Tabs';
import { ReactNode } from 'react';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';
import FileAPISwitcher, { APITabs } from '../FileAPISwitcher';
import { FileImportForm } from '../FileImportForm';
import { ViewPermissions } from '../ViewPermissions';

interface ExchangeIntegrationFormWrapperProps {
  integration: Integration;
  children: ReactNode;
  onCloseModal?: () => void;
}

export default function ExchangeIntegrationFormWrapper({
  integration,
  children,
  onCloseModal,
}: ExchangeIntegrationFormWrapperProps) {
  return (
    <div className="flex flex-col gap-24">
      <ViewPermissions />
      <FileAPISwitcher
        hasCustomFileImporter={
          integration.syncMethod === IntegrationSyncMethod.AutoSyncAndFile
        }
      >
        <TabsContent value={APITabs.AutomaticSync}>
          <div className="mt-24 flex flex-col gap-24">{children}</div>
        </TabsContent>
        <TabsContent value={APITabs.CSVImport}>
          <FileImportForm
            integration={integration}
            onCloseModal={onCloseModal}
          />
        </TabsContent>
      </FileAPISwitcher>
    </div>
  );
}
