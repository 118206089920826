import { isEmpty } from 'lodash';

export type ValidationErrors = {
  missingChain?: boolean;
};

interface validateProps {
  chainName: string;
}

export const isStringValid = (value: string) => !!value?.trim();

export const validate = ({ chainName }: validateProps) => {
  const errors: ValidationErrors = {};
  if (!isStringValid(chainName)) {
    errors.missingChain = true;
  }

  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
