import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ScrollDirection,
  cn,
  useScrollDirection,
  useSidebar,
} from '@cointracker/styleguide';
import { Body, Button, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { CaretDown, CaretRight, List, X } from '@phosphor-icons/react';
import { AccountBar } from 'components/Rebrand/Sidebar/AccountBar';
import {
  getNavigationItems,
  getTaxPageUrl,
} from 'components/Rebrand/Sidebar/utils';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext, type AuthenticatedUser } from 'src/app/user/context';
import { URLS } from 'src/common/urls';
import { useCbeDemo } from 'src/pages/Rebrand/CostBasisEngineDemo/cbeDemoContext';
interface TopBarProps {
  className?: string;
  shouldBeFixed?: boolean;
}
export function CbeDemoTopBar({
  className,
  shouldBeFixed = false,
}: TopBarProps) {
  const { enableCbe, isBroker, cbeSettings } = useCbeDemo();
  const user = useContext(UserContext);
  const { state, openMobile, setOpenMobile, isMobile } = useSidebar();
  const scrollDirection = useScrollDirection({ threshold: 25 });

  if (isBroker) return null;

  const taxPageURL = getTaxPageUrl(
    (user as AuthenticatedUser)?.displayUser?.taxYearToUse,
  );
  const navigationItems = getNavigationItems({
    taxPageUrl: taxPageURL,
    currentPathName: location.pathname,
    isBroker,
    cbeEnabled: enableCbe,
  });

  if (!enableCbe) return null;

  return (
    <div
      className={cn(
        'flex h-topbar-height w-full flex-row place-items-center justify-between gap-8 border-sidebar-border bg-background-default px-16 text-text-primary',
        openMobile && 'border-b-solid border-b border-line-tertiary',
        shouldBeFixed &&
          'transition-translate fixed left-0 right-0 top-0 z-20 translate-y-0 shadow-elevation-1 duration-150 ease-linear',
        shouldBeFixed &&
          scrollDirection === ScrollDirection.DOWN &&
          'translate-y-[-100%]',
        className,
      )}
    >
      <div className="mx-auto flex w-full max-w-screen-xl flex-row place-items-center justify-between">
        <div className="flex flex-row place-items-center gap-20">
          <NavLink to={taxPageURL}>
            <Heading variant="h5">Tax Center</Heading>
          </NavLink>
        </div>
        {!isMobile && (
          <>
            <ul className="flex flex-row place-items-center gap-48">
              {navigationItems.map((item) => {
                const isConnectPage = item.slug === URLS.WALLETS_PAGE;
                if (isConnectPage && !cbeSettings?.allowUserWalletConnect) {
                  return null;
                }
                const isLotsDropdown = item.slug === 'Lots';
                if (isLotsDropdown) {
                  return (
                    <li key={item.slug}>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="flex cursor-pointer flex-row place-items-center gap-6">
                          <Body
                            variant="body3"
                            className={cn(
                              'text-text-secondary hover:text-text-primary',
                              item.isActive && 'text-text-primary',
                            )}
                          >
                            Tax Lots
                          </Body>
                          <CaretDown
                            size={14}
                            weight="bold"
                            className={cn(
                              'text-text-secondary hover:text-text-primary',
                              item.isActive && 'text-text-primary',
                            )}
                          />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className="group"
                          data-state="expanded"
                          side="bottom"
                          align="end"
                          sideOffset={24}
                        >
                          <DropdownMenuItem>
                            <NavLink to={URLS.TAX_LOTS}>
                              <Body
                                variant="body3"
                                className={cn(
                                  'cursor-pointer text-text-secondary hover:text-text-primary',
                                )}
                              >
                                Available Lots
                              </Body>
                            </NavLink>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <NavLink to={URLS.DISPOSED_LOTS}>
                              <Body
                                variant="body3"
                                className={cn(
                                  'cursor-pointer text-text-secondary hover:text-text-primary',
                                )}
                              >
                                Disposed Lots
                              </Body>
                            </NavLink>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <NavLink to={URLS.TAX_LOSS_HARVEST}>
                              <Body
                                variant="body3"
                                className={cn(
                                  'cursor-pointer text-text-secondary hover:text-text-primary',
                                )}
                              >
                                Tax Loss Harvest
                              </Body>
                            </NavLink>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </li>
                  );
                }
                const title = isConnectPage
                  ? 'Connect'
                  : item.slug === taxPageURL
                    ? 'Home'
                    : item.title;

                return (
                  <li key={item.slug}>
                    <NavLink
                      to={item.slug}
                      onClick={(e) => {
                        !item.slug && e.preventDefault();
                        if (isMobile) {
                          setOpenMobile(false);
                        }
                      }}
                      aria-label={title}
                      className={cn(
                        'flex h-22 cursor-pointer flex-row place-items-center gap-16 text-text-secondary hover:text-text-primary-hover active:text-accent-bold-blue md:gap-8',
                        item.isActive && 'text-text-primary',
                      )}
                    >
                      <Body
                        variant={isMobile ? 'body1' : 'body3'}
                        className={cn(
                          'whitespace-nowrap transition-opacity group-data-[state=collapsed]:opacity-0',
                          // fade animation timing
                          'relative delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
                        )}
                      >
                        {title}
                      </Body>
                      <CaretRight
                        className="ml-auto text-text-primary-foreground md:hidden"
                        weight="bold"
                        size={20}
                      />
                    </NavLink>
                  </li>
                );
              })}
              <li>
                <AccountBar />
              </li>
            </ul>
          </>
        )}
        {isMobile && (
          <Button
            variant="line"
            size="small"
            transparent
            onClick={() => setOpenMobile(!openMobile)}
          >
            {openMobile ? (
              <X weight="light" size={32} />
            ) : (
              <List weight="light" size={32} />
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
