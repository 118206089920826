import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import { type ChildAndClassNameProps } from '../types';
import { Body1, Body3, H2, H5 } from '../typography';
import {
  container,
  copy,
  styledSubtitle,
  styledTitle,
  textContainer,
} from './CategoryHeader.css';

export const CategoryHeaderContainer = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div className={classNames(container, className)} ref={ref} {...props}>
      {children}
    </div>
  ),
);

CategoryHeaderContainer.displayName = 'CategoryHeaderContainer';

export const CategoryHeaderCopy = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div className={classNames(copy, className)} ref={ref} {...props}>
      {children}
    </div>
  ),
);

CategoryHeaderCopy.displayName = 'CategoryHeaderCopy';

export const CategoryHeaderTitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLHeadingElement>,
  ) => (
    <H2 className={classNames(styledTitle, className)} ref={ref} {...props}>
      {children}
    </H2>
  ),
);

CategoryHeaderTitle.displayName = 'CategoryHeaderTitle';

export const CategoryHeaderSubtitle = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLHeadingElement>,
  ) => (
    <Body1
      className={classNames(styledSubtitle, className)}
      ref={ref}
      {...props}
    >
      {children}
    </Body1>
  ),
);

CategoryHeaderSubtitle.displayName = 'CategoryHeaderSubtitle';

interface TextBlockProps {
  className?: string;
  title: string;
  subtitle: string;
}

export const TextBlock = forwardRef(
  (props: TextBlockProps, ref: Ref<HTMLDivElement>) => {
    const { title, subtitle, className, ...rest } = props;

    return (
      <div className={classNames(textContainer, className)} ref={ref} {...rest}>
        <H5 className={styledTitle}>{title}</H5>
        <Body3 className={styledSubtitle}>{subtitle}</Body3>
      </div>
    );
  },
);

TextBlock.displayName = 'TextBlock';
