// Shared URLS in CoinTracker
export const URLS = {
  CONTACT_US: 'contact-us',
  CREATE_ACCOUNT_BETA: 'create-beta',
  ONBOARDING: {
    SETTINGS: '/onboarding',
    ADD_WALLET: '/onboarding?add_integration=',
    FILE_TAXES: '/onboarding/file-taxes',
    RESOLVE_TRANSACTIONS: '/onboarding/resolve-transactions',
    SUCCESS: '/onboarding/success',
  },
  BRAND: 'https://brand.cointracker.com/',
  LOGIN: 'login',
  PRIVACY: 'privacy',
  SIGNED_IN: 'user/signed-in',
  SECURITY: 'security',
  BLOG_TAX_GUIDE: 'blog/crypto-tax-guide',
  BLOG_TAX_GUIDE_CPA: 'blog/crypto-tax-guide-for-cpas',
  TAX_PLANS: 'tax/plans',
  TERMS: 'terms',
  TRANSACTIONS: 'transactions',
  PORTFOLIO_SUBSCRIPTION: 'portfolio/subscription',
  GET_CURRENT_USER: '/api/get-current-user',
  WALLETS: '/wallets',
  WALLET_IMPORT: '/import',
  USER_REGISTER: 'user/register',
  REPORTS: '/reports',
  CALCULATOR: '/calculator',
  FEATURES: '/features',
  PORTFOLIO_TRACKER: '/portfolio-tracker',
  GET_STARTED_PAGE: '/user/start',
  BLOG: '/blog',
  LEARN: '/learn',
  SUPPORT: '/support',
  TAX_PROS_LANDING_PAGE: '/tax-professionals',
  AUTH_LOGIN: '/auth0/login',
  AUTH_LOGOUT: '/auth0/logout',
  AUTH_SIGNUP: '/auth0/signup',
  ENABLE_MFA: '/auth0/enable_mfa',
  DISABLE_MFA: '/auth0/disable_mfa',
  MAIN_PAGE: '/',
  HOME_PAGE: '/home',
  DASHBOARD_PAGE: '/dashboard',
  WALLETS_PAGE: '/wallets',
  TRANSACTIONS_PAGE: '/transactions',
  TRANSACTIONS_PAGE_NEEDS_REVIEW: '/transactions?page=1&q=has:review',
  ADD_WALLET: '/add_wallet',
  PERFORMANCE_PAGE: '/performance',
  PRICES_PAGE: '/prices',
  PRICES_ALL_PAGE: '/prices/all',
  PRICES_WATCHLIST_PAGE: '/prices/watchlist',
  TAX_LOSS_HARVEST: '/tax-loss-harvest',
  PORTFOLIO_PLANS_PAGE: '/plans',
  UNIFIED_SUBSCRIPTION_PLANS_PAGE: '/plans',
  UNIFIED_SUBSCRIPTIONS_PLANS_CHECKOUT_PAGE: '/subscription/checkout',
  UNIFIED_SUBSCRIPTIONS_PLANS_MANAGE_PAGE: '/subscription/manage',
  UNIFIED_SUBSCRIPTIONS_UPDATE_PLAN_PAGE: '/subscription/update',
  PORTFOLIO_PLANS_CHECKOUT_PAGE: '/subscription-checkout',
  PORTFOLIO_PLANS_MANAGE_PAGE: '/portfolio/subscription/manage',
  ADD_TRANSACTION: '/add_transaction',
  ADD_ACCOUNT: '/add_wallet',
  UPDATE_ACCOUNT: '/accounts',
  IMPORT_ACCOUNTS_BY_TYPE: '/accounts',
  CUSTOM_WALLETS: '/custom_wallets',
  COIN_PAGE: '/price',
  TAX_PAGE: '/tax',
  TAX_LOTS: '/tax-lots',
  // TODO: the proper url here is /tax/** but i am not sure where to get the number here from. Should we just check the
  //       current year from the window?
  TAX_PAGE_FOR_YEAR: '/tax/2022',
  EDIT_USER_PROFILE: '/user/profile',
  INTEGRATIONS_PAGE: '/integrations',
  TAX_PLANS_PAGE: '/tax/plans',
  WK_LANDING: '/partner/wk',
  VIEW_TAX_PRO_DASHBOARD: '/tax-pros/dashboard',
  VIEW_TAX_PROS_INCOMING_INVITES: '/tax-pros/view-incoming-invites',
  VIEW_TAX_PRO_CLIENTS: '/tax-pros/client-view',
  VIEW_TAX_PRO: '/tax-pros/tax-pro-view',
  TAX_PRO_FIRM_VIEW: '/tax-pros/firm-view',
  LOT_IMPORTER: '/lot-importer-beta',
  LOT_IMPORTER_WAITING: '/lot-importer-waiting',
  COINBASE_ONE_PROMOTION: '/promotion/coinbase-one',
  COINBASE_ONE_LANDING: '/coinbaseone',
  EXTERNAL_COINBASE_ONE_BOUNCE_LINK:
    'https://www.coinbase.com/one?action=verify_cointracker',
  NEW_SUPPORT_TICKET: 'https://support.cointracker.io/hc/en-us/requests/new',
  VIEW_AS: '/user/view-as',
  DISCLAIMER: '/disclaimer',
  STATUS: 'https://status.cointracker.io',
  ABOUT: '/about',
  CAREERS: '/careers',
  PRESS: 'https://www.cointracker.io/p/press',
  DEALS: 'https://www.cointracker.io/p/deals',
  CPA_EXTERNAL: 'https://www.cointracker.io/tax-pros',
  APPLE_APP_STORE:
    'https://apps.apple.com/us/app/cointracker-crypto-portfolio/id1401499763',
  GOOGLE_PLAY_STORE:
    'https://play.google.com/store/apps/details?id=io.cointracker.android',
  S3_STATIC_IMAGES: 'https://cdn.cointracker.io/static/images',
  S3_STATIC_FILES: 'https://cdn.cointracker.io/static/files',
  NOT_FOUND_PAGE: '/not_found',
  TIPS: '/tips',
  REVIEW: '/tips/balance-differences',
  CANNY_NEW_POST: 'https://feedback.cointracker.io/new-posts',
  TAX_PRO_REQUEST_ACCESS_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSc5iAT3WnpltytA1xkX1pdv0brLd0jZ74Yej5gBsmnDQqEjjQ/viewform?usp=sf_link',
  TRUSTPILOT_REVIEW_URL: '/trustpilot_review_url',
  SOCIAL_MEDIA: {
    FACEBOOK: 'https://www.facebook.com/cointracker/',
    LINKEDIN: 'https://www.linkedin.com/company/cointracker',
    YOUTUBE: 'https://www.youtube.com/channel/UCXaLV7_eI4VXPVrEAJpcs5g',
    REDDIT: 'https://www.reddit.com/r/cointracker',
    X: 'https://www.x.com/cointracker',
  },
  LANDING_V2: '/',
  LANDING_ENTERPRISE: '/enterprise',
  ADD_SELL_ORDER: '/add-sell-order',
  ADD_DEPOSIT: '/add-deposit',
  UPLOAD_CSV: '/upload-csv',
  DISPOSED_LOTS: '/disposed-lots',
  LOT_PROOF: '/lot-proof',
};

export const isURLActive = (
  pathname: string,
  urlKey: string | string[],
): boolean => {
  if (Array.isArray(urlKey)) {
    return urlKey.some((url) => isURLActive(pathname, url));
  }
  // This fixes the case where the MAIN_PAGE is always true for every path
  if (urlKey === '/') {
    return pathname === urlKey;
  }
  const urlKeyPrefixSlash = urlKey.startsWith('/') ? urlKey : `/${urlKey}`;
  if (pathname.startsWith(`${urlKeyPrefixSlash}/`)) {
    return true;
  }
  return pathname === urlKeyPrefixSlash;
};

export const REBRAND_URLS = {
  ABOUT: '/about',
  ADD_WALLET: '/add-wallet', // TODO: update with rebrand path
  AUTH_LOGIN: '/auth0/login',
  AUTH_LOGOUT: '/auth0/logout',
  AUTH_SIGNUP: '/auth0/signup',
  BLOG: '/blog',
  BLOG_TAX_GUIDE: '/blog/crypto-tax-guide',
  BLOG_COINBASE_COVERAGE: '/blog/100-coinbase-coverage',
  BRAND: 'https://brand.cointracker.com/',
  CALCULATOR: '/calculator',
  CAMPAIGN: '/get/campaign',
  CAREERS: '/careers',
  COINBASE_ONE_PROMOTION: '/promotion/coinbase-one', // TODO: update with rebrand path
  CONTACT_US: '/contact-us', // TODO: update with rebrand path
  DISCLAIMER: '/disclaimer',
  ENTERPRISE: '/enterprise',
  EMAIL_CONFIRMATION: '/email-confirmation',
  EMAIL_VERIFIED: '/email-verified',
  FEATURES: '/features',
  FULL_SERVICE: '/get/full-service',
  GET_STARTED: '/user/start',
  GLOSSARY: '/learn',
  INTEGRATIONS: '/integrations',
  HOME: '/',
  OPEN_ROLES: 'https://jobs.ashbyhq.com/cointracker',
  PORTFOLIO_TRACKER: '/portfolio-tracker',
  PRICING: '/plans',
  PRICES: '/prices', // TODO: update with rebrand path
  PRIVACY: '/privacy',
  SETTINGS: '/user/profile',
  SUPPORT: 'https://support.cointracker.io',
  SCHEDULE_DEMO: 'https://calendly.com/d/cmqg-nfk-sr5',
  SUPPORT_CSV_INFO:
    'https://support.cointracker.io/hc/en-us/articles/4413071299729-How-to-Convert-Your-Transaction-History-into-CoinTracker-Compatible-CSV-Format',
  SUPPORT_TAX_REPORT:
    'https://support.cointracker.io/hc/en-us/articles/4413071352337-Understand-Tax-Reports?_gl=1%2A1lynuco%2A_gcl_au%2AMTc2OTg3OTExNS4xNzMwMjMxMDc2',
  TAX_PROS: '/tax-pros',
  TAX_GUIDE: '/blog/crypto-tax-guide',
  TERMS: '/terms',
  TESTIMONIALS: '/get/testimonials',
  TROUBLESHOOT:
    'https://support.cointracker.io/hc/en-us/articles/19507789799697-Troubleshoot-reports-that-won-t-download-or-generate',
  SECURITY: '/security',
  STATUS: 'https://status.cointracker.io',

  APPLE_APP_STORE:
    'https://apps.apple.com/us/app/cointracker-crypto-portfolio/id1401499763',
  GOOGLE_PLAY_STORE:
    'https://play.google.com/store/apps/details?id=io.cointracker.android',
  SOCIAL_MEDIA: {
    FACEBOOK: 'https://www.facebook.com/cointracker/',
    LINKEDIN: 'https://www.linkedin.com/company/cointracker',
    YOUTUBE: 'https://www.youtube.com/channel/UCXaLV7_eI4VXPVrEAJpcs5g',
    REDDIT: 'https://www.reddit.com/r/cointracker',
    X: 'https://www.x.com/cointracker',
  },
  // We can do an intellisense search on this later to easily figure out what's
  // missing
  TODO: '#',
};

export const buildBlogUrl = (postPath: string): string => {
  // Strip leading slash
  postPath = postPath.replace(/^\/+?/, '');
  // be sure to strip out rebrands from the url path.
  postPath = postPath.replace('rebrand/blog/', 'blog/');
  // Append "blog/" to beginning if missing
  if (!postPath.startsWith('blog/')) {
    postPath = `blog/${postPath}`;
  }

  return `/${postPath}`;
};

export const buildGetStartedUrl = (nextUrl?: string): string => {
  if (!nextUrl) return REBRAND_URLS.GET_STARTED;

  return `${REBRAND_URLS.GET_STARTED}?next=${encodeURIComponent(nextUrl)}`;
};
