import classNames from 'classnames';
import type { LottieRef } from 'lottie-react';
import { forwardRef, lazy, Suspense, useEffect, useState } from 'react';
import {
  content,
  desktopImageOnlyStyle,
  image,
  root,
} from './TextAndImage.css';

export interface TextAndImageProps {
  children?: React.ReactNode;
  className?: string;
  desktopImageOnly?: boolean;
  imgAlt: string;
  imgClassName?: string;
  imgSrc?: string;
  isLottie?: boolean;
  lottieData?: unknown;
  lottieRefCallback?: LottieRef;
}

// Lazy load the LottieImage component to avoid SSR issues
const LazyLottieImage = lazy(() =>
  import('./LottieImage').then((module) => ({
    default: module.LottieImage,
  })),
);

export const TextAndImage = forwardRef<HTMLDivElement, TextAndImageProps>(
  (props, ref) => {
    const {
      children,
      className,
      desktopImageOnly,
      imgAlt,
      imgClassName,
      imgSrc,
      isLottie,
      lottieData,
      lottieRefCallback,
      ...rest
    } = props;

    // Check if we're in the browser
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, []);

    const imageClassName = classNames(image, imgClassName, {
      [desktopImageOnlyStyle]: desktopImageOnly,
    });

    return (
      <div className={classNames(root, className)} ref={ref} {...rest}>
        <div className={content}>{children}</div>
        {isLottie ? (
          isClient ? (
            <Suspense fallback={<div className={imageClassName} />}>
              <LazyLottieImage
                animationData={lottieData}
                lottieRef={lottieRefCallback}
                className={imageClassName}
              />
            </Suspense>
          ) : (
            <div className={imageClassName} />
          )
        ) : (
          <img className={imageClassName} src={imgSrc} alt={imgAlt} />
        )}
      </div>
    );
  },
);

TextAndImage.displayName = 'TextAndImage';
