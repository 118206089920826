import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import { type ChildAndClassNameProps } from '../types';
import { Body2 } from '../typography';
import {
  attributiion,
  caseStudyContainer,
  caseStudyContent,
  caseStudyImage,
  copy,
  copySection,
} from './CaseStudyCard.css';

export const CaseStudyContainer = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div
      className={classNames(caseStudyContainer, className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);
CaseStudyContainer.displayName = 'CaseStudyContainer';

export const CaseStudyContent = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div
      className={classNames(caseStudyContent, className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);
CaseStudyContent.displayName = 'CaseStudyContent';

interface CaseStudyImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement>,
    ChildAndClassNameProps {}

export const CaseStudyImage = forwardRef(
  (
    { children, className, alt, ...props }: CaseStudyImageProps,
    ref: Ref<HTMLImageElement>,
  ) => (
    <img
      className={classNames(caseStudyImage, className)}
      ref={ref}
      alt={alt}
      {...props}
    >
      {children}
    </img>
  ),
);
CaseStudyImage.displayName = 'CaseStudyImage';

export const CaseStudyCopySection = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <div className={classNames(copySection, className)} ref={ref} {...props}>
      {children}
    </div>
  ),
);
CaseStudyCopySection.displayName = 'CaseStudyCopySection';

export const CaseStudyCopy = forwardRef(
  (
    { children, className, ...props }: ChildAndClassNameProps,
    ref: Ref<HTMLParagraphElement>,
  ) => (
    <Body2 className={classNames(copy, className)} ref={ref} {...props}>
      {children}
    </Body2>
  ),
);
CaseStudyCopy.displayName = 'CaseStudyCopy';

export const CaseStudyAttribution = forwardRef<
  HTMLDivElement,
  ChildAndClassNameProps
>(({ children, className, ...props }, ref) => (
  <div className={classNames(attributiion, className)} ref={ref} {...props}>
    {children}
  </div>
));
CaseStudyAttribution.displayName = 'CaseStudyAttribution';
