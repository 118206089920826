import { ApolloError } from '@apollo/client';
import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Info } from '@phosphor-icons/react';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useContext, useState } from 'react';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsQuery,
  Integration,
  useAddPublicAddressMutation,
} from 'src/types/graphql-types';
import { SUPPORTED_EVM_CHAIN_SLUGS } from '../../../../../SuggestedWalletsModal/constants';
import { useSuggestedWalletsModalStore } from '../../../../../SuggestedWalletsModal/store';
import { AddWalletContext } from '../../context';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ViewPermissions } from '../ViewPermissions';
import { emptyCacheQuery, getWalletInstructions } from '../util';
import { ErrorMessage } from './ErrorMessage';
import { GenericInstructions } from './GenericInstructions';
import { ValidationErrors, validateWalletAddress } from './validate';

interface PublicAddressFormProps {
  integration: Integration;
  defaultWalletAddress?: string;
  onCloseModal?: () => void;
}

export const PublicAddressForm = (props: PublicAddressFormProps) => {
  const integration = props.integration;
  const { description, placeholder } = getWalletInstructions(
    integration.slug,
    integration.supportsHdWallet,
  );
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const [walletAddress, setWalletAddress] = useState<string>(
    props.defaultWalletAddress || '',
  );
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    AddWalletErrors | string
  >();
  const [submitWarningMessage, setSubmitWarningMessage] = useState<string>();

  const hasFieldError = errors && errors.walletAddress !== undefined;
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);
  const { openModal: openSuggestedWalletsModal } =
    useSuggestedWalletsModalStore();

  const onWalletAddressChange = useCallback((e) => {
    setWalletAddress(e.target.value);
    const errors = validateWalletAddress(e.target.value);
    setErrors(errors);
  }, []);

  const onValidateWalletAddress = useCallback(() => {
    const errors = validateWalletAddress(walletAddress);
    setErrors(errors);
  }, [walletAddress]);

  const [mutate, { loading }] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        if (data?.addPublicAddress?.warning) {
          showNotification({
            message: `If you have previously added ${integration.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${integration.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        if (data?.addPublicAddress?.multiAddWarning) {
          showNotification({
            message: data.addPublicAddress.multiAddWarning,
            status: Status.Info,
          });
        }
        if (data?.addPublicAddress?.multiAddError) {
          showNotification({
            message: data.addPublicAddress.multiAddError,
            status: Status.Error,
          });
        }
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
        });
        const createdWalletOrExchange =
          data.addPublicAddress.createdWallet ||
          data.addPublicAddress.createdExchange;
        onSuccess(createdWalletOrExchange);
        props.onCloseModal?.();
        if (
          integration?.info?.isEvmChain &&
          SUPPORTED_EVM_CHAIN_SLUGS.includes(integration.slug)
        ) {
          openSuggestedWalletsModal(walletAddress, integration);
        }
      } else {
        if (data?.addPublicAddress?.error) {
          setSubmitErrorMessage(data?.addPublicAddress?.error);
        } else if (
          data?.addPublicAddress?.multiAddError ||
          data?.addPublicAddress?.multiAddWarning
        ) {
          if (data?.addPublicAddress?.multiAddWarning) {
            setSubmitWarningMessage(data?.addPublicAddress?.multiAddWarning);
          }
          if (data?.addPublicAddress?.multiAddError) {
            setSubmitErrorMessage(data?.addPublicAddress?.multiAddError);
          }
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicAddress.success) {
        return;
      }

      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      if (data.addPublicAddress.createdWallet) {
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.addPublicAddress.createdWallet,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      } else if (data.addPublicAddress.createdExchange) {
        const newExchanges = [
          ...walletsData.exchanges,
          data.addPublicAddress.createdExchange,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      }
    },
    onError: (err: ApolloError) => {
      setSubmitErrorMessage(
        (err?.message as AddWalletErrors) ?? AddWalletErrors.UnknownError,
      );
    },
  });
  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const errors = validateWalletAddress(walletAddress);
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      mutate({
        variables: {
          publicAddressInput: {
            coinSymbol: integration.info.symbol,
            cryptoNetwork: integration.info.cryptoNetwork,
            slug: integration.slug,
            walletAddress,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      integration.info.symbol,
      integration.info.cryptoNetwork,
      integration.slug,
      walletAddress,
      startFasterPollingForSyncCompletion,
    ],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        <ViewPermissions />
        {submitErrorMessage && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitErrorMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}
        {submitWarningMessage && (
          <InlineBanner variant="info">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitWarningMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}
        <TextInput
          name="wallet_address"
          value={walletAddress}
          onChange={onWalletAddressChange}
          label={description}
          placeholder={placeholder}
          hasError={hasFieldError}
          errorMessage={errors?.walletAddress}
          onBlur={onValidateWalletAddress}
          autoFocus={true}
        />
        <Instructions
          genericInstructions={
            <GenericInstructions integration={integration} />
          }
          integration={integration}
        />
      </div>

      <IntegrationFormModalFooter>
        <Button variant="primary" type="submit" size="large" loading={loading}>
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
