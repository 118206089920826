import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { cva } from 'class-variance-authority';
import { isEmpty } from 'lodash-es';
import { useCallback, useState, type ChangeEvent } from 'react';
import { cn } from '../../utils';

type SearchProps = {
  placeholder?: string;
  className?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  size?: 'sm' | 'md' | 'lg';
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  autoFocus?: boolean;
  fluid?: boolean;
};

const searchVariants = cva(
  [
    'rounded-8 border border-solid',
    'leading-24 flex items-center gap-12',
    'focus-within:bg-background-default-pressed',
  ].join(' '),
  {
    variants: {
      size: {
        sm: 'h-[42px] px-16',
        md: 'h-[46px] px-16 py-8',
        lg: 'h-[50px] px-16 py-8',
      },
      disabled: {
        true: 'bg-background-alternate border-background-alternate cursor-not-allowed opacity-[.38]',
        false: 'border-line-secondary bg-transparent',
      },
      error: {
        true: 'border-line-negative',
        false: '',
      },
      hasValue: {
        true: 'bg-background-default-pressed',
        false: 'focus-within:border-accent-bold-blue',
      },
    },
    defaultVariants: {
      size: 'md',
      disabled: false,
      error: false,
      hasValue: false,
    },
  },
);

const inputVariants = cva(
  [
    'text-text-primary placeholder:text-text-secondary',
    'h-[27px] flex-1 outline-none',
    'overflow-hidden text-ellipsis whitespace-nowrap',
    'bg-transparent',
  ].join(' '),
  {
    variants: {
      size: {
        sm: 'text-body3 md:text-body3-md lg:text-body3-lg',
        md: 'text-body2 md:text-body2-md lg:text-body2-lg',
        lg: 'text-body1 md:text-body1-md lg:text-body1-lg',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-[0.38]',
        false: '',
      },
    },
    defaultVariants: {
      size: 'md',
      disabled: false,
    },
  },
);

/**
 * Search component
 *
 * @param {string} placeholder - The placeholder text for the search input. Defaults to `"Search"`.
 * @param {string} size - The size of the search input. `sm`, `md`, `lg`. Defaults to `md`.
 * @param {function} onChange - The function to call when the search input value changes.
 * @param {function} onClear - The function to call when the search input is cleared.
 * @param {string} defaultValue - The default value of the search input.
 * @param {React.ReactNode} children - The children to render inside the search component. `SearchAssistiveText` is recommended.
 * @param {boolean} disabled - Whether the search input is disabled. Defaults to false.
 * @param {boolean} error - Whether the search input has an error. Defaults to false.
 *
 * @returns {React.ReactNode} The search component.
 *
 * @example
 * <Search placeholder="Search" onChange={...}>
 *   <SearchAssistiveText>Search for a coin</SearchAssistiveText>
 * </Search>
 */
export const Search = ({
  placeholder = 'Search',
  className,
  size,
  onChange,
  onClear,
  defaultValue,
  children,
  disabled,
  error,
  value: controlledValue,
  autoFocus,
  fluid,
}: SearchProps) => {
  const [value, setValue] = useState(defaultValue || '');

  const onValueChange = useCallback(
    (newValue: string) => {
      if (!controlledValue) {
        setValue(newValue);
      }

      if (onChange) {
        onChange(newValue);
      }
    },
    [controlledValue, onChange],
  );

  const onClearClick = () => {
    onValueChange('');
    if (onClear) {
      onClear();
    }
  };

  const inputValue = controlledValue ?? value;

  return (
    <div className={cn('relative flex flex-col gap-8', fluid && 'w-full')}>
      <div
        className={cn(
          searchVariants({ size, disabled, error, hasValue: !!inputValue }),
          'focus-within:bg-accent-subtle-blue hover:border-line-secondary-hover dark:focus-within:bg-transparent',
          className,
        )}
      >
        <MagnifyingGlass
          className="text-icon-primary-foreground h-18 w-18"
          weight="bold"
        />
        <input
          type="text"
          className={inputVariants({ size, disabled })}
          placeholder={placeholder}
          disabled={disabled}
          value={inputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onValueChange(e.target.value)
          }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
        {!isEmpty(inputValue) && (
          <X
            className="text-icon-primary-foreground h-16 w-16 hover:cursor-pointer"
            weight="bold"
            onClick={onClearClick}
          />
        )}
      </div>
      {children}
    </div>
  );
};

// NOTE: taking a crack at some composibility -- if we hate this style we can just refactor this one

/**
 * Search assistive text component
 *
 * @param {React.ReactNode} children - The children to render inside the search assistive text component.
 * @returns {React.ReactNode} The search assistive text component.
 *
 * @example
 * <Search placeholder="Search" onChange={...}>
 *   <SearchAssistiveText>Search for a coin</SearchAssistiveText>
 * </Search>
 */
export const SearchAssistiveText = ({
  children,
  ...props
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Body variant="body4" {...props}>
      {children}
    </Body>
  );
};
