import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const useWindowWidth = (delay = 500) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // there are two cases we are trying to handle here:
      //   1. window.innerWidth is bigger than expected because the dom content is overflowing the body on x axis
      //   2. document.clientWidth is bigger than window.innerWidth in cases where the scrollbar is also existant etc
      const viewportWidth =
        window.innerWidth && window.document?.documentElement?.clientWidth
          ? Math.min(
              window.document.documentElement.clientWidth,
              window.innerWidth,
            )
          : window.innerWidth;
      setWidth(viewportWidth);
    };
    const debouncedHandleResize = debounce(handleResize, delay, {
      leading: true,
    });
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return width;
};

export default useWindowWidth;
