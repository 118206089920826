import { useCallback, useEffect, useRef } from 'react';

// taken from https://geoffroymounier.medium.com/react-hook-optimised-scrollevent-listener-13513649a64d
// this component is optimising how many calls will happen on the "onscroll" event
export const useScrollListener = (
  handleScroll: (e: Event) => void,
  throttle = 3000,
) => {
  const scrollingTimer = useRef<NodeJS.Timeout>();
  const listenToScroll = useCallback(
    (e: Event) => {
      window.removeEventListener('scroll', listenToScroll);
      clearTimeout(scrollingTimer.current);
      scrollingTimer.current = setTimeout(
        () => window.addEventListener('scroll', listenToScroll),
        throttle,
      );
      handleScroll(e);
    },
    [throttle, handleScroll],
  );

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
      clearTimeout(scrollingTimer.current);
    };
  }, [listenToScroll]);
};
