import { cn, SheetTrigger, useSidebar } from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { useAnalytics } from 'src/app/analytics';
import { UserContext } from 'src/app/user/context';
import { useTaxReadiness } from '../hooks/useTaxReadiness';
import { ProgressDonut } from './TaxReadinessStatusIndicator';
import { getStatusText } from './utils';

interface StatusButtonProps {
  iconOnly?: boolean;
  className?: string;
}

export const TaxReadinessStatusButton = ({
  iconOnly,
  className,
}: StatusButtonProps) => {
  const {
    isMobile,
    toggleIsTaxReadinessSheetOpen,
    isTaxReadinessSheetOpen,
    setIsTaxReadinessSheetOpen,
  } = useSidebar();
  const user = useContext(UserContext);
  const analytics = useAnalytics();

  const {
    completedStepsCount,
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
  } = useTaxReadiness();

  if (iconOnly) {
    return (
      <SheetTrigger asChild>
        <Button
          variant="line"
          size="small"
          className={cn(
            'flex flex-row place-items-center gap-8 md:p-0',
            className,
          )}
          transparent={!isMobile}
          onClick={() => {
            analytics.track('Tax Readiness Clicked', {
              action: 'Status Indicator Clicked',
              step_1_completed: addWalletsStepChecked,
              step_2_completed: syncWalletsStepChecked,
              step_3_completed: optimizeTaxSavingsStepChecked,
              step_4_completed: reviewTaxFormsStepChecked,
            });
            toggleIsTaxReadinessSheetOpen();
          }}
        >
          <ProgressDonut step={completedStepsCount} maxSteps={4} />
        </Button>
      </SheetTrigger>
    );
  }

  return (
    <SheetTrigger asChild>
      <Button
        variant="line"
        size="small"
        className={cn(
          'flex w-full flex-row justify-start gap-8 md:p-0',
          {
            'border-line-secondary-pressed bg-background-default-pressed':
              isTaxReadinessSheetOpen && isMobile,
          },
          className,
        )}
        transparent={!isMobile}
        onClick={() => {
          analytics.track('Tax Readiness Clicked', {
            action: 'Status Indicator Clicked',
            step_1_completed: addWalletsStepChecked,
            step_2_completed: syncWalletsStepChecked,
            step_3_completed: optimizeTaxSavingsStepChecked,
            step_4_completed: reviewTaxFormsStepChecked,
          });
          setIsTaxReadinessSheetOpen(true);
        }}
      >
        <ProgressDonut step={completedStepsCount} maxSteps={4} />
        <Body
          variant="body5"
          className={cn(
            'whitespace-nowrap text-text-secondary transition-opacity hover:text-text-primary group-data-[state=collapsed]:opacity-0',
            // fade animation timing
            'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
          )}
        >
          {getStatusText(
            user.isAuthenticated
              ? user?.displayUser?.taxYearToUse
              : dayjs().year() - 1,
          )}
        </Body>
      </Button>
    </SheetTrigger>
  );
};
