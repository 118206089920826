import { makeVar, useApolloClient, useReactiveVar } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  CustomWallet,
  GetJobStatusQuery,
  GetWalletsQuery,
  Integration,
  useGetValidationErrorsForCsvLazyQuery,
} from 'src/types/graphql-types';

import { AddWalletContext } from '../../context';

import { Status, ToastContext } from '@cointracker/styleguide';
import { IntegrationSyncMethod } from 'src/common/Integration';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { CSVForm } from './CSVForm';
import useCreateWallet from './hooks/useCreateWallet';

type createdCustomWallet = Record<string, Date>;
export const createdCustomWalletVar = makeVar<createdCustomWallet>({});

interface FileImportFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  redirectUrl?: string;
}

export const FileImportForm = (props: FileImportFormProps) => {
  const { integration } = props;

  const { onSuccess } = useContext(AddWalletContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const createdCustomWallets = useReactiveVar(createdCustomWalletVar);

  const { showNotification } = useContext(ToastContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);

  const client = useApolloClient();

  function showNotifications(data: GetJobStatusQuery) {
    const status = data.csvImportJobStatus;
    if (status.numberImported > 0) {
      showNotification({
        status: Status.Success,
        message: `Successfully imported ${status.numberImported} out of ${status.numberTotal} crypto transactions.`,
      });
    }

    if (status.numberIgnored > 0) {
      showNotification({
        status: Status.Info,
        message: `Ignored ${status.numberIgnored} crypto transaction(s) because they are missing data or are not supported.`,
        delay: -1,
      });
    }

    if (status.numberUserDefinedTokens) {
      showNotification({
        status: Status.Info,
        message: `We could not identify ${data.csvImportJobStatus.numberUserDefinedTokens} token(s) in your file and
        used custom currencies to import them. You can review these on the wallets page, sorted at the bottom of the
        token list for the wallet.`,
        delay: -1,
      });
    }
  }

  const { createWallet } = useCreateWallet({
    onCompleted: (data) => {
      showNotifications(data);

      const newCustomWallet: CustomWallet = {
        ...data.csvImportJobStatus.createdCustomWallet,
        syncInfo: {
          willBeDeleted: false,
          // custom wallets do not have an isSyncing check anywhere so we are forcing it here
          isSyncing: true,
          lastSynced: null,
        },
      };
      createdCustomWalletVar({
        ...createdCustomWallets,
        [data.csvImportJobStatus.createdCustomWallet?.id]: new Date(),
      });
      const walletsData: GetWalletsQuery = client.cache.readQuery({
        query: GetWallets,
      }) || { customWallets: [] };
      const newCustomWallets = [...walletsData.customWallets, newCustomWallet];

      client.cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, customWallets: newCustomWallets },
      });

      onSuccess(newCustomWallet, props.redirectUrl);
      props.onCloseModal?.();
    },
    onError: (error) => {
      setIsSubmitting(false);
      setErrorText(error);
    },
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(event.currentTarget);
    const walletName = formData.get('walletName') as string;

    await createWallet({ walletName, fileUrl, integration });
    startFasterPollingForSyncCompletion();
  };

  const onFileChange = useCallback((filepath) => {
    setFileUrl(filepath);
    setErrorText('');
  }, []);

  const formAction =
    integration.slug === 'other' ? '?t=csv' : `?t=${integration.slug}&csv=true`;
  const isGenericCSV =
    integration.syncMethod != IntegrationSyncMethod.CustomFile &&
    integration.syncMethod != IntegrationSyncMethod.AutoSyncAndFile;
  const [getValidationErrorsForCsv] = useGetValidationErrorsForCsvLazyQuery();
  return (
    <CSVForm
      integration={integration}
      onCloseModal={props.onCloseModal}
      isSubmitting={isSubmitting}
      onFileChange={onFileChange}
      fileUrl={fileUrl}
      onSubmit={onSubmit}
      importError={errorText}
      getValidationErrorsForCsv={getValidationErrorsForCsv}
      isGeneric={isGenericCSV}
      formAction={formAction}
    />
  );
};
