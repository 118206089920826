import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
} from 'react';
import { Body2, H5 } from '../typography';
import {
  column,
  columnHighlighted,
  container,
  disabled as disabledClass,
  header,
  headerInner,
  icon,
  overflowGradient,
  pricingTableContainer,
  pricingTableContainerOuter,
  row,
  scrollGradient,
  scrollGradientGrid,
  text as textClass,
} from './PricingTable.css';

import PricingTableCheck from '@cointracker/styleguide/src/icons/check-pricing.svg?react';
import PricingTableClose from '@cointracker/styleguide/src/icons/close-pricing.svg?react';
import PricingTableMinus from '@cointracker/styleguide/src/icons/minus-pricing.svg?react';
import classNames from 'classnames';
import { Grid } from '../Grid';
import { Tags } from '../LoggedIn';
import { cn } from '../utils';

export interface PricingTableHeaderProps {
  children?: React.ReactNode;
  className?: string;
  headerClassName?: string;
  columnClassName?: string;
  emphasizedColumn?: 'free' | 'base' | 'prime' | 'ultra';
  highlightedColumn?: 'free' | 'base' | 'prime' | 'ultra' | 'all';
  firstHighlightElement?: boolean;
  isLoggedIn?: boolean;
}

export const PricingTableHeader = forwardRef(
  (props: PricingTableHeaderProps, ref: Ref<HTMLElement>) => {
    const {
      children,
      className,
      headerClassName,
      columnClassName,
      emphasizedColumn = '',
      highlightedColumn = '',
      firstHighlightElement,
      isLoggedIn,
      ...rest
    } = props;

    const backgroundColor = isLoggedIn
      ? 'bg-background-default'
      : 'bg-background-card-background';
    return (
      <header
        className={classNames(header, headerClassName)}
        ref={ref}
        {...rest}
      >
        <Grid className={classNames(headerInner, className)}>
          <div className={row}>
            <div
              className={classNames(
                textClass,
                backgroundColor,
                columnClassName,
              )}
            >
              <H5>{children}</H5>
            </div>
            <div
              className={classNames(
                column,
                {
                  [columnHighlighted]:
                    highlightedColumn === 'free' || highlightedColumn === 'all',
                  'border-line-card !bg-background-card-highlight border-l-2 border-r-2':
                    emphasizedColumn === 'free',
                  [backgroundColor]: emphasizedColumn !== 'free',
                  'rounded-t-8 border-t-2':
                    emphasizedColumn === 'free' && firstHighlightElement,
                },
                columnClassName,
              )}
            >
              <Body2 variant="inherit">Free</Body2>
            </div>
            <div
              className={classNames(
                column,
                {
                  [columnHighlighted]:
                    highlightedColumn === 'base' || highlightedColumn === 'all',
                  'border-line-card !bg-background-card-highlight border-l-2 border-r-2':
                    emphasizedColumn === 'base',
                  [backgroundColor]: emphasizedColumn !== 'base',
                  'rounded-t-8 border-t-2':
                    emphasizedColumn === 'base' && firstHighlightElement,
                },
                columnClassName,
              )}
            >
              <Tags
                color={emphasizedColumn === 'base' ? 'blue' : 'transparent'}
                filled
                condensed
              >
                <Body2 variant="inherit">Base/Base+</Body2>
              </Tags>
            </div>
            <div
              className={classNames(
                column,
                {
                  'border-line-card !bg-background-card-highlight border-l-2 border-r-2':
                    emphasizedColumn === 'prime',
                  [backgroundColor]: emphasizedColumn !== 'prime',
                  [columnHighlighted]:
                    highlightedColumn === 'prime' ||
                    highlightedColumn === 'all',
                  'rounded-t-8 border-t-2':
                    emphasizedColumn === 'prime' && firstHighlightElement,
                },
                columnClassName,
              )}
            >
              <Tags
                color={emphasizedColumn === 'prime' ? 'blue' : 'transparent'}
                filled
                condensed
              >
                <Body2 variant="inherit">Prime/Prime+</Body2>
              </Tags>
            </div>
            <div
              className={classNames(
                column,
                {
                  [columnHighlighted]:
                    highlightedColumn === 'ultra' ||
                    highlightedColumn === 'all',
                  'border-line-card !bg-background-card-highlight border-l-2 border-r-2':
                    emphasizedColumn === 'ultra',
                  [backgroundColor]: emphasizedColumn !== 'ultra',
                  'rounded-t-8 border-t-2':
                    emphasizedColumn === 'ultra' && firstHighlightElement,
                },
                columnClassName,
              )}
            >
              <Tags
                color={emphasizedColumn === 'ultra' ? 'blue' : 'transparent'}
                filled
                condensed
              >
                <Body2 variant="inherit">Ultra/Ultra+</Body2>
              </Tags>
            </div>
          </div>
        </Grid>
      </header>
    );
  },
);

PricingTableHeader.displayName = 'PricingTableHeader';

export interface PricingTableEntry {
  type: 'x' | 'check' | 'dash' | 'text';
  text?: string;
  disabled?: boolean;
}

export const PricingTableRow = forwardRef(
  (
    { children, className, ...rest }: HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <Grid className={cn(row, className)} ref={ref} {...rest}>
        {children}
      </Grid>
    );
  },
);

PricingTableRow.displayName = 'PricingTableRow';

export interface PricingTableItemProps {
  type?: 'x' | 'check' | 'dash' | 'text';
  children?: ReactNode;
  disabled?: boolean;
  isFirstItem?: boolean;
  isEmphasized?: boolean;
  highlight?: boolean;
  lastHighlightElement?: boolean;
  firstHighlightElement?: boolean;
  className?: string;
}

export const PricingTableItem = (props: PricingTableItemProps) => {
  const {
    type = 'text',
    disabled,
    children,
    isFirstItem,
    isEmphasized,
    highlight,
    lastHighlightElement,
    className,
  } = props;

  const iconClass = classNames(icon, { [disabledClass]: disabled });

  return (
    <div
      className={classNames(
        {
          [columnHighlighted]: highlight,
          [textClass]: isFirstItem,
          [column]: !isFirstItem,
          'border-line-card !bg-background-card-highlight border-l-2 border-r-2':
            isEmphasized,
          'rounded-b-8 border-b-2': isEmphasized && lastHighlightElement,
        },
        className,
      )}
    >
      {type === 'x' && (
        <PricingTableClose
          className={classNames(
            iconClass,
            '!text-icon-primary-foreground-disabled',
          )}
          height="16px"
        />
      )}
      {type === 'check' && (
        <PricingTableCheck className={iconClass} height="24px" />
      )}
      {type === 'dash' && (
        <PricingTableMinus className={iconClass} height="24px" />
      )}
      {/* Default to zero width space to preserve natural text height on empty rows */}
      {type === 'text' && (
        <Body2 variant="inherit">{children || '\u200b'}</Body2>
      )}
    </div>
  );
};

export const PricingTable = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={cn(container, className)} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

PricingTable.displayName = 'PricingTable';

export const PricingTableContainer = (
  props: React.HTMLAttributes<HTMLDivElement>,
) => {
  const { children, ...rest } = props;

  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isNotScrolled, setIsNotScrolled] = useState(true);

  const ref = useRef<HTMLDivElement>(null);

  const checkScroll = () => {
    const element = ref.current;
    if (element) {
      setIsScrolledToEnd(
        !(element.scrollLeft + element.clientWidth < element.scrollWidth - 1),
      );
      setIsNotScrolled(element.scrollLeft < 1);
      setIsScrollable(element.scrollWidth > element.clientWidth);
    }
  };

  useEffect(() => {
    checkScroll();
    const element = ref.current;

    window.addEventListener('resize', checkScroll);
    element?.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('resize', checkScroll);
      element?.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <div className={pricingTableContainerOuter}>
      <div
        className={classNames(
          pricingTableContainer({ scrollable: isScrollable }),
        )}
        ref={ref}
      >
        <div
          className={cn(
            'to-background-card-background bg-gradient-to-r from-[hsl(var(--background-card-background)_/_0.1)] from-70% sm:from-20%',
            overflowGradient({
              active: isScrollable && !isScrolledToEnd,
            }),
          )}
          {...rest}
        />
        {children}
        <Grid className={scrollGradientGrid}>
          <div
            className={cn(
              'to-background-card-background bg-gradient-to-l from-[hsl(var(--background-card-background)_/_0.1)] from-70% sm:from-20%',
              scrollGradient({
                active: isScrollable && !isNotScrolled,
              }),
            )}
          ></div>
        </Grid>
      </div>
    </div>
  );
};

PricingTableContainer.displayName = 'PricingTableContainer';
