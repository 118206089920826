import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { cn } from '../utils';

type DotProps = {
  variant?: 'red' | 'black';
  className?: string;
  value: string | number;
};

const dotVariants = cva(
  'text-text-negative-foreground text-body2 md:text-body2-md lg:text-body2-lg flex h-24 flex-row items-center justify-center rounded-full',
  {
    variants: {
      variant: {
        red: 'bg-accent-bold-red',
        black: 'bg-accent-black',
      },
      size: {
        1: 'w-24',
        2: 'w-[26px]',
        3: 'w-36',
      },
    },
    defaultVariants: {
      variant: 'red',
      size: 1,
    },
  },
);

export const Dot = forwardRef<HTMLSpanElement, DotProps>(
  ({ variant, className, value, ...props }, ref) => {
    const size =
      value?.toString().length > 3 ? 3 : (value?.toString().length ?? 1);
    return (
      <span
        ref={ref}
        className={cn(
          dotVariants({ variant, size: size as 1 | 2 | 3 }),
          className,
        )}
        {...props}
      >
        {value}
      </span>
    );
  },
);

Dot.displayName = 'Dot';
