import classNames from 'classnames';
import { type CSSProperties, type ReactNode } from 'react';
import { Body3 } from '../typography';
import { tag } from './Tag.css';

interface TagProps {
  liveData?: boolean;
  children: ReactNode;
  caps?: boolean;
  inheritColor?: boolean;
  secondary?: boolean;
  pricing?: boolean;
  pricingDark?: boolean;
  className?: string;
  filled?: boolean;
  style?: CSSProperties;
}
export function Tag({
  filled,
  caps,
  children,
  inheritColor,
  secondary,
  className,
  style,
}: TagProps) {
  return (
    <div
      className={classNames(
        tag({ secondary, filled, inheritColor }),
        className,
      )}
      style={style}
    >
      <Body3 variant="inherit" caps={caps}>
        {children}
      </Body3>
    </div>
  );
}
