import { URLS } from 'src/common/urls';
import {
  CustomWallet,
  Exchange,
  Integration,
  Wallet,
} from 'src/types/graphql-types';
import {
  isCustomWallet,
  isExchange,
  isWallet,
} from '../../../WalletsContainer/utils';
import { NavigationType } from '../../../constants';

export const getIntegrationNameParamFromURL = (): string | null => {
  const url = new URL(window.location.toString());
  return url.searchParams.get('add_integration');
};

export function getNavigationUrlByWalletOrExchangeType(
  walletOrExchange: Exchange | Wallet | CustomWallet,
) {
  if (isExchange(walletOrExchange)) {
    return `${URLS.WALLETS}/${NavigationType.EXCHANGE}/${walletOrExchange.id}`;
  }
  if (isWallet(walletOrExchange)) {
    return `${URLS.WALLETS}/${NavigationType.WALLET}/${walletOrExchange.id}`;
  }
  if (isCustomWallet(walletOrExchange)) {
    return `${URLS.WALLETS}/${NavigationType.CUSTOM_WALLET}/${walletOrExchange.id}`;
  }
  return URLS.WALLETS;
}

export const getSelectedIntegration = (
  integrations: Integration[],
  integrationName: string,
): Integration => {
  if (!integrationName) return null;
  const integrationInfo = integrationName.toLowerCase().split('_');
  return integrations?.find((integration) => {
    return (
      integration.slug?.toLowerCase() === integrationInfo[0] &&
      integration.category?.toLowerCase() == integrationInfo[1]
    );
  });
};

export const getDataFromURL = (): string | null => {
  const url = new URL(document.location.toString());
  return url.searchParams.get('public_keys');
};
