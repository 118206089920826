import { type ReactNode } from 'react';

interface LoadingContainerProps {
  children?: ReactNode;
  dataTestId?: string;
}
export const LoadingContainer = ({
  children,
  dataTestId,
}: LoadingContainerProps) => {
  return (
    <div
      className="flex h-full flex-1 items-center justify-center text-center"
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
