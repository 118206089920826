import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { navLinkText } from '../typography/Typography.css';
import { styledList, styledListItem } from './Tabs.css';

// documentation https://www.radix-ui.com/docs/primitives/components/tabs
export const Root = (props: RadixTabs.TabsProps) => {
  return <RadixTabs.Root {...props} />;
};
export const List = (props: RadixTabs.TabsListProps) => {
  return (
    <RadixTabs.List
      {...props}
      className={classNames(props?.className, styledList)}
    />
  );
};

interface TriggerProps extends RadixTabs.TabsTriggerProps {
  useNavLinkText?: boolean;
}
export const Trigger = (props: TriggerProps) => {
  // These tabs are used in the Spam page and the way the NavLink works breaks the page in some layouts.
  // This is a temporary workaround until design introduces a radio group/tabs component on the SPA.
  // Check linear for more info https://linear.app/cointracker/issue/FST-4348/scaling-across-viewports-spam-page
  const { useNavLinkText = true, ...rest } = props;
  return (
    <RadixTabs.Trigger
      {...rest}
      className={classNames(
        useNavLinkText ? navLinkText() : '',
        styledListItem,
        props?.className,
      )}
    />
  );
};

export const Content = (props: RadixTabs.TabsContentProps) => {
  return <RadixTabs.Content {...props} />;
};
