import { Modal, TextInput } from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { URLS } from 'src/common/urls';

interface ViewAsModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export default function ViewAsModal({
  isOpen = false,
  onClose = () => {},
}: ViewAsModalProps) {
  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content style={{ minWidth: 400 }}>
          <Modal.ContentHeader title="Select user to view as" />
          <form action={URLS.VIEW_AS} method="POST">
            <Modal.ContentBody>
              <TextInput label="User" name="user-email" id="user-email" />
            </Modal.ContentBody>
            <Modal.ContentFooter className="flex flex-col gap-16">
              <Button type="submit" size="small" fluid>
                Submit
              </Button>
              <Button
                onClick={onClose}
                type="button"
                variant="line"
                size="small"
                fluid
              >
                Cancel
              </Button>
            </Modal.ContentFooter>
          </form>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
