import { UnifiedPlanDetails } from '@cointracker/graphql-types';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { Body5 } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { Sparkle } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'src/app/analytics';
import { URLS } from 'src/common/urls';
import { useExperiment } from 'statsig-react';

interface TransactionLimitCardProps {
  loading: boolean;
  transactionCount: number;
  transactionLimit: number;
  recommendedPlanForTax: UnifiedPlanDetails;
}

export function TransactionLimitCard({
  loading,
  transactionCount,
  transactionLimit,
  recommendedPlanForTax,
}: TransactionLimitCardProps) {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const transactionLimitCardGateOn = useExperiment(
    'transaction_limit_card',
  ).config.getValue('show');

  if (loading || !transactionLimitCardGateOn) return null;

  const hasExceededLimit = transactionCount > transactionLimit;

  if (!hasExceededLimit) return null;

  const usagePercentage = Math.min(
    (transactionCount / transactionLimit) * 100,
    100,
  );

  const discountReason = recommendedPlanForTax?.price?.discountReason;

  const handleUpgradePlan = () => {
    analytics.track('TransactionLimitCard Clicked', {
      discountReason,
      transactionCount,
      transactionLimit,
    });
    navigate(URLS.UNIFIED_SUBSCRIPTION_PLANS_PAGE);
  };

  return (
    <div className="mx-4 mb-4 flex flex-row items-center gap-24 rounded-16 border border-line-tertiary bg-background-default p-16 shadow-sm md:flex-col md:gap-0">
      <span>
        <div className="mb-4">
          <Body5 className="leading-tight text-text-secondary">
            Transaction{' '}
            <span className="text-text-primary">limit exceeded</span> for your
            current plan.
          </Body5>
          {discountReason && (
            <Body5 className="leading-tight text-text-secondary">
              {discountReason}
            </Body5>
          )}
        </div>

        <Body5 className="mb-6 text-text-negative">
          {transactionCount}
          <span className="text-text-primary">/{transactionLimit}</span>
        </Body5>

        <div className="bg-background-disabled mb-4 h-4 w-full overflow-hidden rounded-full">
          <div
            className="h-full rounded-full bg-text-negative"
            style={{ width: `${usagePercentage}%` }}
          />
        </div>
      </span>

      <Button
        size="x-small"
        className="mt-8 whitespace-nowrap md:w-full"
        variant="highEmphasis"
        onClick={handleUpgradePlan}
      >
        <Sparkle weight="bold" className="hidden md:block" />
        Upgrade plan
      </Button>
    </div>
  );
}
