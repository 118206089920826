import { BaseCurrency } from '@coin-tracker/number-formatter';
import { User } from '@sentry/react';
import { Decimal } from 'decimal.js';

import {
  GetBalanceDifferenceWalletsForReviewQueryResult,
  SpamAsset,
  Wallet,
} from '../../../types/graphql-types';
import { WalletAssetColumn } from '../Wallets/WalletsContainer/types';
import {
  getWalletAssetDataFromExchangeWallet,
  getWalletAssetDataFromUserAssetBalance,
  getWalletAssetDataFromWallet,
  getWalletAssetDataFromWalletToken,
  isExchange,
  isWallet,
} from '../Wallets/WalletsContainer/utils';

export const getListOfWallets = (
  {
    exchanges,
    localWallets,
  }: GetBalanceDifferenceWalletsForReviewQueryResult['data'],
  user: User,
) => {
  const walletsListWithBalanceDifference: Wallet[] = [];
  const walletGroups = [...exchanges, ...localWallets];

  walletGroups.forEach((walletGroup) => {
    const baseCurrency = user.isAuthenticated
      ? (user.baseCurrency as BaseCurrency)
      : null;
    if (isExchange(walletGroup)) {
      walletGroup.wallets?.forEach((wallet) => {
        walletsListWithBalanceDifference.push(
          getWalletAssetDataFromExchangeWallet(wallet, baseCurrency, true),
        );
      });
    }
    if (isWallet(walletGroup)) {
      walletGroup.tokens?.forEach((token) => {
        walletsListWithBalanceDifference.push(
          getWalletAssetDataFromWalletToken(
            walletGroup,
            token,
            baseCurrency,
            true,
          ),
        );
      });

      walletGroup.userAssetBalances?.forEach((balance) =>
        walletsListWithBalanceDifference.push(
          getWalletAssetDataFromUserAssetBalance(
            walletGroup,
            balance,
            baseCurrency,
            true,
          ),
        ),
      );

      // since we also include the wallet in the assets row and the wallet contains the total value amount for
      // tokens as well, we need to do this minor calculation to show only the wallets' main currency amount
      const totalDecimalValue = new Decimal(walletGroup.balance ?? 0).times(
        walletGroup.currency?.price ?? 0,
      );
      const totalDecimalCalculatedValue = new Decimal(
        walletGroup.calculatedBalance ?? 0,
      ).times(walletGroup.currency?.price ?? 0);
      const walletRow = getWalletAssetDataFromWallet(
        {
          ...walletGroup,
          name: walletGroup.name,
          value: totalDecimalValue.toNumber(),
          calculatedValue: totalDecimalCalculatedValue.toNumber(),
        },
        baseCurrency,
        true,
      );
      // for the wallet asset we show on the list, we want it to navigate us to the transactions page but filter other tokens out
      walletRow.url = `${walletRow.url}+${walletRow.currency.symbol}`;
      walletsListWithBalanceDifference.unshift(walletRow);
    }
  });
  // Make all value differences into absolute differences
  walletsListWithBalanceDifference.forEach((wallet: WalletAssetColumn) => {
    wallet.valueDifference = Math.abs(wallet.valueDifference);
  });
  return walletsListWithBalanceDifference;
};

export const getBalanceDifferenceWallets = (walletsList: Wallet[]) => {
  const walletsListWithBalanceDifference: Wallet[] = [];
  walletsList.forEach((wallet) => {
    if (!wallet.isBalanceDifferenceSignificant) return;

    // naming in the backend is inconsistent, so we have to add this hack
    const walletName = wallet?.name?.startsWith?.(wallet?.parentAccountName)
      ? wallet.name
      : `${wallet.parentAccountName} ${wallet.name}`;

    walletsListWithBalanceDifference.push({
      ...wallet,
      headerLabel:
        wallet.parentAccountName?.toLowerCase() ||
        wallet.address?.toLowerCase(),
      name: walletName,
    });
  });
  return walletsListWithBalanceDifference;
};

export const GetResolvedAndUnresolvedSpamAssets = (spamAssets: SpamAsset[]) => {
  const unresolvedSpamAssets: SpamAsset[] = [];
  const resolvedSpamAssets: SpamAsset[] = [];
  spamAssets.forEach((asset) => {
    if (asset.isSpam === null) {
      unresolvedSpamAssets.push(asset);
    } else {
      resolvedSpamAssets.push(asset);
    }
  });

  return { unresolvedSpamAssets, resolvedSpamAssets };
};

export const localUpdateSpamAssetsIsSpam = (
  unresolvedSpamAssets: SpamAsset[],
  resolvedSpamAssets: SpamAsset[],
  isSpam: boolean,
  updatingAsset: SpamAsset,
) => {
  const updatedUnresolvedSpamAssets = mapAndUpdateisSpam(
    unresolvedSpamAssets,
    isSpam,
    updatingAsset,
  );
  const updatedResolvedSpamAssets = mapAndUpdateisSpam(
    resolvedSpamAssets,
    isSpam,
    updatingAsset,
  );
  return { updatedUnresolvedSpamAssets, updatedResolvedSpamAssets };
};

const mapAndUpdateisSpam = (
  assetList: SpamAsset[],
  isSpam: boolean,
  updatingAsset: SpamAsset,
) => {
  return assetList.map((asset: SpamAsset) => {
    if (
      asset.contractAddress === updatingAsset.contractAddress &&
      asset.cryptoNetwork === updatingAsset.cryptoNetwork
    )
      return { ...asset, isSpam: isSpam };
    return { ...asset };
  });
};
