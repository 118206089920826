import ArrowHeadDownIcon from '@cointracker/assets/general/arrow-head-down.svg?react';
import CheckmarkIcon from '@cointracker/assets/general/checkmark.svg?react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as RadixSelect from '@radix-ui/react-select';
import classNames from 'classnames';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { Link } from '../../typography/Link';
import {
  errorMessage,
  errorText,
  scrollableContainer,
  scrollAreaRoot,
  scrollAreaViewport,
  scrollbar,
  selectContent,
  selectItem,
  selectItemText,
  styledIcon,
  styledIconContainer,
  styledTrigger,
} from './DropdownSelect.css';

interface SelectProps extends RadixSelect.SelectProps {
  placeholder?: string;
  hasError?: boolean;
  hint?: string;
  width?: string;
  children: ReactNode[];
  triggerStyle?: string;
  'aria-label'?: string;
}
export const DropdownSelect = forwardRef(
  (
    {
      children,
      placeholder,
      hasError,
      hint,
      width,
      triggerStyle,
      ...props
    }: SelectProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ) => {
    return (
      <RadixSelect.Root {...props}>
        <div style={{ width }}>
          <RadixSelect.Trigger
            className={classNames(
              styledTrigger,
              hasError && errorText,
              triggerStyle,
            )}
            ref={forwardedRef}
            aria-label={props['aria-label']}
          >
            <RadixSelect.Value placeholder={placeholder} />
            <RadixSelect.Icon className={styledIconContainer}>
              <ArrowHeadDownIcon className={styledIcon} />
            </RadixSelect.Icon>
          </RadixSelect.Trigger>
          {hasError && hint && (
            <Link size="small" className={errorMessage}>
              {hint}
            </Link>
          )}
        </div>
        <RadixSelect.Portal>
          <RadixSelect.Content className={selectContent} position="popper">
            <ScrollArea.Root className={scrollAreaRoot} type="auto">
              <RadixSelect.Viewport asChild>
                <ScrollArea.Viewport
                  className={scrollAreaViewport}
                  asChild
                  style={{ overflowY: undefined }}
                >
                  <div className={scrollableContainer}>{children}</div>
                </ScrollArea.Viewport>
              </RadixSelect.Viewport>
              <ScrollArea.Scrollbar
                className={scrollbar}
                orientation="vertical"
              >
                <ScrollArea.Thumb />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </RadixSelect.Content>
        </RadixSelect.Portal>
      </RadixSelect.Root>
    );
  },
);

DropdownSelect.displayName = 'DropdownSelect';

export const DropdownSelectItem = forwardRef(
  (
    { children, ...props }: RadixSelect.SelectItemProps,
    forwardedRef: Ref<HTMLDivElement>,
  ) => {
    return (
      <RadixSelect.Item
        {...props}
        ref={forwardedRef}
        className={classNames(selectItem, props.className)}
      >
        <RadixSelect.ItemText className={selectItemText}>
          {children}
        </RadixSelect.ItemText>
        <RadixSelect.ItemIndicator>
          <CheckmarkIcon />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  },
);

DropdownSelectItem.displayName = 'DropdownSelectItem';

export default DropdownSelect;
