import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import { Grid } from '../Grid';
import { type ChildAndClassNameProps } from '../types';
import {
  article,
  container,
  footer,
  grid,
  sidebar,
  sidebarInner,
} from './ScrollingText.css';

export const ScrollingText = forwardRef(
  (props: ChildAndClassNameProps, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div className={className} ref={ref} {...rest}>
        <Grid className={grid}>
          <div className={container}>{children}</div>
        </Grid>
      </div>
    );
  },
);

ScrollingText.displayName = 'ScrollingText';

export const ScrollingTextBody = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;

  return (
    <article className={classNames(article, className)} {...rest}>
      {children}
    </article>
  );
};

ScrollingTextBody.displayName = 'ScrollingTextBody';

export const ScrollingTextSidebar = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;

  return (
    <aside className={classNames(sidebar, className)} {...rest}>
      <div className={sidebarInner}>{children}</div>
    </aside>
  );
};

ScrollingTextSidebar.displayName = 'ScrollingTextSidebar';

export const ScrollingTextBottom = (props: ChildAndClassNameProps) => {
  const { children, className, ...rest } = props;

  return (
    <footer className={classNames(footer, className)} {...rest}>
      {children}
    </footer>
  );
};

ScrollingTextBottom.displayName = 'ScrollingTextBottom';
