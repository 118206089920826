import {
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  cn,
  useSidebar,
} from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { bodyVariants } from '@cointracker/styleguide/src/LoggedIn/Typography/variants';

interface ViewingAsWidgetProps {
  email: string;
}

export const ViewingAsWidget = ({ email }: ViewingAsWidgetProps) => {
  const { state } = useSidebar();
  return (
    <Popover open key={state}>
      <PopoverAnchor
        className={cn(
          'fixed bottom-[80px]',
          state === 'expanded' ? 'left-[250px]' : 'left-[100px]',
        )}
      />
      <PopoverContent side="right">
        <PopoverBody>
          <div className="flex flex-col gap-12">
            <Body variant="body3">
              Viewing as
              <br />
              {email}
            </Body>
            <a
              href="/tax-pros/return-to-clients"
              className={cn(
                bodyVariants({ variant: 'body3', weight: 'bold' }),
                'text-text-link',
              )}
            >
              Back to tax pro dashboard
            </a>
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
