import classNames from 'classnames';
import React from 'react';
import { Box } from '../../layout/Box';
import { link, type LinkVariants } from './Link.css';

type BoxProps = React.ComponentProps<typeof Box>;
type LinkProps = BoxProps & LinkVariants;

export const Link = ({ className, variant, size, ...props }: LinkProps) => {
  return (
    <Box
      className={classNames([link({ variant, size }), className])}
      {...props}
    />
  );
};
