export const LIVE_PRICE_DATA_API_URI = process.env.COINS_API_ENDPOINT;
export const API_URI =
  '/api/JYycCEGjCaRqrYFGLB7kK96avEXRiTqbahGAehC68inQXNjDB4BFTg3b2gQP97zt/graphql';
export const LIVE_PRICE_DATA_OPERATION_NAME = 'CoinTrackerLiveCoinPrices';

export const SENTRY_IGNORE_ERRORS = [
  // Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
  "TypeError: can't access dead object",
  // React internal error thrown when something outside react modifies the DOM
  // This is usually because of a browser extension or Chrome's built-in translate
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
  'Node.removeChild: The node to be removed is not a child of this node',
  // Error occurs many times when a user clicks the "X" button on the browser before a page fully loads.
  // More info: https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
  'TypeError: Failed to fetch',

  // fetched from https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit',

  // Ignored errors to help cleanup sentry
  'Large Render Blocking Asset',
  /NetworkError when attempting to fetch resource/i,
  /The object can not be found here/i,
  /Cannot redefine property: googletag/i,
  /Faild to fetch/i,
  /Cannot redefine property: ethereum/i,
  /Can't find variable: bytecode/i,
  /AbortError/i,
  /NotSupportedError/i,
  // this happens when we try to access something from the Browser API that the user didn't allow us to
  /NotAllowedError/i,
  /ApolloError/i,
  // some error thrown by the web3modal
  /No matching key./i,
  /Unexpected message received/i,
  /WebSocket connection failed for host:/i,
  // errors coming from the adaEmbed iframe,
  /wui/i,
  'styleOwnerIsDarkReader',
  'Properties can only be defined on Objects',
  'NS_ERROR_ABORT',
  'NS_ERROR_FAILURE',
  'NS_ERROR_FILE_NO_DEVICE_SPACE',
  /Failed to read the 'localStorage' property/i,
  /Failed to read the 'sessionStorage' property/i,
  'Unknown error',
  'HTTP Error',
  'The operation is insecure.',
  'executing a cancelled action',
  'Failed to set cookie.',
  /error loading dynamically imported module/i,
  'WKWebView API client did not respond to this postMessage',
  /Unable to preload CSS/i,
  'Java bridge method invocation error',
  'Unexpected end of script',
  'Failed to initialize NumberFormat since used feature is not supported in the linked ICU version',
  'cancelled',
  /Loading CSS chunk/i,
  'Too many retries',
  /UnknownError/i,
  /TimeoutError/i,
  'cannot parse response',
  'Retrieving "b5x-stateful-inline-icon" flag timed out',
  'XHR Error',
  'No internet connection',
  'The network connection was lost.',
  'The object can not be cloned.',
  'RPCRequestError',
  'QuotaExceededError',
  'Type error',
  'Failed to get ServiceWorkerRegistration objects',
  'Internal error',
];

export const SENTRY_DENY_URLS = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /webkit-masked-url:\/\//i,
  /safari-web-extension:\/\//i,
  /moz-extension:\/\//i,
];
