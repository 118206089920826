export const chunkFailedMessages = [
  /Failed to fetch dynamically imported module/,
  /error loading dynamically imported module/,
  /Importing a module script failed/,
  /Loading chunk (\d+) failed/,
  /ChunkLoadError/,
];

export const isMessageMatchingModuleError = (error: Error) =>
  chunkFailedMessages.some(
    (chunkFailedMessage) =>
      error?.message && chunkFailedMessage.test(error.message),
  );
