import { cva } from 'class-variance-authority';

// first circle is background, second is foreground
export const indicatorVariants = cva('animate-spin', {
  variants: {
    variant: {
      loading:
        '[&_circle:last-of-type]:stroke-background-mode-accents [&_circle:first-of-type]:stroke-accent-subtle-slate',
      general:
        '[&_circle:last-of-type]:stroke-accent-bold-yellow [&_circle:first-of-type]:stroke-accent-subtle-slate',
      sync: '[&_circle:last-of-type]:stroke-accent-bold-yellow [&_circle:first-of-type]:stroke-accent-subtle-yellow',
      basis:
        '[&_circle:last-of-type]:stroke-accent-bold-green [&_circle:first-of-type]:stroke-accent-subtle-green',
    },
    transparent: {
      true: '[&_circle:first-of-type]:stroke-transparent',
    },
  },
  defaultVariants: {
    variant: 'loading',
  },
});
