import { cn } from '@cointracker/styleguide';
import CoinTrackerLogo from '@cointracker/styleguide/src/icons/logo-icon.svg?react';
import { Body } from '@cointracker/styleguide/src/LoggedIn/Typography';
import { URLS } from '@cointracker/ui';
import { Link } from 'react-router-dom';

export function Footer({ className }: { className?: string }) {
  return (
    <section className="p-0 md:p-12">
      <div
        className={cn(
          'flex w-full flex-row justify-between bg-background-default-highlight px-12 py-8 text-text-secondary md:place-items-center md:rounded-12 md:px-24 md:py-16',
          className,
        )}
      >
        <ul className="flex flex-col gap-12 px-8 md:flex-row md:gap-48 md:px-0">
          <li>
            <Link to={URLS.PRIVACY} target="_blank">
              <Body variant="body5">Privacy Policy</Body>
            </Link>
          </li>
          <li>
            <Link to={URLS.TERMS} target="_blank">
              <Body variant="body5">Terms</Body>
            </Link>
          </li>
          <li>
            <Link to={URLS.DISCLAIMER} target="_blank">
              <Body variant="body5">Disclaimer</Body>
            </Link>
          </li>
        </ul>
        <CoinTrackerLogo className="h-18 w-18 text-text-secondary" />
      </div>
    </section>
  );
}
