import { storeUccInCookies } from '@cointracker/consumer-web/src/app/cello/storeCelloReferrer';
import { getPartnerFromPathname } from '@cointracker/shared-utils';
import { getCookie, setCookie } from '../../utils/cookies';

/**
 * Gets the context for the segment call to be passed to the server side.
 * Other informations are added through the request itself like the ip.
 */
export const getPageContext = () => {
  return {
    referrer: document.referrer || window.frames?.top?.document.referrer,
    full_url: window.location.href,
    page_title: document.title,
    screen_height: window.screen.height,
    screen_width: window.screen.width,
    screen_density: window.devicePixelRatio,
    locale: navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = (url: URL | RequestInfo, data: any) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

interface FirstTouchData {
  expiresAt: number;
  referrer: string;
  url: string;
}

export const registerAnalyticsFirstTouch = () => {
  if (isServer() || !window.localStorage) {
    return;
  }
  // store the ucc referral code in the cookies by just looking at the url or the cello-referral cookie
  storeUccInCookies(false);
  initialiseFirstTouchData();
  initialiseLastTouchData();
};

const initialiseFirstTouchData = () => {
  const firstTouchData = getFirstTouchData();
  if (firstTouchData) {
    const hasDataExpired = Date.now() > firstTouchData.expiresAt;
    if (!hasDataExpired) {
      return;
    }
  }

  const data = {
    expiresAt: Date.now() + 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
    referrer: document.referrer,
    url: window.location.href,
    session_started_at: Date.now(),
    partner: getPartnerFromPathname(document.location.pathname),
  };

  setCookie('firstTouchData', data, 30);
};

const initialiseLastTouchData = () => {
  const data = {
    referrer: document.referrer,
    url: window.location.href,
    session_started_at: Date.now(),
    partner: getPartnerFromPathname(document.location.pathname),
  };

  setCookie('lastTouchData', data, 30);
};

export const getFirstTouchData = () => {
  if (isServer() || !window.localStorage) {
    return;
  }
  const firstTouch = getCookie('firstTouchData');
  try {
    const firstTouchData = JSON.parse(firstTouch ?? '') as FirstTouchData;
    const hasExpired = Date.now() > firstTouchData.expiresAt;
    if (hasExpired) {
      return;
    }
    return firstTouchData;
  } catch (e) {
    return;
  }
};

export const isServer = () => {
  return typeof window === 'undefined';
};

// Regexps from is-mobile package
// https://github.com/juliangruber/is-mobile/blob/v4.0.0/index.js#L7-L10
const mobileRE =
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
const notMobileRE = /CrOS/;
const tabletRE = /android|ipad|playbook|silk/i;

/**
 * Evaluates the current user agent to determine if the user is on a mobile device.
 *
 * @returns Whether the user is on a mobile device (phone or tablet) or not.
 */
export const isMobile = () => {
  const ua = navigator?.userAgent;
  if (!ua) return false;

  return (mobileRE.test(ua) && !notMobileRE.test(ua)) || tabletRE.test(ua);
};
