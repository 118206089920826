import { useContext } from 'react';
import { AnalyticsContext } from '../context';

/**
 * @returns analytics library - currently only supported methods are: identify, track, page
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('Analytics context used outside of its Provider!');
  }

  return context?.analytics;
};

export const useTrackSignUpButtonClicked = () => {
  const analytics = useAnalytics();
  return (
    button_text: string,
    section_name: string,
    section_title: string,
    page_title: string,
  ) => {
    analytics.track('Sign Up Button Clicked', {
      button_text,
      section_name,
      section_title,
      page_title,
    });
  };
};
