import { UserFreezingStatus } from '@cointracker/graphql-types';
import { useContext } from 'react';
import { FreezingStatusContext } from '../context';

export function useGetIsFreezingOperationInProgress() {
  const context = useContext(FreezingStatusContext);
  return (
    context?.status === UserFreezingStatus.FreezingInProgress ||
    context?.status === UserFreezingStatus.UnfreezingInProgress
  );
}
