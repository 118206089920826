import { parseCookie } from './cookies';

export const isClient = () => {
  return typeof window !== 'undefined';
};

export const isServer = () => {
  return !isClient();
};

export const isAuthenticated = () => {
  if (!isClient()) {
    return false;
  }
  const cookies = parseCookie(document.cookie);
  const authCookie = cookies?.['is_auth'];
  return authCookie === 'true' || authCookie === 'True';
};
