import {
  cn,
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
  useSidebar,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { useAnalytics } from 'src/app/analytics';
import { useTaxReadiness } from '../hooks/useTaxReadiness';
import { StaticIconSidebar } from '../StaticIconSidebar';
import { TaxReadinessStatusButton } from './TaxReadinessStatusButton';
import { ProgressDonut } from './TaxReadinessStatusIndicator';
import { TaxReadinessStatusSheetContent } from './TaxReadinessStatusSheetContent';

interface StatusProps {
  closeOnly?: boolean;
  className?: string;
}

export const TaxReadinessStatus = ({
  closeOnly = false,
  className,
}: StatusProps) => {
  const {
    isMobile,
    toggleIsTaxReadinessSheetOpen,
    isTaxReadinessSheetOpen,
    setIsTaxReadinessSheetOpen,
  } = useSidebar();
  const {
    completedStepsCount,
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
  } = useTaxReadiness();
  const analytics = useAnalytics();

  if (closeOnly) {
    return (
      <SheetTrigger asChild>
        <Button
          variant="line"
          size="small"
          className={cn(
            'flex flex-row place-items-center gap-8 md:p-0',
            className,
          )}
          transparent={!isMobile}
          onClick={() => {
            analytics.track('Tax Readiness Clicked', {
              action: 'Status Indicator Clicked',
              step_1_completed: addWalletsStepChecked,
              step_2_completed: syncWalletsStepChecked,
              step_3_completed: optimizeTaxSavingsStepChecked,
              step_4_completed: reviewTaxFormsStepChecked,
            });
            toggleIsTaxReadinessSheetOpen();
          }}
        >
          <ProgressDonut step={completedStepsCount} maxSteps={4} />
        </Button>
      </SheetTrigger>
    );
  }

  return (
    <Sheet
      modal={true}
      onOpenChange={setIsTaxReadinessSheetOpen}
      open={isTaxReadinessSheetOpen}
    >
      <TaxReadinessStatusButton />
      <SheetContent
        side={isMobile ? 'bottom' : 'left'}
        className={cn(
          'z-50 h-full max-w-[--sidebar-width-status-open] overscroll-contain border-sidebar-border bg-sidebar p-0 text-sidebar-foreground md:z-10',
          {
            'top-topbar-height !max-w-full': isMobile,
          },
        )}
        statusSlider
      >
        <SheetTitle className="sr-only">Account Status</SheetTitle>
        <div className="flex h-full w-full flex-row">
          <StaticIconSidebar className="hidden md:flex" />
          <TaxReadinessStatusSheetContent />
        </div>
      </SheetContent>
    </Sheet>
  );
};
