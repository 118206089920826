import { useEffect } from 'react';

import { cn } from '@cointracker/styleguide';
import { Body, buttonVariants } from '@cointracker/styleguide/src/LoggedIn';
import { useAnalytics } from 'src/app/analytics';

interface InstitutionalContactProps {
  slug: string;
}

export const InstitutionalContact = ({ slug }: InstitutionalContactProps) => {
  const analytics = useAnalytics();
  useEffect(() => {
    analytics?.track('Institutional Gate Shown', {
      integration: slug,
    });
  }, [analytics, slug]);

  return (
    <div className="flex flex-col items-center gap-16 p-16">
      <Body variant="body1" className="text-center">
        Please contact our crypto accounting team enterprise@cointracker.com for
        our business product.
      </Body>
      <a
        href="mailto:enterprise@cointracker.com"
        target="_blank"
        rel="noreferrer"
        className={cn(
          buttonVariants({ variant: 'primary', transparent: false }),
        )}
      >
        Contact us
      </a>
    </div>
  );
};
