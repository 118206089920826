import useWindowWidth from './useWindowWidth';

interface useIsMobileProps {
  maxWidth?: number;
}
export function useIsMobile({ maxWidth = 992 }: useIsMobileProps = {}) {
  const width = useWindowWidth(100);

  if (width < maxWidth) {
    return true;
  }
  return false;
}
