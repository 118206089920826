import { CookieBanner } from '@cointracker/styleguide';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/app/user/context';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

type SelectedTheme = 'SYSTEM' | 'DARK' | 'LIGHT' | undefined;

function getIsDarkTheme(selectedTheme: SelectedTheme) {
  if (!selectedTheme || selectedTheme === 'LIGHT') {
    return false;
  }
  if (selectedTheme === 'DARK') {
    return true;
  }
  // use system preference if selectedTheme is SYSTEM
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export const CookieConsentWrapper = () => {
  const user = useContext(UserContext);
  const [isDarkTheme, setIsDarkTheme] = useState(
    getIsDarkTheme(user.selectedTheme as SelectedTheme),
  );

  useEffect(() => {
    if (user.hasFetched && user.isAuthenticated && user.selectedTheme) {
      const isDarkTheme = getIsDarkTheme(user.selectedTheme as SelectedTheme);
      setIsDarkTheme(isDarkTheme);
    }
  }, [user.hasFetched, user.isAuthenticated, user.selectedTheme]);

  useEffect(() => {
    // add event listener for window match media and flip the isDarkTheme flag
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e: MediaQueryListEvent) => {
      const userTheme = user.selectedTheme;
      if (userTheme === 'SYSTEM') {
        setIsDarkTheme(e.matches);
      }
    };
    mediaQueryList.addEventListener('change', handleChange);
    return () => mediaQueryList.removeEventListener('change', handleChange);
  }, [user.selectedTheme]);

  return (
    <>
      <CookieBanner
        isDarkTheme={isDarkTheme}
        loading={!user.hasFetched}
        showManageButton={user.hasFetched && !user.isAuthenticated}
      />
    </>
  );
};
