import blogHero1Url from '@cointracker/styleguide/src/icons/blog/blog1.webp?url';
import blogHero10Url from '@cointracker/styleguide/src/icons/blog/blog10.webp?url';
import blogHero11Url from '@cointracker/styleguide/src/icons/blog/blog11.webp?url';
import blogHero12Url from '@cointracker/styleguide/src/icons/blog/blog12.webp?url';
import blogHero13Url from '@cointracker/styleguide/src/icons/blog/blog13.webp?url';
import blogHero14Url from '@cointracker/styleguide/src/icons/blog/blog14.webp?url';
import blogHero15Url from '@cointracker/styleguide/src/icons/blog/blog15.webp?url';
import blogHero16Url from '@cointracker/styleguide/src/icons/blog/blog16.webp?url';
import blogHero17Url from '@cointracker/styleguide/src/icons/blog/blog17.webp?url';
import blogHero18Url from '@cointracker/styleguide/src/icons/blog/blog18.webp?url';
import blogHero19Url from '@cointracker/styleguide/src/icons/blog/blog19.webp?url';
import blogHero2Url from '@cointracker/styleguide/src/icons/blog/blog2.webp?url';
import blogHero20Url from '@cointracker/styleguide/src/icons/blog/blog20.webp?url';
import blogHero21Url from '@cointracker/styleguide/src/icons/blog/blog21.webp?url';
import blogHero22Url from '@cointracker/styleguide/src/icons/blog/blog22.webp?url';
import blogHero23Url from '@cointracker/styleguide/src/icons/blog/blog23.webp?url';
import blogHero24Url from '@cointracker/styleguide/src/icons/blog/blog24.webp?url';
import blogHero25Url from '@cointracker/styleguide/src/icons/blog/blog25.webp?url';
import blogHero26Url from '@cointracker/styleguide/src/icons/blog/blog26.webp?url';
import blogHero27Url from '@cointracker/styleguide/src/icons/blog/blog27.webp?url';
import blogHero28Url from '@cointracker/styleguide/src/icons/blog/blog28.webp?url';
import blogHero29Url from '@cointracker/styleguide/src/icons/blog/blog29.webp?url';
import blogHero3Url from '@cointracker/styleguide/src/icons/blog/blog3.webp?url';
import blogHero30Url from '@cointracker/styleguide/src/icons/blog/blog30.webp?url';
import blogHero31Url from '@cointracker/styleguide/src/icons/blog/blog31.webp?url';
import blogHero32Url from '@cointracker/styleguide/src/icons/blog/blog32.webp?url';
import blogHero33Url from '@cointracker/styleguide/src/icons/blog/blog33.webp?url';
import blogHero34Url from '@cointracker/styleguide/src/icons/blog/blog34.webp?url';
import blogHero35Url from '@cointracker/styleguide/src/icons/blog/blog35.webp?url';
import blogHero36Url from '@cointracker/styleguide/src/icons/blog/blog36.webp?url';
import blogHero37Url from '@cointracker/styleguide/src/icons/blog/blog37.webp?url';
import blogHero38Url from '@cointracker/styleguide/src/icons/blog/blog38.webp?url';
import blogHero39Url from '@cointracker/styleguide/src/icons/blog/blog39.webp?url';
import blogHero4Url from '@cointracker/styleguide/src/icons/blog/blog4.webp?url';
import blogHero5Url from '@cointracker/styleguide/src/icons/blog/blog5.webp?url';
import blogHero6Url from '@cointracker/styleguide/src/icons/blog/blog6.webp?url';
import blogHero7Url from '@cointracker/styleguide/src/icons/blog/blog7.webp?url';
import blogHero8Url from '@cointracker/styleguide/src/icons/blog/blog8.webp?url';
import blogHero9Url from '@cointracker/styleguide/src/icons/blog/blog9.webp?url';
import classNames from 'classnames';
import { forwardRef, useState, type Ref } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid } from '../Grid';
import { Body1, Body3, Body4, Body5, H2, H4, H5 } from '../typography';
import {
  authorLarge,
  authorLinkLarge,
  blogCardLarge,
  blogCardMedium,
  blogCardSmall,
  blogCardsContainer,
  blogCardsContainerInner,
  cardBackground,
  contentLarge,
  contentMedium,
  excerptLarge,
  excerptMedium,
  imageLarge,
  imageLargeContainer,
  imageMedium,
  imageMediumContainer,
  imageZoom,
  textHoverColor,
  titleLarge,
  titleLink,
  titleMedium,
  titleSmall,
} from './BlogCard.css';

const BLOG_HERO_URLS: string[] = [
  blogHero1Url,
  blogHero2Url,
  blogHero3Url,
  blogHero4Url,
  blogHero5Url,
  blogHero6Url,
  blogHero7Url,
  blogHero8Url,
  blogHero9Url,
  blogHero10Url,
  blogHero11Url,
  blogHero12Url,
  blogHero13Url,
  blogHero14Url,
  blogHero15Url,
  blogHero16Url,
  blogHero17Url,
  blogHero18Url,
  blogHero19Url,
  blogHero20Url,
  blogHero21Url,
  blogHero22Url,
  blogHero23Url,
  blogHero24Url,
  blogHero25Url,
  blogHero26Url,
  blogHero27Url,
  blogHero28Url,
  blogHero29Url,
  blogHero30Url,
  blogHero31Url,
  blogHero32Url,
  blogHero33Url,
  blogHero34Url,
  blogHero35Url,
  blogHero36Url,
  blogHero37Url,
  blogHero38Url,
  blogHero39Url,
];

const HOVER_COLORS = ['lime', 'blue', 'lilac', 'teal', 'slate'] as const;

export interface BlogPostInfo {
  imageSrc: string;
  imageAlt?: string;
  date?: string;
  readingTime?: string;
  href?: string;
  title: string;
  excerpt: string;
  author?: string;
  authorHref?: string;
}

export interface BlogCardProps {
  className?: string;
  post: BlogPostInfo;
  hoverBackground?: 'lime' | 'blue' | 'lilac' | 'teal' | 'slate';
}

export const BlogCardLarge = forwardRef(
  (
    {
      className,
      post: {
        imageSrc,
        imageAlt,
        date,
        readingTime,
        href,
        title,
        excerpt,
        author,
        authorHref,
      },
      ...rest
    }: BlogCardProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <a
        className={classNames(blogCardLarge, className)}
        href={href}
        ref={ref}
        {...rest}
      >
        <div className={imageLargeContainer}>
          <img
            className={classNames(imageLarge, imageZoom)}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>

        <div className={contentLarge}>
          <Body3 variant="secondary">
            {date ?? ''}
            {date !== null && readingTime !== null ? '・' : ''}
            {readingTime ?? ''}
          </Body3>
          <H2 className={titleLarge}>{title}</H2>
          <Body1 className={excerptLarge} variant="secondary">
            {excerpt}
          </Body1>
          <Body5 className={authorLarge}>
            {authorHref ? (
              <a href={authorHref} className={authorLinkLarge}>
                {author}
              </a>
            ) : (
              author
            )}
          </Body5>
        </div>
      </a>
    );
  },
);

BlogCardLarge.displayName = 'BlogCardLarge';

export const BlogCardMedium = forwardRef(
  (
    {
      className,
      post: { imageSrc, imageAlt, date, readingTime, href, title, excerpt },
      ...rest
    }: BlogCardProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <a
        className={classNames(blogCardMedium, className)}
        href={href}
        ref={ref}
        {...rest}
      >
        <div className={imageMediumContainer}>
          <img
            className={classNames(imageMedium, imageZoom)}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>

        <div className={contentMedium}>
          <div>
            <Body4 variant="secondary">
              {date ?? ''}
              {date !== null && readingTime !== null ? '・' : ''}
              {readingTime ?? ''}
            </Body4>
            <H4 className={titleMedium}>
              <div>{title}</div>
            </H4>
          </div>

          <Body1 variant="secondary" className={excerptMedium}>
            {excerpt}
          </Body1>
        </div>
      </a>
    );
  },
);

BlogCardMedium.displayName = 'BlogCardMedium';

export const BlogCardSmall = forwardRef(
  (
    {
      className,
      post: { date, readingTime, href, title },
      hoverBackground,
      ...rest
    }: BlogCardProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <a
        className={classNames(
          blogCardSmall,
          cardBackground({ hoverBackground }),
          className,
        )}
        ref={ref}
        href={href}
        {...rest}
      >
        <Body3 className={classNames(textHoverColor({ hoverBackground }))}>
          {date ?? ''}
          {date !== null && readingTime !== null ? '・' : ''}
          {readingTime ?? ''}
        </Body3>
        <H5
          className={classNames(
            titleSmall,
            textHoverColor({ hoverBackground }),
          )}
        >
          <span className={titleLink}>{title}</span>
        </H5>
      </a>
    );
  },
);

BlogCardSmall.displayName = 'BlogCardSmall';

export interface BlogCardsContainerProps {
  className?: string;
  posts: BlogPostInfo[];
  infiniteScroll?: boolean;
  smallCardOnly?: boolean;
}

export const BlogCardsContainer = forwardRef(
  (
    {
      className,
      infiniteScroll,
      smallCardOnly,
      posts,
      ...rest
    }: BlogCardsContainerProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const BLOG_LAYOUT_CYCLE = 23;
    const BLOG_LAYOUT = {
      LARGE: 1,
      MEDIUM: [8, 17],
    };
    const [limit, setLimit] = useState(48);

    const hasMore = limit < posts.length;
    const loadMore = () => {
      setLimit((prev) => prev + 3);
    };

    const visiblePosts = infiniteScroll ? posts.slice(0, limit) : posts;
    let heroIndex = 0;
    let hoverIndex = 0;

    return (
      <Grid
        className={classNames(blogCardsContainer, className)}
        ref={ref}
        {...rest}
      >
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <div className={blogCardsContainerInner}>
            {visiblePosts.map((post, index) => {
              if (smallCardOnly) {
                const hoverColorIndex = hoverIndex % HOVER_COLORS.length;
                hoverIndex++;

                return (
                  <BlogCardSmall
                    post={post}
                    key={post.href}
                    hoverBackground={HOVER_COLORS[hoverColorIndex]}
                  />
                );
              }

              const mod = (index + 1) % BLOG_LAYOUT_CYCLE;

              if (mod == BLOG_LAYOUT.LARGE) {
                post.imageSrc =
                  BLOG_HERO_URLS[heroIndex++ % BLOG_HERO_URLS.length]!;
                return <BlogCardLarge post={post} key={post.href} />;
              } else if (BLOG_LAYOUT.MEDIUM.includes(mod)) {
                post.imageSrc =
                  BLOG_HERO_URLS[heroIndex++ % BLOG_HERO_URLS.length]!;
                return <BlogCardMedium post={post} key={post.href} />;
              } else {
                const hoverColorIndex = hoverIndex % HOVER_COLORS.length;
                hoverIndex++;
                return (
                  <BlogCardSmall
                    post={post}
                    key={post.href}
                    hoverBackground={HOVER_COLORS[hoverColorIndex]}
                  />
                );
              }
            })}
          </div>
        </InfiniteScroll>
      </Grid>
    );
  },
);

BlogCardsContainer.displayName = 'BlogCardsContainer';
