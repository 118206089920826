import { cva } from 'class-variance-authority';
export const inputVariants = cva(
  'rounded-8 text-text-primary [&_input]:placeholder-text-secondary bg-background-default flex items-center gap-8 border p-16',
  {
    variants: {
      variant: {
        default: '',
        positive: '',
        negative: '',
      },
      size: {
        small: 'h-[42px]',
        medium: 'h-[46px]',
        large: 'h-[50px]',
      },
      hasError: {
        true: '!border-line-negative !hover:border-line-negative-hover-',
      },
      align: {
        left: '',
        right: '[&_input]:text-right',
      },
      disabled: { true: 'opacity-[0.38]' },
    },
    compoundVariants: [
      {
        disabled: false,
        variant: 'default',
        className:
          'border-line-secondary focus-within:bg-background-default-pressed hover:border-line-secondary-hover',
      },
      {
        disabled: true,
        variant: 'default',
        className: 'hover:border-inherit',
      },
      {
        disabled: false,
        variant: 'positive',
        className: 'border-line-positive hover:border-line-positive-hover',
      },
      {
        disabled: true,
        variant: 'positive',
        className: 'border-line-positive-disabled hover:border-inherit',
      },
      {
        disabled: false,
        variant: 'negative',
        className: 'border-line-negative hover:border-line-negative-hover',
      },
      {
        disabled: true,
        variant: 'negative',
        className: 'border-line-negative-disabled hover:border-inherit',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'large',
      disabled: false,
      align: 'left',
    },
  },
);

export const supportTextVariants = cva('mt-space-8', {
  variants: {
    variant: {
      default: '',
      positive: 'text-text-positive',
      negative: 'text-text-negative',
    },
    hasError: {
      true: '!text-text-negative',
    },
  },
  defaultVariants: {
    variant: 'default',
    hasError: false,
  },
});
