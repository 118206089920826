/**
 * Hook to access the Phantom wallet if available in the browser
 * @returns The Phantom wallet object or null if not available
 */
export function usePhantom() {
  if ('phantom' in window) {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      return provider;
    }
  }

  return null;
}
