import { cva, type VariantProps } from 'class-variance-authority';

export const bodyVariants = cva('m-0 font-sans', {
  variants: {
    variant: {
      body1: 'text-body1 md:text-body1-md lg:text-body1-lg',
      body2: 'text-body2 md:text-body2-md lg:text-body2-lg',
      body3: 'text-body3 md:text-body3-md lg:text-body3-lg',
      body4: 'text-body4 md:text-body4-md lg:text-body4-lg',
      body5: 'text-body5 md:text-body5-md lg:text-body5-lg',
    },
    caps: {
      true: '-tracking-1 font-medium uppercase',
      false: '',
    },
    weight: {
      normal: 'font-normal',
      bold: '-tracking-1 !font-medium',
    },
  },
  defaultVariants: {
    variant: 'body1',
    caps: false,
    weight: 'normal',
  },
});

export type BodyVariants = VariantProps<typeof bodyVariants>;

export const buttonTextVariants = cva(
  'text-buttonText md:text-buttonText-md lg:text-buttonText-lg font-sans',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        default: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonTextVariants = VariantProps<typeof buttonTextVariants>;

export const headingVariants = cva('m-0 font-sans', {
  variants: {
    variant: {
      h1: 'text-heading1 md:text-heading1-md lg:text-heading1-lg',
      h2: 'text-heading2 md:text-heading2-md lg:text-heading2-lg',
      h3: 'text-heading3 md:text-heading3-md lg:text-heading3-lg',
      h4: 'text-heading4 md:text-heading4-md lg:text-heading4-lg',
      h5: 'text-heading5 md:text-heading5-md lg:text-heading5-lg font-normal',
      h6: 'text-heading6 md:text-heading6-md lg:text-heading6-lg',
    },
    as: {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
    },
  },
  defaultVariants: {
    variant: 'h1',
    as: 'h1',
  },
});

export type HeadingVariants = VariantProps<typeof headingVariants>;

export const navLinkTextVariants = cva(
  'text-navLinkText md:text-navLinkText-md lg:text-navLinkText-lg font-sans',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        default: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type NavLinkTextVariants = VariantProps<typeof navLinkTextVariants>;

export const navButtonTextVariants = cva(
  'text-navButtonText md:text-navButtonText-md lg:text-navButtonText-lg font-sans',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        default: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type NavButtonTextVariants = VariantProps<typeof navButtonTextVariants>;

export const numberVariants = cva('m-0', {
  variants: {
    variant: {
      h3: 'text-number-h3 font-sans',
      h4: 'text-number-h4 font-sans',
      h5: 'text-number-h5 font-sans',
      h6: 'text-number-h6 font-sans',
      body1: 'text-number-body1 font-sans',
      body2: 'text-number-body2 font-sans',
      body3: 'text-number-body3 font-sans',
      body4: 'text-number-body4 font-sans',
      body5: 'text-number-body5 font-sans',
    },
    as: {
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      p: 'p',
      span: 'span',
    },
  },
  defaultVariants: {
    variant: 'body1',
    as: 'p',
  },
});

export type NumberVariants = VariantProps<typeof numberVariants>;
