import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Search } from '@cointracker/styleguide/src/LoggedIn/Inputs/Search';
import React from 'react';
import { Integration } from 'src/types/graphql-types';
import { SearchResults } from '../SearchResults';

interface IntegrationsListProps {
  setSelectedIntegration: React.Dispatch<React.SetStateAction<Integration>>;
  integrations: Integration[];
  searchOnTextChange: (val: string) => void;
  searchQuery: string;
  loading: boolean;
  isOnboarding?: boolean;
}

export const IntegrationsList = (props: IntegrationsListProps) => {
  const {
    setSelectedIntegration,
    integrations,
    searchOnTextChange,
    searchQuery,
    loading,
    isOnboarding = false,
  } = props;

  return (
    <div className="flex h-full flex-col gap-24">
      {!isOnboarding && (
        <Body variant="body2" className="text-text-secondary">
          Search for an integration or paste your wallet address or ENS domain
          or xPub key into the field below.
        </Body>
      )}
      <Search
        value={searchQuery}
        onChange={searchOnTextChange}
        placeholder="Search..."
        fluid
        size="lg"
      />
      <SearchResults
        integrations={integrations}
        loading={loading}
        setSelectedIntegration={setSelectedIntegration}
        searchQuery={searchQuery}
      />
    </div>
  );
};
