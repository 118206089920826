import React from 'react';
import { User } from '../user/context';
import { useCello } from './useCello';

interface CelloProviderProps {
  children: React.ReactNode;
  user?: User;
  celloScriptUrl: string;
  celloProductId: string;
}

const CelloProvider: React.FC<CelloProviderProps> = ({
  children,
  user,
  celloScriptUrl,
  celloProductId,
}) => {
  const { loading, error } = useCello(user, celloScriptUrl, celloProductId);

  if (loading) return <>{children}</>;
  if (error) {
    console.error('❌ Error fetching Cello token:', error);
    return <>{children}</>;
  }

  return <>{children}</>;
};

export default CelloProvider;
