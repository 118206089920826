import React, { forwardRef, type Ref } from 'react';
import { Body4 } from '../LoggedIn';
import { bodyVariants } from '../LoggedIn/Typography/variants';
import { inputVariants, supportTextVariants } from '../TextInput/styles';
import { type InputVariants } from '../TextInput/TextInput';
import { cn } from '../utils';

type TextareaProps = {
  disabled?: boolean;
  id?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  name?: string;
  supportText?: string;
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  hasError?: boolean;
  errorMessage?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  rows?: number;
  required?: boolean;
} & InputVariants;

export const Textarea = forwardRef(
  (
    {
      id,
      className,
      inputClassName,
      onChange,
      disabled,
      placeholder,
      hasError = false,
      label,
      size,
      supportText,
      errorMessage,
      variant,
      value,
      align,
      prefix,
      suffix,
      ...props
    }: TextareaProps,
    forwardedRef: Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <div className={cn(className)}>
        {label && (
          <label
            className={cn(
              bodyVariants({ variant: 'body4', weight: 'bold' }),
              'mb-space-8',
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div>
          {prefix && <div>{prefix}</div>}
          <textarea
            id={id}
            ref={forwardedRef}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            className={cn(
              inputVariants({
                variant,
                size,
                hasError,
                disabled,
                align,
              }),
              'w-full',
              inputClassName,
            )}
            {...props}
          />
          {suffix && <div className="">{suffix}</div>}
        </div>
        {supportText && (
          <Body4 className={supportTextVariants({ variant, hasError })}>
            {supportText}
          </Body4>
        )}
        {hasError && (
          <Body4 className="mt-space-8 text-text-negative">
            {errorMessage}
          </Body4>
        )}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
