import BurgerIcon from '@cointracker/styleguide/src/icons/burger.svg?react';
import CloseIcon from '@cointracker/styleguide/src/icons/close-light.svg?react';
import LogoIcon from '@cointracker/styleguide/src/icons/logo.svg?react';
import { REBRAND_URLS, URLS, useScrollListener } from '@cointracker/ui';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Tags } from '../LoggedIn';
import { NavLink } from '../NavLink';
import ChevronDownIcon from '../icons/chevron-down.svg?react';
import { NavLinkText } from '../typography';
import { cn } from '../utils';
import { NavigationMenu, NavigationMenuItem } from './NavigationMenu';
import { leftContainer } from './TaxProTopBar.css';
import {
  burgerButton,
  flexBasisContainer,
  fullHeightContainer,
  headerContents,
  hideBelowDesktop,
  logo,
  logoLink,
  mobileDropdownContainer,
  mobileDropdownMenu,
  mobileLinksContainer,
  styledHeader,
} from './TopBar.css';

const dropdownItemStyles = cn(
  'text-text-primary hover:bg-background-card-highlight cursor-pointer px-16 py-8 outline-none',
);
const dropdownContentStyles = cn(
  'rounded-8 border-line-card bg-background-pop-up text-text-primary shadow-elevation-1 max-h-[var(--radix-select-content-available-height)] min-w-[var(--radix-select-trigger-width)] max-w-[280px] overflow-hidden border',
);

const TAX_PRO_UPGRADE_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSc5iAT3WnpltytA1xkX1pdv0brLd0jZ74Yej5gBsmnDQqEjjQ/viewform?usp=sf_link';

function setViewportHeight() {
  document.documentElement.style.setProperty(
    '--viewport-height',
    `${window.innerHeight}px`,
  );
}

interface TaxProTopBarProps {
  pathname?: string;
  userEmail?: string;
  onLogout?: () => void;
}

export const TaxProTopBar = ({ userEmail, onLogout }: TaxProTopBarProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const scrollThreshold = 100; // safe number between 70 (desktop header height) and 96 (mobile header height)

  useScrollListener(() => {
    if (window.scrollY < scrollThreshold) {
      setIsVisible(true);
    } else if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  });

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const onToggleDropdown = useCallback(() => {
    setDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  useEffect(() => {
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
  return (
    <header className={classNames(styledHeader, { hide: !isVisible })}>
      <div className={headerContents}>
        <div className={leftContainer}>
          <a className={logoLink} href={REBRAND_URLS.HOME}>
            <LogoIcon className={logo} />
          </a>
          <Tags color="black" filled condensed>
            Tax Pros
          </Tags>
        </div>
        <NavigationMenu>
          <NavigationMenuItem className={hideBelowDesktop}>
            <NavLink href={URLS.VIEW_TAX_PRO_DASHBOARD}>Dashboard</NavLink>
          </NavigationMenuItem>
          <NavigationMenuItem className={hideBelowDesktop}>
            <NavLink
              href="https://feedback.cointracker.io/new-posts"
              target="_blank"
            >
              Request a Feature
            </NavLink>
          </NavigationMenuItem>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className={hideBelowDesktop}>
              <div className="text-text-secondary flex items-center gap-[2px]">
                <NavLinkText className="!text-text-secondary">
                  {userEmail?.toLowerCase() || ''}
                </NavLinkText>
                <ChevronDownIcon />
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className={dropdownContentStyles}
                style={{ zIndex: 100 }}
              >
                <DropdownMenu.Item className={dropdownItemStyles}>
                  <a href={URLS.TAX_PAGE}>Go to personal account</a>
                </DropdownMenu.Item>
                <DropdownMenu.Item className={dropdownItemStyles}>
                  <a href={URLS.EDIT_USER_PROFILE}>Settings</a>
                </DropdownMenu.Item>
                <DropdownMenu.Item className={dropdownItemStyles}>
                  <a
                    href={TAX_PRO_UPGRADE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Upgrade account
                  </a>
                </DropdownMenu.Item>
                <DropdownMenu.Item className={dropdownItemStyles}>
                  <a
                    href="https://support.cointracker.io/hc/en-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tax pro support
                  </a>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className={dropdownItemStyles}
                  onClick={onLogout}
                >
                  Logout
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          <button
            className={burgerButton}
            onClick={onToggleDropdown}
            aria-label="burger-menu-button"
          >
            {!isDropdownOpen && <BurgerIcon />}
            {isDropdownOpen && <CloseIcon width="30px" height="30px" />}
          </button>
        </NavigationMenu>
      </div>
      <Collapsible.Collapsible
        open={isDropdownOpen}
        onOpenChange={onToggleDropdown}
        className={mobileDropdownMenu}
        data-testid="collapsible-container"
      >
        <Collapsible.CollapsibleContent className={mobileDropdownContainer}>
          <RemoveScroll enabled={isDropdownOpen}>
            <div className={flexBasisContainer}>
              <div className={fullHeightContainer}>
                <div className={mobileLinksContainer}>
                  <NavLink href={URLS.VIEW_TAX_PRO_DASHBOARD}>
                    Dashboard
                  </NavLink>
                  <NavLink
                    href="https://feedback.cointracker.io/new-posts"
                    target="_blank"
                  >
                    Request a Feature
                  </NavLink>
                  <NavLink href={URLS.EDIT_USER_PROFILE}>Settings</NavLink>
                  <NavLink href={URLS.TAX_PAGE}>Go to personal account</NavLink>
                  <NavLink href={TAX_PRO_UPGRADE_URL} target="_blank">
                    Upgrade account
                  </NavLink>
                  <NavLink
                    href="https://support.cointracker.io/hc/en-us"
                    target="_blank"
                  >
                    Tax pro support
                  </NavLink>
                  <NavLink onClick={onLogout}>Sign out</NavLink>
                </div>
              </div>
            </div>
          </RemoveScroll>
        </Collapsible.CollapsibleContent>
      </Collapsible.Collapsible>
    </header>
  );
};
