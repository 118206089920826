import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

/* eslint-disable import/no-named-as-default-member */
dayjs.extend(duration);
dayjs.extend(relativeTime);
// needed or else the datepicker is crashing when trying to update a date
dayjs.extend(weekday);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export default dayjs;
/* eslint-enable import/no-named-as-default-member */
