import { Button } from '@cointracker/styleguide/src/LoggedIn/Button';
import classNames from 'classnames';
import { forwardRef, type Ref } from 'react';
import { SearchInput } from '../SearchInput';
import { Select, SelectItem } from '../Select/Select';
import { Body2, Body4 } from '../typography';
import {
  currencyIcon,
  outer,
  searchContainer,
  searchInput,
  selectItemContent,
  selectTrigger,
  showBalance,
} from './FieldLockup.css';

export interface FieldLockupValue {
  address: string;
}

export interface FieldLockupProps {
  address: string;
  className?: string;
  coinSymbol: string;
  currencies: { symbol: string; imgSrc: string }[];
  error?: string;
  loading?: boolean;
  onChange?: (value: string) => void;
  onNavigateToCoin?: (coinSymbol: string) => void;
  placeholder?: string;
}

export const FieldLockup = forwardRef(
  (props: FieldLockupProps, ref: Ref<HTMLHeadingElement>) => {
    const {
      address,
      className,
      coinSymbol,
      currencies,
      error,
      loading,
      onChange,
      placeholder,
      onNavigateToCoin,
      ...rest
    } = props;

    return (
      <div className={classNames(outer, className)} ref={ref} {...rest}>
        <div className={searchContainer}>
          <div className={searchInput}>
            <SearchInput
              placeholder={placeholder || 'Paste a wallet address...'}
              onChange={(e) => onChange?.(e.target.value)}
              onClear={() => onChange?.('')}
              value={address}
              errored={!!error}
            />

            {error && (
              <Body4 variant="red" className="[&&]:mt-8">
                {error}
              </Body4>
            )}
          </div>

          <Select
            value={coinSymbol}
            className={selectTrigger}
            aria-label="coin-select"
            onValueChange={(value) => onNavigateToCoin?.(value)}
          >
            {currencies?.map((currency) => (
              <SelectItem key={currency.symbol} value={currency.symbol}>
                <div className={selectItemContent}>
                  <img
                    src={currency.imgSrc}
                    alt={currency.symbol}
                    className={currencyIcon}
                  />
                  <Body2 variant="secondary">{currency.symbol}</Body2>
                </div>
              </SelectItem>
            ))}
          </Select>

          <div className={showBalance}>
            <Button
              variant="primary"
              type="submit"
              loading={loading}
              disabled={!address.trim()}
              fluid
            >
              Check balance
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

FieldLockup.displayName = 'FieldLockup';
