import { usePrevious } from '@cointracker/ui';
import { CaretDown, Check } from '@phosphor-icons/react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'cmdk';
import { useEffect, useMemo, useState, type ReactNode } from 'react';
import { cn } from '../../../utils';
import { Button } from '../../Button';
import { Body2, Body4 } from '../../Typography';
import { bodyVariants } from '../../Typography/variants';

export interface ComboboxItem {
  value?: string;
  label?: string | ReactNode;
}

interface ComboboxProps {
  id?: string;
  placeholder?: string;
  emptyMessage?: string;
  items: ComboboxItem[];
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  searchPlaceholder?: string;
  defaultValue?: string;
  shouldFilter?: boolean;
  hasError?: boolean;
  onValueChange?: (value: string) => void;
  onInputValueChange?: (value: string) => void;
}

export function Combobox({
  id,
  placeholder,
  emptyMessage,
  searchPlaceholder,
  loading,
  disabled,
  items,
  className,
  defaultValue,
  onValueChange,
  shouldFilter = true,
  hasError,
  onInputValueChange,
}: ComboboxProps) {
  const [open, setOpen] = useState(false);
  const defaultValueItem = items.find(({ value }) => value === defaultValue);
  const [selectedItem, setSelectedItem] = useState<ComboboxItem | undefined>(
    defaultValueItem,
  );

  const previousItems: ComboboxItem[] | undefined = usePrevious(items);
  useEffect(() => {
    if (!previousItems) {
      if (items.length > 0) {
        setSelectedItem(defaultValueItem);
      }

      return;
    }
    if ((previousItems as ComboboxItem[])?.length !== items?.length) {
      setSelectedItem(defaultValueItem);
    }
  }, [items, previousItems, defaultValueItem]);

  const formattedItems = useMemo(
    () =>
      items.map((item) => ({ ...item, textLabel: getNodeText(item.label) })),
    [items],
  );
  const onSelectItem = (selectedValue: string) => {
    const selectedItem = formattedItems.find(
      ({ textLabel }) => textLabel === selectedValue,
    );

    setSelectedItem(selectedItem ?? undefined);
    setOpen(false);

    if (!selectedItem || !selectedItem.value) {
      console.error('Selected item not found or missing value');
      return;
    }
    onValueChange?.(selectedItem.value);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          id={id}
          variant="line"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'bg-background-default group justify-between px-16',
            {
              'border-line-negative': hasError,
            },
            className,
          )}
          fluid
          disabled={disabled}
          loading={loading}
        >
          <Body2
            className={cn({
              'text-text-secondary': !selectedItem,
            })}
          >
            {selectedItem?.label ?? placeholder ?? 'Select'}
          </Body2>
          <CaretDown
            className={cn(
              'text-icon-primary-foreground w transition-transform duration-200 ease-out group-data-[state=open]:rotate-180',
              {
                'text-text-secondary-disabled': disabled || loading,
              },
            )}
            size={18}
            weight="bold"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'bg-background-card text-text-primary data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 rounded-8 shadow-elevation-1 relative min-w-[var(--radix-popper-anchor-width)] overflow-hidden',
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
          'max-h-[300px] max-w-[var(--radix-popover-trigger-width)] px-4 py-8',
        )}
        side="bottom"
        style={{ zIndex: 999 }}
      >
        <Command shouldFilter={shouldFilter}>
          <CommandInput
            onValueChange={(value) => onInputValueChange?.(value)}
            placeholder={searchPlaceholder ?? 'Search...'}
            className={cn(
              bodyVariants({ variant: 'body3' }),
              'mb-4 h-48 w-full shrink-0 basis-48 bg-transparent px-16 py-8 outline-none',
            )}
          />
          <CommandList>
            <CommandEmpty>
              <Body4 className="text-text-secondary px-16">
                {emptyMessage}
              </Body4>
            </CommandEmpty>
            <ScrollArea.Root
              type="auto"
              // picking 300px or the available height whichever is less, and then subtracting 48px for the command input and 16px for the padding
              className="max-h-[calc(min(var(--radix-popper-available-height),_300px)_-_48px_-_16px)] overflow-hidden"
            >
              <ScrollArea.Viewport asChild className="h-full w-full">
                <div className="flex max-h-[calc(min(var(--radix-popper-available-height),_300px)_-_48px_-_16px)] flex-col gap-4 overflow-auto overscroll-contain">
                  <CommandGroup className="[&>div]:flex [&>div]:flex-col [&>div]:gap-4">
                    {formattedItems.map((item, index) => (
                      <CommandItem
                        key={`${item.value}-${index}`}
                        // CMDK is filtering based on the value, but a user expects to search by the label.
                        value={item.textLabel}
                        data-state={
                          selectedItem?.value === item.value
                            ? 'checked'
                            : 'unchecked'
                        }
                        onSelect={onSelectItem}
                        className={cn(
                          'text-body2 md:text-body2-md lg:text-body2-lg rounded-8 text-text-primary bg-background-card flex cursor-pointer select-none items-center gap-8 border-[1.5px] border-transparent px-16 py-10 outline-none',
                          'data-[state=checked]:border-accent-bold-blue data-[state=checked]:bg-accent-subtle-blue',
                          'hover:bg-background-card-highlight hover:!border-transparent',
                        )}
                      >
                        {item.label}
                        <Check
                          size={18}
                          weight="bold"
                          className={cn(
                            'text-accent-bold-blue ml-auto',
                            selectedItem?.value === item.value
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </div>
              </ScrollArea.Viewport>
            </ScrollArea.Root>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

const getNodeText = (node: React.ReactNode): string => {
  if (node == null) return '';

  switch (typeof node) {
    case 'string':
    case 'number':
      return node.toString();

    case 'boolean':
      return '';

    case 'object': {
      if (node instanceof Array) return node.map(getNodeText).join('');

      if ('props' in node) return getNodeText(node.props.children);
    } // eslint-ignore-line no-fallthrough

    default:
      console.warn('Unresolved `node` of type:', typeof node, node);
      return '';
  }
};
