import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Body3, Button } from '@cointracker/styleguide/src/LoggedIn';
import { Info } from '@phosphor-icons/react';
import {
  useAppKitAccount,
  useAppKitNetwork,
  useAppKitTheme,
  useDisconnect,
} from '@reown/appkit/react';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAnalytics } from 'src/app/analytics';
import { UserContext } from 'src/app/user/context';
import { getNiceWalletName } from 'src/common/walletName';
import { CostBasisContext } from 'src/components/CostBasisProvider';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import {
  AddWalletErrors,
  GetWalletsQuery,
  Integration,
  useAddPublicAddressMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { ViewPermissions } from '../ViewPermissions';
import { emptyCacheQuery } from '../util';
import { ErrorMessage } from './ErrorMessage';
import { ValidationErrors, validate } from './validate';

interface WalletConnectProps {
  integration: Integration;
  onCloseModal?: () => void;
}

export const WalletConnect = (props: WalletConnectProps) => {
  const { caipNetwork } = useAppKitNetwork();
  const { address: walletAddress } = useAppKitAccount();
  const user = useContext(UserContext);
  const analytics = useAnalytics();
  const { setThemeMode } = useAppKitTheme();
  const { disconnect } = useDisconnect();
  useEffect(() => {
    if (user?.isAuthenticated) {
      if (user.selectedTheme === 'SYSTEM') {
        setThemeMode(
          window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light',
        );
      }
      if (user.selectedTheme === 'DARK') {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode('light');
    }
  }, [user, setThemeMode]);

  const integration = props.integration;
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<AddWalletErrors>();
  const [walletName, setWalletName] = useState<string>(undefined);
  const onWalletNameChange = useCallback((e) => {
    setWalletName(e.target.value);
  }, []);
  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const { startFasterPollingForSyncCompletion } = useContext(CostBasisContext);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const [mutate, { loading }] = useAddPublicAddressMutation({
    onCompleted: (data) => {
      if (data?.addPublicAddress?.success) {
        if (data?.addPublicAddress?.warning) {
          showNotification({
            message: `If you have previously added ${integration.info.symbol} transactions manually or marked related transactions as transfers, you may now have duplicate transactions. Please review your ${integration.info.symbol} transactions and if necessary undo those changes.`,
            status: Status.Info,
          });
        }
        showNotification({
          message: data.addPublicAddress.success,
          status: Status.Success,
        });
        analytics?.track('Wallet Added', {
          integration: 'WalletConnect',
          web: true,
          chain: caipNetwork.name,
        });
        const createdWalletOrExchange =
          data.addPublicAddress.createdWallet ||
          data.addPublicAddress.createdExchange;
        onSuccess(createdWalletOrExchange);
        props.onCloseModal?.();
        try {
          disconnect();
        } catch (error) {
          console.error('Error disconnecting wallet', error);
        }
      } else {
        if (data?.addPublicAddress?.error) {
          setSubmitErrorMessage(data?.addPublicAddress?.error);
        } else {
          setSubmitErrorMessage(AddWalletErrors.UnknownError);
        }
      }
    },
    update: (cache, { data }) => {
      if (!data.addPublicAddress.success) {
        return;
      }

      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      if (data.addPublicAddress.createdWallet) {
        const newLocalWallets = [
          ...walletsData.localWallets,
          data.addPublicAddress.createdWallet,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, localWallets: newLocalWallets },
        });
      } else if (data.addPublicAddress.createdExchange) {
        const newExchanges = [
          ...walletsData.exchanges,
          data.addPublicAddress.createdExchange,
        ];

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      }
    },
    onError: () => {
      setSubmitErrorMessage(AddWalletErrors.UnknownError);
    },
  });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const errors = validate({ chainName: caipNetwork?.name });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      const kebabNetwork = caipNetwork.name.replace(/\W+/g, '-').toLowerCase();
      mutate({
        variables: {
          publicAddressInput: {
            coinSymbol: caipNetwork.nativeCurrency.symbol,
            cryptoNetwork: kebabNetwork,
            walletAddress,
            walletName,
          },
        },
      });
      startFasterPollingForSyncCompletion();
    },
    [
      mutate,
      caipNetwork,
      walletAddress,
      startFasterPollingForSyncCompletion,
      walletName,
    ],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        <ViewPermissions />
        {submitErrorMessage && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>
              <ErrorMessage error={submitErrorMessage} />
            </InlineBannerContent>
          </InlineBanner>
        )}

        <div className="flexflex-col gap-24">
          <appkit-button data-testid="appkit-button" />
          {!isEmpty(walletAddress) && <appkit-network-button />}
          {errors.missingChain && (
            <Body3 className="text-text-negative">
              You need to connect with a wallet first
            </Body3>
          )}
        </div>

        <TextInput
          errorMessage="Missing wallet name"
          id="walletName"
          name="walletName"
          label="Wallet name"
          placeholder={getNiceWalletName(walletAddress, caipNetwork?.name)}
          value={walletName}
          onChange={onWalletNameChange}
          autoFocus={true}
        />
      </div>

      <IntegrationFormModalFooter>
        <Button variant="primary" loading={loading} type="submit" size="large">
          {loading ? 'Please wait...' : `Add Wallet`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
