import { SidebarProvider, ToastProvider } from '@cointracker/styleguide';
import { CtStatsigProvider } from 'components/statsig-helpers/CtStatsigProvider';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'src/app/router';
import { UserContext } from 'src/app/user/context';
import Helmet from 'src/common/Helmet';
import { CostBasisProvider } from 'src/components/CostBasisProvider';
import { useGetAppInfoQuery } from 'src/types/graphql-types';
import { CookieConsentWrapper } from '../components/CookieConsentWrapper';
import { FreezingStatusProvider } from '../components/FreezingStatusProvider';
import { CbeDemoProvider } from '../pages/Rebrand/CostBasisEngineDemo/cbeDemoContext';
import ConditionalAdoraProvider from './AdoraProvider';
import { AppKitProvider } from './AppKitProvider/AppKitProvider';
import AppThemeProvider from './AppThemeProvider';
import { AnalyticsProvider } from './analytics';
import CelloProvider from './cello/CelloProvider';
import { AppInfoContext } from './context/AppInfoContext';
import { router } from './router/Router';
import useGetUser from './user/hooks/useGetUser';
import './utils/dayjsConfig';

export const App = () => {
  const { data } = useGetAppInfoQuery();
  const appInfo = data
    ? {
        ...data.appInfo,
        isLoaded: true,
      }
    : { isLoaded: false };
  const { user } = useGetUser();

  return (
    <HelmetProvider>
      <Helmet />
      <AppInfoContext.Provider value={appInfo}>
        <AuthProvider>
          <UserContext.Provider value={user}>
            <AnalyticsProvider
              user={user}
              writeKey={process.env.SEGMENT_WEB_WRITE_KEY}
              mixpanelToken={process.env.PUBLIC_MIXPANEL_TOKEN}
            >
              <CtStatsigProvider>
                <CelloProvider
                  user={user}
                  celloProductId={process.env.PUBLIC_CELLO_PRODUCT_ID}
                  celloScriptUrl={process.env.PUBLIC_CELLO_SCRIPT_URL}
                >
                  <ConditionalAdoraProvider
                    adoraOrgId={process.env.PUBLIC_ADORA_ORG_ID}
                    user={user}
                  >
                    <AppThemeProvider>
                      <CbeDemoProvider>
                        <SidebarProvider>
                          <CookieConsentWrapper />
                          <ToastProvider>
                            <CostBasisProvider>
                              <AppKitProvider>
                                <FreezingStatusProvider>
                                  <RouterProvider router={router} />
                                </FreezingStatusProvider>
                              </AppKitProvider>
                            </CostBasisProvider>
                          </ToastProvider>
                        </SidebarProvider>
                      </CbeDemoProvider>
                    </AppThemeProvider>
                  </ConditionalAdoraProvider>
                </CelloProvider>
              </CtStatsigProvider>
            </AnalyticsProvider>
          </UserContext.Provider>
        </AuthProvider>
      </AppInfoContext.Provider>
    </HelmetProvider>
  );
};

export default App;
