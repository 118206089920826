import { useEffect, useRef, useState } from 'react';
import dayjs from 'src/app/utils/dayjsConfig';

/**
 * For more info:
 * https://www.notion.so/cointracker/Polling-Intervals-bcc47e27077c45c698f2953c8033b754
 */
export const POLLING_INTERVALS = [
  dayjs.duration(5, 'seconds'),
  dayjs.duration(10, 'seconds'),
  dayjs.duration(10, 'seconds'),
  dayjs.duration(15, 'seconds'),
  dayjs.duration(30, 'seconds'),
  dayjs.duration(1, 'minutes'),
  dayjs.duration(5, 'minutes'), // thats the default, slow rate of polling
];

interface useVariableSpeedPollingProps {
  pollingFn: () => void;
}
export const useVariableSpeedPolling = ({
  pollingFn,
}: useVariableSpeedPollingProps): {
  triggerFasterPolling: () => void;
  stopFasterPolling: () => void;
} => {
  const pollingIntervals = POLLING_INTERVALS.map((dur) => dur.asMilliseconds());

  const ticker = useRef<NodeJS.Timeout | null>(null);
  const [pollingIndex, setPollingIndex] = useState(pollingIntervals.length - 1);

  useEffect(() => {
    if (pollingIndex === pollingIntervals.length - 1) {
      ticker.current = setInterval(() => {
        pollingFn();
      }, pollingIntervals[pollingIndex]);
    } else {
      clearInterval(ticker.current);
      ticker.current = setTimeout(() => {
        pollingFn();
        setPollingIndex((i) => i + 1);
      }, pollingIntervals[pollingIndex]);
    }

    return () => {
      clearInterval(ticker.current);
    };
  }, [pollingFn, pollingIndex, pollingIntervals]);

  return {
    triggerFasterPolling: () => {
      setPollingIndex(0);
    },
    stopFasterPolling: () => {
      if (pollingIndex === pollingIntervals.length - 1) return;

      clearTimeout(ticker.current);
      setPollingIndex(pollingIntervals.length - 1);
    },
  };
};
