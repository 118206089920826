import { createContext, useContext, useEffect, useState } from 'react';
import { AuthenticatedUser, UserContext } from 'src/app/user/context';
import { GATES } from 'src/common/statsig_flags';
import { useGate } from 'statsig-react';
import {
  VIEW_MODES,
  clearStoredViewMode,
  getStoredCbeSettings,
  getStoredViewMode,
  setStoredCbeSettings,
  setStoredViewMode,
  type ViewMode,
} from './utils';

interface CbeDemoContext {
  enableCbe: boolean;
  viewMode: ViewMode | null;
  isBroker: boolean;
  isConsumer: boolean;
  changeViewMode: (forcedViewMode?: ViewMode) => void;
  canViewAuditTrail: boolean;
  VIEW_MODES: typeof VIEW_MODES;
  cbeSettings: {
    allowUserWalletConnect: boolean;
  };
  changeCbeSettings: (settings: { allowUserWalletConnect: boolean }) => void;
}

const CbeDemoContext = createContext<CbeDemoContext | null>(null);

export const CbeDemoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = useContext(UserContext);
  const { value: isAuditTrailEnabled } = useGate(GATES.audit_trail_access);
  // Initialize state from localStorage or defaults
  const [enableCbe, setEnableCbe] = useState<boolean>(
    (user as AuthenticatedUser)?.enableCbe,
  );
  const [cbeSettings, setCbeSettings] = useState<{
    allowUserWalletConnect: boolean;
  }>(() => {
    try {
      return JSON.parse(getStoredCbeSettings());
    } catch (error) {
      return { allowUserWalletConnect: false };
    }
  });

  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    return getStoredViewMode() as ViewMode;
  });

  // Save to localStorage whenever state changes
  useEffect(() => {
    if ((user as AuthenticatedUser)?.enableCbe !== enableCbe) {
      setEnableCbe((user as AuthenticatedUser)?.enableCbe);
    }
    if (
      !enableCbe &&
      !(user as AuthenticatedUser)?.enableCbe &&
      user.hasFetched &&
      user.isAuthenticated
    ) {
      // make sure to wipe out sessionStorage if CBE is disabled on both user and this context.
      clearStoredViewMode();
    }
  }, [enableCbe, (user as AuthenticatedUser)?.enableCbe]);

  useEffect(() => {
    if (getStoredViewMode() !== viewMode) {
      setViewMode((getStoredViewMode() as ViewMode) ?? VIEW_MODES.BROKER);
    }
  }, [user.hasFetched, user.isAuthenticated, user.isFetching]);

  /**
   * Change the view mode to the passed view mode or cycle through the options:
   *  Consumer > Broker > Consumer > ...
   *
   * @param forcedViewMode - Optional. The view mode to set. If not passed, the view mode will be cycled through the options.
   *
   * @returns Void
   * @example
   * ```tsx
   * const { changeViewMode, VIEW_MODES } = useCbeDemo();
   * changeViewMode(); // Cycles through the options Consumer > Broker > Consumer...
   * changeViewMode(VIEW_MODES.CONSUMER); // Sets the view mode to Consumer
   * ```
   */
  const changeViewMode = (forcedViewMode?: ViewMode) => {
    // Set the passed view mode or cycle through the options Consumer > Broker > Consumer...
    const newMode =
      forcedViewMode ??
      (viewMode === VIEW_MODES.CONSUMER
        ? VIEW_MODES.BROKER
        : VIEW_MODES.CONSUMER);
    setViewMode(newMode);
    setStoredViewMode(newMode);
  };

  const changeCbeSettings = (settings: { allowUserWalletConnect: boolean }) => {
    setCbeSettings(settings);
    setStoredCbeSettings(JSON.stringify(settings));
  };

  // can view audit trail if the user is an admin in debug mode viewing another user or the statsig gate is enabled for this user.
  const canViewAuditTrail =
    user.isAuthenticated && (!!user.viewAsUserId || isAuditTrailEnabled);

  const value = {
    enableCbe,
    canViewAuditTrail,
    isBroker: enableCbe && viewMode === VIEW_MODES.BROKER,
    isConsumer: enableCbe && viewMode === VIEW_MODES.CONSUMER,
    changeViewMode,
    VIEW_MODES,
    viewMode,
    cbeSettings,
    changeCbeSettings,
  };

  return (
    <CbeDemoContext.Provider value={value}>{children}</CbeDemoContext.Provider>
  );
};

export const useCbeDemo = () => {
  const context = useContext(CbeDemoContext);
  if (!context) {
    throw new Error('useCbeDemo must be used within a CbeDemoProvider');
  }
  return context;
};

export { CbeDemoContext };
