/**
 * Parses a cookie into a JSON object
 *
 * Example:
 * "pkg=math; equation=E%3Dmc%5E2" => { pkg: 'math', equation: 'E=mc^2' }
 */
export const parseCookie = (str: string) => {
  if (!str || str.length === 0) {
    return null;
  }

  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce(
      (acc, [key, value]) => {
        if (!key) return acc;
        acc[decodeURIComponent(key.trim())] = decodeURIComponent(
          value?.trim() ?? '',
        );
        return acc;
      },
      {} as { [key: string]: string },
    );
};

/**
 * Delete a field in the cookies
 * @param key - field to delete
 */
export const deleteCookie = (key: string) => {
  const cookie = parseCookie(document.cookie);
  if (cookie?.[key]) {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

export const setCookie = (key: string, value: string, days = 365) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${key}=${value};${expires};path=/`;
};

export const COOKIE_KEYS = {
  theme: 'ui_theme',
  partnerSlug: 'partner_slug',
  partnerUserId: 'partner_user_id',
  utmSource: 'utm_source',
};
