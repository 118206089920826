import SpinnerIcon from '../icons/spinner.svg?react';
import { cn } from '../utils';
import { indicatorVariants } from './indicatorVariants';

interface LoadingIndicatorProps {
  className?: string;
  transparent?: boolean | null | undefined;
  variant?: 'loading' | 'general' | 'basis' | 'sync';
  size?: number;
}
export const LoadingIndicator = ({
  className,
  variant = 'loading',
  transparent = false,
  size = 24,
  ...props
}: LoadingIndicatorProps) => {
  return (
    <SpinnerIcon
      className={cn(indicatorVariants({ variant, transparent }), className)}
      width={size}
      height={size}
      {...props}
    />
  );
};
