import { v4 as uuid } from 'uuid';
import { getCookie, setCookie } from '../cookies';

const STORAGE_KEYS = ['ajs_anonymous_id', 'anonymous_id'] as const;

function cleanAnonymousId(id: string): string {
  let result = id;
  try {
    // First try to decode any URL encoding
    result = decodeURIComponent(id);
  } finally {
    // Then remove any quotes
    result = result.replace(/['"]/g, '');
  }
  return result;
}

export function getAndSetAnonymousIdCookiesAndLocalStorage(): string {
  let anonymousId: string | null = null;

  // Try to get existing ID from storage
  for (const key of STORAGE_KEYS) {
    const storedId = getCookie(key) || localStorage?.getItem(key);
    if (storedId) {
      anonymousId = cleanAnonymousId(storedId);
      break;
    }
  }

  // Generate new ID if none found
  if (!anonymousId) {
    anonymousId = uuid();
  }

  // Store clean ID in all locations
  for (const key of STORAGE_KEYS) {
    localStorage?.setItem(key, anonymousId);
    setCookie(key, anonymousId, 30);
  }

  return anonymousId;
}
