import { Modal } from '@cointracker/styleguide';
import { type ReactNode } from 'react';

interface IntegrationFormModalFooterProps {
  children: ReactNode;
}
export const IntegrationFormModalFooter = ({
  children,
}: IntegrationFormModalFooterProps) => {
  return (
    <Modal.ContentFooter className="sticky -bottom-16 bg-background-card !pl-0 !pr-0">
      {children}
    </Modal.ContentFooter>
  );
};
