import { cn } from '../utils';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {}

function Skeleton({ className, style, ...props }: SkeletonProps) {
  return (
    <div
      className={cn(
        'from-background-mode-accents/40 to-background-card h-12 animate-pulse bg-gradient-to-r',
        className,
      )}
      style={style}
      {...props}
    />
  );
}

function convertToSizeStyle({
  height,
  width,
}: {
  height?: number | string;
  width?: number | string;
}) {
  return {
    height: typeof height === 'number' ? `${height}px` : height,
    width: typeof width === 'number' ? `${width}px` : width,
  };
}

interface SkeletonCircleProps extends SkeletonProps {
  size?: number | string;
}

function SkeletonCircle({
  className,
  size = 48,
  ...props
}: SkeletonCircleProps) {
  const style = convertToSizeStyle({ height: size, width: size });
  return <Skeleton className={cn(className)} style={style} {...props} />;
}

interface SkeletonLineProps extends SkeletonProps {
  height?: number | string;
  width?: number | string;
}

function SkeletonLine({
  className,
  height = 20,
  width = '100%',
  ...props
}: SkeletonLineProps) {
  const style = convertToSizeStyle({ height, width });
  return (
    <Skeleton
      className={cn(className)}
      {...props}
      style={{ ...props.style, ...style }}
      data-testid="skeleton-line"
    />
  );
}

interface SkeletonSquareProps extends SkeletonProps {
  size?: number | string;
}

function SkeletonSquare({ className, size, ...props }: SkeletonSquareProps) {
  const style = convertToSizeStyle({ height: size, width: size });
  return <Skeleton className={cn(className)} style={style} {...props} />;
}

export { Skeleton, SkeletonCircle, SkeletonLine, SkeletonSquare };
