import { Check, Minus } from '@phosphor-icons/react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type ReactNode, type Ref } from 'react';
import { bodyVariants } from '../LoggedIn/Typography/variants';
import { cn } from '../utils';

interface WrapperProps {
  label?: ReactNode;
  children: ReactNode;
  size: CheckboxVariants['size'];
}

const Wrapper = ({ label, size, children }: WrapperProps) =>
  label ? (
    <label
      className={cn(
        'text-text-secondary flex cursor-pointer select-none items-center gap-8',
        'has-[button[data-state=checked]]:text-text-primary',
        'has-[button[data-state=indeterminate]]:text-text-primary',
        'has-[button:hover]:text-text-primary',
        'has-[button:disabled]:!text-text-secondary-disabled',
        'has-[button:disabled]:cursor-default',
        size === 'small' && bodyVariants({ variant: 'body4' }),
        size === 'medium' && bodyVariants({ variant: 'body2' }),
      )}
    >
      {children}
      {label}
    </label>
  ) : (
    <>{children}</>
  );

const checkboxVariants = cva(
  [
    'border-line-secondary rounded-4 focus:!outline-background-mode-accents disabled:border-line-secondary-disabled flex items-center justify-center bg-transparent focus:!outline focus:!outline-2 disabled:bg-transparent',
    'data-[state=checked]:bg-background-inverse',
    'data-[state=checked]:border-background-inverse',
    'data-[state=checked]:hover:!bg-background-primary-hover',
    'data-[state=checked]:hover:!border-background-primary-hover',
    'data-[state=checked]:active:!bg-background-primary-pressed',
    'data-[state=checked]:active:!border-background-primary-pressed',
    'data-[state=checked]:disabled:bg-background-primary-disabled',
    'data-[state=checked]:disabled:border-background-primary-disabled',

    'data-[state=indeterminate]:bg-background-inverse',
    'data-[state=indeterminate]:border-background-inverse',
    'data-[state=indeterminate]:hover:!bg-background-primary-hover',
    'data-[state=indeterminate]:hover:!border-background-primary-hover',
    'data-[state=indeterminate]:active:!bg-background-primary-pressed',
    'data-[state=indeterminate]:active:!border-background-primary-pressed',
    'data-[state=indeterminate]:disabled:bg-background-primary-disabled',
    'data-[state=indeterminate]:disabled:border-background-primary-disabled',
  ],
  {
    variants: {
      size: {
        small: 'h-14 w-14 border outline-1',
        medium: 'h-20 w-20 border-2 outline-2',
      },
    },
    defaultVariants: { size: 'medium' },
  },
);

export type CheckboxVariants = VariantProps<typeof checkboxVariants>;
export type CheckboxProps = RadixCheckbox.CheckboxProps &
  CheckboxVariants & {
    label?: ReactNode;
  };

export const Checkbox = forwardRef(
  (
    { label, checked, id, className, size, ...props }: CheckboxProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <div className="flex items-center gap-8">
        <Wrapper label={label} size={size}>
          <RadixCheckbox.Root
            ref={ref}
            className={cn(checkboxVariants({ size }), className)}
            {...props}
            checked={checked}
            id={id}
          >
            <RadixCheckbox.Indicator className="text-icon-primary-foreground-inverse">
              {checked === 'indeterminate' ? (
                <Minus weight="bold" size={size === 'small' ? 11 : 16} />
              ) : (
                <Check weight="bold" size={size === 'small' ? 11 : 16} />
              )}
            </RadixCheckbox.Indicator>
          </RadixCheckbox.Root>
        </Wrapper>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
