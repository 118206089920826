import { isEmpty } from 'lodash-es';
import { Integration } from 'src/types/graphql-types';

const FIELD_REQUIRED_HINT = 'This field is required';
export type ValidationErrors = {
  walletAddress?: string;
  cryptoSelect?: string;
};

export const validateWalletAddress = (
  walletAddress: string,
): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!walletAddress || !walletAddress.trim()) {
    errors.walletAddress = FIELD_REQUIRED_HINT;
  }

  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};

export const validateCryptoSelector = (
  selectedCrypto: Integration,
): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!selectedCrypto) {
    errors.cryptoSelect = FIELD_REQUIRED_HINT;
  }
  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};
