import {
  InlineBanner,
  InlineBannerContent,
  Status,
  TextInput,
  ToastContext,
} from '@cointracker/styleguide';
import { Button } from '@cointracker/styleguide/src/LoggedIn';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import {
  ExchangeMutationErrors,
  GetWalletsQuery,
  Integration,
  useAddExchangeWithCustomerIdMutation,
  useUpdateExchangeWithCustomerIdMutation,
} from 'src/types/graphql-types';
import { AddWalletContext } from '../../context';

import { Info } from '@phosphor-icons/react';
import { GetWallets } from 'src/pages/Rebrand/Wallets/queries/getWallets.graphql';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';
import { emptyCacheQuery } from '../util';
import { isStringValid, validate, ValidationErrors } from './validate';

interface CustomerIdFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  isEditMode?: boolean;
  exchangeId?: string;
  redirectUrl?: string;
}

export const GENERIC_ERROR_MESSAGE =
  'There was a problem while trying to add your exchange. Try again or contact support';
export const CustomerIdForm = (props: CustomerIdFormProps) => {
  const integration = props.integration;

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [errorText, setErrorText] = useState('');
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { showNotification } = useContext(ToastContext);
  const { onSuccess } = useContext(AddWalletContext);
  const [addExchange, { loading }] = useAddExchangeWithCustomerIdMutation({
    onCompleted: (data) => {
      if (data.addExchangeWithCustomerId.success) {
        showNotification({
          status: Status.Success,
          message: `Added new exchange`,
        });
        onSuccess(
          data.addExchangeWithCustomerId.createdExchange,
          props.redirectUrl,
        );
        props.onCloseModal?.();
        return;
      }

      if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.AccountAlreadyLinked
      ) {
        setErrorText('Account is already linked');
      } else if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.InvalidAccount
      ) {
        setErrorText('Account is invalid');
      } else if (
        data.addExchangeWithCustomerId.error ===
        ExchangeMutationErrors.InvalidApiKey
      ) {
        setErrorText('API key is invalid');
      } else {
        setErrorText(GENERIC_ERROR_MESSAGE);
      }
    },
    update: (cache, { data }) => {
      if (!data.addExchangeWithCustomerId.success) {
        return;
      }
      const walletsData: GetWalletsQuery =
        cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
      const newExchanges = [
        ...walletsData.exchanges,
        data.addExchangeWithCustomerId.createdExchange,
      ];

      cache.writeQuery({
        query: GetWallets,
        data: { ...walletsData, exchanges: newExchanges },
      });
    },
    onError: () => {
      setErrorText(GENERIC_ERROR_MESSAGE);
    },
  });

  const [updateExchange, { loading: updateLoading }] =
    useUpdateExchangeWithCustomerIdMutation({
      onCompleted: (data) => {
        if (data.updateExchangeWithCustomerId.success) {
          showNotification({
            status: Status.Success,
            message: `Updated exchange details`,
          });
          onSuccess(
            data.updateExchangeWithCustomerId.updatedExchange,
            props.redirectUrl,
          );
          props.onCloseModal?.();
          return;
        }

        if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.AccountAlreadyLinked
        ) {
          setErrorText('Account is already linked');
        } else if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.InvalidAccount
        ) {
          setErrorText('Account is invalid');
        } else if (
          data.updateExchangeWithCustomerId.error ===
          ExchangeMutationErrors.InvalidApiKey
        ) {
          setErrorText('API key is invalid');
        } else {
          setErrorText(GENERIC_ERROR_MESSAGE);
        }
      },
      update: (cache, { data }) => {
        if (!data.updateExchangeWithCustomerId.success) {
          return;
        }
        const walletsData: GetWalletsQuery =
          cache.readQuery({ query: GetWallets }) || emptyCacheQuery;
        const newExchanges = walletsData.exchanges.map((exchange) => {
          if (
            exchange.id === data.updateExchangeWithCustomerId.updatedExchange.id
          ) {
            return data.updateExchangeWithCustomerId.updatedExchange;
          }
          return exchange;
        });

        cache.writeQuery({
          query: GetWallets,
          data: { ...walletsData, exchanges: newExchanges },
        });
      },
      onError: () => {
        setErrorText(GENERIC_ERROR_MESSAGE);
      },
    });

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const apiKey = formData.get('apiKey') as string;
      const apiSecret = formData.get('apiSecret') as string;
      const customerId = formData.get('customerId') as string;

      const errors = validate({ apiSecret, apiKey, customerId });
      if (!isEmpty(errors)) {
        setErrors(errors);
        return;
      }

      if (props.isEditMode && props.exchangeId) {
        updateExchange({
          variables: {
            updateExchangeInput: {
              apiKey,
              apiSecret,
              customerId,
              publicId: props.exchangeId,
            },
          },
        });
      } else {
        addExchange({
          variables: {
            addExchangeInput: {
              apiKey,
              apiSecret,
              customerId,
              accountTypeSlug: integration.slug,
            },
          },
        });
      }
    },
    [
      integration,
      addExchange,
      updateExchange,
      props.isEditMode,
      props.exchangeId,
    ],
  );

  const hasApiKeyError = errors.apiKeyMissing;
  const hasApiKeySecretError = errors.apiSecretMissing;
  const hasCustomerIdError = errors.customerIdMissing;

  const onApiKeyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiKey(e.target.value);
      setErrors({ ...errors, apiKeyMissing: !isStringValid(e.target.value) });
    },
    [errors],
  );

  const onApiKeySecretChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setApiSecret(e.target.value);
      setErrors({
        ...errors,
        apiSecretMissing: !isStringValid(e.target.value),
      });
    },
    [errors],
  );

  const onCustomerIdChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCustomerId(e.target.value);
      setErrors({
        ...errors,
        customerIdMissing: !isStringValid(e.target.value),
      });
    },
    [errors],
  );

  const isLoading = loading || updateLoading;

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-24">
        {errorText && (
          <InlineBanner variant="negative">
            <Info size={25} />
            <InlineBannerContent>{errorText}</InlineBannerContent>
          </InlineBanner>
        )}

        <TextInput
          id="customerId"
          name="customerId"
          onChange={onCustomerIdChange}
          value={customerId}
          label="Customer ID"
          hasError={hasCustomerIdError}
          errorMessage={'Field is required'}
          autoFocus={true}
        />

        <TextInput
          id="apiKey"
          name="apiKey"
          onChange={onApiKeyChange}
          value={apiKey}
          label="API Key"
          hasError={hasApiKeyError}
          errorMessage={'Field is required'}
        />

        <TextInput
          id="apiSecret"
          name="apiSecret"
          onChange={onApiKeySecretChange}
          value={apiSecret}
          label="API Secret"
          hasError={hasApiKeySecretError}
          errorMessage={'Field is required'}
        />
        <Instructions integration={integration} showCSVInstructions={false} />
      </div>

      <IntegrationFormModalFooter>
        <Button
          variant="primary"
          loading={isLoading}
          type="submit"
          size="large"
        >
          {loading ? 'Please wait...' : `Add ${integration.name}`}
        </Button>
      </IntegrationFormModalFooter>
    </form>
  );
};
