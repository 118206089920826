import { cn } from '@cointracker/styleguide';
import { buttonVariants } from '@cointracker/styleguide/src/LoggedIn';
import { Integration } from 'src/types/graphql-types';
import { Instructions } from '../Instructions';
import { IntegrationFormModalFooter } from '../IntegrationFormModalFooter';

interface PartnerRedirectFormProps {
  integration: Integration;
  redirectLink: string;
}

export const PartnerRedirectForm = (props: PartnerRedirectFormProps) => {
  const integration = props.integration;
  return (
    <>
      <Instructions integration={integration} showCSVInstructions={false} />
      <IntegrationFormModalFooter>
        <a
          href={props.redirectLink}
          className={cn(buttonVariants({ variant: 'primary', size: 'large' }))}
        >
          Continue with {integration.name}
        </a>
      </IntegrationFormModalFooter>
    </>
  );
};
