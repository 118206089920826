import { Checkbox, cn } from '@cointracker/styleguide';
import { Body3 } from '@cointracker/styleguide/src/LoggedIn';

interface SelectChainCardProps {
  name: string;
  value: string;
  selected: boolean;
  imageUrl: string;
  onChange: (value: boolean) => void;
}

export const SelectChainCard = ({
  name,
  value,
  selected,
  imageUrl,
  onChange,
}: SelectChainCardProps) => {
  return (
    <button
      className={cn(
        'flex h-[48px] w-full cursor-pointer flex-row items-center justify-between rounded-8 border-2 border-solid px-[14px] py-[16px]',
        selected ? 'border-line-secondary-pressed' : 'border-line-secondary',
        'mb-9',
      )}
      key={value}
      onClick={() => onChange(!selected)}
    >
      <div className="flex items-center">
        <img src={imageUrl} className="mr-8 w-[24px]" alt={name} />
        <Body3>{name}</Body3>
      </div>
      <div className="flex items-center">
        <Checkbox
          id="add-evm-wallets"
          name="add-evm-wallets"
          value="add-evm-wallets"
          checked={selected}
        />
      </div>
    </button>
  );
};
