import { cn } from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import {
  IntegrationCategories,
  IntegrationSyncMethod,
} from 'src/common/Integration';
import { Integration } from 'src/types/graphql-types';
import { DefaultGenericInstructions } from './DefaultGenericInstructions';
import { GenericCSVInstructions } from './GenericCSVInstructions';
import { parseInstructions } from './util';

export const InstructionCard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-24 rounded-8 bg-background-card-highlight p-16 empty:hidden">
      {children}
    </div>
  );
};

interface InstructionProps {
  integration: Integration;
  genericInstructions?: JSX.Element;
  showCSVInstructions?: boolean;
  isOnEditScreen?: boolean;
}

const getInstructionsHeader = (
  integration: Integration,
  isOnEditScreen = false,
) => {
  let integrationName = integration.name;
  if (integrationName.includes('Wallet')) {
    integrationName = integrationName.replace(' Wallet', '');
  }
  if (isOnEditScreen) {
    return `How to edit your ${integrationName} ${
      integration.category === IntegrationCategories.exchange
        ? 'account'
        : 'wallet'
    }`;
  }
  return `How to add your ${integrationName} ${
    integration.category === IntegrationCategories.exchange
      ? 'account'
      : 'wallet'
  }`;
};

export const Instructions = ({
  integration,
  genericInstructions,
  showCSVInstructions,
  isOnEditScreen,
}: InstructionProps) => {
  const { apiInstructions, fileInstructions } = parseInstructions(
    integration.instructions,
    integration.syncMethod as IntegrationSyncMethod,
  );

  if (showCSVInstructions) {
    if (fileInstructions) {
      return (
        <InstructionCard>
          <Body variant="body2" className="text-text-secondary">
            Steps to add your {integration.name} transactions:
          </Body>
          <div
            className={cn(
              'group',
              'prose max-w-none prose-headings:text-text-primary prose-strong:text-text-primary prose-ul:text-text-secondary',
              'prose-headings:text-text-primary',
              'prose-h3:text-heading6 md:prose-h3:text-heading6-md lg:prose-h3:text-heading6-lg',
              'prose-h4:text-heading6 md:prose-h4:text-heading6-md lg:prose-h4:text-heading6-lg',
              'prose-p:text-body3 prose-p:text-text-secondary md:prose-p:text-body3-md lg:prose-p:text-body3-lg',
              'prose-div:!text-text-secondary prose-a:text-text-link prose-a:hover:text-text-link-hover prose-li:text-text-secondary',
            )}
            dangerouslySetInnerHTML={{ __html: fileInstructions }}
          ></div>
        </InstructionCard>
      );
    }
    return (
      <InstructionCard>
        <GenericCSVInstructions integration={integration} />
      </InstructionCard>
    );
  }

  if (integration.instructions) {
    return (
      <InstructionCard>
        <Body variant="body2" className="text-text-secondary">
          {getInstructionsHeader(integration, isOnEditScreen)}
        </Body>
        <div
          className={cn(
            'group',
            'prose max-w-none prose-headings:text-text-primary prose-strong:text-text-primary prose-ul:text-text-secondary',
            'prose-headings:text-text-primary',
            'prose-h3:text-heading6 md:prose-h3:text-heading6-md lg:prose-h3:text-heading6-lg',
            'prose-h4:text-heading6 md:prose-h4:text-heading6-md lg:prose-h4:text-heading6-lg',
            'prose-p:text-body3 prose-p:text-text-secondary md:prose-p:text-body3-md lg:prose-p:text-body3-lg',
            'prose-a:text-text-link prose-a:hover:text-text-link-hover prose-li:text-text-secondary',
            '[&_.text-muted]:!text-text-secondary',
            '[&]:text-text-secondary [&_.alert-danger]:text-text-negative',
          )}
          dangerouslySetInnerHTML={{ __html: apiInstructions }}
        ></div>
      </InstructionCard>
    );
  }

  if (genericInstructions) {
    return <InstructionCard>{genericInstructions}</InstructionCard>;
  }

  return (
    <InstructionCard>
      <DefaultGenericInstructions integration={integration} />
    </InstructionCard>
  );
};
