import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cva } from 'class-variance-authority';
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react';
import { cn } from '../utils';

type TabVariants = 'default' | 'plain';

interface TabsProps extends TabsPrimitive.TabsProps {
  variant?: TabVariants;
}

const tabsListVariants = cva(
  'group relative inline-flex items-center justify-center',
  {
    variants: {
      variant: {
        default:
          'after:bg-line-secondary gap-24 after:absolute after:inset-x-0 after:bottom-0 after:h-[1px] after:w-full',
        plain: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const tabsTriggerVariants = cva(
  'group inline-flex items-center justify-center whitespace-nowrap',
  {
    variants: {
      variant: {
        default:
          'text-text-secondary data-[state=active]:text-text-primary data-[state=active]:border-b-line-card-highlight hover:border-b-line-secondary-hover focus:outline-accent-bold-blue active:border-b-line-secondary-pressed z-10 gap-8 border-b-2 border-b-transparent py-16',
        plain:
          'data-[state=active]:bg-background-expressive-pressed data-[state=active]:text-text-primary-consistent rounded-full px-12 py-8',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Tabs = TabsPrimitive.Root;

const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> & TabsProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabsListVariants({ variant }), className)}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & TabsProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabsTriggerVariants({ variant }), className)}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={cn('', className)} {...props} />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
