import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { type ComponentProps } from 'react';
import { DayPicker } from 'react-day-picker';

import { buttonVariants } from '../LoggedIn/Button';
import { cn } from '../utils';

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-12', className)}
      classNames={{
        months:
          'flex flex-col sm:flex-row space-y-16 sm:space-x-16 sm:space-y-0',
        month: 'space-y-16',
        caption: 'flex justify-center pt-16 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-4 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'line' }),
          'h-28 w-28 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-4',
        nav_button_next: 'absolute right-4',
        table: 'w-full border-collapse space-y-4',
        head_row: 'flex',
        head_cell:
          'text-text-secondary rounded-md w-36 font-normal text-[0.8rem]',
        row: 'flex w-full mt-8',
        cell: 'h-36 w-36 text-center p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'line' }),
          'h-36 w-36 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-background-expressive text-text-primary-foreground hover:bg-background-expressive-hover hover:text-text-primary-foreground focus:bg-background-expressive-pressed focus:text-text-primary-foreground',
        day_today: 'bg-background-mode-accents text-text-primary-foreground',
        day_outside:
          'day-outside text-text-secondary aria-selected:bg-accent/50 aria-selected:text-text-secondary',
        day_disabled: 'text-text-secondary opacity-50',
        day_range_middle:
          'aria-selected:bg-background-mode-accents aria-selected:text-text-primary-foreground-inverse',
        day_range_end:
          'day-range-end !bg-background-expressive-pressed dark:text-text-primary-foreground-inverse',
        day_range_start:
          'day-range-start !bg-background-expressive-pressed dark:text-text-primary-foreground-inverse',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ className, ...props }) => (
          <CaretLeft className={cn('h-16 w-16', className)} {...props} />
        ),
        IconRight: ({ className, ...props }) => (
          <CaretRight className={cn('h-16 w-16', className)} {...props} />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
