import { useCallback } from 'react';
import { logout } from 'src/app/router';

declare global {
  interface Window {
    mixpanel: {
      cookie: {
        clear: () => void;
      };
    };
  }
}

export function useOnLogout() {
  return useCallback((e?) => {
    e?.preventDefault();
    logout();
  }, []);
}
