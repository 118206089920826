import classNames from 'classnames';
import React, { forwardRef, type ReactNode, type Ref } from 'react';
import ArrowRight from '../icons/arrow-right.svg?react';
import Check from '../icons/check.svg?react';
import ChevronDownIcon from '../icons/chevron-down.svg?react';
import { NavLinkText } from '../typography';
import {
  checkStyle,
  navLink,
  rightArrowStyle,
  rightChevronStyle,
  type NavLinkVariants,
} from './NavLink.css';

type NavLinkProps = {
  children?: ReactNode;
  href?: string;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & NavLinkVariants;

export const NavLinkContent = ({
  variant,
  socialLink,
  children,
  withIcon,
}: NavLinkProps) => {
  if (variant === 'primary') {
    return (
      <>
        <NavLinkText variant="inherit">{children}</NavLinkText>
        {withIcon && <ChevronDownIcon className={rightChevronStyle} />}
      </>
    );
  }

  return (
    <>
      <NavLinkText variant="inherit">{children}</NavLinkText>
      {!socialLink &&
        (variant !== 'secondaryConfirm' ? (
          <ArrowRight className={rightArrowStyle} />
        ) : (
          <Check className={checkStyle} />
        ))}
    </>
  );
};

// Navlink icon works best when paired with the Radix dropdown component
export const NavLink = forwardRef(
  (
    {
      children,
      className,
      style,
      target,
      onClick,
      disabled,
      href,
      variant,
      withIcon,
      socialLink,
      ...props
    }: NavLinkProps,
    forwardedRef: Ref<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    if (href) {
      return (
        <a
          ref={forwardedRef as Ref<HTMLAnchorElement>}
          className={classNames(navLink({ variant, withIcon }), className)}
          style={style}
          href={href}
          target={target}
          {...props}
        >
          <NavLinkContent
            variant={variant}
            withIcon={withIcon}
            socialLink={socialLink}
          >
            {children}
          </NavLinkContent>
        </a>
      );
    }
    return (
      <button
        ref={forwardedRef as Ref<HTMLButtonElement>}
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={classNames(navLink({ variant, withIcon }), className)}
        style={style}
        {...props}
      >
        <NavLinkContent variant={variant} withIcon={withIcon}>
          {children}
        </NavLinkContent>
      </button>
    );
  },
);

NavLink.displayName = 'NavLink';
