import { Body } from '@cointracker/styleguide/src/LoggedIn';
import { Integration } from 'src/types/graphql-types';

export function GenericCSVInstructions(props: { integration: Integration }) {
  return (
    <>
      <Body variant="body2" className="text-text-secondary">
        Steps to add your {props.integration.name} transactions:
      </Body>
      <ol className="my-14 list-outside list-decimal pl-40">
        <li>
          Download the CSV export of your transactions{' '}
          {props.integration.websiteUrl ? (
            <span>
              from{' '}
              {
                <a href={props.integration.websiteUrl}>
                  {props.integration.name}
                </a>
              }
            </span>
          ) : (
            ''
          )}
        </li>
        <li>
          Re-format it into the CoinTracker CSV format{' '}
          <a href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format">
            like this
          </a>
        </li>
        <li>Import this file here</li>
      </ol>
      <span>
        For more details, you can follow{' '}
        <a href="https://support.cointracker.io/hc/en-us/articles/4413071299729-Converting-transaction-history-CSVs-to-the-CoinTracker-CSV-format">
          this guide
        </a>
        .
      </span>
    </>
  );
}
