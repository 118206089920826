import { cn, useSidebar } from '@cointracker/styleguide';
import { Body5, Button } from '@cointracker/styleguide/src/LoggedIn';
import { Gift } from '@phosphor-icons/react';

interface ReferralButtonProps {
  iconOnly?: boolean;
}

export const ReferralButton = ({ iconOnly = false }: ReferralButtonProps) => {
  const { isMobile } = useSidebar();
  return (
    <div
      className={cn(
        'relative flex items-center md:gap-6',
        '[&_div>div[class^=cello-]]:h-4 [&_div>div[class^=cello-]]:w-4',
        {
          '[&_div>div[class^=cello-]]:left-13 [&_div>div[class^=cello-]]:top-13':
            !isMobile,
          '[&_div>div[class^=cello-]]:right-15 [&_div>div[class^=cello-]]:top-12':
            isMobile,
        },
      )}
      data-cello-launcher=""
    >
      <Button
        variant="line"
        size="small"
        transparent={!isMobile}
        className="text-text-secondary hover:text-text-primary md:p-0"
      >
        <Gift />
        {!iconOnly && (
          <Body5
            className={cn(
              'transition-opacity group-data-[state=collapsed]:opacity-0',
              'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
            )}
          >
            Earn up to $500
          </Body5>
        )}
      </Button>
    </div>
  );
};
