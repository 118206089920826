import { UserFreezingStatus } from '@cointracker/graphql-types';
import { createContext } from 'react';

export type FreezingStatusContextType = {
  startTime: string | null;
  endTime: string | null;
  lastUpdatedAt: string | null;
  status: UserFreezingStatus | null;
  error: string | null;
  isAvailable: boolean | null;
  startFasterPollingForSyncCompletion: () => void;
};
export const FreezingStatusContext = createContext<FreezingStatusContextType>({
  startTime: null,
  endTime: null,
  lastUpdatedAt: null,
  status: null,
  error: null,
  isAvailable: null,
  startFasterPollingForSyncCompletion: () => {},
});
