import { clsx, type ClassValue } from 'clsx';
import React, { useEffect, useState } from 'react';
import { extendTailwindMerge } from 'tailwind-merge';
import { darkThemeClass } from './darkTheme.css';
import { lightThemeClass } from './lightTheme.css';

interface getInitialThemeProps {
  pathname: string;
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
  STILL = 'still',
}

export const isClient = () => {
  return typeof window !== 'undefined';
};

export const isServer = () => {
  return !isClient();
};

export const getInitialTheme = ({ pathname }: getInitialThemeProps) => {
  if (isServer()) return null;

  if (pathname.includes('enterprise')) {
    return Theme.DARK;
  }
  return Theme.LIGHT;
};

export const getTwTheme = () => {
  if (isServer()) return null;
  return document.body.classList.contains('dark') ? 'dark' : 'light';
};

export const addThemeClass = (theme: Theme) => {
  if (theme === Theme.LIGHT) {
    document.documentElement.classList.remove(darkThemeClass, 'dark');
    document.documentElement.classList.add(lightThemeClass);
  } else {
    document.documentElement.classList.remove(lightThemeClass);
    document.documentElement.classList.add(darkThemeClass, 'dark');
  }
};

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        {
          text: [
            'body1',
            'body2',
            'body3',
            'body4',
            'body5',
            'body1-lg',
            'body2-lg',
            'body3-lg',
            'body4-lg',
            'body5-lg',
            'body1-md',
            'body2-md',
            'body3-md',
            'body4-md',
            'body5-md',
            'heading1',
            'heading1-landing',
            'heading2',
            'heading3',
            'heading4',
            'heading5',
            'heading6',
            'heading1-landing-lg',
            'heading1-lg',
            'heading2-lg',
            'heading3-lg',
            'heading4-lg',
            'heading5-lg',
            'heading6-lg',
            'heading1-landing-md',
            'heading1-md',
            'heading2-md',
            'heading3-md',
            'heading4-md',
            'heading5-md',
            'heading6-md',
            'buttonText',
            'buttonText-md',
            'buttonText-lg',
            'navButtonText',
            'navButtonText-md',
            'navButtonText-lg',
            'navLinkText',
            'navLinkText-md',
            'navLinkText-lg',
            'number-h1',
            'number-h2',
            'number-h3',
            'number-h4',
            'number-h5',
            'number-h6',
            'number-body1',
            'number-body2',
            'number-body3',
            'number-body4',
            'number-body5',
            '12',
            '13',
            '14',
            '15',
            '16',
            '18',
            '20',
            '22',
            '24',
            '28',
            '30',
            '32',
            '34',
            '38',
            '40',
            '44',
            '46',
            '48',
            '52',
            '60',
            '68',
            '80',
            '82',
          ],
        },
      ],
      'text-color': [
        {
          text: [
            'text',
            'background',
            'icon',
            'line',
            'accent',
            'elevation',
            'transparency',
            'red',
            'green',
            'yellow',
            'blue',
            'lilac',
            'teal',
            'slate',
            'grey',
          ],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export const isChromaticEnv = () => {
  return process.env.IS_CHROMATIC && process.env.IS_CHROMATIC === 'true';
};

export const useScroll = (): number => {
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  useEffect(() => {
    let animationFrame: number;

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setScrollHeight(scrollTop);
      animationFrame = requestAnimationFrame(handleScroll);
    };

    handleScroll();
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return scrollHeight;
};

// Returns the scroll progress between min and max, with a range of 0 to 1
// (representing percentage).
export const useScrollRange = (min: number, max: number): number => {
  const [scrollProgress, setScrollProgress] = useState<number>(0);

  useEffect(() => {
    let animationFrame: number;

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const clampedScroll = Math.min(Math.max(scrollTop, min), max);
      setScrollProgress((clampedScroll - min) / (max - min));
      animationFrame = requestAnimationFrame(handleScroll);
    };

    handleScroll();
    return () => cancelAnimationFrame(animationFrame);
  }, [min, max]);

  return scrollProgress;
};

export const useScrollRangeForElement = (
  ref: React.RefObject<HTMLElement>,
  { marginTop = 0, marginBottom = 0 },
): number => {
  const [range, setRange] = useState<{ min: number; max: number }>({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    const updateRange = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        setRange({
          min: scrollTop + rect.top - marginTop,
          max: scrollTop + rect.bottom + marginBottom,
        });
      }
    };

    updateRange();
    window.addEventListener('resize', updateRange);
    return () => window.removeEventListener('resize', updateRange);
  }, [ref, marginTop, marginBottom]);

  return useScrollRange(range.min, range.max);
};

interface UseScrollDirectionProps {
  threshold?: number;
}

export const useScrollDirection = ({
  threshold = 0,
}: UseScrollDirectionProps): ScrollDirection => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.STILL,
  );
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrolledPastThreshold = currentScrollY > threshold;

      if (isScrolledPastThreshold && currentScrollY > lastScrollY) {
        setScrollDirection(ScrollDirection.DOWN);
      } else if (isScrolledPastThreshold && currentScrollY < lastScrollY) {
        setScrollDirection(ScrollDirection.UP);
      } else {
        setScrollDirection(ScrollDirection.STILL);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, threshold]);

  return scrollDirection;
};

export const getElementTop = (ref: React.RefObject<HTMLElement>): number => {
  if (!ref.current) return 0;
  const element = ref.current;
  const rect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return scrollTop + rect.top;
};

export const getElementBottom = (ref: React.RefObject<HTMLElement>): number => {
  if (!ref.current) return 0;
  const element = ref.current;
  const rect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return scrollTop + rect.bottom;
};

// Gets the page scroll height at which the element would be vertically centered
// on the screen
export const getElementScreenCenter = (
  ref: React.RefObject<HTMLElement>,
): number => {
  if (!ref.current) return 0;
  const element = ref.current;
  const rect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const windowHeight = window.innerHeight;
  return scrollTop + rect.top - (windowHeight / 2 - rect.height / 2);
};
