import { wrapCreateBrowserRouter } from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import ErrorPage from 'src/app/router/Error';
import { Root } from 'src/app/router/Root';
import { PageNotFound } from 'src/pages/404';
import { Routes } from './routes';

const routes = createRoutesFromElements(
  <Route element={<Root />} errorElement={<ErrorPage />}>
    {Routes}
    <Route path="*" element={<PageNotFound />} />
  </Route>,
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);
