import { QueryResult } from '@apollo/client';
import { BaseCurrency } from '@coin-tracker/number-formatter';
import { isEmpty } from 'lodash';
import dayjs from 'src/app/utils/dayjsConfig';
import { URLS } from 'src/common/urls';
import { replaceWalletNameIfNeeded } from 'src/common/utils';
import {
  Balance,
  CurrencyType,
  CustomWallet,
  Exact,
  Exchange,
  GetCustomWalletDetailsQuery,
  GetExchangeDetailsQuery,
  GetWalletDetailsQuery,
  GetWalletsForWalletListQuery,
  OtherTransactions,
  Token,
  Wallet,
} from 'src/types/graphql-types';
import { formatWalletLabel } from '../../../../common/wallet-address';
import { isChromaticEnv } from '../../../../helpers/isChromatic';
import { formatValueFromCurrency } from '../utils';
import { WalletAssetColumn } from './types';

export function isWallet(
  wallet: Wallet | CustomWallet | Exchange | OtherTransactions,
): wallet is Wallet {
  if ((wallet as Wallet)?.__typename == 'Wallet') {
    return true;
  }
  return false;
}

export function isCustomWallet(
  wallet: Wallet | CustomWallet | Exchange | OtherTransactions,
): wallet is CustomWallet {
  if ((wallet as CustomWallet)?.__typename === 'CustomWallet') {
    return true;
  }
  return false;
}

export function isOtherTransactions(
  wallet: Wallet | CustomWallet | Exchange | OtherTransactions,
): wallet is OtherTransactions {
  if ((wallet as OtherTransactions)?.__typename === 'OtherTransactions') {
    return true;
  }
  return false;
}

export function isExchange(
  wallet: Wallet | CustomWallet | Exchange | OtherTransactions,
): wallet is Exchange {
  if ((wallet as Exchange)?.__typename === 'Exchange') {
    return true;
  }
  return false;
}

export function getWalletName(wallet: Wallet | CustomWallet | Exchange) {
  if (!wallet) {
    return null;
  }
  if (isExchange(wallet) || isCustomWallet(wallet)) {
    return wallet.name;
  }

  return (
    wallet.name ||
    `${wallet.currency?.symbol} ${formatWalletLabel(wallet?.address || 'Wallet')}`
  );
}

export function getWalletAssetDataFromWallet(
  wallet: Wallet,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  const reportedValue = wallet.value;
  // "Current" values are those that are shown to user on main screen
  const currentValue = showCalculatedBalances
    ? wallet.calculatedValue
    : reportedValue;
  const currentBalance = showCalculatedBalances
    ? wallet.calculatedBalance
    : wallet.balance;
  return {
    type: wallet.__typename,
    id: wallet.id,
    address: wallet.address,
    name: replaceWalletNameIfNeeded(wallet.name ?? wallet.currency?.symbol),
    parentAccountName: wallet.parentAccountName,
    currency: wallet.currency,
    value: reportedValue,
    reportedHoldingsValue: formatValueFromCurrency(reportedValue, baseCurrency),
    calculatedHoldingsValue: formatValueFromCurrency(
      wallet.calculatedValue,
      baseCurrency,
    ),
    calculatedValue: wallet.calculatedValue,
    balance: wallet.balance,
    calculatedBalance: wallet.calculatedBalance,
    isBalanceDifferenceSignificant: wallet.isBalanceDifferenceSignificant,
    currentHoldingsValue: formatValueFromCurrency(currentValue, baseCurrency),
    currentHoldingsBalance: formatValueFromCurrency(
      currentBalance,
      wallet.currency as BaseCurrency,
    ),
    holdingsValueDifference: formatValueFromCurrency(
      reportedValue - wallet.calculatedValue,
      baseCurrency,
    ),
    valueDifference: wallet.calculatedValue - reportedValue,
    // the old code was making a distinction when rendering wallets inside exchanges vs rendering wallets so this is
    // kept as it was.
    numberOfTransactions: wallet.computedCryptoTransactionCount,
    url: `${URLS.TRANSACTIONS_PAGE}?q=wallet:${wallet.id}`,
    isMining: wallet.isMining,
    canToggleMining: true,
    numManualEdits: wallet.numManualEdits ?? 0,
  };
}

export function getWalletAssetDataFromExchangeWallet(
  wallet: Wallet,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  return {
    ...getWalletAssetDataFromWallet(
      wallet,
      baseCurrency,
      showCalculatedBalances,
    ),
    type: 'Exchange',
    // wallets inside exchanges might also contain fiats so we show the total number
    numberOfTransactions: wallet.computedTransactionCount,
  };
}

export function getWalletAssetDataFromCustomWalletBalances(
  wallet: CustomWallet,
  balance: Balance,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  const reportedValue = balance.value;
  const currentValue = showCalculatedBalances
    ? balance.calculatedValue
    : balance.value;
  const currentBalance = showCalculatedBalances
    ? balance.calculatedBalance
    : balance.balance;
  return {
    type: wallet.__typename,
    id: balance.sanitizedCryptoSymbol,
    address: 'custom wallet',
    name: balance.name ?? balance.currency.symbol,
    value: reportedValue,
    reportedHoldingsValue: formatValueFromCurrency(reportedValue, baseCurrency),
    calculatedHoldingsValue: formatValueFromCurrency(
      balance.calculatedValue,
      baseCurrency,
    ),
    calculatedValue: balance.calculatedValue,
    balance: balance.balance,
    calculatedBalance: balance.calculatedBalance,
    isBalanceDifferenceSignificant: false,
    currency: balance.currency,
    currentHoldingsValue: formatValueFromCurrency(currentValue, baseCurrency),
    currentHoldingsBalance: formatValueFromCurrency(
      currentBalance,
      balance.currency as BaseCurrency,
    ),
    holdingsValueDifference: formatValueFromCurrency(
      reportedValue - balance.calculatedValue,
      baseCurrency,
    ),
    valueDifference: balance.calculatedValue - reportedValue,
    numberOfTransactions: balance.entryCount ?? 0,
    url: `${URLS.TRANSACTIONS_PAGE}?q=wallet:${wallet.id}+${balance.sanitizedCryptoSymbol}`,
    canToggleMining: false,
  };
}

export function getWalletAssetDataFromWalletToken(
  wallet: Wallet,
  token: Token,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  let tokenCurrency = token.currency as BaseCurrency;
  if (!token.currency && token.sanitizedCryptoSymbol) {
    tokenCurrency = {
      symbol: token.sanitizedCryptoSymbol,
      type: CurrencyType.Crypto,
      priceUsd: null,
    };
  }
  const reportedValue = token.value;
  const currentValue = showCalculatedBalances
    ? token.calculatedValue
    : token.value;
  const currentBalance = showCalculatedBalances
    ? token.calculatedBalance
    : token.balance;

  const url = getUrlForToken(token, wallet);
  return {
    type: wallet.__typename,
    id: token.sanitizedCryptoSymbol,
    address: wallet.address,
    name: replaceWalletNameIfNeeded(token.sanitizedCryptoSymbol),
    parentAccountName: wallet.parentAccountName,
    value: reportedValue,
    reportedHoldingsValue: formatValueFromCurrency(reportedValue, baseCurrency),
    calculatedHoldingsValue: formatValueFromCurrency(
      token.calculatedValue,
      baseCurrency,
    ),
    calculatedValue: token.calculatedValue,
    balance: token.balance,
    calculatedBalance: token.calculatedBalance,
    isBalanceDifferenceSignificant: token.isBalanceDifferenceSignificant,
    currentHoldingsValue: formatValueFromCurrency(currentValue, baseCurrency),
    currentHoldingsBalance: formatValueFromCurrency(
      currentBalance,
      tokenCurrency,
    ),
    holdingsValueDifference: formatValueFromCurrency(
      reportedValue - token.calculatedValue,
      baseCurrency,
    ),
    valueDifference: token.calculatedValue - reportedValue,
    currency: token.currency,
    url: url,
    imgUrl:
      token.currency &&
      token.currency.iconUrlFromInfo &&
      token.currency.iconUrlFromInfo,
    numberOfTransactions: token.computedTransactionCount,
    canToggleMining: false,
  };
}

export function getWalletAssetDataFromOtherTransactionBalance(
  wallet: OtherTransactions,
  balance: Balance,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  const reportedValue = balance.value;
  const currentValue = showCalculatedBalances
    ? balance.calculatedValue
    : balance.value;
  const currentBalance = showCalculatedBalances
    ? balance.calculatedBalance
    : balance.balance;
  return {
    id: balance.sanitizedCryptoSymbol,
    name: balance.name ?? balance.currency.symbol,
    value: reportedValue,
    reportedHoldingsValue: formatValueFromCurrency(reportedValue, baseCurrency),
    calculatedHoldingsValue: formatValueFromCurrency(
      balance.calculatedValue,
      baseCurrency,
    ),
    calculatedValue: balance.calculatedValue,
    balance: balance.balance,
    calculatedBalance: balance.calculatedBalance,
    isBalanceDifferenceSignificant: false,
    currency: balance.currency,
    currentHoldingsValue: formatValueFromCurrency(currentValue, baseCurrency),
    currentHoldingsBalance: formatValueFromCurrency(
      currentBalance,
      balance.currency as BaseCurrency,
    ),
    holdingsValueDifference: formatValueFromCurrency(
      reportedValue - balance.calculatedValue,
      baseCurrency,
    ),
    numberOfTransactions: balance.entryCount ?? 0,
    url: `${URLS.TRANSACTIONS_PAGE}?q=is:uncategorized+${balance.sanitizedCryptoSymbol}`,
    canToggleMining: false,
  };
}

export function getWalletAssetDataFromUserAssetBalance(
  wallet: Wallet | CustomWallet | OtherTransactions,
  balance: Balance,
  baseCurrency: BaseCurrency,
  showCalculatedBalances: boolean,
): WalletAssetColumn {
  const reportedValue = balance.value;
  const currentValue = showCalculatedBalances
    ? balance.calculatedValue
    : balance.value;
  const currentBalance = showCalculatedBalances
    ? balance.calculatedBalance
    : balance.balance;
  let url;
  if (isWallet(wallet) || isCustomWallet(wallet)) {
    url = `${URLS.TRANSACTIONS_PAGE}?q=wallet:${wallet.id}+asset_id:${balance.currency.uaid}`;
  } else {
    url = `${URLS.TRANSACTIONS_PAGE}?q=is:uncategorized+asset_id:${balance.currency.uaid}`;
  }

  return {
    id: balance.sanitizedCryptoSymbol,
    name: balance.name ?? balance.currency.symbol,
    value: reportedValue,
    parentAccountName: wallet.parentAccountName,
    reportedHoldingsValue: formatValueFromCurrency(reportedValue, baseCurrency),
    calculatedHoldingsValue: formatValueFromCurrency(
      balance.calculatedValue,
      baseCurrency,
    ),
    calculatedValue: balance.calculatedValue,
    balance: balance.balance,
    calculatedBalance: balance.calculatedBalance,
    isBalanceDifferenceSignificant: false,
    currency: balance.currency,
    currentHoldingsValue: formatValueFromCurrency(currentValue, baseCurrency),
    currentHoldingsBalance: formatValueFromCurrency(
      currentBalance,
      balance.currency as BaseCurrency,
    ),
    holdingsValueDifference: formatValueFromCurrency(
      reportedValue - balance.calculatedValue,
      baseCurrency,
    ),
    valueDifference: balance.calculatedValue - reportedValue,
    numberOfTransactions: balance.entryCount ?? 0,
    url,
    // custom user assets will be returned as Crypto types without a cmcId so this is the currently best way to handle this
    imgUrl: null,
    canToggleMining: false,
  };
}

export const isWalletOrExchangeSyncing = (
  walletOrExchange: Wallet | Exchange | CustomWallet | OtherTransactions,
): walletOrExchange is Exchange | Wallet | CustomWallet => {
  if (isExchange(walletOrExchange)) {
    return isExchangeSyncing(walletOrExchange);
  }
  if (isWallet(walletOrExchange)) {
    return isWalletSyncing(walletOrExchange);
  }
  if (isCustomWallet(walletOrExchange)) {
    return isCustomWalletSyncing(walletOrExchange);
  }
  return false;
};

export const isExchangeSyncing = (exchange: Exchange) => {
  const isSyncing =
    exchange.syncInfo?.isSyncing || exchange.syncInfo?.willBeDeleted;
  if (isSyncing) {
    return true;
  }
  return didSyncLessThan10SecondsAgo(exchange);
};

export const isWalletSyncing = (localWallet: Wallet) => {
  const isSyncing =
    localWallet.syncInfo?.isSyncing || localWallet.syncInfo?.willBeDeleted;
  if (isSyncing) {
    return true;
  }

  return didSyncLessThan10SecondsAgo(localWallet);
};

export const isCustomWalletSyncing = (customWallet: CustomWallet) => {
  if (customWallet.syncInfo?.willBeDeleted) {
    return true;
  }
  return didSyncLessThan10SecondsAgo(customWallet);
};

export const didSyncLessThan10SecondsAgo = (
  walletOrExchange: Exchange | Wallet | CustomWallet,
) => {
  if (walletOrExchange.syncInfo?.lastSynced) {
    const timeElapsedSinceLastSync = dayjs().diff(
      dayjs(walletOrExchange.syncInfo.lastSynced),
    );
    return timeElapsedSinceLastSync < 10000;
  }
  return false;
};

export const getCurrentQuery = (
  walletOrExchange: Exchange | Wallet | CustomWallet | OtherTransactions,
  exchangeQuery: QueryResult<
    GetExchangeDetailsQuery,
    Exact<{
      publicId: string;
    }>
  >,
  walletQuery: QueryResult<
    GetWalletDetailsQuery,
    Exact<{
      publicId: string;
    }>
  >,
  customWalletQuery: QueryResult<
    GetCustomWalletDetailsQuery,
    Exact<{
      publicId: string;
    }>
  >,
) => {
  if (isExchange(walletOrExchange)) {
    return exchangeQuery;
  }
  if (isWallet(walletOrExchange)) {
    return walletQuery;
  }
  if (isCustomWallet(walletOrExchange)) {
    return customWalletQuery;
  }
  return null;
};

export const pollCurrentQuery = (
  currentQuery: QueryResult<
    | GetExchangeDetailsQuery
    | GetCustomWalletDetailsQuery
    | GetWalletDetailsQuery,
    Exact<{
      publicId: string;
    }>
  > | null,
  isWalletSyncing: boolean,
) => {
  if (
    !currentQuery ||
    isEmpty(currentQuery.data) ||
    currentQuery.error ||
    currentQuery.loading
  ) {
    return;
  }

  if (isWalletSyncing && !isChromaticEnv()) {
    currentQuery.startPolling(5000);
  } else {
    currentQuery.stopPolling();
  }
};

const getUrlForToken = (token: Token, wallet: Wallet) => {
  if (!token?.currency?.uaid) {
    return `${URLS.TRANSACTIONS_PAGE}?q=wallet:${wallet.id}`;
  } else {
    const currencyPrefix = getCurrencyPrefixFromUaid(token.currency.uaid);
    return `${URLS.TRANSACTIONS_PAGE}?q=wallet:${wallet.id}+${currencyPrefix}${token.currency.uaid}`;
  }
};

const getCurrencyPrefixFromUaid = (uaid: string) => {
  if (uaid.startsWith('crypto_')) {
    return 'crypto_id:';
  } else if (uaid.startsWith('fiat_')) {
    return 'fiat_id:';
  } else if (uaid.startsWith('user_asset_')) {
    return 'user_asset_id:';
  } else if (uaid.startsWith('nft_')) {
    return 'nft_id:';
  } else if (uaid.startsWith('blockchain_')) {
    return 'blockchain_token_id:';
  }
};

export const isAnyAssetSyncing = (
  data: GetWalletsForWalletListQuery,
  isCostBasisRunning: boolean,
) => {
  if (isCostBasisRunning) {
    return true;
  }
  if (!data) {
    return false;
  }
  const isAnyExchangeSyncing = data.exchanges?.some(isExchangeSyncing);
  if (isAnyExchangeSyncing) {
    return true;
  }

  const isAnyLocalWalletSyncing = data.localWallets?.some(isWalletSyncing);
  if (isAnyLocalWalletSyncing) {
    return true;
  }

  const isAnyCustomWalletSyncing = data.customWallets?.some(
    isCustomWalletSyncing,
  );
  if (isAnyCustomWalletSyncing) {
    return true;
  }

  return false;
};
