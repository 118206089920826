import { cn } from '@cointracker/styleguide';

interface ProgressDonutProps {
  step: number;
  maxSteps: number;
  className?: string;
  size?: number;
  strokeWidth?: number;
}

export function ProgressDonut({
  step,
  maxSteps,
  className,
  size = 16,
  strokeWidth = 5,
}: ProgressDonutProps) {
  const progress = Math.min(Math.max(step / maxSteps, 0), 1);

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference * (1 - progress);

  return (
    <div className={cn('relative', className)}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className="-rotate-90 transform"
      >
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          className={cn(`stroke-accent-subtle-slate`)}
        />

        {/* Progress circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          className={cn(`stroke-line-positive`)}
        />
      </svg>
    </div>
  );
}
