import classNames from 'classnames';
import { forwardRef } from 'react';
import {
  body1,
  body2,
  body3,
  body4,
  body5,
  buttonText,
  h1,
  h1landing,
  h2,
  h3,
  h4,
  h5,
  h6,
  navButtonText,
  navLinkText,
  type Body1Variant,
  type Body2Variant,
  type Body3Variant,
  type Body4Variant,
  type Body5Variant,
  type ButtonTextVariant,
  type H1Variant,
  type H2Variant,
  type H3Variant,
  type H4Variant,
  type H5Variant,
  type H6Variant,
  type NavButtonTextVariant,
  type NavLinkTextVariant,
} from './Typography.css';

type TypographyProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const H1Landing = forwardRef<
  HTMLHeadingElement,
  TypographyProps & H1Variant
>(({ children, className, style, ...props }, ref) => (
  <h1
    ref={ref}
    className={classNames([h1landing({ ...props }), className])}
    style={style}
  >
    {children}
  </h1>
));

H1Landing.displayName = 'H1Landing';

export const H1 = forwardRef<HTMLHeadingElement, TypographyProps & H1Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h1
      ref={ref}
      className={classNames([h1({ ...props }), className])}
      style={style}
    >
      {children}
    </h1>
  ),
);

H1.displayName = 'H1';
export const H2 = forwardRef<HTMLHeadingElement, TypographyProps & H2Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h2
      ref={ref}
      className={classNames([h2({ ...props }), className])}
      style={style}
    >
      {children}
    </h2>
  ),
);

H2.displayName = 'H2';

export const H3 = forwardRef<HTMLHeadingElement, TypographyProps & H3Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h3
      ref={ref}
      className={classNames([h3({ ...props }), className])}
      style={style}
    >
      {children}
    </h3>
  ),
);

H3.displayName = 'H3';

export const H4 = forwardRef<HTMLHeadingElement, TypographyProps & H4Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h4
      ref={ref}
      className={classNames([h4({ ...props }), className])}
      style={style}
    >
      {children}
    </h4>
  ),
);

H4.displayName = 'H4';

export const H5 = forwardRef<HTMLHeadingElement, TypographyProps & H5Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h5
      ref={ref}
      className={classNames([h5({ ...props }), className])}
      style={style}
    >
      {children}
    </h5>
  ),
);

H5.displayName = 'H5';

export const H6 = forwardRef<HTMLHeadingElement, TypographyProps & H6Variant>(
  ({ children, className, style, ...props }, ref) => (
    <h6
      ref={ref}
      className={classNames([h6({ ...props }), className])}
      style={style}
    >
      {children}
    </h6>
  ),
);

H6.displayName = 'H6';

export const Body1 = forwardRef<
  HTMLParagraphElement,
  TypographyProps & Body1Variant
>(({ children, className, style, ...props }, ref) => (
  <p
    ref={ref}
    className={classNames([body1({ ...props }), className])}
    style={style}
  >
    {children}
  </p>
));

Body1.displayName = 'Body1';

export const Body2 = forwardRef<
  HTMLParagraphElement,
  TypographyProps & Body2Variant
>(({ children, className, style, ...props }, ref) => (
  <p
    ref={ref}
    className={classNames([body2({ ...props }), className])}
    style={style}
  >
    {children}
  </p>
));

Body2.displayName = 'Body2';

export const Body3 = forwardRef<
  HTMLParagraphElement,
  TypographyProps & Body3Variant
>(({ children, className, style, ...props }, ref) => (
  <p
    ref={ref}
    className={classNames([body3({ ...props }), className])}
    style={style}
  >
    {children}
  </p>
));

Body3.displayName = 'Body3';

export const Body4 = forwardRef<
  HTMLParagraphElement,
  TypographyProps & Body4Variant
>(({ children, className, style, ...props }, ref) => (
  <p
    ref={ref}
    className={classNames([body4({ ...props }), className])}
    style={style}
  >
    {children}
  </p>
));

Body4.displayName = 'Body4';

export const Body5 = forwardRef<
  HTMLParagraphElement,
  TypographyProps & Body5Variant
>(({ children, className, style, ...props }, ref) => (
  <p
    ref={ref}
    className={classNames([body5({ ...props }), className])}
    style={style}
  >
    {children}
  </p>
));

Body5.displayName = 'Body5';

export const ButtonText = forwardRef<
  HTMLSpanElement,
  TypographyProps & ButtonTextVariant
>(({ children, className, style, ...props }, ref) => (
  <span
    ref={ref}
    className={classNames([buttonText({ ...props }), className])}
    style={style}
  >
    {children}
  </span>
));

ButtonText.displayName = 'ButtonText';

export const NavButtonText = forwardRef<
  HTMLSpanElement,
  TypographyProps & NavButtonTextVariant
>(({ children, className, style, ...props }, ref) => (
  <span
    ref={ref}
    className={classNames([navButtonText({ ...props }), className])}
    style={style}
  >
    {children}
  </span>
));

NavButtonText.displayName = 'NavButtonText';

export const NavLinkText = forwardRef<
  HTMLSpanElement,
  TypographyProps & NavLinkTextVariant
>(({ children, className, style, ...props }, ref) => (
  <span
    ref={ref}
    className={classNames([navLinkText({ ...props }), className])}
    style={style}
  >
    {children}
  </span>
));

NavLinkText.displayName = 'NavLinkText';
