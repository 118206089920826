import PropTypes from 'prop-types';

const META_TITLE = 'Crypto Tax and Portfolio Software';
const META_DESCRIPTION =
  'CoinTracker is the most trusted cryptocurrency tax and portfolio management software. Automatically connect Coinbase, Binance, and all other exchanges & wallets.';
const META_IMAGE =
  'https://cdn.cointracker.io/static/images/social/cointracker.png?v=1';
const META_SHARE_URL = 'https://www.cointracker.io';

export interface HeadProps {
  title?: string;
  description?: string;
  image?: string;
  shareUrl?: string;
  useFlatTitle?: boolean;
  canonicalUrl?: string | null;
  // graphiteLDJSON is a custom object we can pass in to apply a graphite ld+json schema to the page.
  // This is used for the price page to help discover related coins for our related coins API
  graphiteLDJSON?: {
    title?: string;
    url?: string;
    content?: string;
  };
  graphiteTags?: {
    [key: string]: string;
  };
}
export const Head = (
  {
    title = META_TITLE,
    description = META_DESCRIPTION,
    image = META_IMAGE,
    shareUrl = META_SHARE_URL,
    useFlatTitle = false,
    canonicalUrl,
    graphiteLDJSON = {},
    graphiteTags,
  }: HeadProps = {
    title: META_TITLE,
    description: META_DESCRIPTION,
    image: META_IMAGE,
    shareUrl: META_SHARE_URL,
    useFlatTitle: false,
    canonicalUrl: null,
    graphiteLDJSON: {},
  },
): JSX.Element => {
  title = useFlatTitle ? title : `${title} | CoinTracker`;
  const LD_JSON = {
    '@context': 'https://graphite.io/ns',
    '@type': 'WebPage',
    ...graphiteLDJSON,
  };

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Canonical Url */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* Apple app store */}
      <meta name="apple-itunes-app" content="app-id=1401499763" />

      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content="CoinTracker" />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={shareUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {graphiteTags &&
        Object.entries(graphiteTags).map(([key, value]) => {
          const label = `graphite:${key}`;
          return (
            <meta key={label} name={label} property={label} content={value} />
          );
        })}
      {graphiteLDJSON?.url && (
        <script type="application/ld+json">
          {JSON.stringify(LD_JSON, null, 2)}
        </script>
      )}
    </>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  shareUrl: PropTypes.string,
};
