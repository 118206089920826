import {
  Tabs,
  TabsList,
  TabsTrigger,
} from '@cointracker/styleguide/src/LoggedIn/Tabs';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface FileAPISwitcherProps {
  children: ReactNode[];
  hasCustomFileImporter?: boolean;
}

export enum APITabs {
  AutomaticSync = 'automaticSync',
  CSVImport = 'CSVImport',
}

export default function FileAPISwitcher({
  children,
  hasCustomFileImporter,
}: FileAPISwitcherProps) {
  const csvTabTitle = hasCustomFileImporter ? 'Custom file' : 'CoinTracker CSV';
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<APITabs>(
    APITabs.AutomaticSync,
  );

  useEffect(() => {
    if (searchParams.get('csv')) {
      setSelectedTab(APITabs.CSVImport);
    }
  }, []);

  const onTabChange = (val: APITabs) => {
    if (val === APITabs.AutomaticSync) {
      searchParams.delete('csv');
      setSearchParams(searchParams);
    }
    setSelectedTab(val);
  };
  return (
    <Tabs value={selectedTab} onValueChange={onTabChange}>
      <TabsList>
        <TabsTrigger asChild value={APITabs.AutomaticSync}>
          <span>Automatic sync</span>
        </TabsTrigger>
        <TabsTrigger asChild value={APITabs.CSVImport}>
          <span>{csvTabTitle}</span>
        </TabsTrigger>
      </TabsList>
      {children}
    </Tabs>
  );
}
