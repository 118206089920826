import classNames from 'classnames';
import {
  useCallback,
  useState,
  type ChangeEvent,
  type InputHTMLAttributes,
  type ReactNode,
} from 'react';
import SearchIcon from '../icons/search.svg?react';
import ClearIcon from '../icons/x-circle.svg?react';
import { body2 } from '../typography/Typography.css';
import {
  clearButton,
  clearIcon,
  erroredInputContainer,
  inputContainer,
  prefixContainer,
  searchIcon,
  searchInputContainer,
  styledInput,
  styledInputWithPrefix,
  styledInputWithSuffix,
  suffixContainer,
} from './SearchInput.css';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  errored?: boolean;
  onClear?: () => void;
  prefixNode?: ReactNode;
  suffixNode?: ReactNode;
  withSearchIcon?: boolean;
}

export const SearchInput = ({
  value: controlledValue,
  errored,
  onClear,
  onChange,
  defaultValue,
  prefixNode,
  suffixNode,
  className,
  withSearchIcon = true,
  ...props
}: SearchInputProps) => {
  const [value, setValue] = useState(defaultValue || '');

  const onValueChange = useCallback(
    (newValue: string, e?: ChangeEvent<HTMLInputElement>) => {
      if (!controlledValue) {
        setValue(newValue);
      }

      if (onChange && e) {
        onChange(e);
      }
    },
    [controlledValue, onChange],
  );

  const onClearClick = () => {
    onValueChange('');
    if (onClear) {
      onClear();
    }
  };

  const inputValue = controlledValue ?? value;

  return (
    <div className={classNames(searchInputContainer, className)}>
      {prefixNode && <div className={prefixContainer}>{prefixNode}</div>}
      <div
        className={classNames(inputContainer, {
          [styledInputWithPrefix]: !!prefixNode,
          [styledInputWithSuffix]: !!suffixNode,
          [erroredInputContainer]: errored,
        })}
      >
        {withSearchIcon && (
          <SearchIcon className={searchIcon} width={18} height={18} />
        )}
        <input
          value={inputValue}
          placeholder={props.placeholder ?? 'Search'}
          className={classNames(body2(), styledInput, {
            [styledInputWithPrefix]: !!prefixNode,
            [styledInputWithSuffix]: !!suffixNode,
          })}
          onChange={(e) => onValueChange(e.target.value, e)}
          type="search"
        />
        {inputValue && (
          <button className={clearButton} onClick={onClearClick} type="button">
            <ClearIcon width={24} height={24} className={clearIcon} />
          </button>
        )}
      </div>
      {suffixNode && <div className={suffixContainer}>{suffixNode}</div>}
    </div>
  );
};
