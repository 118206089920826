import {
  cn,
  SidebarMenu,
  SidebarMenuItem,
  useSidebar,
} from '@cointracker/styleguide';
import { Body } from '@cointracker/styleguide/src/LoggedIn';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@cointracker/styleguide/src/sidebar/tooltip';
import { CaretRight } from '@phosphor-icons/react';
import { NavLink } from 'react-router-dom';
import { type KnownNavigationItem } from './utils';

interface DemoAreaProps {
  title: string;
  subItems?: KnownNavigationItem[];
  className?: string;
}

export function DemoArea({ title, subItems, className }: DemoAreaProps) {
  const { state, isMobile, setOpenMobile } = useSidebar();
  return (
    <SidebarMenu
      className={cn(
        'relative gap-20 rounded-12 bg-background-card-highlight py-4 transition-all',
        className,
      )}
    >
      <SidebarMenuItem className="text-center">
        <Body variant="body5">{title}</Body>
      </SidebarMenuItem>
      {subItems?.map((item) => (
        <SidebarMenuItem key={item.title}>
          <NavLink
            to={item.slug}
            onClick={(e) => {
              !item.slug && e.preventDefault();
              if (isMobile) {
                setOpenMobile(false);
              }
            }}
            aria-label={item.title}
            className={cn(
              'flex h-22 cursor-pointer flex-row place-items-center gap-16 text-text-secondary hover:text-text-primary-hover active:text-accent-bold-blue md:gap-8',
              item.isActive && 'text-text-primary',
            )}
          >
            <Tooltip>
              <TooltipTrigger>
                {item.icon({
                  weight: item.isActive ? 'fill' : 'regular',
                })}
              </TooltipTrigger>
              <TooltipContent
                side="right"
                align="center"
                className={state === 'expanded' ? 'hidden' : ''}
              >
                <Body
                  className="text-text-primary-foreground-inverse"
                  variant="body5"
                >
                  {item.title}
                </Body>
                <TooltipArrow width={11} height={5} />
              </TooltipContent>
            </Tooltip>
            <Body
              variant={isMobile ? 'body1' : 'body3'}
              className={cn(
                'whitespace-nowrap transition-opacity group-data-[state=collapsed]:opacity-0',
                // fade animation timing
                'delay-300 duration-150 ease-in group-data-[state=collapsed]:delay-0 group-data-[state=collapsed]:ease-out',
              )}
            >
              {item.title}
            </Body>
            <CaretRight
              className="ml-auto text-text-primary-foreground md:hidden"
              weight="bold"
              size={20}
            />
          </NavLink>
        </SidebarMenuItem>
      ))}
    </SidebarMenu>
  );
}
