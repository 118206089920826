import classNames from 'classnames';
import { useState, type ReactNode } from 'react';
import { NavLink } from '../../NavLink';
import LeftArrow from '../../icons/arrow-left.svg?react';
import { Body3 } from '../../typography';
import {
  leftArrow,
  menuList,
  subMenuContent,
  subMenuHeader,
} from './MobileSubmenuNavLink.css';

interface MobileSubmenuNavLinkProps {
  text: ReactNode;
  menuTitle: string;
  children: ReactNode;
}
export const MobileSubmenuNavLink = ({
  text,
  menuTitle,
  children,
}: MobileSubmenuNavLinkProps) => {
  const [isOpen, setOpen] = useState(false);
  const onToggle = () => {
    setOpen(!isOpen);
  };
  return (
    <>
      <NavLink withIcon onClick={onToggle}>
        {text}
      </NavLink>
      <div className={classNames(subMenuContent, isOpen ? 'open' : '')}>
        <header className={subMenuHeader}>
          <LeftArrow className={leftArrow} onClick={onToggle} />
          <Body3 caps>{menuTitle}</Body3>
        </header>
        <div className={menuList}>{children}</div>
      </div>
    </>
  );
};
