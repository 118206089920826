import { type HTMLAttributes } from 'react';
import { cn } from '../../utils';
import { Heading } from '../Typography';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export const PageContainer = ({
  children,
  className,
  ...props
}: PageContainerProps) => {
  return (
    <div
      className={cn('mx-auto min-h-screen w-full max-w-screen-xl', className)}
      {...props}
    >
      <div className="mx-16 sm:mx-32 lg:mx-48 2xl:mx-64">{children}</div>
    </div>
  );
};

interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}
export const PageHeader = ({
  children,
  className,
  ...props
}: PageHeaderProps) => {
  return (
    <div
      className={cn(
        'md:min-h-46 mb-24 mt-48 flex flex-col gap-8 sm:flex-row md:items-center md:gap-16',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

interface PageTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}
export const PageTitle = ({
  children,
  className,
  as,
  ...props
}: PageTitleProps) => {
  return (
    <Heading variant="h5" className={cn(className)} as={as} {...props}>
      {children}
    </Heading>
  );
};
