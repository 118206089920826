import {
  TaskStatus,
  useGetSpamInfoForSideBarQuery,
  useGetTaxInfoForSideBarQuery,
} from '@cointracker/graphql-types';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo } from 'react';
import { UserContext } from '../../../../app/user/context';
import { useTaxReadinessStore } from '../TaxReadinessSection/utils';

interface TaxReadinessResult {
  currentActiveStepNumber: number;
  completedStepsCount: number;
  addWalletsStepChecked: boolean;
  syncWalletsStepChecked: boolean;
  optimizeTaxSavingsStepChecked: boolean;
  reviewTaxFormsStepChecked: boolean;
  syncIssuesCount: number;
  automaticallySyncedWalletsCount: number;
  errorsCount: number;
  transactionCount: number;
  walletsCount: number;
  taxPlanShouldRenew: boolean;
  autoResolvedSpamAssetsCount: number;
  capitalGain: string;
  taxableIncomeTotal: string;
  estimatedTaxes: string;
  taxYear: number;
  loading: boolean;
}

export const useTaxReadiness = (): TaxReadinessResult => {
  const {
    addWalletsCheckedByUser,
    syncWalletsCheckedByUser,
    optimizeTaxSavingsCheckedByUser,
    reviewTaxFormsCheckedByUser,
    errorsCount,
    syncIssuesCount,
    walletsCount,
    autoResolvedSpamAssetsCount,
    setErrorsCount,
    setSyncIssuesCount,
    setWalletsCount,
    setAutoResolvedSpamAssetsCount,
  } = useTaxReadinessStore();
  const user = useContext(UserContext);

  const taxYear = user.isAuthenticated
    ? user?.displayUser?.taxYearToUse
    : dayjs().year() - 1;

  const { data: taxInfoData, loading } = useGetTaxInfoForSideBarQuery({
    variables: {
      taxYear: taxYear,
      taskStatus: TaskStatus.ToDo,
    },
  });

  const { data: spamInfoData } = useGetSpamInfoForSideBarQuery();

  useEffect(() => {
    if (!taxInfoData?.tasksByType?.tasks) return;
    setSyncIssuesCount(
      taxInfoData.tasksByType.tasks.filter(
        (task) => task?.__typename === 'FixApiCredentialsTask',
      ).length,
    );
  }, [taxInfoData?.tasksByType?.tasks]);

  useEffect(() => {
    if (!taxInfoData?.taxSummary) return;
    setErrorsCount(
      (taxInfoData.taxSummary.calloutCountInsufficientQuantity || 0) +
        (taxInfoData.taxSummary.calloutCountMissingBalance || 0) +
        (taxInfoData.taxSummary.calloutCountMissingPriceHistory || 0),
    );
  }, [taxInfoData?.taxSummary]);

  useEffect(() => {
    if (!taxInfoData?.userStats) return;
    setWalletsCount(
      (taxInfoData.userStats.numberOfExchanges || 0) +
        (taxInfoData.userStats.numberOfLocalWallets || 0) +
        (taxInfoData.userStats.numberOfCustomWallets || 0),
    );
  }, [taxInfoData?.userStats]);

  const automaticallySyncedWalletsCount = walletsCount - syncIssuesCount;

  useEffect(() => {
    setErrorsCount(
      (taxInfoData?.taxSummary?.calloutCountInsufficientQuantity || 0) +
        (taxInfoData?.taxSummary?.calloutCountMissingBalance || 0) +
        (taxInfoData?.taxSummary?.calloutCountMissingPriceHistory || 0),
    );
  }, [taxInfoData?.taxSummary]);

  const transactionCount = taxInfoData?.taxSummary?.transactionCount || 0;

  const addWalletsStepChecked = !!addWalletsCheckedByUser;
  const syncWalletsStepChecked =
    syncWalletsCheckedByUser === null
      ? syncIssuesCount === 0
      : syncWalletsCheckedByUser;
  const optimizeTaxSavingsStepChecked =
    optimizeTaxSavingsCheckedByUser === null
      ? errorsCount === 0
      : optimizeTaxSavingsCheckedByUser;
  const reviewTaxFormsStepChecked = !!reviewTaxFormsCheckedByUser;

  const stepCompletionStatus = useMemo(
    () => [
      addWalletsStepChecked,
      syncWalletsStepChecked,
      optimizeTaxSavingsStepChecked,
      reviewTaxFormsStepChecked,
    ],
    [
      addWalletsStepChecked,
      syncWalletsStepChecked,
      optimizeTaxSavingsStepChecked,
      reviewTaxFormsStepChecked,
    ],
  );

  const [currentActiveStepNumber, completedStepsCount] = useMemo(() => {
    const index = stepCompletionStatus.findIndex((step) => !step);
    return [
      index === -1 ? 4 : index + 1,
      stepCompletionStatus.filter(Boolean).length,
    ];
  }, [stepCompletionStatus]);

  const taxPlanShouldRenew = Boolean(
    taxInfoData?.taxSummary?.taxPlan &&
      taxInfoData?.unifiedSubscription?.recommendedPlanForTax,
  );

  useEffect(() => {
    if (!spamInfoData?.spamAssets) return;
    setAutoResolvedSpamAssetsCount(
      spamInfoData.spamAssets.filter(
        (spamAsset) => spamAsset?.wasAutomarkedAsSpam || spamAsset?.isSpam,
      ).length,
    );
  }, [spamInfoData?.spamAssets]);

  const capitalGain = taxInfoData?.taxSummary?.capitalGain || '0';
  const taxableIncomeTotal = taxInfoData?.taxSummary?.taxableIncomeTotal || '0';

  const estimatedTaxes = taxInfoData?.taxSummary?.estimatedTaxes || '0';

  return {
    currentActiveStepNumber,
    completedStepsCount,
    addWalletsStepChecked,
    syncWalletsStepChecked,
    optimizeTaxSavingsStepChecked,
    reviewTaxFormsStepChecked,
    syncIssuesCount,
    automaticallySyncedWalletsCount,
    errorsCount,
    transactionCount,
    walletsCount,
    taxPlanShouldRenew,
    autoResolvedSpamAssetsCount: autoResolvedSpamAssetsCount,
    capitalGain,
    taxableIncomeTotal,
    estimatedTaxes,
    taxYear,
    loading,
  };
};
