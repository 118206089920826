import { ReactNode, useEffect, useMemo } from 'react';
import usePrevious from 'src/hooks/usePrevious';
import { useWindowFocus } from 'src/hooks/useWindowFocus';
import {
  UserFreezingStatus,
  useGetFreezingStatusQuery,
} from 'src/types/graphql-types';
import { useVariableSpeedPolling } from '../CostBasisProvider/hooks/useVariableSpeedPolling';
import { FreezingStatusContext } from './context';

export function FreezingStatusProvider({ children }: { children: ReactNode }) {
  const { data, startPolling, stopPolling, refetch } =
    useGetFreezingStatusQuery();

  const { isWindowFocused } = useWindowFocus();
  const { triggerFasterPolling, stopFasterPolling } = useVariableSpeedPolling({
    pollingFn: () => {
      if (!isWindowFocused) {
        return;
      }
      refetch();
    },
  });

  const previousWindowFocused = usePrevious(isWindowFocused);
  useEffect(() => {
    if (!previousWindowFocused && isWindowFocused) {
      triggerFasterPolling();
      return;
    }

    if (!isWindowFocused) {
      stopFasterPolling();
    }
  }, [
    previousWindowFocused,
    isWindowFocused,
    triggerFasterPolling,
    stopFasterPolling,
  ]);

  const isFreezingOperationInProgress = useMemo(() => {
    switch (data?.userInfo?.freezing?.status) {
      case UserFreezingStatus.FreezingInProgress:
      case UserFreezingStatus.UnfreezingInProgress:
        return true;
      default:
        return false;
    }
  }, [data]);

  const prevIsFreezingOperationInProgress = usePrevious(
    isFreezingOperationInProgress,
  );

  useEffect(() => {
    const shouldStartPolling =
      !prevIsFreezingOperationInProgress && isFreezingOperationInProgress;
    stopPolling(); // this is in case the gate value flips, to clean up any polling that might be happening
    if (shouldStartPolling) {
      triggerFasterPolling();
    }
    if (!isFreezingOperationInProgress) {
      stopFasterPolling();
    }
  }, [
    data,
    isFreezingOperationInProgress,
    prevIsFreezingOperationInProgress,
    startPolling,
    stopFasterPolling,
    stopPolling,
    triggerFasterPolling,
  ]);

  return (
    <FreezingStatusContext.Provider
      value={{
        isAvailable: data?.userInfo?.freezing?.isAvailable,
        startTime: data?.userInfo?.freezing?.startTime,
        endTime: data?.userInfo?.freezing?.endTime,
        lastUpdatedAt: data?.userInfo?.freezing?.lastUpdatedAt,
        status: data?.userInfo?.freezing?.status,
        error: data?.userInfo?.freezing?.error,
        startFasterPollingForSyncCompletion: triggerFasterPolling,
      }}
    >
      {children}
    </FreezingStatusContext.Provider>
  );
}
