import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type HTMLAttributes } from 'react';
import { cn } from '../../utils';
import { numberVariants, type NumberVariants } from './variants';

export interface NumberProps
  extends HTMLAttributes<HTMLHeadingElement>,
    NumberVariants {
  asChild?: boolean;
}

export const Number = forwardRef<HTMLHeadingElement, NumberProps>(
  ({ className, variant, asChild = false, as, ...props }, ref) => {
    let Comp = asChild ? Slot : as;
    if (!Comp) {
      switch (variant) {
        case 'h3':
          Comp = 'h3';
          break;
        case 'h4':
          Comp = 'h4';
          break;
        case 'h5':
          Comp = 'h5';
          break;
        case 'h6':
          Comp = 'h6';
          break;
        default:
          Comp = 'p';
          break;
      }
    }
    return (
      <Comp
        ref={ref}
        className={cn(numberVariants({ className, variant, as }))}
        {...props}
      />
    );
  },
);

Number.displayName = 'Number';
