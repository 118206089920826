export const GATES = {
  partnership_landing_connect_upsell_cohort_2:
    'partnership_landing_connect_upsell_cohort_2',
  partnership_landing_connect_upsell: 'partnership_landing_connect_upsell',
  landing_page_v2: 'landing_page_v2',
  show_review_balance_differences: 'show_review_balance_differences',
  show_review_spam: 'show_review_spam',
  show_cheaper_prime_banner: 'show_cheaper_prime_banner',
  show_taxes_complete_celebration_modal:
    'show_taxes_complete_celebration_modal',
  enable_2024_csv_reports: 'enable_2024_csv_reports',
  incremental_reads: 'incremental_reads',
  early_bird_discount_2025: 'early_bird_discount_2025',
  audit_trail_access: 'audit_trail_access',
  welcome_back_30_2025: 'welcome_back_30_2025',
};
