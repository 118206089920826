import { GetWalletsForWalletListQuery } from 'src/types/graphql-types';

export function getWalletInstructions(slug: string, supportsHdWallet: boolean) {
  if (slug === 'ethereum') {
    return {
      description: 'Public address or ENS domain',
      placeholder: 'Paste a public address or ENS domain...',
    };
  } else if (slug === 'cardano') {
    return {
      description: 'Public address or stake key',
      placeholder: 'Paste a public address or stake key...',
    };
  } else if (supportsHdWallet) {
    return {
      description: 'Public address or xPub/yPub/zPub key',
      placeholder: 'Paste a public address or xPub/yPub/zPub key...',
    };
  } else {
    return {
      description: 'Public address',
      placeholder: 'Paste a public address...',
    };
  }
}

export const emptyCacheQuery: GetWalletsForWalletListQuery = {
  localWallets: [],
  exchanges: [],
  customWallets: [],
  otherTransactions: undefined,
};

const isPrivateKeyIntegration = (integrationSlug: string) => {
  return integrationSlug === 'kraken' || integrationSlug === 'robinhood';
};

export const formatAPISecretLabel = (integrationSlug: string) => {
  if (isPrivateKeyIntegration(integrationSlug)) {
    return 'Private Key';
  } else {
    return 'API Secret';
  }
};

// CT database slug to hexidecimal chainId standard. See https://chainlist.org/
export enum chainIds {
  ethereum = '0x1',
  'binance-smart-chain' = '0x38',
  optimism = '0x7E82',
  'arbitrum-one' = '0xa4b1',
  polygon = '0x89',
  'ethereum-classic' = '0x3d',
  avalanche = '0xA86A',
  fantom = '0xFA',
  moonriver = '0x505',
  'huobi-token' = '0x80',
}
