import { cn } from '@cointracker/styleguide';
import React, { forwardRef, MouseEventHandler, Ref } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

interface NavLinkStateProps {
  keepScrollPosition?: boolean;
}
export interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: string;
  rel?: string;
  replace?: boolean;
  state?: NavLinkStateProps;
  className?: string;
}

export const NavLink = forwardRef(
  (props: NavLinkProps, ref: Ref<HTMLAnchorElement>) => {
    return (
      <RouterNavLink
        {...props}
        ref={ref}
        className={cn('block', props.className)}
      />
    );
  },
);

NavLink.displayName = 'NavLink';

export default NavLink;
