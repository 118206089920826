import { forwardRef, type Ref } from 'react';

import classNames from 'classnames';
import { Grid } from '../Grid';
import { Body2 } from '../typography';
import {
  container,
  profileCard,
  styledImage,
  styledTitle,
  textContainer,
  title,
} from './Profile.css';

interface ProfileCardProps {
  className?: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
}

export const ProfileCard = forwardRef(
  (props: ProfileCardProps, ref: Ref<HTMLDivElement>) => {
    const { imgSrc, imgAlt, title, className, ...rest } = props;
    return (
      <div className={profileCard} ref={ref} {...rest}>
        <img
          className={classNames(styledImage, className)}
          src={imgSrc}
          alt={imgAlt}
          {...rest}
        />
        <div className={textContainer}>
          <Body2 className={styledTitle}>{title}</Body2>
        </div>
      </div>
    );
  },
);

ProfileCard.displayName = 'ProfileCard';

export const ProfileTitle = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { children, ...rest } = props;
    return (
      <Grid className={title} ref={ref} {...rest}>
        {children}
      </Grid>
    );
  },
);

ProfileTitle.displayName = 'ProfileTitle';

export const ProfileBlock = forwardRef(
  (props: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => {
    const { children, ...rest } = props;
    return (
      <Grid className={container} ref={ref} {...rest}>
        {children}
      </Grid>
    );
  },
);

ProfileBlock.displayName = 'ProfileBlock';
