import { ApolloProvider } from '@apollo/client';
import '@cointracker/styleguide/src/globals.css';
import {
  BrowserTracing,
  init as SentryInit,
  reactRouterV6Instrumentation,
} from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { client } from './app/ApolloClient';
import App from './app/App';
import { SENTRY_DENY_URLS, SENTRY_IGNORE_ERRORS } from './app/constants';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statsig: any;
  }
}

const isProduction = import.meta.env.PROD;
if (isProduction) {
  SentryInit({
    dsn: 'https://b4a0da007b9e4d8d9f943fdc046b5b72@o371812.ingest.sentry.io/5185372',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
);
