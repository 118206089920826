import { cn, Tooltip } from '@cointracker/styleguide';
import { Copy } from '@phosphor-icons/react';
import { useCallback, useRef, useState } from 'react';

interface PublicKeyCopyButtonProps {
  publicKey?: string;
}
export default function PublicKeyCopyButton({
  publicKey,
}: PublicKeyCopyButtonProps) {
  const [showCopied, setShowCopied] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const onCopy = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(publicKey);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    },
    [publicKey],
  );

  if (!publicKey) {
    return null;
  }

  return (
    <Tooltip
      content={showCopied ? 'Copied' : 'Click to copy'}
      side="bottom"
      onPointerDownOutside={(event) => {
        // prevent default here will prevent the tooltip from closing when clicking on the button
        if (!triggerRef.current) {
          return;
        }
        if (
          event.target === triggerRef.current ||
          triggerRef.current.contains(event.target as Node)
        )
          event.preventDefault();
      }}
    >
      <button
        onClick={onCopy}
        ref={triggerRef}
        className={cn(
          'inline-flex max-w-[100px] items-center gap-4 overflow-hidden text-ellipsis whitespace-nowrap align-middle text-text-secondary hover:text-text-link active:text-text-link sm:max-w-[120px] md:max-w-[140px] lg:max-w-[160px] xl:max-w-[180px]',
        )}
      >
        <Copy size={16} />
      </button>
    </Tooltip>
  );
}
