import * as Toast from '@radix-ui/react-toast';
import { cx } from 'class-variance-authority';
import { useCallback, useContext, useState } from 'react';
import { UserContext } from 'src/app/user/context';

// Define a prefix for all localStorage keys to avoid collisions
const STORAGE_PREFIX = 'ct_toast_';

export interface OfferToastProps {
  children: React.ReactNode;
  showOnceWithKey?: string;
  duration?: number;
  className?: string;
  onOpenChange?: (open: boolean) => void;
}

export function OfferToast({
  children,
  showOnceWithKey,
  duration = 600000,
  onOpenChange: originalOnOpenChange,
  className,
}: OfferToastProps) {
  const user = useContext(UserContext);

  const [hasBeenShown, setHasBeenShown] = useState(() => {
    if (!showOnceWithKey) return false;
    const storageKey = `${STORAGE_PREFIX}${showOnceWithKey}`;
    try {
      return localStorage.getItem(storageKey) === 'true';
    } catch (error) {
      console.error('Failed to read localStorage:', error);
      return true;
    }
  });

  const [open, setOpen] = useState(true);

  const handleOpenChange = useCallback(
    (openState: boolean) => {
      setOpen(openState);
      if (!openState && showOnceWithKey && !hasBeenShown) {
        const storageKey = `${STORAGE_PREFIX}${showOnceWithKey}`;
        try {
          localStorage.setItem(storageKey, 'true');
          setHasBeenShown(true);
        } catch (error) {
          console.error('Failed to set localStorage item:', error);
        }
      }
      originalOnOpenChange?.(openState);
    },
    [showOnceWithKey, hasBeenShown, originalOnOpenChange],
  );

  if (!user.isAuthenticated || hasBeenShown) {
    return null;
  }

  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        open={open}
        onOpenChange={handleOpenChange}
        className={cx(
          'flex h-fit items-stretch gap-12 rounded-8 border border-line-card p-16 shadow-elevation-base',
          className,
        )}
        duration={duration}
      >
        {children}
      </Toast.Root>

      <Toast.Viewport className="fixed bottom-48 left-1/2 z-[2147483647] m-0 flex max-w-full -translate-x-1/2 list-none flex-col gap-2 p-6 outline-none md:left-auto md:right-16 md:top-16 md:translate-x-0" />
    </Toast.Provider>
  );
}
