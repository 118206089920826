import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, Fragment, type Ref } from 'react';
import { Body1, Body3 } from '../LoggedIn';
import { cn } from '../utils';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverClose = PopoverPrimitive.Close;

export const PopoverTitle = Body1;
export const PopoverBody = Body3;

export type PopoverContentProps = PopoverPrimitive.PopoverContentProps &
  PopoverVariants & {
    withArrow?: boolean;
    withPortal?: boolean;
  };

export const PopoverContent = forwardRef(
  (
    {
      children,
      withArrow = false,
      variant = 'white',
      className,
      withPortal = true,
      ...props
    }: PopoverContentProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardedRef: Ref<any>,
  ) => {
    const Wrapper = withPortal ? PopoverPrimitive.Portal : Fragment;
    return (
      <Wrapper>
        <PopoverPrimitive.Content
          {...props}
          ref={forwardedRef}
          className={cn(
            popoverVariants({ variant }),
            'group/popover-content',
            className,
          )}
        >
          {children}
          {withArrow && (
            <PopoverPrimitive.Arrow
              className={cn({
                'fill-line-primary': variant === 'purple',
                'fill-line-secondary': variant === 'white',
              })}
              asChild
              height={8}
              width={8}
            >
              <span
                className={cn(
                  'relative z-10 h-8 w-8 rotate-[315deg] transform',
                  'group-data-[side=bottom]/popover-content:top-[-4.2px] group-data-[side=bottom]/popover-content:border-b group-data-[side=bottom]/popover-content:border-l',
                  'group-data-[side=top]/popover-content:bottom-[4.2px] group-data-[side=top]/popover-content:border-b group-data-[side=top]/popover-content:border-l',
                  {
                    'bg-background-pop-up border-line-primary':
                      variant === 'white',
                    'bg-background-card-highlight border-line-card':
                      variant === 'purple',
                  },
                )}
              />
            </PopoverPrimitive.Arrow>
          )}
        </PopoverPrimitive.Content>
      </Wrapper>
    );
  },
);
PopoverContent.displayName = 'PopoverContent';

export type PopoverVariants = VariantProps<typeof popoverVariants>;

const popoverVariants = cva(
  [
    'rounded-8 shadow-elevation-1 z-10 flex max-w-[342px] flex-col gap-12 border p-16',
    'data-[state=delayed-open]:animate-in',
  ],
  {
    variants: {
      variant: {
        white: 'border-line-primary bg-background-pop-up',
        purple: 'border-line-secondary bg-background-card-highlight',
      },
    },
    defaultVariants: {
      variant: 'white',
    },
  },
);
